import { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import orderBy from 'lodash/orderBy';

import { DeletePurchaseOrderShipmentPayload } from 'api/purchaseOrders/types';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { Carriers, OrderItem, Shipment } from 'types/order.types';

import { TableDropdownCell } from 'components/shared/table/TableDropdownCell';
import { OrderItemsReviewer } from 'components/shared/OrderItemsReviewer';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { NestedTable } from 'components/shared/table/NestedTable';
import { deletePuchaseOrderShipment } from 'api/purchaseOrders';
import { CreateShipmentForm } from './form/CreateShipmentForm';
import { UpdateShipmentForm } from './form/UpdateShipmentForm';
import { ShipmentHistoryModal } from './ShipmentHistoryModal';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { DownArrowIcon } from 'assets/icons/DownArrow';
import { useOrderStore } from 'stores/OrderStore';
import { EditIcon } from 'assets/icons/EditIcon';
import { DragIcon } from 'assets/icons/DragIcon';
import { Table } from 'components/shared/table';
import useTableData from 'hooks/useTableData';
import { getOrderedItem } from 'util/orders';
import { formatDate } from 'util/dates';
import { formatUom } from 'util/mix';

interface Props {
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

export const ShipmentTable = ({ dragHandleProps }: Props) => {
  const [shipmentForUpdate, setShipmentForUpdate] = useState('');
  const [activeShipment, setActiveShipment] = useState<Shipment | null>(null);
  const [itemsReviewer, setItemsReviewer] = useState<OrderItem[]>([]);
  const [isOpenCreateForm, setIsOpenCreateForm] = useState(false);
  const [cellID, setCellID] = useState('');
  const [collapsed, setCollapsed] = useState<string[]>([]);
  const [nestedCollapsed, setNestedCollapsed] = useState<string[]>([]);

  const { order, setOrder } = useOrderStore();
  const allData = orderBy(order.shipments || [], (e) => e.eta || e.originalETA);

  const { meta, tableData } = useTableData({ allData });
  const { mutate: deleteInvoice } = useMutation(deletePuchaseOrderShipment);

  const dotsRef = useRef(null);
  useOutsideClick(dotsRef, () => cellID && setCellID(''));

  const toggleCollapse = (id: string) => setCollapsed((p) => (p.includes(id) ? p.filter((e) => e !== id) : [...p, id]));
  const toggleNestedCollapse = (id: string) =>
    setNestedCollapsed((p) => (p.includes(id) ? p.filter((e) => e !== id) : [...p, id]));

  const onDelete = () => {
    const payload: DeletePurchaseOrderShipmentPayload = {
      orgID: order.orgID,
      orderID: order.orderID,
      shipmentID: cellID.replace(/:.*/, '')
    };

    deleteInvoice(payload, {
      onSuccess: (res) => {
        setOrder(res);
        setCellID('');
      }
    });
  };

  const getAllTrackingNumbers = (shipment: Shipment) => {
    const res = [
      {
        status: shipment.historyStatus[shipment.historyStatus.length - 1]?.status || shipment.status,
        eta: shipment.originalETA || shipment.eta,
        shipmentID: shipment.shipmentID,
        trackingNumber: shipment.trackingNumber,
        carrier: shipment.carrier
      }
    ];

    if (shipment.subshipments)
      shipment.subshipments.forEach((el) =>
        res.push({
          shipmentID: el.shipmentID,
          trackingNumber: el.trackingNumber,
          eta: el.originalETA || el.eta,
          carrier: el.carrier as Carriers,
          status: el.status
        })
      );

    return res;
  };

  return (
    <>
      <Table
        name="OrderShipments"
        title={
          <>
            <span {...dragHandleProps}>
              <DragIcon />
            </span>
            Tracking Numbers
          </>
        }
        nav={[
          'Seller',
          "Tracking #'s",
          'Tracking status',
          '# Items',
          'QTY',
          'Est. Delivery Date',
          'Item Level Tracking',
          '',
          ' '
        ]}
        meta={meta}
        allData={allData}
        headerContent={
          <div className="btns">
            <button className="outlined lg" onClick={() => setIsOpenCreateForm(true)}>
              Create Shipment
            </button>
          </div>
        }
        content={
          <div className="tableBody nested">
            {tableData.map((shipment: Shipment) => {
              const status = shipment.historyStatus[shipment.historyStatus.length - 1]?.status || '-';
              const eta = shipment.originalETA || shipment.eta;

              return (
                <div key={shipment.shipmentID} className="groupWithChildren">
                  <div className="tableRow">
                    <TableCellTooltip content={shipment.provider} />
                    <TableCellTooltip content={getAllTrackingNumbers(shipment).length} />
                    <div className="tableCell">
                      <div className={`badge ${status}`}>{status}</div>
                    </div>
                    <TableCellTooltip content={shipment.items.length} />
                    <TableCellTooltip content={shipment.items.reduce((acc, item) => acc + item.quantity, 0)} />
                    <TableCellTooltip content={eta ? formatDate(eta) : '-'} />
                    <TableCellTooltip content={shipment.subshipments ? 'Yes' : 'No'} />
                    <TableDropdownCell id={shipment.shipmentID} active={cellID} onClick={setCellID} ref={dotsRef}>
                      <li onClick={() => setShipmentForUpdate(shipment.shipmentID)}>Update shipment</li>
                      <li className="danger" onClick={onDelete}>
                        Delete Shipment
                      </li>
                    </TableDropdownCell>
                    <div className="tableCell">
                      <div className="tableDownArrow" onClick={() => toggleCollapse(shipment.shipmentID)}>
                        <DownArrowIcon />
                      </div>
                    </div>
                  </div>
                  {collapsed.includes(shipment.shipmentID) && (
                    <NestedTable
                      className="tableOrderShipmentItems"
                      nav={[
                        'Item',
                        'Seller SKU',
                        'MFR Code',
                        'Packaging',
                        'QTY Ordered',
                        'QTY Applied',
                        'QTY Left',
                        ''
                      ]}
                    >
                      {shipment.items.map((item) => {
                        const orderedQty = getOrderedItem(order.items, item)?.quantity || 1;

                        return (
                          <div key={item.item.objectID}>
                            <div className="tableRow">
                              <TableCellTooltip content={item.item.title} />
                              <TableCellTooltip content={item.price.sku} />
                              <TableCellTooltip content={item.item.manufacturerCode} />
                              <TableCellTooltip content={formatUom(item.item, false)} />
                              <TableCellTooltip content={orderedQty} />
                              <TableCellTooltip content={item.quantity} />
                              <TableCellTooltip content={orderedQty - item.quantity} />
                              <div className="tableCell">
                                <div
                                  className="tableDownArrow"
                                  onClick={() => toggleNestedCollapse(`${shipment.shipmentID}-${item.item.objectID}`)}
                                >
                                  <DownArrowIcon />
                                </div>
                              </div>
                            </div>
                            {nestedCollapsed.includes(`${shipment.shipmentID}-${item.item.objectID}`) && (
                              <NestedTable
                                className="tableNestedTrackingNumbers"
                                nav={['#', 'Tracking #', 'Carrier', 'Est. Delivery Date', 'Status', 'Edit']}
                              >
                                {getAllTrackingNumbers(shipment).map((el: any, elI: number) => (
                                  <div key={`${shipment.shipmentID}-${item.item.objectID}`}>
                                    <div className="tableRow">
                                      <TableCellTooltip content={`${elI + 1}.`} />
                                      <TableCellTooltip content={el.trackingNumber} />
                                      <TableCellTooltip content={el.carrier} />
                                      <TableCellTooltip content={el.eta ? formatDate(el.eta) : '-'} />
                                      <div className="tableCell">
                                        <div className={`badge ${el.status}`}>{el.status}</div>
                                      </div>
                                      <EditIcon onClick={() => setShipmentForUpdate(el.shipmentID)} />
                                    </div>
                                  </div>
                                ))}
                              </NestedTable>
                            )}
                          </div>
                        );
                      })}
                    </NestedTable>
                  )}
                </div>
              );
            })}
          </div>
        }
      />
      {isOpenCreateForm && <CreateShipmentForm close={() => setIsOpenCreateForm(false)} />}
      {activeShipment && <ShipmentHistoryModal close={() => setActiveShipment(null)} shipment={activeShipment} />}
      {itemsReviewer.length ? <OrderItemsReviewer items={itemsReviewer} close={() => setItemsReviewer([])} /> : null}
      {shipmentForUpdate && (
        <UpdateShipmentForm shipmentID={shipmentForUpdate} onClose={() => setShipmentForUpdate('')} />
      )}
    </>
  );
};
