import { DownArrowIcon } from 'assets/icons/DownArrow';
import { Select } from 'components/ui/Select';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { FieldError } from './FormikError';

interface Props {
  value: string;
}

export const StateSelect = ({ value }: Props) => {
  const { setFieldValue, errors, touched }: any = useFormikContext();

  const [inputValue, setInputValue] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [allItems, setItems] = useState<string[]>(usStates);

  const onSelect = (item: string) => {
    setFieldValue('state', item);
    setIsOpen(false);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    const result = usStates.filter((el) =>
      el.toLowerCase().includes(input.toLowerCase())
    );
    setInputValue(input);
    setFieldValue('state', input);
    setItems(result);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div className="autocompleteDropdown">
      <label>State</label>
      <Select
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        className={`regular withSearch gray ${
          errors.state && touched.state ? 'invalid' : 'valid'
        }`}
        options={
          allItems.length ? (
            <ul className="customScrollbar">
              {allItems.map((item) => (
                <li key={item} onClick={() => onSelect(item)}>
                  {item}
                </li>
              ))}
            </ul>
          ) : (
            <p className="noResults">No results...</p>
          )
        }
      >
        <input type="text" value={inputValue} onChange={onChange} />
        <DownArrowIcon />
      </Select>
      <FieldError name="state" />
    </div>
  );
};

const usStates = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];
