import { useQuery } from 'react-query';

import { Supplier } from 'types/algolia.types';
import { Org } from 'api/orgs/types';

import { getOrgItemsPrices } from 'api/orgs';
import { orgsKeys } from 'api/orgs/queries';
import { convertPrice } from 'util/prices';

interface Props {
  org?: Org;
  ids: string[];
}

export const useSupplierPrice = ({ org, ids }: Props) => {
  const orgID = org?.metadata.orgID || '';

  const { data: prices, refetch } = useQuery(orgsKeys.prices(orgID, ids), () => getOrgItemsPrices(orgID, ids));

  const isConnected = (kind: Supplier['kind'], name: string) => {
    if (kind === 'PARTNER') return true;
    const cred = org?.credentials.some((el) => el.providerName === name) || false;
    return cred;
  };

  const isPricePending = (id: string, kind: Supplier['kind'], name: string) => {
    return kind === 'PROCUREMENT' && !isConnected(kind, name) && apiPrice(id, name) <= 0;
  };

  const apiPrice = (id: string, name: string) =>
    prices?.find((price) => id === price.variantID && price.partner === name)?.sellingPrice || 0;

  const findPriceVaraintID = (id: string, name: string) =>
    prices?.find((price) => id === price.variantID && price.partner === name)?.variantID;

  const findPricePromotions = (id: string, name: string) =>
    prices?.find((price) => id === price.variantID && price.partner === name)?.promotions;

  const price = (id: string, name: string, sellingPrice: number, customSellingPrice?: number) => {
    const activePrice = apiPrice(id, name);
    if (customSellingPrice && customSellingPrice > 0) return customSellingPrice;
    else if (activePrice > 0) return activePrice;
    else if (sellingPrice > 0) return sellingPrice;
    return 0;
  };

  const displayPrice = (
    id: string,
    kind: Supplier['kind'],
    name: string,
    sellingPrice: number,
    customSellingPrice?: number
  ) => {
    const activePrice = price(id, name, sellingPrice, customSellingPrice);

    if (!isConnected(kind, name)) return 'Not Connected';
    else if (isPricePending(id, kind, name)) return 'Price fetching is in progress';
    else if (activePrice) return convertPrice(activePrice);
    return 'Price Unavailable';
  };

  return {
    isConnected,
    isPricePending,
    price,
    displayPrice,
    findPriceVaraintID,
    findPricePromotions,
    prices,
    refetchPrices: refetch
  };
};
