import { Form, Formik, Field } from 'formik';
import { useMutation } from 'react-query';

import { UpdateUserPayload, User } from 'api/users/types';

import { updateUser } from 'api/users';
import { useState } from 'react';
import { UserImpersonateModal } from 'pages/users/UserImpersonateModal';

interface Props {
  metadata: User['metadata'];
  onSuccess?: () => void;
}

export const UserDetails = ({ metadata, onSuccess }: Props) => {
  const [userForImpersonate, setUserForImpersonate] = useState<User['metadata'] | null>(null);

  const { mutate, isLoading } = useMutation((payload: UpdateUserPayload) => updateUser(metadata.userID, payload));
  const onSubmit = (values: UpdateUserPayload) => {
    if (values.password === '') {
      values.password = undefined;
    }
    mutate(values, { onSuccess });
  };

  return (
    <>
      <Formik
        initialValues={{
          name: metadata.name,
          email: metadata.email,
          phone: metadata.phone,
          confirmed: metadata.confirmed,
          password: undefined
        }}
        onSubmit={onSubmit}
      >
        <Form className="paper">
          <div className="paperHeader">
            <h2>User informations (ID: {metadata.userID})</h2>
            <div className="flexAlign gg8">
              <button type="button" className="outlined lg auto-width" onClick={() => setUserForImpersonate(metadata)}>
                Impersonate User
              </button>
              <button type="submit" className="contained lg auto-width" disabled={isLoading}>
                Save changes
              </button>
            </div>
          </div>
          <div className="paperBody">
            <div className="fields">
              <div className="field">
                <label htmlFor="name">Name</label>
                <Field id="name" name="name" />
              </div>
              <div className="field">
                <label htmlFor="email">Email</label>
                <Field id="email" type="email" name="email" />
              </div>
              <div className="field">
                <label htmlFor="phone">Phone number</label>
                <Field id="phone" name="phone" />
              </div>
              <div className="field">
                <label htmlFor="confirmed">Confirmed</label>
                <Field id="confirmed" type="checkbox" name="confirmed" />
              </div>
              <div className="field">
                <label htmlFor="password">Password</label>
                <Field id="password" type="password" name="password" />
              </div>
            </div>
          </div>
        </Form>
      </Formik>
      <UserImpersonateModal user={userForImpersonate} close={() => setUserForImpersonate(null)} />
    </>
  );
};
