import create from 'zustand';

import { Provider } from 'api/providers/types';

interface State {
  providers: Provider[];
  setProviders: (providers: Provider[]) => void;
}

const useProvidersStrore = create<State>((set) => ({
  providers: [],
  setProviders: (providers: Provider[]) => set({ providers }),
}));

export { useProvidersStrore };
