import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Portal } from 'react-portal';

import { UpdateOrderPayload } from 'api/orders/types';
import { OrderItem } from 'types/order.types';
import { Org } from 'api/orgs/types';

import { MoreItemsState } from 'components/shared/moreItems/AlgoliaHits';
import { OrderItems } from 'components/shared/orgs/OrderItems';
import { MoreItems } from 'components/shared/moreItems';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { useOrderStore } from 'stores/OrderStore';
import { orderSupplierPrice } from 'util/prices';
import { updateOrder } from 'api/orders';
import { Summary } from './Summary';

interface Props {
  org?: Org;
  close: () => void;
}

export const EditOrderItemsModal = ({ org, close }: Props) => {
  const { order, setOrder } = useOrderStore();

  const [addedItems, setAddedItems] = useState<MoreItemsState[]>([]);
  const [payload, setPayload] = useState(order.items);

  useEffect(() => {
    const fillMoreItemsState: MoreItemsState[] = payload.map((item) => ({
      ...item.item,
      supplier: {
        connected: true,
        pricePending: false,
        displayPrice: String(orderSupplierPrice(item.price)),
        price: orderSupplierPrice(item.price),
        name: item.price.provider,
        sku: item.price.sku
      }
    }));

    setAddedItems(fillMoreItemsState);
  }, [payload]);

  const { mutate, isLoading } = useMutation(updateOrder);

  const formatPayload = (item: OrderItem) => ({
    variantID: item.item.objectID,
    providerName: item.price.provider,
    quantity: item.quantity,
    sellingPrice: item.price.sellingPrice,
    buyingPrice: item.price.buyingPrice,
    customSellingPrice: item.price.customSellingPrice,
    promotion: item.price.promotion
  });

  const saveChanges = () => {
    const body: UpdateOrderPayload = {
      orgID: order.orgID,
      orderID: order.orderID,
      body: { items: payload.map(formatPayload) }
    };

    mutate(body, {
      onSuccess(res) {
        close();
        setOrder(res);
      }
    });
  };

  const getQuantity = (el: MoreItemsState) => {
    const stateQty =
      payload.find((item) => item.item.objectID === el.objectID && item.price.provider === el.supplier.name)
        ?.quantity || 1;

    const orderedQty =
      order.items.find((item) => item.item.objectID === el.objectID && item.price.provider === el.supplier.name)
        ?.quantity || 1;

    return stateQty || orderedQty;
  };

  const convertMoreItemsFormatToOrderItemFormat = (items: MoreItemsState[]): OrderItem[] => {
    return items.map(({ supplier, ...rest }) => ({
      item: rest,
      quantity: getQuantity({ ...rest, supplier }),
      backOrder: null,
      cancelled: '',
      price: {
        buyingPrice: supplier.price,
        sellingPrice: supplier.price,
        customSellingPrice: supplier.price,
        provider: supplier.name,
        sku: supplier.sku
      }
    }));
  };

  return (
    <Portal>
      <div className="editOrderModal">
        <div className="editModalHeader">
          <h2>
            Edit order <span>#{order.orderID}</span>
          </h2>
          <CloseIcon onClick={close} />
        </div>
        <div className="editModalBody customScrollbar">
          <div className="leftSide">
            <div className="paper">
              <div className="paperHeader">
                <h2>Search and add products</h2>
              </div>
              <div className="paperBody">
                <MoreItems
                  org={org}
                  addedItems={addedItems}
                  setAddedItems={setAddedItems}
                  callback={(items) => setPayload(convertMoreItemsFormatToOrderItemFormat(items))}
                />
              </div>
            </div>
            <OrderItems orderItems={payload} setOrderItems={setPayload} hasPromotions />
          </div>
          <div className="rightSide">
            <Summary tax={order.tax} orderItems={payload} saveChanges={saveChanges} isLoadingButton={isLoading} />
          </div>
        </div>
      </div>
    </Portal>
  );
};
