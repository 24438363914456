import { useState } from 'react';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';
import { Calendar } from 'react-calendar';
import { useMutation } from 'react-query';

import { CreateFaliamInvoice, PurchaseOrderItemPayload, UpdateFaliamInvoice } from 'api/purchaseOrders/types';
import { FaliamInvoice, Order, ProviderInvoice } from 'types/order.types';
import { OrgOrderItem } from 'types/orgs.types';

import { convertOtherCostsToFormikFormat, setOtherCostsToPayload } from 'util/purchaseOrders';
import { OtherCostsField } from 'pages/order/fullScreenModals/fields/OtherCostsField';
import { createFaliamInvoice, updateFaliamInvoice } from 'api/purchaseOrders';
import { FaliamInvoiceModalTable } from './FaliamInvoiceModalTable';
import { PdfViewer } from 'components/shared/orders/PDF/PdfViewer';
import { AttachPDF } from 'components/shared/orders/PDF/AttachPDF';
import { ProviderInvoicesSelect } from './ProviderInvoicesSelect';
import { getCurrencyValue, roundCurrencyValue } from 'util/mix';
import { CurrencyField } from 'components/ui/CurrencyField';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { useOrderStore } from 'stores/OrderStore';
import { getOrderedItem } from 'util/orders';
import { Modal } from 'components/ui/Modal';
import { formatDate } from 'util/dates';

interface Props {
  providerInvoices: ProviderInvoice[] | null;
  faliamInvoice: FaliamInvoice | null;
  close: () => void;
  refetch: () => void;
}

export interface FaliamInvoiceFormikState {
  invoicesIDS: string[];
  dueDate: Date;
  shippingCosts: number | string;
  taxCosts: number | string;
  otherCosts: Array<{ label: string; price: string | number }>;
  allItems: OrgOrderItem[];
  items: OrgOrderItem[];
}

export const FaliamInvoiceForm = ({ providerInvoices, faliamInvoice, close, refetch }: Props) => {
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState<{
    [key: string]: number;
  }>({});

  const { order, setOrder } = useOrderStore();
  const { mutate: createMutate, isLoading: isLoadingCreate } = useMutation(createFaliamInvoice);
  const { mutate: updateMutate, isLoading: isLoadingUpdate } = useMutation(updateFaliamInvoice);

  const initialValues: FaliamInvoiceFormikState = {
    invoicesIDS: faliamInvoice?.invoiceIDs || [],
    dueDate: faliamInvoice?.due ? new Date(faliamInvoice?.due) : new Date(),
    shippingCosts: faliamInvoice?.shippingCosts ? getCurrencyValue(faliamInvoice.shippingCosts) : '',
    taxCosts: faliamInvoice?.tax ? getCurrencyValue(faliamInvoice.tax) : '',
    otherCosts: convertOtherCostsToFormikFormat(faliamInvoice?.otherCosts),
    allItems: faliamInvoice?.items || [],
    items: faliamInvoice?.items || []
  };

  const getNumberOfQtyPerItem = (item: OrgOrderItem) => {
    const obj = Object.keys(checkedBoxes).map((el) => el.split(':')[0]);

    const sum = obj.reduce((acc: any, val: any) => {
      acc[val] = (acc[val] || 0) + 1;
      return acc;
    }, {});

    return sum[item?.item?.objectID || ''] || 0;
  };

  const getPurchasePayload = (values: FaliamInvoiceFormikState) => {
    const payload: any = {};

    if (values.shippingCosts) payload.shippingCosts = roundCurrencyValue(values.shippingCosts);
    if (values.taxCosts) payload.taxCosts = roundCurrencyValue(values.taxCosts);
    if (values.otherCosts.length && values.otherCosts[0].label)
      payload.otherCosts = setOtherCostsToPayload(values.otherCosts);

    return payload;
  };

  const formatItems = (items: OrgOrderItem[], provider?: string) => {
    const res: PurchaseOrderItemPayload[] = items
      .filter((e) => e.price.provider)
      .map((item) => {
        const orderedItem = getOrderedItem(order.items, item);
        return {
          variantID: item.item.objectID,
          price: orderedItem?.price.customSellingPrice || orderedItem?.price.sellingPrice || 0,
          provider: provider || item.price.provider,
          quantity: getNumberOfQtyPerItem(item)
        };
      });

    return res;
  };

  const onCreateInvoice = (values: FaliamInvoiceFormikState) => {
    const payload: CreateFaliamInvoice = {
      orderID: order.orderID,
      orgID: order.orgID,
      body: {
        providerInvoices: values.invoicesIDS,
        dueDate: dayjs(values.dueDate).format('YYYY-MM-DD'),
        items: formatItems(values.items),
        ...getPurchasePayload(values)
      }
    };

    createMutate(payload, { onSuccess });
  };

  const onUpdateInvoice = (values: FaliamInvoiceFormikState) => {
    if (!faliamInvoice) return;

    const payload: UpdateFaliamInvoice = {
      orderID: order.orderID,
      orgID: order.orgID,
      invoiceID: faliamInvoice.id,
      body: {
        dueDate: dayjs(values.dueDate).format('YYYY-MM-DD'),
        items: formatItems(values.items),
        ...getPurchasePayload(values)
      }
    };

    updateMutate(payload, { onSuccess });
  };

  const onSubmit = (values: FaliamInvoiceFormikState) => {
    if (faliamInvoice) return onUpdateInvoice(values);

    onCreateInvoice(values);
  };

  const onSuccess = (res: Order) => {
    setOrder(res);
    close();
  };

  const totalInvoicePrice = (values: FaliamInvoiceFormikState) => {
    const itemsTotal =
      values.items.reduce((sum, item) => {
        const orderedItem = getOrderedItem(order.items, item);
        const activePrice = orderedItem?.price?.customSellingPrice || orderedItem?.price?.sellingPrice || 0;

        return sum + activePrice * getNumberOfQtyPerItem(orderedItem);
      }, 0) / 100;
    const shippingCosts = Number(values.shippingCosts) || 0;
    const taxCosts = Number(values.taxCosts) || 0;
    const otherCosts = values.otherCosts.reduce((sum, item) => sum + Number(item.price), 0);
    const total = itemsTotal + shippingCosts + taxCosts + otherCosts;

    return Number(total.toFixed(2));
  };

  const subtotalInvoicePrice = (values: FaliamInvoiceFormikState) => {
    const itemsTotal =
      values.items.reduce((sum, item) => {
        const orderedItem = getOrderedItem(order.items, item);
        const activePrice = orderedItem?.price.customSellingPrice || orderedItem?.price.sellingPrice || 0;

        return sum + activePrice * getNumberOfQtyPerItem(orderedItem);
      }, 0) / 100;

    return Number(itemsTotal.toFixed(2));
  };

  return (
    <div className="orderFullScreenModal">
      <div className="paper">
        <div className="paperHeader">
          <h2>{faliamInvoice ? 'Update' : 'Create'} Faliam Invoice</h2>
          <CloseIcon className="closeIcon" onClick={close} />
        </div>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, setFieldValue }) => (
            <Form>
              <div className={`paperBody ${Object.values(faliamInvoice?.files || {}).length ? 'gridX2' : ''}`}>
                {Object.values(faliamInvoice?.files || {}).length ? (
                  <PdfViewer docID={faliamInvoice?.id || ''} files={faliamInvoice?.files || {}} refetch={refetch} />
                ) : null}
                <div>
                  <div className="fielsGroup">
                    {!faliamInvoice && (
                      <ProviderInvoicesSelect order={order} allProviderInvoices={providerInvoices || []} />
                    )}
                    <div className="field">
                      <label>Due Date</label>
                      <div className="fakeField" onClick={() => setIsOpenCalendar(true)}>
                        {formatDate(values.dueDate)}
                      </div>
                      {isOpenCalendar && (
                        <Modal
                          open={true}
                          close={() => setIsOpenCalendar(false)}
                          title="Select Date"
                          className="ordersDatePicker"
                        >
                          <Calendar
                            onChange={(dueDate: Date) => {
                              setFieldValue('dueDate', dueDate);
                              setIsOpenCalendar(false);
                            }}
                            value={values.dueDate}
                          />
                        </Modal>
                      )}
                    </div>
                    <CurrencyField name="shippingCosts" label="Shipping" />
                    <CurrencyField name="taxCosts" label="Tax" />
                    <div className="x2Fields">
                      <CurrencyField
                        name="itemSubtotal"
                        label="Item Subtotal"
                        value={subtotalInvoicePrice(values)}
                        readOnly
                      />
                      <CurrencyField
                        name="invoiceTotal"
                        label="Invoice Total"
                        value={totalInvoicePrice(values)}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="otherCostsRow">
                    <OtherCostsField />
                  </div>
                  {faliamInvoice && <AttachPDF docID={faliamInvoice.id} refetch={refetch} />}
                </div>
              </div>
              <FaliamInvoiceModalTable
                checkedBoxes={checkedBoxes}
                setCheckedBoxes={setCheckedBoxes}
                getNumberOfQtyPerItem={getNumberOfQtyPerItem}
                faliamInvoice={faliamInvoice}
              />
              <div className="submitBtnHolder">
                <button className="contained" type="submit" disabled={isLoadingCreate || isLoadingUpdate}>
                  {faliamInvoice ? 'Update' : 'Create'} Faliam Invoice
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
