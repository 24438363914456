export enum PATHNAMES {
  USERS = '/',
  USER = '/user/:userID',
  ORDERS = '/orders',
  ORDER = '/order/:orgID/:orderID',
  ORGANIZATIONS = '/organizations',
  ORGANIZATION = '/org/:orgID',
  GPO = '/gpo',
  GPO_SINGLE = '/gpo/:gpoID',
  PRODUCTS = '/products',
  MANUFACTURERS = '/manufacturers',
  SUPPLIERS = '/suppliers',
  PRODUCT = '/product/:productID',
  JOBS = '/jobs',
  AUTO_CART_QUEUE = '/order/autocart',
  ORG_HEALTH = 'health/org',
  GPO_HEALTH = 'health/gpo',
  SETTINGS = 'settings'
}
