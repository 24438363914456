import { useMemo } from 'react';
import findIndex from 'lodash/findIndex';

import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { Org, OrgUser } from 'api/orgs/types';

import { TableCellTooltip } from 'components/shared/table/TableCell';
import { useOrderStore } from 'stores/OrderStore';
import { Table } from 'components/shared/table';
import useTableData from 'hooks/useTableData';
import { DragIcon } from 'assets/icons/DragIcon';
import { getOfficeUsers } from 'util/offices';

interface Props {
  org?: Org;
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

export const OfficeUsers = ({ org, dragHandleProps }: Props) => {
  const { order } = useOrderStore();

  const data = useMemo(
    () => getOfficeUsers(order.office.officeID, org?.users || [], org?.officeUsers || []),
    [org, order.office]
  );

  const { meta, tableData } = useTableData({ allData: data });

  const usersForCC = data
    ?.filter((el) => el.userID !== order.creatorInfo.UserID)
    .map((el) => el.email)
    .join(',');

  return (
    <Table
      name="OfficeUsers"
      title={
        <>
          <span {...dragHandleProps}>
            <DragIcon />
          </span>
          Office Users
        </>
      }
      headerContent={
        <a
          target="_blank"
          className="outlined lg"
          rel="noreferrer"
          href={`mailto:${order.creatorInfo.Email}?cc=${encodeURIComponent(usersForCC)}`}
        >
          Send Email
        </a>
      }
      nav={['#', 'Name', 'Email', 'Role']}
      meta={meta}
      allData={data}
      content={
        <div className="tableBody nested">
          {tableData.map((el: OrgUser) => (
            <div key={el.userID} className="tableRow">
              <TableCellTooltip content={findIndex(data, (fi) => fi.userID === el.userID) + 1 + '.'} />
              <TableCellTooltip content={el.name} />
              <TableCellTooltip content={el.email} />
              <TableCellTooltip
                content={el.role.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
              />
            </div>
          ))}
        </div>
      }
    />
  );
};
