import { useEffect, useRef } from 'react'
import { CSVLink } from 'react-csv'
import { CommonPropTypes } from 'react-csv/components/CommonPropTypes'

// This is needed because CSVDownload component in react-csv doesn't support filename prop
export const CSVDownload = (props: CommonPropTypes) => {
  const btnRef = useRef<HTMLElement>(null)
  useEffect(() => btnRef.current?.click(), [btnRef])
  return (
    <CSVLink {...props}>
      <span ref={btnRef} />
    </CSVLink>
  )
}