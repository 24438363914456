import { CardCvcElement } from '@stripe/react-stripe-js';

import { stripeStyle } from './styles';

export const StripeCardCvcField = () => (
  <div className="field">
    <label>CVV</label>
    <CardCvcElement
      options={stripeStyle}
      className="stripeField stripeCardCvcField"
    />
  </div>
);
