import { GpoPartner } from 'api/gpo/types';
import reactSelectStylesConfig from 'lib/react-select';
import ReactSelect from 'react-select';
import { useProvidersStrore } from 'stores/ProvidersStore';
import { useEffect } from 'react';
import { Org } from 'api/orgs/types';
import { OfficesSelect, SelectOption, SelectSetter } from 'components/shared/OfficesSelect';

type Props = {
  setOffice: SelectSetter;
  setProvider: SelectSetter;
  partners?: GpoPartner[];
  provider?: SelectOption;
  office?: SelectOption;
  org?: Org;
};

export const RepresentativeSelects = ({ partners, office, provider, setOffice, setProvider, org }: Props) => {
  const defaultOfficeSelectValue = { label: 'Organization', value: org?.metadata.orgID || '' };

  const { providers } = useProvidersStrore();

  const partnerNames = partners?.map(({ providerName }) => providerName) || [];

  const availableOfflineeRepsProviders = providers.filter((provider) =>
    Boolean(partners)
      ? provider.connections.offline && partnerNames.includes(provider.name)
      : provider.connections.offline
  );

  const providersSelectOptions = availableOfflineeRepsProviders.map((provider) => ({
    value: provider.name,
    label: provider.name
  }));

  useEffect(() => {
    if (org) setOffice(defaultOfficeSelectValue);
  }, [org]);

  return (
    <>
      <div className={`input-field`}>
        <label>Select provider</label>
        <ReactSelect
          options={providersSelectOptions}
          onChange={(option) => setProvider(option as SelectOption)}
          value={provider}
          styles={reactSelectStylesConfig}
          classNames={{ menuList: () => 'shorter' }}
        />
      </div>

      <div className={`input-field`}>
        <label>Select Offices</label>
        <OfficesSelect org={org} setOffice={setOffice} office={office} className="shorter" />
      </div>
    </>
  );
};
