import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import orderBy from 'lodash/orderBy';

import { Org, OrgUser } from 'api/orgs/types';
import { HandleCellOrders } from 'types/table.types';

import { deleteOrgUser } from 'api/orgs';
import { getOrgUserOfficeNames } from 'util/offices';
import { TableDropdownCell } from 'components/shared/table/TableDropdownCell';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { UpdateUserModal } from './UpdateUserModal';
import { Table } from 'components/shared/table';
import useTableData from 'hooks/useTableData';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { UserImpersonateModal } from 'pages/users/UserImpersonateModal';

interface Props {
  org: Org;
  refetchOrg: () => void;
}

export const OrgUsersTable = ({ org, refetchOrg }: Props) => {
  const [selectedCell, setSelectedCell] = useState('');
  const [userForUpdate, setUserForUpdate] = useState('');
  const [data, setData] = useState<OrgUser[]>([]);
  const { meta, tableData } = useTableData({ allData: data });
  const [userToImpersonate, setUserToImpersonate] = useState<OrgUser | null>(null);

  const allSortedData = orderBy(org.users, ['name'], ['asc']);

  const dotsRef = useRef(null);
  useOutsideClick(dotsRef, () => selectedCell && setSelectedCell(''));

  useEffect(() => {
    setData(allSortedData);
  }, [org.users]);

  const { mutate: deleteUser } = useMutation((id: string) => deleteOrgUser(org.metadata.orgID, id));

  const onDelete = (id: string) => {
    deleteUser(id, {
      onSuccess() {
        setSelectedCell('');
        refetchOrg();
      }
    });
  };

  const handleCellOrders = ({ cell, direction }: HandleCellOrders) => {
    let res: any[] = [];

    switch (cell) {
      case 'Name':
        res = orderBy(org.users, 'name', direction);
        break;
      case 'Email':
        res = orderBy(org.users, 'email', direction);
        break;
      case 'Role':
        res = orderBy(org.users, 'role', direction);
        break;
      default:
        res = data;
        break;
    }

    setData(res);
  };

  const displayRole = (role: string): string => {
    if (role === 'RegionalPurchasingTeam') {
      return 'PurchasingTeam';
    }
    return role;
  };

  const displayOffices = (offices: string[]): string => {
    if (offices.length === 0) {
      return '-';
    }
    return `${offices.length}`;
  };

  return (
    <>
      <div className="space" />
      <Table
        name="OrgUsers"
        title="Users"
        nav={['Name', 'Email', 'Role', 'Assigned offices']}
        handleCellOrders={handleCellOrders}
        allData={allSortedData}
        meta={meta}
        setData={setData}
        searchKeys={['name', 'email', 'role']}
        content={
          <div className="tableBody customScrollbar">
            {tableData.map((user: OrgUser) => (
              <div key={user.userID} className="tableRow">
                <TableCellTooltip content={user.name}>
                  <Link to={`/user/${user.userID}`} className="link">
                    {user.name}
                  </Link>
                </TableCellTooltip>
                <TableCellTooltip content={user.email} />
                <TableCellTooltip content={displayRole(user.role)} />
                <TableCellTooltip
                  tip={getOrgUserOfficeNames(org.officeUsers, org.offices, user.userID).join(', ')}
                  content={displayOffices(getOrgUserOfficeNames(org.officeUsers, org.offices, user.userID))}
                />
                <TableDropdownCell id={user.userID} active={selectedCell} onClick={setSelectedCell} ref={dotsRef}>
                  <li onClick={() => setUserToImpersonate(user)}>Impersonate User</li>
                  <li onClick={() => setUserForUpdate(user.userID)}>Update User</li>
                  <li className="danger" onClick={() => onDelete(user.userID)}>
                    Delete User
                  </li>
                </TableDropdownCell>
              </div>
            ))}
          </div>
        }
      />
      {userForUpdate && (
        <UpdateUserModal userID={userForUpdate} close={() => setUserForUpdate('')} refetchOrg={refetchOrg} />
      )}
      <UserImpersonateModal user={userToImpersonate} close={() => setUserToImpersonate(null)} />
    </>
  );
};
