import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import { AlgoliaTableProps } from 'types/table.types';

import { TableBody } from './TableBody';
import { TableSearch } from './TableSearch';
import { TableLegend } from './TableLegend';
import { TableHeader } from '../table/TableHeader';

export const AlgoliaTable = ({
  name,
  nav,
  content,
  hits,
  totalHits = 0,
}: AlgoliaTableProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      <div className={`tableWrapper table${name}`}>
        <TableSearch />
        <div className="table">
          <div className="tableHolder">
            <TableHeader nav={nav} />
            <TableBody hits={hits} content={content} />
          </div>
        </div>
        {hits.length ? <TableLegend totalHits={totalHits} /> : null}
      </div>
      <ReactTooltip place="bottom" />
    </>
  );
};
