import { useState } from 'react';

import { OrderItem } from 'types/order.types';

import { formatUom, groupOrdersBySupplier, showLastPurchased } from 'util/mix';
import { convertPrice, orderItemsTotalPrice, orderSupplierPrice } from 'util/prices';
import { TrashIcon } from 'assets/icons/TrashIcon';
import { useSupplierLogo } from 'hooks/useSupplierLogo';
import { Counter } from 'components/ui/Counter';
import { EditPrice } from 'pages/order/items/editOrderItemsModal/EditPrice';
import { useHistoryItemsStore } from 'stores/HistoryItemsStore';
import { AddPromotionModal } from './AddPromotionModal';

interface Props {
  orderItems: OrderItem[];
  setOrderItems: (items: OrderItem[]) => void;
  canUpdatePrice?: boolean;
  hasPromotions?: boolean;
}

export const OrderItems = ({ orderItems, setOrderItems, canUpdatePrice = true, hasPromotions = false }: Props) => {
  const [itemForEditPrice, setItemForEditPrice] = useState<OrderItem | null>(null);
  const [itemForPromotion, setItemForPromotion] = useState<OrderItem | null>(null);
  const { historyItems } = useHistoryItemsStore();
  const { getSupplierLogo } = useSupplierLogo();

  const onUpdateQuantity = (supplier: string, item: OrderItem['item'], quantity: number) => {
    const cloned = [...orderItems];
    const i = cloned.findIndex((el) => el.item.objectID === item.objectID && el.price.provider === supplier);
    cloned[i] = { ...cloned[i], quantity };
    setOrderItems(cloned);
  };

  const onDeleteItem = (supplier: string, item: OrderItem['item']) => {
    const output = orderItems.filter((el) => el.price.provider !== supplier || el.item.objectID !== item.objectID);
    setOrderItems(output);
  };

  return (
    <>
      <div className="suppliersItemsGroup">
        {groupOrdersBySupplier(orderItems).map(([supplier, items]) => (
          <div key={supplier} className="supplierGroup">
            <div className="supplierGroupHeader">
              <div className="flexAlign gg8">
                <div className="supplierLogoBox">
                  <img src={getSupplierLogo(supplier)} alt={supplier} />
                </div>
                <p>
                  {supplier} ({items.length} items)
                </p>
              </div>
              <span>Total · {convertPrice(orderItemsTotalPrice(items))}</span>
            </div>
            <div className="supplierGroupItems">
              {items.map((item) => (
                <div key={`${supplier}:${item.item.objectID}`} className="item">
                  <div className="itemPhoto">
                    <img src={item.item.images[0]} alt={item.item.title} />
                  </div>
                  <div className="itemContent">
                    <div className="itemDetails">
                      <p>{item.item.title}</p>
                      <span>
                        <b>{convertPrice(orderSupplierPrice(item.price))}</b>
                        {formatUom(item.item)}
                      </span>
                      <span>{showLastPurchased(historyItems, item.item.objectID, item.item.uom)}</span>
                    </div>
                    <div className="itemActions">
                      <div className="actions">
                        <button className="rounded" onClick={() => onDeleteItem(supplier, item.item)}>
                          <TrashIcon />
                        </button>
                        <Counter
                          quantity={item.quantity}
                          onUpdateQuantity={(newQuantity) => onUpdateQuantity(supplier, item.item, newQuantity)}
                        />
                        {canUpdatePrice && (
                          <button type="button" className="outlined lg" onClick={() => setItemForEditPrice(item)}>
                            Edit Price
                          </button>
                        )}
                        {!item.price.promotion && hasPromotions && (
                          <button type="button" className="outlined lg" onClick={() => setItemForPromotion(item)}>
                            Add Promotion
                          </button>
                        )}
                        <p className="price">{convertPrice(orderSupplierPrice(item.price) * item.quantity)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      {itemForEditPrice && (
        <EditPrice
          order={itemForEditPrice}
          items={orderItems}
          setItems={setOrderItems}
          close={() => setItemForEditPrice(null)}
        />
      )}
      {itemForPromotion && (
        <AddPromotionModal
          onSuccess={(payload) => {
            const clonedItems = [...orderItems];
            const i = clonedItems.findIndex(
              (el) =>
                el.item.objectID === itemForPromotion.item.objectID &&
                el.price.provider === itemForPromotion.price.provider
            );
            clonedItems[i] = { ...clonedItems[i], price: { ...clonedItems[i].price, promotion: payload } };
            setOrderItems(clonedItems);
            setItemForPromotion(null);
          }}
          onClose={() => setItemForPromotion(null)}
        />
      )}
    </>
  );
};
