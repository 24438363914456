import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import orderBy from 'lodash/orderBy';
import findIndex from 'lodash/findIndex';

import { DeleteProviderInvoicePayload, PayFaliamInvoicePayload } from 'api/purchaseOrders/types';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { FaliamInvoice, OrderItem } from 'types/order.types';
import { HandleCellOrders } from 'types/table.types';
import { Org } from 'api/orgs/types';

import { PayInvoiceSubmitProps, SelectPayment } from 'components/shared/payments/SelectPayment';
import { TableDropdownCell } from 'components/shared/table/TableDropdownCell';
import { deleteFaliamInvoice, payFaliamInvoice } from 'api/purchaseOrders';
import { OrderItemsReviewer } from 'components/shared/OrderItemsReviewer';
import { getOrderItemsProviders, totalOrderItemsQty } from 'util/orders';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { AttachPDF } from 'components/shared/orders/PDF/AttachPDF';
import { NestedTable } from 'components/shared/table/NestedTable';
import { FaliamInvoiceForm } from './form/FaliamInvoiceForm';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { DownArrowIcon } from 'assets/icons/DownArrow';
import { useOrderStore } from 'stores/OrderStore';
import { Table } from 'components/shared/table';
import useTableData from 'hooks/useTableData';
import { convertPrice } from 'util/prices';
import { formatDate } from 'util/dates';
import { DragIcon } from 'assets/icons/DragIcon';
import { getPDF } from 'api/orders';

interface Props {
  org?: Org;
  refetch: () => void;
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

export const FaliamInvoicesTable = ({ org, refetch, dragHandleProps }: Props) => {
  const { order, setOrder } = useOrderStore();
  const { orgID, orderID, faliamInvoices, payments } = order;
  const [cellID, setCellID] = useState('');
  const [collapsed, setCollapsed] = useState<string[]>([]);
  const [isOpenCreateForm, setIsOpenCreateForm] = useState(false);
  const [itemForEdit, setItemForEdit] = useState<FaliamInvoice | null>(null);
  const [itemForPay, setItemForPay] = useState<string | null>(null);
  const [data, setData] = useState<FaliamInvoice[]>([]);
  const [itemsReviewer, setItemsReviewer] = useState<OrderItem[]>([]);

  const ids = { orgID, orderID };
  const { meta, tableData } = useTableData({ allData: data });
  const { mutate: deleteInvoice } = useMutation(deleteFaliamInvoice);
  const { mutate: payMutate, isLoading } = useMutation(payFaliamInvoice);

  const [pdfParams, setPdfParams] = useState({ orderID, orgID, filename: '', docID: '' });

  const { isFetching: isFetchingPdf, refetch: refetchPdf } = useQuery(['fetch-pdf'], () => getPDF(pdfParams), {
    enabled: false,
    onSuccess: (data) => {
      try {
        if (!data) return;
        if (data.type === 'image') {
          const a = document.createElement('a');
          a.href = data.file as string;
          a.target = '_blank';
          a.download = pdfParams.filename || '';
          a.click();
          return;
        } else {
          const blobUrl = URL.createObjectURL(data.file as any);
          const a = document.createElement('a');
          a.href = blobUrl;
          a.download = pdfParams.filename || '';
          a.click();
        }
      } catch (err) {
      } finally {
        setPdfParams((p) => ({ ...p, filename: '', docID: '' }));
      }
    }
  });

  const dotsRef = useRef(null);
  useOutsideClick(dotsRef, () => cellID && setCellID(''));

  useEffect(() => {
    if (pdfParams.filename && pdfParams.docID) refetchPdf();
  }, [pdfParams]);

  useEffect(() => {
    setData(orderBy(faliamInvoices, 'created'));
  }, [faliamInvoices]);

  useEffect(() => {
    if (itemForEdit) {
      const el = order.faliamInvoices.find((e) => e.id === itemForEdit.id);
      if (el) setItemForEdit(el);
    }
  }, [order, itemForEdit]);

  const onPay = (res: PayInvoiceSubmitProps) => {
    if (!itemForPay) return;

    const payload: PayFaliamInvoicePayload = {
      ...ids,
      invoiceID: itemForPay
    };
    if (res.paymentID) payload.paymentID = res.paymentID;
    if (res.checkInfo) payload.checkInfo = res.checkInfo;

    payMutate(payload, {
      onSuccess: (res) => {
        setItemForPay(null);
        setOrder(res);
      }
    });
  };

  const onDelete = () => {
    const payload: DeleteProviderInvoicePayload = {
      ...ids,
      invoiceID: cellID
    };

    deleteInvoice(payload, {
      onSuccess: (res) => {
        setOrder(res);
        setCellID('');
      }
    });
  };

  const onDownload = async (invoice: FaliamInvoice) => {
    const filename = Object.values(invoice.files)[Object.values(invoice.files).length - 1]?.Filename;
    setPdfParams((p) => ({ ...p, docID: invoice.id, filename }));
  };

  const handleCellOrders = ({ cell, direction }: HandleCellOrders) => {
    let res: any[] = [];
    const d = faliamInvoices || [];

    switch (cell) {
      case 'Order Date':
        res = orderBy(d, (e) => formatDate(e.created), direction);
        break;
      case 'Due Date':
        res = orderBy(d, (e) => formatDate(e.due), direction);
        break;
      case 'Sellers':
        res = orderBy(d, (e) => getOrderItemsProviders(e.items), direction);
        break;
      case 'Invoice #':
        res = orderBy(d, 'id', direction);
        break;
      case '# Items':
        res = orderBy(d, (e) => e.items.length, direction);
        break;
      case 'Qty':
        res = orderBy(d, (e) => totalOrderItemsQty(e.items), direction);
        break;
      case 'Subtotal':
        res = orderBy(d, 'subtotal', direction);
        break;
      case 'Shipping':
        res = orderBy(d, 'shippingCosts', direction);
        break;
      case 'Tax':
        res = orderBy(d, 'tax', direction);
        break;
      case 'Total':
        res = orderBy(d, 'total', direction);
        break;
      default:
        res = data;
        break;
    }

    setData(res);
  };

  const toggleCollapse = (id: string) => setCollapsed((p) => (p.includes(id) ? p.filter((e) => e !== id) : [...p, id]));

  const getPayment = (id: string) => {
    const el = payments.find((e) => e.invoiceID === id);

    if (el?.cardInfo)
      return `Card: ${el.cardInfo.brand}, Exp: ${el.cardInfo.expMonth}/${el.cardInfo.expYear}, Ending: ${el.cardInfo.last4}`;
    else if (el?.checkInfo) {
      return `Check number: ${el.checkInfo.checkNumber}, Date: ${formatDate(el.checkInfo.date)}`;
    }

    return '';
  };

  return (
    <>
      <Table
        name="FaliamInvoices"
        title={
          <>
            <span {...dragHandleProps}>
              <DragIcon />
            </span>
            Faliam Invoices
          </>
        }
        nav={[
          '#',
          'Order Date',
          'Due Date',
          'Sellers',
          'Invoice #',
          '# Items',
          'Qty',
          'Subtotal',
          'Shipping',
          'Tax',
          'Total',
          'Status',
          'Attachment',
          '',
          '.'
        ]}
        meta={meta}
        allData={data}
        handleCellOrders={handleCellOrders}
        headerContent={
          <button className="outlined lg" onClick={() => setIsOpenCreateForm(true)}>
            Create Faliam Invoice
          </button>
        }
        content={
          <div className="tableBody nested">
            {tableData.map((item: FaliamInvoice) => (
              <div key={item.id} className="groupWithChildren">
                <div className="tableRow">
                  <TableCellTooltip content={findIndex(data, (fi) => fi.id === item.id) + 1 + '.'} />
                  <TableCellTooltip content={formatDate(item.created)} />
                  <TableCellTooltip content={formatDate(item.due)} />
                  <TableCellTooltip content={getOrderItemsProviders(item.items).join(', ')} />
                  <TableCellTooltip content={item.id} />
                  <TableCellTooltip content={item.items.length} />
                  <TableCellTooltip content={totalOrderItemsQty(item.items)} />
                  <TableCellTooltip content={convertPrice(item.subtotal)} />
                  <TableCellTooltip content={convertPrice(item.shippingCosts)} />
                  <TableCellTooltip content={convertPrice(item.tax)} />
                  <TableCellTooltip content={convertPrice(item.total)} />
                  <TableCellTooltip content={getPayment(item.id)}>
                    {item.paid ? (
                      <div className="badge success">Paid</div>
                    ) : (
                      <button className="contained sm" onClick={() => setItemForPay(item.id)}>
                        Pay invoice
                      </button>
                    )}
                  </TableCellTooltip>
                  <div className="tableCell">
                    {item.files ? 'Yes' : <AttachPDF docID={item.id} refetch={refetch} />}
                  </div>
                  <TableDropdownCell id={item.id} active={cellID} onClick={setCellID} ref={dotsRef}>
                    <li onClick={() => setItemForEdit(item)}>Update Faliam Invoice</li>
                    <li className={`lg outline ${isFetchingPdf ? 'disabled' : ''}`} onClick={() => onDownload(item)}>
                      {isFetchingPdf ? 'PDF is fetching...' : 'Download Faliam Invoice'}
                    </li>
                    <li className="danger" onClick={onDelete}>
                      Delete Faliam Invoice
                    </li>
                  </TableDropdownCell>
                  <div className="tableCell">
                    <div className="tableDownArrow" onClick={() => toggleCollapse(item.id)}>
                      <DownArrowIcon />
                    </div>
                  </div>
                </div>
                {collapsed.includes(item.id) && (
                  <NestedTable
                    className="tableFaliamInvoicesOrdersItems"
                    nav={['# Item', 'Seller', 'Seller SKU', 'SIUR', 'Qty', 'SIIT', 'Item']}
                  >
                    {item.items.map((item, itemIndex) => (
                      <div key={item.item.objectID} className="tableRow">
                        <TableCellTooltip content={`${itemIndex + 1}.`} />
                        <TableCellTooltip content={item.price.provider} />
                        <TableCellTooltip content={item.price.sku} />
                        <TableCellTooltip content={convertPrice(item.price.sellingPrice)} />
                        <TableCellTooltip content={item.quantity} />
                        <TableCellTooltip content={convertPrice(item.price.buyingPrice * item.quantity)} />
                        <TableCellTooltip content={item.item.title} />
                      </div>
                    ))}
                  </NestedTable>
                )}
              </div>
            ))}
            <div className="tableRow totalRow">
              <div className="tableCell" />
              <div className="tableCell" />
              <div className="tableCell" />
              <div className="tableCell" />
              <div className="tableCell" />
              <div className="tableCell" />
              <div className="tableCell" />
              <TableCellTooltip content={convertPrice(data.reduce((acc, e) => acc + e.subtotal, 0))} />
              <TableCellTooltip content={convertPrice(data.reduce((acc, e) => acc + e.shippingCosts, 0))} />
              <TableCellTooltip content={convertPrice(data.reduce((acc, e) => acc + e.tax, 0))} />
              <TableCellTooltip content={convertPrice(data.reduce((acc, e) => acc + e.total, 0))} />
              <div className="tableCell" />
              <div className="tableCell" />
              <div className="tableCell" />
            </div>
          </div>
        }
      />
      {(isOpenCreateForm || itemForEdit) && (
        <FaliamInvoiceForm
          faliamInvoice={itemForEdit || null}
          providerInvoices={order.providerInvoices}
          close={() => {
            setIsOpenCreateForm(false);
            setItemForEdit(null);
          }}
          refetch={refetch}
        />
      )}
      {itemForPay && (
        <SelectPayment
          payments={org?.payments || []}
          close={() => setItemForPay(null)}
          onSubmit={onPay}
          isLoading={isLoading}
        />
      )}
      {itemsReviewer.length ? <OrderItemsReviewer items={itemsReviewer} close={() => setItemsReviewer([])} /> : null}
    </>
  );
};
