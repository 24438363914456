import { forwardRef } from 'react';

import { DotsIcon } from 'assets/icons/DotsIcon';

interface Props {
  id: string;
  active: string;
  onClick: React.Dispatch<React.SetStateAction<string>>;
  children: any;
}

export const TableDropdownCell = forwardRef(({ id, active, onClick, children }: Props, ref: any) => (
  <div className="tableCell regular">
    <button className="moreBtn" onClick={() => onClick((p) => (p === id ? '' : id))}>
      <DotsIcon />
    </button>
    {id === active && <ul ref={ref}>{children}</ul>}
  </div>
));
