import { useLocation, useNavigate } from 'react-router-dom';

export const useQueryParams = () => {
  const { search, pathname } = useLocation();
  const urlParams = new URLSearchParams(search);
  const navigate = useNavigate();

  const getQueryParam = (key: string) => {
    const value = urlParams.get(key);
    return value ? decodeURIComponent(value) : null;
  };

  const updateQueryParam = (key: string, value: string) => {
    const newParams = new URLSearchParams(urlParams.toString());
    newParams.set(key, encodeURIComponent(value));
    navigate({ pathname, search: newParams.toString() }, { replace: true });
  };

  const deleteQueryParam = (key: string) => {
    const newParams = new URLSearchParams(urlParams.toString());
    newParams.delete(key);
    navigate({ pathname, search: newParams.toString() }, { replace: true });
  };

  // In case we have to delete some params when some other is updated
  const updateCustomParams = (forUpdate: { [key: string]: string }, forDelete: string[]) => {
    const newParams = new URLSearchParams(urlParams.toString());

    for (const key in forUpdate) newParams.set(key, encodeURIComponent(forUpdate[key]));
    for (const key of forDelete) newParams.delete(key);

    navigate({ pathname, search: newParams.toString() }, { replace: true });
  };

  return { getQueryParam, updateQueryParam, deleteQueryParam, updateCustomParams };
};
