import { OrderItem } from 'types/order.types';
import { OrgOrderItem } from 'types/orgs.types';
import { getPromotion } from 'types/promotions.types';

export const convertPrice = (price: number) =>
  (price / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

export const convertRegularPrice = (price: number) =>
  price.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

export const orderSupplierPrice = (item: OrderItem['price'] | OrgOrderItem['price'], quantity?: number) => {
  const price = item.customSellingPrice || item.sellingPrice;
  const qty = quantity || 1;

  if (item.promotion) {
    const promotion = getPromotion(item.promotion.type, item.promotion.data);

    const total = promotion.calculateTotal(price, qty);

    return Math.round(total);
  }

  return price;
};

export const orderItemsTotalPrice = (items: any[]) =>
  items.reduce((sum, item) => sum + orderSupplierPrice(item.price) * item.quantity, 0);

export const sumOtherCosts = (otherCosts?: { [key: string]: number }) => {
  if (!otherCosts) return 0;

  return Object.values(otherCosts).reduce((sum, cost) => sum + cost, 0);
};

export const dollarsToCents = (amount: string) => {
  let str = amount.replace('$', '');
  str = str.replace(',', '');
  if (str && str.includes('.')) {
    str = str.substring(0, str.indexOf('.') + 3);
  }

  return str ? Math.round(parseFloat(str) * 100) : 0;
};
