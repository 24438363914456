import { useProvidersStrore } from 'stores/ProvidersStore';

export const useSupplierKind = () => {
  const { providers } = useProvidersStrore();

  const getSupplierKind = (name: string) => {
    const provider = providers.find((el) => el.name === name);
    const kind = provider?.category || 'PROCUREMENT';
    return kind;
  };

  return {
    getSupplierKind,
  };
};
