import { signOut } from 'aws-amplify/auth';
import { NavLink } from 'react-router-dom';

import { DoubleArrowIcon } from 'assets/icons/DoubleArrowIcon';
import { FaliamLogoIcon } from 'assets/icons/FaliamLogo';
import { SignOutIcon } from 'assets/icons/SignOutIcon';
import { OrderIcon } from 'assets/icons/OrderIcon';
import { UsersIcon } from 'assets/icons/UsersIcon';
import { HomeIcon } from 'assets/icons/HomeIcon';

import { PATHNAMES } from 'routes/pathnames';
import { removeStorageItem } from 'util/localStorage';
import { isActiveClass } from 'util/classes';
import { GpoIcon } from 'assets/icons/GpoIcon';
import { ProductsIcon } from 'assets/icons/ProductsIcon';
import { JobsIcon } from 'assets/icons/JobsIcon';
import { HealthIcon } from 'assets/icons/HealthIcon';
import { SettingsIcon } from 'assets/icons/SettingsIcon';

interface Props {
  setIsLogged: (status: boolean) => void;
  isExpand: boolean;
  toggleExpand: () => void;
}

export const Sidebar = ({ setIsLogged, isExpand, toggleExpand }: Props) => {
  const onSignOut = async () => {
    try {
      await signOut();
      removeStorageItem('isLogged');
      setIsLogged(false);
    } catch (err) { }
  };

  return (
    <aside className={isActiveClass('appSidebar', isExpand)}>
      <div className="top">
        <FaliamLogoIcon />
        <span onClick={toggleExpand}>
          <DoubleArrowIcon />
        </span>
      </div>
      <nav>
        <NavLink to={PATHNAMES.USERS}>
          <UsersIcon />
          Users
        </NavLink>
        <NavLink to={PATHNAMES.ORDERS}>
          <OrderIcon />
          Orders
        </NavLink>

        <NavLink to={PATHNAMES.ORGANIZATIONS}>
          <HomeIcon />
          Organizations
        </NavLink>
        <NavLink to={PATHNAMES.GPO}>
          <GpoIcon />
          GPOs
        </NavLink>
        <NavLink to={PATHNAMES.PRODUCTS}>
          <ProductsIcon />
          Products
        </NavLink>
        <NavLink to={PATHNAMES.MANUFACTURERS}>
          <ProductsIcon />
          Manufacturers
        </NavLink>
        <NavLink to={PATHNAMES.SUPPLIERS}>
          <ProductsIcon />
          Suppliers
        </NavLink>
        <NavLink to={PATHNAMES.JOBS}>
          <JobsIcon />
          Jobs
        </NavLink>

        <NavLink to={PATHNAMES.ORG_HEALTH}>
          <HealthIcon />
          Org Health
        </NavLink>

        <NavLink to={PATHNAMES.GPO_HEALTH}>
          <HealthIcon />
          GPO Health
        </NavLink>

        <NavLink to={PATHNAMES.SETTINGS}>
          <SettingsIcon />
          Settings
        </NavLink>
      </nav>
      <button className="rounded logoutBtn" onClick={onSignOut}>
        <SignOutIcon />
      </button>
    </aside>
  );
};
