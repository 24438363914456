import { FormikProps } from 'formik';
import get from 'lodash/get';

interface Props {
  formik: FormikProps<any>;
  label: string;
  name: string;
}

export type InputT = Props & React.InputHTMLAttributes<HTMLInputElement>;

export const InputField = ({ formik, name, label, ...rest }: InputT) => {
  const hasError =
    (get(formik.touched, name) && get(formik.errors, name)) || (formik.submitCount > 0 && get(formik.errors, name));

  return (
    <div className={`input-field ${hasError ? 'invalid' : 'valid'}`}>
      <label>{label}</label>
      <input
        name={name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={get(formik.values, name) || ''}
        {...rest}
      />
      {hasError && <span className="errorMsg">{get(formik.errors, name) as string}</span>}
    </div>
  );
};
