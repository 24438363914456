import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { usersKeys } from 'api/users/queries';
import { getUser } from 'api/users';
import { LoaderIcon } from 'assets/icons/LoaderIcon';
import { UserDetails } from './UserDetails';
import { UserOrganizations } from './UserOrganizations';

export const UserPage = () => {
  const { userID }: any = useParams();
  const { data: user, status } = useQuery(usersKeys.user(userID), async () => {
    const data = await getUser(userID);
    return data;
  });

  if (status === 'error') return <h1 className="pageTitle center">User not found</h1>;
  if (status === 'loading') return <LoaderIcon />;

  return (
    <div className="userPage">
      {user && (
        <>
          <UserDetails metadata={user.metadata} />
          <UserOrganizations organizations={user.organizations} />
        </>
      )}
    </div>
  );
};
