import algoliasearch from 'algoliasearch';

export const algoliaKeys = {
  indexName: {
    regular: process.env.REACT_APP_ALGOLIA_INDEX_NAME || '',
    orders: 'admin-orders',
    users: 'admin-users',
    organizations: 'admin-organizations'
  },
  algoliaSecret: process.env.REACT_APP_ALGOLIA_LOCAL_SECRET,
  algoliaSecretAdmin: process.env.REACT_APP_ALGOLIA_LOCAL_SECRET_ADMIN,
  algoliaSearchKey: process.env.REACT_APP_ALGOLIA_SEARCH_KEY
};

export const adminSearchClient = algoliasearch(
  (algoliaKeys as any).algoliaSearchKey,
  (algoliaKeys as any).algoliaSecretAdmin
);
export const searchClient = algoliasearch((algoliaKeys as any).algoliaSearchKey, (algoliaKeys as any).algoliaSecret);
export const algoliaIndex = searchClient.initIndex((algoliaKeys as any).indexName.regular);
