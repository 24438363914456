import { useState } from 'react';
import { CSVImporter } from "csv-import-react";


import { Org, UpdateOrgItemsPromotionsPayload, UpdateOrgItemsPromotionsResponse } from 'api/orgs/types';
import { Promotion, UploadData } from 'types/tableflow.types';

import { CSVDownload } from 'components/shared/CSVDownload';
import { convertPrice, dollarsToCents } from 'util/prices';
import { updateOrgItemsPromotions } from 'api/orgs';
import { User } from 'util/session';
import { debounce } from 'lodash';

interface Props {
  user?: User;
  org: Org;
}

export const UploadPromotions = ({ org, user }: Props) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [issues, setIssues] = useState<UpdateOrgItemsPromotionsResponse[]>([]);

  const formattedData = issues.map((issue) => ({
    ...issue,
    fixedDiscount: issue.fixedDiscount ? convertPrice(issue.fixedDiscount) : undefined,
    bulkPrice: issue.bulkPrice ? convertPrice(issue.bulkPrice) : undefined,
    getYDiscount: issue.getYDiscount ? convertPrice(issue.getYDiscount) : undefined,
  }));

  const dataHandler = async (data: UploadData<Promotion>): Promise<void> => {
    setIsOpenModal(false);
    setIssues([]);

    // Alert on API issues
    if (data.error) return alert(data.error);

    let body: UpdateOrgItemsPromotionsPayload = { promotions: [] };
    const issues: UpdateOrgItemsPromotionsResponse[] = [];
    let i = -1;

    for (const row of data.rows) {
      i++;
      body.promotions.push({
        provider: row.values.supplier,
        sku: row.values.sku,
        type: row.values.type,
        startDate: row.values.start_date == "" ? undefined : row.values.start_date,
        endDate: row.values.end_date == "" ? undefined : row.values.end_date,
        fixedDiscount: row.values.fixed_discount ? dollarsToCents(row.values.fixed_discount) : undefined,
        percentageDiscount: row.values.percentage_discount ? parseInt(row.values.percentage_discount) : undefined,
        bulkLimit: row.values.bulk_limit ? parseInt(row.values.bulk_limit) : undefined,
        bulkPrice: row.values.bulk_price ? dollarsToCents(row.values.bulk_price) : undefined,
        buyX: row.values.buy_x ? parseInt(row.values.buy_x) : undefined,
        getY: row.values.get_y ? parseInt(row.values.get_y) : undefined,
        getYDiscount: row.values.get_y_discount ? parseInt(row.values.get_y_discount) : undefined,
      });
      // Update prices every 1000 rows or at the end of the file
      if (body.promotions.length === 1000 || i === data.rows.length - 1) {
        const rsp = await updateOrgItemsPromotions(org.metadata.orgID, body);
        if (rsp.length > 0) issues.push(...rsp);
        body = { promotions: [] };
      }
    }

    setIssues(issues);
  };

  const onComplete: any = debounce(dataHandler, 500);

  return (
    <>
      <button className="outlined lg" onClick={() => setIsOpenModal(true)}>
        Upload Promotions
      </button>
      <CSVImporter
        isModal={true}
        modalIsOpen={isOpenModal}
        modalOnCloseTriggered={() => setIsOpenModal(false)}
        onComplete={onComplete}
        primaryColor="#36bebc"
        modalCloseOnOutsideClick={true}
        template={{
          columns: [
            {
              "id": "43817715-e96b-4a50-a0fb-84349f890e60",
              "template_id": "c16cc939-d2d1-4d72-9df5-c8079a7885fb",
              "name": "Supplier",
              "key": "supplier",
              "required": true,
              "data_type": "string",
              "description": null,
              "suggested_mappings": [],
              "index": 0,
              "validations": [
                {
                  "id": 1060,
                  "template_column_id": "43817715-e96b-4a50-a0fb-84349f890e60",
                  "validate": "not_blank",
                  "options": null,
                  "message": "The cell must contain a value",
                  "severity": "error"
                }
              ]
            },
            {
              "id": "335cde41-352b-4c60-a58e-8afcb266fe52",
              "template_id": "c16cc939-d2d1-4d72-9df5-c8079a7885fb",
              "name": "SKU",
              "key": "sku",
              "required": true,
              "data_type": "string",
              "description": null,
              "suggested_mappings": [],
              "index": 1,
              "validations": [
                {
                  "id": 1061,
                  "template_column_id": "335cde41-352b-4c60-a58e-8afcb266fe52",
                  "validate": "not_blank",
                  "options": null,
                  "message": "The cell must contain a value",
                  "severity": "error"
                }
              ]
            },
            {
              "id": "e39236e1-68ce-4821-95a3-ccaa4dfc99d1",
              "template_id": "c16cc939-d2d1-4d72-9df5-c8079a7885fb",
              "name": "Type",
              "key": "type",
              "required": true,
              "data_type": "string",
              "description": null,
              "suggested_mappings": [],
              "index": 2,
              "validations": [
                {
                  "id": 1062,
                  "template_column_id": "e39236e1-68ce-4821-95a3-ccaa4dfc99d1",
                  "validate": "not_blank",
                  "options": null,
                  "message": "The cell must contain a value",
                  "severity": "error"
                },
                {
                  "id": 1063,
                  "template_column_id": "e39236e1-68ce-4821-95a3-ccaa4dfc99d1",
                  "validate": "list",
                  "options": [
                    "Fixed",
                    "Percentage",
                    "BuyXGetY",
                    "BulkPrice",
                    "Delete"
                  ],
                  "message": "The cell must be 'Fixed', 'Percentage', 'BuyXGetY', 'BulkPrice', or 'Delete'",
                  "severity": "error"
                }
              ]
            },
            {
              "id": "98d87c5c-6eec-4745-a299-be2c48398e17",
              "template_id": "c16cc939-d2d1-4d72-9df5-c8079a7885fb",
              "name": "Start Date",
              "key": "start_date",
              "required": false,
              "data_type": "date",
              "description": null,
              "suggested_mappings": [],
              "index": 3,
              "validations": [
                {
                  "id": 1064,
                  "template_column_id": "98d87c5c-6eec-4745-a299-be2c48398e17",
                  "validate": "date",
                  "options": null,
                  "message": "The cell must be a date",
                  "severity": "error"
                }
              ]
            },
            {
              "id": "6854c09b-00df-4b81-9a6d-662fee70edcd",
              "template_id": "c16cc939-d2d1-4d72-9df5-c8079a7885fb",
              "name": "End Date",
              "key": "end_date",
              "required": false,
              "data_type": "date",
              "description": null,
              "suggested_mappings": [],
              "index": 4,
              "validations": [
                {
                  "id": 1065,
                  "template_column_id": "6854c09b-00df-4b81-9a6d-662fee70edcd",
                  "validate": "date",
                  "options": null,
                  "message": "The cell must be a date",
                  "severity": "error"
                }
              ]
            },
            {
              "id": "aa50eec9-faa2-45f2-b08d-fdaebc65135f",
              "template_id": "c16cc939-d2d1-4d72-9df5-c8079a7885fb",
              "name": "Fixed Discount",
              "key": "fixed_discount",
              "required": false,
              "data_type": "string",
              "description": null,
              "suggested_mappings": [],
              "index": 5,
              "validations": []
            },
            {
              "id": "7e51d2bd-c531-4751-b1b4-4cd7428f5b4d",
              "template_id": "c16cc939-d2d1-4d72-9df5-c8079a7885fb",
              "name": "Percentage Discount",
              "key": "percentage_discount",
              "required": false,
              "data_type": "number",
              "description": null,
              "suggested_mappings": [],
              "index": 6,
              "validations": [
                {
                  "id": 1066,
                  "template_column_id": "1063ed81-0a2f-4849-809d-23176cfe8854",
                  "validate": "range",
                  "options": {
                    "min": 1,
                    "max": 100,
                  },
                  "message": "The value must be between 1 and 100",
                  "severity": "error"
                },
                {
                  "id": 1072,
                  "template_column_id": "1063ed81-0a2f-4849-809d-23176cfe8854",
                  "validate": "number",
                  "options": null,
                  "message": "The cell must be a number",
                  "severity": "error"
                }
              ]
            },
            {
              "id": "fd1ebde3-7107-4b37-8a85-c82affc94902",
              "template_id": "c16cc939-d2d1-4d72-9df5-c8079a7885fb",
              "name": "Bulk Limit",
              "key": "bulk_limit",
              "required": false,
              "data_type": "number",
              "description": null,
              "suggested_mappings": [],
              "index": 7,
              "validations": [
                {
                  "id": 1067,
                  "template_column_id": "fd1ebde3-7107-4b37-8a85-c82affc94902",
                  "validate": "range",
                  "options": {
                    "min": 1
                  },
                  "message": "The value must be greater than or equal to 1",
                  "severity": "error"
                },
                {
                  "id": 1068,
                  "template_column_id": "fd1ebde3-7107-4b37-8a85-c82affc94902",
                  "validate": "number",
                  "options": null,
                  "message": "The cell must be a number",
                  "severity": "error"
                }
              ]
            },
            {
              "id": "89ce2c2b-148f-4837-9536-0928c7d04bda",
              "template_id": "c16cc939-d2d1-4d72-9df5-c8079a7885fb",
              "name": "Bulk Price",
              "key": "bulk_price",
              "required": false,
              "data_type": "string",
              "description": null,
              "suggested_mappings": [],
              "index": 8,
              "validations": []
            },
            {
              "id": "d87e27aa-58d3-45ae-be51-e241480173f9",
              "template_id": "c16cc939-d2d1-4d72-9df5-c8079a7885fb",
              "name": "Buy X",
              "key": "buy_x",
              "required": false,
              "data_type": "number",
              "description": null,
              "suggested_mappings": [],
              "index": 9,
              "validations": [
                {
                  "id": 1069,
                  "template_column_id": "d87e27aa-58d3-45ae-be51-e241480173f9",
                  "validate": "range",
                  "options": {
                    "min": 1
                  },
                  "message": "The value must be greater than or equal to 1",
                  "severity": "error"
                },
                {
                  "id": 1070,
                  "template_column_id": "d87e27aa-58d3-45ae-be51-e241480173f9",
                  "validate": "number",
                  "options": null,
                  "message": "The cell must be a number",
                  "severity": "error"
                }
              ]
            },
            {
              "id": "1063ed81-0a2f-4849-809d-23176cfe8854",
              "template_id": "c16cc939-d2d1-4d72-9df5-c8079a7885fb",
              "name": "Get Y",
              "key": "get_y",
              "required": false,
              "data_type": "number",
              "description": null,
              "suggested_mappings": [],
              "index": 10,
              "validations": [
                {
                  "id": 1071,
                  "template_column_id": "1063ed81-0a2f-4849-809d-23176cfe8854",
                  "validate": "range",
                  "options": {
                    "min": 1
                  },
                  "message": "The value must be greater than or equal to 1",
                  "severity": "error"
                },
                {
                  "id": 1072,
                  "template_column_id": "1063ed81-0a2f-4849-809d-23176cfe8854",
                  "validate": "number",
                  "options": null,
                  "message": "The cell must be a number",
                  "severity": "error"
                }
              ]
            },
            {
              "id": "e7f0cb59-507f-4037-92dc-7813075c8317",
              "template_id": "c16cc939-d2d1-4d72-9df5-c8079a7885fb",
              "name": "Get Y Discount",
              "key": "get_y_discount",
              "required": false,
              "data_type": "number",
              "description": null,
              "suggested_mappings": [],
              "index": 11,
              "validations": [
                {
                  "id": 1071,
                  "template_column_id": "1063ed81-0a2f-4849-809d-23176cfe8854",
                  "validate": "range",
                  "options": {
                    "min": 1,
                    "max": 100,
                  },
                  "message": "The value must be between 1 and 100",
                  "severity": "error"
                },
                {
                  "id": 1072,
                  "template_column_id": "1063ed81-0a2f-4849-809d-23176cfe8854",
                  "validate": "number",
                  "options": null,
                  "message": "The cell must be a number",
                  "severity": "error"
                }
              ]
            }
          ]
        }}
      />

      {issues.length ? <CSVDownload data={formattedData} filename="promotion_upload_issues.csv" /> : null}
    </>
  );
};
