import orderBy from 'lodash/orderBy';

import { Shipment, ShipmentHistoryStatus } from 'types/order.types';

import { TableCellTooltip } from 'components/shared/table/TableCell';
import { Table } from 'components/shared/table';
import { formatDateWithTime } from 'util/dates';
import useTableData from 'hooks/useTableData';
import { Modal } from 'components/ui/Modal';

interface Props {
  close: () => void;
  shipment: Shipment;
}

export const ShipmentHistoryModal = ({ close, shipment }: Props) => {
  const allData = shipment.historyStatus;
  const { meta, tableData } = useTableData({ allData });

  return (
    <Modal open={true} close={close} title={shipment.trackingNumber} className="shipmentHistoryModal">
      <Table
        name="ShipmentHistory"
        title="Shipment History"
        nav={['Date', 'ETC', 'Location', 'Status', 'Description']}
        meta={meta}
        allData={allData}
        content={
          <div className="tableBody">
            {orderBy(tableData, (item) => item.date, ['desc']).map((item: ShipmentHistoryStatus, i) => (
              <div key={`${item.status}:${i}`} className="tableRow">
                <TableCellTooltip content={formatDateWithTime(item.date)} />
                <TableCellTooltip
                  content={i === 0 ? formatDateWithTime(shipment.originalETA || shipment.eta) : 'N/A'}
                />
                <TableCellTooltip
                  content={
                    item.location
                      ? `${item.location.city}, ${item.location.state}, ${item.location.country}, ${item.location.zip}`
                      : 'N/A'
                  }
                />
                <div className="tableCell">
                  <div className={`badge ${item.status}`}>{item.status}</div>
                </div>
                <TableCellTooltip content={item.details} />
              </div>
            ))}
          </div>
        }
      />
    </Modal>
  );
};
