import { Org } from 'api/orgs/types';
import { isActiveClass } from 'util/classes';
import { formatOffice, formatOfficeAddress } from 'util/offices';

interface Props {
  org: Org;
  activeOffice: string;
  setActiveOffice: (activeOffice: string) => void;
}

export const Offices = ({ org, activeOffice, setActiveOffice }: Props) => (
  <div className="paper">
    <div className="paperHeader">
      <h2>2. Select office</h2>
    </div>
    <div className="paperBody">
      <div className="options">
        {org.offices.map((office) => (
          <div
            key={office.officeID}
            className="optionItem"
            onClick={() => setActiveOffice(office.officeID)}
          >
            <div
              className={isActiveClass(
                'radio',
                activeOffice === office.officeID
              )}
            />
            <div>
              <p>{formatOffice(office)}</p>
              <span>{formatOfficeAddress(office)}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);
