import CurrencyInput from 'react-currency-input-field';
import { useFormikContext } from 'formik';

interface Props {
  name: string;
  label: string;
  value?: number;
  readOnly?: boolean;
}

export const CurrencyField = ({ name, label, value, readOnly = false }: Props) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <div className="field">
      <label>{label}</label>
      <CurrencyInput
        name={name}
        value={(values as any)[name] || value}
        prefix="$"
        decimalsLimit={2}
        decimalScale={2}
        onValueChange={(value: any) => setFieldValue(name, value)}
        readOnly={readOnly}
      />
    </div>
  );
};
