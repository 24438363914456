import { useRef } from 'react';

import { useOutsideClick } from 'hooks/useOutsideClick';
import { isActiveClass } from 'util/classes';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: any;
  options: any;
  className?: string;
}

export const Select = ({
  isOpen,
  setIsOpen,
  children,
  options,
  className = '',
}: Props) => {
  const ref = useRef(null);
  useOutsideClick(ref, () => isOpen && setIsOpen(false));

  return (
    <div className={isActiveClass(`select ${className}`, isOpen)} ref={ref}>
      <div className="selectPreview" onClick={() => setIsOpen((prev) => !prev)}>
        {children}
      </div>
      <div className="selectOptions">{options}</div>
    </div>
  );
};
