import { OrderItem } from 'types/order.types';

import { convertPrice, orderItemsTotalPrice } from 'util/prices';
import { useSupplierLogo } from 'hooks/useSupplierLogo';
import { groupOrdersBySupplier } from 'util/mix';

interface Props {
  tax: number;
  orderItems: OrderItem[];
  saveChanges: () => void;
  isLoadingButton: boolean;
}

export const Summary = ({
  tax,
  orderItems,
  saveChanges,
  isLoadingButton,
}: Props) => {
  const { getSupplierLogo } = useSupplierLogo();
  const suppliers = groupOrdersBySupplier(orderItems);
  const totalPrice = orderItemsTotalPrice(orderItems);

  return (
    <div className="summary">
      <div className="summaryHeader">
        <p>Order Summary ({orderItems.length} items)</p>
      </div>
      <div className="summarySuppliers">
        {suppliers.map(([supplier, items]) => (
          <div key={supplier} className="flexBetween">
            <div className="flexAlign">
              <div className="supplierLogoBox">
                <img src={getSupplierLogo(supplier)} alt={supplier} />
              </div>
              <p>
                {supplier} ({items.length} items)
              </p>
            </div>
            <span>{convertPrice(orderItemsTotalPrice(items))}</span>
          </div>
        ))}
      </div>
      <div className="summaryDetails">
        <div className="flexBetween">
          <p>Shipping & handling</p>
          <p>Free</p>
        </div>
        <div className="flexBetween">
          <p>Total before tax</p>
          <p>{convertPrice(totalPrice)}</p>
        </div>
        <div className="flexBetween">
          <p>Estimated tax to be collected</p>
          <p>{convertPrice(tax)}</p>
        </div>
        <div className="flexBetween total">
          <p>Total</p>
          <p>{convertPrice(tax + totalPrice)}</p>
        </div>
      </div>
      <button
        className="contained"
        onClick={saveChanges}
        disabled={isLoadingButton}
      >
        Save changes
      </button>
    </div>
  );
};
