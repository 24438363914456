import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useFormikContext } from 'formik';
import uniqBy from 'lodash/uniqBy';
import dayjs from 'dayjs';

import { CreateBackOrderPayload, DeleteBackOrderPayload } from 'api/purchaseOrders/types';
import { Order, OrderItem, ProviderInvoice } from 'types/order.types';
import { OrgOrderItem } from 'types/orgs.types';

import { createBackOrder, deleteBackOrder } from 'api/purchaseOrders';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { NestedTable } from 'components/shared/table/NestedTable';
import { PlusCircleIcon } from 'assets/icons/PlusCircleIcon';
import { getStackInfoStatusColor } from 'util/stackInfoUtil';
import { EditBoxPriceModal } from './EditBoxPriceModal';
import { DownArrowIcon } from 'assets/icons/DownArrow';
import { ItemWithBoxes } from './ProviderInvoiceForm';
import { Checkbox } from 'components/ui/Checkbox';
import { useOrderStore } from 'stores/OrderStore';
import { Table } from 'components/shared/table';
import { getOrderedItem } from 'util/orders';
import { convertPrice } from 'util/prices';
import { useToggles } from './useToggles';
import { orderBy } from 'lodash';

interface Props {
  purchaseInvoice: ProviderInvoice | null;
  itemsWithBoxes: ItemWithBoxes[];
  setItemsWithBoxes: React.Dispatch<React.SetStateAction<ItemWithBoxes[]>>;
}

export const ProviderInvoiceModalTable = ({ purchaseInvoice, itemsWithBoxes, setItemsWithBoxes }: Props) => {
  const [itemForEditDFUR, setItemForEditDFUR] = useState<ItemWithBoxes | null>(null);
  const [itemForEditSIUR, setItemForEditSIUR] = useState<ItemWithBoxes | null>(null);
  const [boxForEditPrice, setBoxForEditPrice] = useState<{
    item: ItemWithBoxes;
    box: ItemWithBoxes['boxes'][0];
  } | null>(null);

  const { order, setOrder } = useOrderStore();
  const { mutate: createBackOrderMutate } = useMutation(createBackOrder);
  const { mutate: deleteBackOrderMutate } = useMutation(deleteBackOrder);
  const { values }: any = useFormikContext();
  const { onAddNewItemBox, onToggleAll, onToggleBox, onToggleCollapse, onToggleItem } = useToggles({
    itemsWithBoxes,
    setItemsWithBoxes
  });

  useEffect(() => {
    initialSetItemsWithBoxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.provider, values.purchaseOrderID]);

  const initialSetItemsWithBoxes = () => {
    if (!values.provider) return;

    const { purchaseOrders, orderOverview } = order;

    const stack = orderOverview.StackInfo;
    const items = purchaseInvoice
      ? purchaseInvoice.items
      : purchaseOrders
          ?.filter((el) => el.id === values.purchaseOrderID)
          .reduce((acc, curr) => [...acc, ...curr.items], [] as OrgOrderItem[])
          .filter((el) => el.price.provider === values.provider) || [];

    const res: ItemWithBoxes[] = items.map((item) => {
      const box = stack.find((s) => s.VariantID === item.item.objectID && s.SupplierName === item.price.provider);

      return {
        id: `${item.item.objectID}:${item.price.buyingPrice}:${item.price.provider}`,
        qty: box?.Quantity || item.quantity || 0,
        isCollapsed: false,
        isChecked: true,
        item,
        boxes: Array.from({ length: item.quantity }).map((_, i) => ({
          id: `${item.item.objectID}:${i}`,
          status: box?.ItemStackStatus.replace('ITEM_STACK_', '') || '',
          price: item.price.buyingPrice || 0,
          isChecked: true
        }))
      };
    });

    setItemsWithBoxes(res);
  };

  const onCreateBackOrder = ({ item }: ItemWithBoxes) => {
    const payload: CreateBackOrderPayload = {
      orgID: order.orgID,
      orderID: order.orderID,
      body: {
        items: [
          {
            expectedDeliveryDate: dayjs().format('YYYY-MM-DD'),
            variantID: item.item.objectID,
            provider: item.price.provider,
            quantity: 1
          }
        ]
      }
    };

    createBackOrderMutate(payload, { onSuccess });
  };

  const onDeleteBackOrder = ({ item }: ItemWithBoxes) => {
    const ordredItem = getOrderedItem(order.items, item);

    const payload: DeleteBackOrderPayload = {
      orgID: order.orgID,
      orderID: order.orderID,
      backOrderID: ordredItem.backOrder?.id || ''
    };

    deleteBackOrderMutate(payload, { onSuccess });
  };

  const allProviders = uniqBy(itemsWithBoxes, (e) => e.item.item.objectID);

  const onSuccess = (res: Order) => {
    setOrder(res);
  };

  const isRowCoveredByProviderInvoice = (item: ItemWithBoxes) => {
    const allProviderInvoicesItems: OrderItem[] = order.providerInvoices.reduce(
      (acc: any, curr) => [...acc, ...curr.items],
      []
    );

    return allProviderInvoicesItems.some((el) => {
      return (
        el.item.objectID === item.item.item.objectID &&
        el.price.provider === item.item.price.provider &&
        el.quantity >= item.qty
      );
    });
  };

  return (
    <>
      <div className="selectAllCheckbox">
        <Checkbox
          isEnabled={itemsWithBoxes.length > 0 && itemsWithBoxes.every((el) => el.isChecked)}
          onChange={onToggleAll}
        />
        <p>{itemsWithBoxes.length > 0 && itemsWithBoxes.every((el) => el.isChecked) ? 'Unselect all' : 'Select all'}</p>
      </div>
      <Table
        name="ProvierInvoiceModal"
        title={allProviders.length ? `${values.provider} (${allProviders.length} items)` : 'Select Provider'}
        nav={[
          'Select',
          'Item',
          'Seller SKU',
          'MFR Code',
          'QTY Ordered',
          'QTY Left',
          'DFUR',
          'DFIT',
          'SIUR',
          'SIIT',
          ''
        ]}
        allData={itemsWithBoxes}
        content={
          <div className="tableBody nested">
            {orderBy(itemsWithBoxes, (e) => isRowCoveredByProviderInvoice(e)).map((el) => {
              const { id, qty, isChecked, isCollapsed, item, boxes } = el;
              const orderedItem = getOrderedItem(order.items, item);
              const totalBoxesPrice = boxes.filter((b) => b.isChecked).reduce((acc, curr) => acc + curr.price, 0);
              const activePrice = orderedItem?.price?.customSellingPrice || orderedItem?.price?.sellingPrice || 0;
              const numOfCheckedBoxes = itemsWithBoxes
                .filter(
                  (i) =>
                    i.item.item.objectID === item.item.objectID &&
                    i.item.price.provider === item.price.provider &&
                    i.isChecked
                )
                .reduce((total, e) => total + e.boxes.filter((b) => b.isChecked).length, 0);

              const maxBoxPrice = boxes.reduce((acc, curr) => {
                if (curr.price > acc) return curr.price;
                return acc;
              }, 0);

              return (
                <div key={id} className="groupWithChildren">
                  <div className={`tableRow ${isRowCoveredByProviderInvoice(el) ? 'successRow' : 'dangerRow'}`}>
                    <div className="tableCell">
                      <Checkbox isEnabled={isChecked} onChange={() => onToggleItem(el)} />
                    </div>
                    <TableCellTooltip content={item.item.title} />
                    <TableCellTooltip content={item.price.sku} />
                    <TableCellTooltip content={item.item.manufacturerCode} />
                    <TableCellTooltip content={qty} />
                    <TableCellTooltip content={qty - numOfCheckedBoxes} />
                    <TableCellTooltip content={convertPrice(activePrice)} />
                    <TableCellTooltip content={convertPrice(activePrice * (orderedItem?.quantity || 1))} />
                    <TableCellTooltip content={convertPrice(maxBoxPrice)} />
                    <TableCellTooltip content={convertPrice(totalBoxesPrice)} />
                    <button type="button" className="outlined lg editBtn" onClick={() => setItemForEditDFUR(el)}>
                      Edit DFUR
                    </button>
                    <button type="button" className="outlined lg editBtn" onClick={() => setItemForEditSIUR(el)}>
                      Edit SIUR
                    </button>
                    <div className="tableCell">
                      <div className="tableDownArrow" onClick={() => onToggleCollapse(el)}>
                        <DownArrowIcon />
                      </div>
                    </div>
                  </div>
                  {isCollapsed && (
                    <NestedTable
                      className="nestedTableProvierInvoiceModal"
                      nav={['Select', 'Box #', 'Box Status', 'DFUR', 'SIUR', 'On BO']}
                    >
                      {boxes.map((box, boxIndex) => {
                        return (
                          <div key={box.id} className="tableRow">
                            <div className="tableCell">
                              <Checkbox isEnabled={box.isChecked} onChange={() => onToggleBox(el, box)} />
                            </div>
                            <TableCellTooltip content={`${boxIndex + 1}.`} />
                            <div className="tableCell">
                              <span style={{ color: getStackInfoStatusColor(box.status) }}>{box.status}</span>
                            </div>
                            <TableCellTooltip content={convertPrice(activePrice)} />
                            <div className="tableCell flexAlign gg8">
                              {convertPrice(box.price)}
                              <button
                                type="button"
                                className="outlined sm"
                                style={{ padding: '3px 6px' }}
                                onClick={() => setBoxForEditPrice({ item: el, box })}
                              >
                                EDIT
                              </button>
                            </div>
                            <div className="tableCell backOrderCell">
                              <span
                                onClick={() => {
                                  // TODO: fix backorder issue
                                  if (box.status === 'BACKORDERED') onDeleteBackOrder(el);
                                  else onCreateBackOrder(el);
                                }}
                              >
                                {box.status === 'ITEM_BACKORDERED' ? 'Yes' : 'No'}
                                <DownArrowIcon />
                              </span>
                            </div>
                          </div>
                        );
                      })}
                      <div className="tableRow fullWidthRow">
                        <div className="tableCell">
                          <span onClick={() => onAddNewItemBox(el)}>
                            Add Another Box
                            <PlusCircleIcon />
                          </span>
                        </div>
                      </div>
                    </NestedTable>
                  )}
                </div>
              );
            })}
          </div>
        }
      />
      {itemForEditDFUR && (
        <EditBoxPriceModal
          item={getOrderedItem(order.items, itemForEditDFUR.item)}
          itemsWithBoxes={itemsWithBoxes}
          setItemsWithBoxes={setItemsWithBoxes}
          close={() => setItemForEditDFUR(null)}
          onlySellingPrice
        />
      )}
      {itemForEditSIUR && (
        <EditBoxPriceModal
          item={itemForEditSIUR.item}
          itemsWithBoxes={itemsWithBoxes}
          setItemsWithBoxes={setItemsWithBoxes}
          close={() => setItemForEditSIUR(null)}
        />
      )}
      {boxForEditPrice && (
        <EditBoxPriceModal
          item={boxForEditPrice.item.item}
          itemsWithBoxes={itemsWithBoxes}
          setItemsWithBoxes={setItemsWithBoxes}
          close={() => setBoxForEditPrice(null)}
          box={boxForEditPrice.box}
        />
      )}
    </>
  );
};
