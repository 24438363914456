import { Routes, Route } from 'react-router-dom';

import { PATHNAMES } from 'routes/pathnames';

import { OrganizationsPage } from 'pages/organizations';
import { OrganizationPage } from 'pages/organization';
import { AutoCartQueuePage } from 'pages/autocart';
import { OrgHealthPage } from 'pages/health/org';
import { GpoHealthPage } from 'pages/health/gpo';
import { SingleGpoPage } from 'pages/singleGpo';
import { ProductsPage } from 'pages/products';
import { OrdersPage } from 'pages/orders';
import { OrderPage } from 'pages/order';
import { UsersPage } from 'pages/users';
import { JobsPage } from 'pages/jobs';
import { UserPage } from 'pages/user';
import { GpoPage } from 'pages/gpo';
import { SettingsPage } from 'pages/settings';
import { ManufacturersPage } from 'pages/manufacturers';
import { SuppliersPage } from 'pages/suppliers';

export const AppRouter = () => (
  <aside className="appHolder">
    <div className="pageHolder customScrollbar">
      <Routes>
        <Route path={PATHNAMES.USERS} element={<UsersPage />} />
        <Route path={PATHNAMES.USER} element={<UserPage />} />
        <Route path={PATHNAMES.ORDERS} element={<OrdersPage />} />
        <Route path={PATHNAMES.ORDER} element={<OrderPage />} />
        <Route path={PATHNAMES.ORGANIZATIONS} element={<OrganizationsPage />} />
        <Route path={PATHNAMES.ORGANIZATION} element={<OrganizationPage />} />
        <Route path={PATHNAMES.GPO} element={<GpoPage />} />
        <Route path={PATHNAMES.GPO_SINGLE} element={<SingleGpoPage />} />
        <Route path={PATHNAMES.PRODUCTS} element={<ProductsPage />} />
        <Route path={PATHNAMES.MANUFACTURERS} element={<ManufacturersPage />} />
        <Route path={PATHNAMES.SUPPLIERS} element={<SuppliersPage />} />
        <Route path={PATHNAMES.JOBS} element={<JobsPage />} />
        <Route path={PATHNAMES.AUTO_CART_QUEUE} element={<AutoCartQueuePage />} />
        <Route path={PATHNAMES.ORG_HEALTH} element={<OrgHealthPage />} />
        <Route path={PATHNAMES.GPO_HEALTH} element={<GpoHealthPage />} />
        <Route path={PATHNAMES.SETTINGS} element={<SettingsPage />} />
      </Routes>
    </div>
  </aside>
);
