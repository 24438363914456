import { InstantSearch } from 'react-instantsearch-hooks';

import { adminSearchClient, algoliaKeys } from 'lib/algolia/aloglia-config';
import { OrdersTable } from './OrdersTable';
import { Filters } from './filters';
import { Link } from 'react-router-dom';
import { PATHNAMES } from 'routes/pathnames';

export const OrdersPage = () => {
  return (
    <div className="ordersPage">
      <div className="pageHeader">
        <h1 className="pageTitle">Orders</h1>
        <Link target="_blank" to={PATHNAMES.AUTO_CART_QUEUE} className="contained">
          Auto-Cart Queue
        </Link>
      </div>
      <InstantSearch searchClient={adminSearchClient} indexName={algoliaKeys.indexName.orders}>
        <Filters />
        <OrdersTable />
      </InstantSearch>
    </div>
  );
};
