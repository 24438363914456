import { useState } from 'react';
import { PaymentMethod } from '@stripe/stripe-js';
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';

import { StripeProps } from '.';

import { StripeCardNameField } from './StripeCardNameField';
import { StripeCardNumberField } from './StripeCardNumberField';
import { StripeCardExpiryField } from './StripeCardExpiryField';
import { StripeCardCvcField } from './StripeCardCvcField';
import { AssignOffice } from '../addPayment/AssignOffice';
import { useMutation } from 'react-query';
import { createOrgPayment } from 'api/orgs';
import { CreateOrgPaymentPayload } from 'api/orgs/types';

export const StripeForm = ({
  officeID,
  setOfficeID,
  onSuccess,
  org,
}: StripeProps) => {
  const [cardNameError, setCardNameError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [cardName, setCardName] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const { mutate: createPayment, isLoading } = useMutation(
    (body: CreateOrgPaymentPayload) =>
      createOrgPayment(org.metadata.orgID, body)
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (stripe === null || elements === null) return;
    if (!cardName.length) setCardNameError(true);

    const cardEl: any = elements.getElement(CardNumberElement);

    const { paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      billing_details: { name: cardName },
      card: cardEl,
    });

    handleResponse(paymentMethod);
  };

  const handleResponse = (paymentMethod?: PaymentMethod) => {
    if (!paymentMethod || !paymentMethod.card) {
      setIsValid(true);
      toast('Incorrect card number. Please enter a valid card number.', {
        type: 'error',
      });
    } else {
      setIsValid(false);
      const data: any = {
        name: cardName,
        paymentID: paymentMethod.id,
      };
      if (officeID && officeID !== 'main') data.officeID = officeID;

      createPayment(data, {
        onSuccess() {
          onSuccess(data);
        },
      });
    }
  };

  return (
    <form className="stripeForm" onSubmit={handleSubmit}>
      <div className="fieldsWrap">
        <StripeCardNameField
          value={cardName}
          setValue={setCardName}
          error={cardNameError}
          setError={setCardNameError}
        />
        <div className="fieldsRow">
          <StripeCardNumberField />
          <StripeCardExpiryField />
          <StripeCardCvcField />
        </div>
      </div>
      <AssignOffice org={org} officeID={officeID} setOfficeID={setOfficeID} />
      {isValid && <p className="fieldError mb24">Incorrect number</p>}
      <button
        type="submit"
        disabled={isLoading || (officeID ? false : true)}
        className="contained"
      >
        Add payment method
      </button>
    </form>
  );
};
