export const HomeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="#080C2D"
      d="M2 8.667h.666v4.667c0 .736.598 1.334 1.334 1.334h8c.735 0 1.333-.598 1.333-1.334V8.667H14a.667.667 0 00.471-1.138l-6-6a.666.666 0 00-.943 0l-6 6A.667.667 0 002 8.667zm6-5.724l4 4v6.391H4V6.943l4-4z"
    ></path>
    <path
      fill="#080C2D"
      d="M8 12c2.469 0 3.268-2.359 3.3-2.459l-1.267-.414c-.005.015-.52 1.54-2.033 1.54-1.492 0-2.013-1.48-2.034-1.544L4.7 9.541c.033.1.832 2.46 3.3 2.46z"
    ></path>
  </svg>
);
