import { AccountingRole, AdministratorRole, PurchasingTeamRole } from './data';
import { OrgOfficeUser, OrgUser } from 'api/orgs/types';
import { Office } from 'types/offices.types';

export const formatOffice = (office: Office) => {
  let res = `${office.streetNum} ${office.route}`;
  if (office.unit) res += ` ${office.unit}`;

  return res;
};

export const formatOfficeAddress = (office: Office) => `${office.locality}, ${office.state} ${office.zip}`;

export const getOrgUserOfficeNames = (officeUsers: OrgOfficeUser[], offices: Office[], userID: string): string[] => {
  const officeIDs = officeUsers.filter((el) => el.userID === userID).map(({ officeID }) => officeID) || [];

  return offices.filter((el) => officeIDs.includes(el.officeID)).map((el) => el.name);
};

export const getPaymentOffice = (offices: Office[], officeID: string) => {
  const el = offices.find((office) => office.officeID === officeID);
  if (el) return formatOffice(el);
  return 'All Offices';
};

export const getOfficeUsers = (officeID: string, users: OrgUser[], officeUsers: OrgOfficeUser[]): OrgUser[] => {
  const officeUserIDs = officeUsers.filter((ou) => ou.officeID === officeID).map((ou) => ou.userID);

  return users.filter(
    (u) =>
      officeUserIDs.includes(u.userID) ||
      u.role === AdministratorRole ||
      u.role === AccountingRole ||
      u.role === PurchasingTeamRole
  );
};
