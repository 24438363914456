import { InstantSearch } from 'react-instantsearch-hooks';

import { adminSearchClient, algoliaKeys } from 'lib/algolia/aloglia-config';
import { UsersTable } from './UsersTable';
import { useState } from 'react';
import { AlgoliaUser } from 'types/algolia.types';
import { UserImpersonateModal } from './UserImpersonateModal';
import { CreateUserModal } from './CreateUserModal';

export const UsersPage = () => {
  const [activeUser, setActiveUser] = useState<AlgoliaUser | null>(null);

  const [isOpenNewUserModal, setIsOpenNewUserModal] = useState(false);
  const toggleNewUserModal = () => setIsOpenNewUserModal((prev) => !prev);

  return (
    <InstantSearch searchClient={adminSearchClient} indexName={algoliaKeys.indexName.users}>
      <div className="flexBetween">
        <h1 className="pageTitle">Users</h1>
        <button className="outlined lg auto-width" onClick={toggleNewUserModal}>
          Create User
        </button>
      </div>
      <UsersTable setActiveUser={setActiveUser} />
      <UserImpersonateModal user={activeUser} close={() => setActiveUser(null)} />
      <CreateUserModal open={isOpenNewUserModal} close={toggleNewUserModal} />
    </InstantSearch>
  );
};
