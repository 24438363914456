import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import { TableProps } from 'types/table.types';

import { TableHeader } from './TableHeader';
import { TableLegend } from './TableLegend';
import { TableBody } from './TableBody';
import { TableSearch } from './TableSearch';

export const Table = ({
  title,
  name,
  nav,
  content,
  allData,
  headerContent = null,
  meta,
  searchKeys,
  setData,
  handleCellOrders,
  emptyMessage,
  isLoading
}: TableProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <div className="paper tablePaper">
      <div className="paperHeader">
        <div className="left">
          <h2>{title}</h2>
          {meta && (
            <TableSearch
              allData={allData}
              setData={setData}
              searchKeys={searchKeys}
              currentPage={meta.currentPage}
              setCurrentPage={meta.setCurrentPage}
            />
          )}
        </div>
        {headerContent}
      </div>
      <div className="paperBody">
        <div className={`tableWrapper table${name}`}>
          <div className="table">
            <div className="tableHolder">
              <TableHeader nav={nav} handleCellOrders={handleCellOrders} />
              <TableBody isLoading={isLoading} data={allData} content={content} emptyMessage={emptyMessage} />
            </div>
          </div>
          {meta && allData.length ? <TableLegend {...meta} /> : null}
        </div>
        <ReactTooltip place="bottom" />
      </div>
    </div>
  );
};
