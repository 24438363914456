import { skipOrder } from 'api/jobs';
import { AutoCartProviderData } from 'api/jobs/types';
import { Table } from 'components/shared/table';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';

type Props = {
  orgName: string;
  refetch: () => void;
  data: { orgID: string; [providerName: string]: AutoCartProviderData };
};

type Row = { name: string; current: string; pending: string[] };

type DataBySupplier = { supplierName: string; allData: Row[] };

export const AutoCartQueueTable = ({ orgName, data, refetch }: Props) => {
  const arrangedBySuppier: DataBySupplier[] = Object.entries(data)
    .filter(([_, value]) => typeof value !== 'string')
    .map(([supplierName, value]: any) => {
      const officData = Object.entries(value?.offices || {}).map(([name, officeData]: any) => ({
        name,
        current: officeData.current,
        pending: officeData.pending
      }));
      const orgData = { name: 'Organization', current: value.current, pending: value.pending };
      return { supplierName, allData: [orgData, ...officData] };
    });

  return (
    <div className="supplierGroup">
      <Link className="orgName" to={`/org/${data.orgID}`} target="_blank">
        {orgName}
      </Link>

      {arrangedBySuppier.map(
        (item, i) =>
          item && (
            <AutoCartQueueTableBySupplier refetch={refetch} orgID={data.orgID} key={item.supplierName + i} {...item} />
          )
      )}
    </div>
  );
};

const AutoCartQueueTableBySupplier = ({
  supplierName,
  allData,
  orgID,
  refetch
}: DataBySupplier & { orgID: string; refetch: () => void }) => {
  const [expandIndex, setExpandIndex] = useState<number>();

  const { mutate } = useMutation(skipOrder, {
    onSuccess: () => {
      refetch();
    }
  });

  return (
    <div className="bySupplierTable">
      <Table
        name="AutoCartQueueBySupplier"
        title={supplierName}
        nav={['', 'Current', 'Pending']}
        allData={allData}
        content={
          <div className="tableBody">
            {allData.map((item: Row, i) => (
              <div key={item.name + i} className="tableRow">
                <TableCellTooltip content={item.name}>
                  {i === 0 ? <Link to={`/org/${orgID}`}>{item.name}</Link> : null}
                </TableCellTooltip>
                <TableCellTooltip content={item.current} className="withButton">
                  <Link to={`/order/${orgID}/${item.current}`} target="_blank">
                    {item.current}
                  </Link>

                  {item.current && (
                    <button
                      type="button"
                      className="contained danger"
                      onClick={() => mutate({ orderID: item.current, provider: supplierName })}
                    >
                      Skip
                    </button>
                  )}
                </TableCellTooltip>
                <div
                  className="relativeCont"
                  onMouseOver={() => setExpandIndex(i)}
                  onMouseLeave={() => setExpandIndex(undefined)}
                >
                  {item?.pending?.length || 0}

                  {expandIndex === i && item?.pending?.length && (
                    <div className="paper listWrapper">
                      <p>Pending orders</p>
                      <div className="pendingList">
                        {item?.pending?.map((pendingItem, i) => (
                          <Link key={pendingItem + i} to={`/order/${orgID}/${pendingItem}`} target="_blank">
                            {pendingItem}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        }
      />
    </div>
  );
};
