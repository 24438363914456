import { useState } from 'react';
import { useFormikContext } from 'formik';

import { PurchaseOrder } from 'types/order.types';

import { DownArrowIcon } from 'assets/icons/DownArrow';
import { isActiveClass } from 'util/classes';
import { Select } from 'components/ui/Select';
import { useOrderStore } from 'stores/OrderStore';
import { OrgOrderItem } from 'types/orgs.types';

interface Props {
  purchaseOrders: PurchaseOrder[];
}

export const PurcahseOrderSelect = ({ purchaseOrders }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { values, setValues }: any = useFormikContext();
  const { order } = useOrderStore();

  const onClick = (purchaseOrder: PurchaseOrder) => {
    setValues({
      ...values,
      purchaseOrder,
      selectedItems: purchaseOrder.items,
      allItems: purchaseOrder.items
    });
    setIsOpen(false);
  };

  const renderBadgeByProvider = (provider: string) => {
    const allShipmentsItems = order.shipments.reduce((acc, curr) => [...acc, ...curr.items], [] as OrgOrderItem[]);

    const supplierShipmentsItems = allShipmentsItems.filter((item) => item.price.provider === provider);
    const supplierItems = values.allItems.filter((item: OrgOrderItem) => item.price.provider === provider);

    if (!supplierShipmentsItems.length) return null;
    if (supplierShipmentsItems.length >= supplierItems.length)
      return <span className="badge success">Fully Covered</span>;
    return (
      allShipmentsItems.some((el) => el.price.provider === provider) && (
        <span className="badge secondary">Partially Covered</span>
      )
    );
  };

  return (
    <div className="field">
      <label>Provider</label>
      <Select
        className="regular"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        options={
          <ul>
            {purchaseOrders.map((item) => (
              <li
                key={item.id}
                onClick={() => onClick(item)}
                className={isActiveClass('flexAlign gg16', values.purchaseOrder?.id === item.id)}
              >
                {item.provider}
                {renderBadgeByProvider(item.provider)}
              </li>
            ))}
          </ul>
        }
      >
        <p>{values.purchaseOrder ? values.purchaseOrder.provider : ''}</p>
        <DownArrowIcon />
      </Select>
    </div>
  );
};
