import { useState } from 'react';
import dayjs from 'dayjs';
import Calendar from 'react-calendar';
import { useMutation } from 'react-query';

import { UpdateShipmentPayload } from 'api/orders/types';

import { DownArrowIcon } from 'assets/icons/DownArrow';
import { useOrderStore } from 'stores/OrderStore';
import { Select } from 'components/ui/Select';
import { isActiveClass } from 'util/classes';
import { Modal } from 'components/ui/Modal';
import { updateShipment } from 'api/orders';
import { formatDate } from 'util/dates';

interface Props {
  shipmentID: string;
  onClose: () => void;
}

const options = [
  { label: 'Transit', value: 'TRANSIT' },
  { label: 'Delivered', value: 'DELIVERED' }
];

export const UpdateShipmentForm = ({ shipmentID, onClose }: Props) => {
  const [payload, setPayload] = useState({ status: '', eta: new Date() });
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { order, setOrder } = useOrderStore();
  const { mutate, isLoading } = useMutation(updateShipment);

  const onSubmit = () => {
    const body: UpdateShipmentPayload = {
      orderID: order.orderID,
      orgID: order.orgID,
      shipmentID,
      body: {
        status: payload.status
      }
    };

    if (payload.status === 'TRANSIT' && payload.eta) {
      body.body.eta = dayjs(payload.eta).format('YYYY-MM-DD');
    }

    mutate(body, {
      onSuccess: (res) => {
        setOrder(res);
        onClose();
      }
    });
  };

  return (
    <Modal open={true} close={onClose} title="Update Shipment" className="updateShipmentModal">
      <div className="field">
        <label>Status</label>
        <Select
          className="regular"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          options={
            <ul>
              {options.map((item) => (
                <li
                  key={item.value}
                  className={isActiveClass('', payload.status === item.value)}
                  onClick={() => {
                    setPayload({ ...payload, status: item.value });
                    setIsOpen(false);
                  }}
                >
                  {item.label}
                </li>
              ))}
            </ul>
          }
        >
          <p>{options.find((o) => o.value === payload.status)?.label || 'Select Status'}</p>
          <DownArrowIcon />
        </Select>
      </div>
      {payload.status === 'TRANSIT' && (
        <div className="field">
          <label>Expected Delivery Date</label>
          <div className="fakeField" onClick={() => setIsOpenCalendar(true)}>
            {formatDate(payload.eta)}
          </div>
          {isOpenCalendar && (
            <Modal open={true} close={() => setIsOpenCalendar(false)} title="Select Date" className="ordersDatePicker">
              <Calendar
                onChange={(date: Date) => {
                  setPayload({ ...payload, eta: date });
                  setIsOpenCalendar(false);
                }}
                value={payload.eta}
              />
            </Modal>
          )}
        </div>
      )}
      <button className="contained mt24" onClick={onSubmit} disabled={isLoading}>
        Update Shipment
      </button>
    </Modal>
  );
};
