/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';
import findIndex from 'lodash/findIndex';

import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { getPromotion } from 'types/promotions.types';
import { HandleCellOrders } from 'types/table.types';
import { OrderItem } from 'types/order.types';
import { Org } from 'api/orgs/types';

import { TableCellTooltip } from 'components/shared/table/TableCell';
import { convertPrice, orderSupplierPrice } from 'util/prices';
import { EditOrderItemsModal } from './editOrderItemsModal';
import { useOrderStore } from 'stores/OrderStore';
import { LinkIcon } from 'assets/icons/LinkIcon';
import { Table } from 'components/shared/table';
import useTableData from 'hooks/useTableData';
import { formatProductHref } from 'util/mix';
import { DragIcon } from 'assets/icons/DragIcon';

interface Props {
  org?: Org;
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

export const ItemsTable = ({ org, dragHandleProps }: Props) => {
  const [openEditModal, setOpenOrderModal] = useState(false);
  const [data, setData] = useState<OrderItem[]>([]);
  const [supplierDirection, setSupplierDirection] = useState<'asc' | 'desc'>('asc');

  const { order } = useOrderStore();
  const { items } = order;
  const sortedItems = orderBy(order.items, (e) => e.price.provider.toLowerCase());

  useEffect(() => {
    setData(orderBy(sortedItems, (e) => e.item.title));
  }, [items]);

  const { meta, tableData } = useTableData({ allData: data });

  const handleCellOrders = ({ cell, direction }: HandleCellOrders) => {
    let res = items;

    switch (cell) {
      case 'Title':
        res = orderBy(
          items,
          [(e) => e.price.provider.toLowerCase(), (e) => e.item.title],
          [supplierDirection, direction]
        );
        break;
      case 'Variant ID':
        res = orderBy(
          items,
          [(e) => e.price.provider.toLowerCase(), (e) => e.item.variantID],
          [supplierDirection, direction]
        );
        break;
      case 'MFR Code':
        res = orderBy(
          items,
          [(e) => e.price.provider.toLowerCase(), (e) => e.item.manufacturerCode],
          [supplierDirection, direction]
        );
        break;
      case 'Partner SKU':
        res = orderBy(
          items,
          [(e) => e.price.provider.toLowerCase(), (e) => e.price.sku],
          [supplierDirection, direction]
        );
        break;
      case 'Supplier':
        res = orderBy(items, (el) => el.price.provider.toLowerCase(), direction);
        setSupplierDirection(supplierDirection === 'asc' ? 'desc' : 'asc');
        break;
      case 'Quantity':
        res = orderBy(
          items,
          [(e) => e.price.provider.toLowerCase(), (e) => e.quantity],
          [supplierDirection, direction]
        );
        break;
      case 'Per Item':
        res = orderBy(
          items,
          [(e) => e.price.provider.toLowerCase(), (e) => orderSupplierPrice(e.price)],
          [supplierDirection, direction]
        );
        break;
      case 'Total':
        res = orderBy(
          items,
          [(e) => e.price.provider.toLowerCase(), (e) => orderSupplierPrice(e.price) * e.quantity],
          [supplierDirection, direction]
        );
        break;
    }

    setData(res);
  };

  return (
    <>
      <Table
        name="OrderItems"
        title={
          <>
            <span {...dragHandleProps}>
              <DragIcon />
            </span>
            Items
          </>
        }
        setData={setData}
        handleCellOrders={handleCellOrders}
        headerContent={
          <div className="flexAlign gg8">
            <button className="outlined lg" onClick={() => setOpenOrderModal(true)}>
              Edit Order Items
            </button>
            <p>Total: {convertPrice(order.subtotal)}</p>
          </div>
        }
        nav={[
          '#',
          'Title',
          'Variant ID',
          'MFR Code',
          'Partner SKU',
          'Supplier',
          'Quantity',
          'Per Item',
          'Subotal',
          'Promo Text',
          'Total',
          'URL'
        ]}
        meta={meta}
        allData={items}
        content={
          <div className="tableBody">
            {tableData.map(({ item, price, quantity }: OrderItem) => (
              <div key={`${item.objectID}-${price.provider}`} className="tableRow">
                <div className="tableCell indexOf">
                  {findIndex(data, (el) => el.item.objectID === item.objectID && el.price.provider === price.provider) +
                    1}
                </div>
                <TableCellTooltip content={item.title} />
                <TableCellTooltip content={item.variantID} />
                <TableCellTooltip content={item.manufacturerCode}>
                  <a
                    href={formatProductHref(item.objectID || '', item.title)}
                    className="link"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {item.manufacturerCode}
                  </a>
                </TableCellTooltip>
                <TableCellTooltip content={price.sku} />
                <TableCellTooltip content={price.provider} />
                <TableCellTooltip content={quantity} />
                <TableCellTooltip content={convertPrice(orderSupplierPrice(price))} />
                <TableCellTooltip content={convertPrice(orderSupplierPrice(price) * quantity)} />
                <TableCellTooltip
                  content={price.promotion ? getPromotion(price.promotion.type, price.promotion.data).toString() : ''}
                />
                <TableCellTooltip
                  content={
                    price.promotion
                      ? convertPrice(
                          getPromotion(price.promotion.type, price.promotion.data).calculateTotal(
                            orderSupplierPrice(price),
                            quantity
                          )
                        )
                      : ''
                  }
                />
                <div className="tableCell">
                  {price.URL && (
                    <a href={price.URL} target="_blank" rel="noreferrer">
                      <LinkIcon />
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
        }
      />
      {openEditModal && <EditOrderItemsModal org={org} close={() => setOpenOrderModal(false)} />}
    </>
  );
};
