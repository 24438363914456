import { Dispatch, SetStateAction, useState } from 'react';
import { CreateGpoForm } from './CreateGpoForm';

interface Props {
  refetch: () => void;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
}

export const Hero = ({ refetch, search, setSearch }: Props) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <>
      <div className="gpoHero">
        <div>
          <h1 className="pageTitle">GPOs</h1>
        </div>

        <button className="outlined lg" onClick={() => setIsOpenModal(true)}>
          Create GPO
        </button>
      </div>

      {isOpenModal && <CreateGpoForm close={() => setIsOpenModal(false)} refetch={refetch} />}
    </>
  );
};
