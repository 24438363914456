import {
  StripeElementClasses,
  StripeElementStyle,
  StripeElementStyleVariant,
} from '@stripe/stripe-js';

const base: StripeElementStyleVariant = {
  color: '#080c2d',
  fontFamily: 'LexendRegular',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  '::placeholder': {
    fontSize: '0px',
  },
};

const invalid: StripeElementStyleVariant = {
  color: '#ef5628',
  '::placeholder': {
    color: '#ef5628',
  },
};

const style: StripeElementStyle = { base, invalid };

const classes: StripeElementClasses = {
  focus: 'focused',
  empty: 'empty',
  invalid: 'invalid',
};

export const stripeStyle = { style, classes };
