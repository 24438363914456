import { useState } from 'react';

import { SearchIcon } from 'assets/icons/SearchIcon';
import { searchByKeys } from 'util/mix';

interface Props {
  allData: any[];
  searchKeys?: string[];
  currentPage: number;
  setCurrentPage: (page: number) => void;
  setData?: (data: any[]) => void;
}

export const TableSearch = ({ allData, searchKeys, currentPage, setCurrentPage, setData }: Props) => {
  const [inputValue, setInputValue] = useState('');

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!searchKeys) return;

    const value = e.target.value;

    const result = searchByKeys(searchKeys, allData, value.trim());

    setInputValue(value);
    if (setData) setData(result);
    if (currentPage !== 1) setCurrentPage(1);
  };

  return (
    <>
      {searchKeys && (
        <div className="tableSearch">
          <SearchIcon />
          <input value={inputValue} onChange={onSearch} placeholder="Search" />
        </div>
      )}
    </>
  );
};
