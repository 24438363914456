import { FormikProps } from 'formik';
import { MDXEditor, headingsPlugin, listsPlugin, linkPlugin } from '@mdxeditor/editor';
import get from 'lodash/get';

interface Props {
  formik: FormikProps<any>;
  label: string;
  name: string;
}

export type InputT = Props & React.InputHTMLAttributes<HTMLInputElement>;

export const MarkdownField = ({ formik, name, label }: InputT) => {
  const hasError =
    (get(formik.touched, name) && get(formik.errors, name)) || (formik.submitCount > 0 && get(formik.errors, name));

  return (
    <div className={`input-field ${hasError ? 'invalid' : 'valid'}`}>
      <label>{label}</label>
      <MDXEditor
        markdown={formik.values.description}
        plugins={[headingsPlugin(), listsPlugin(), linkPlugin()]}
        onChange={(value) => formik.setFieldValue(name, value)}
        onBlur={() => {
          formik.handleBlur({ target: { name: name } });
        }}
        placeholder="Add text here..."
        className="mdxEditor"
      />
      {hasError && <span className="errorMsg">{get(formik.errors, name) as string}</span>}
    </div>
  );
};
