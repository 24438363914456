export const DotsIcon = (props?: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
    className="dotsIcon"
    {...props}
  >
    <path
      fill="#080C2D"
      d="M10 2.5c-.688 0-1.25.563-1.25 1.25C8.75 4.438 9.313 5 10 5c.688 0 1.25-.563 1.25-1.25 0-.688-.563-1.25-1.25-1.25zM10 15c-.688 0-1.25.563-1.25 1.25 0 .688.563 1.25 1.25 1.25.688 0 1.25-.563 1.25-1.25 0-.688-.563-1.25-1.25-1.25zm0-6.25c-.688 0-1.25.563-1.25 1.25 0 .688.563 1.25 1.25 1.25.688 0 1.25-.563 1.25-1.25 0-.688-.563-1.25-1.25-1.25z"
    ></path>
  </svg>
);
