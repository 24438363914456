import { useState } from 'react';

const useDebounce = () => {
  const [delay, setDelay] = useState<any>('');

  const onDebounce = (value: string, callback: (value: string) => void) => {
    if (delay) clearTimeout(delay);
    let copyState = delay;
    copyState = setTimeout(() => {
      callback(value);
    }, 300);

    setDelay(copyState);
  };

  return {
    onDebounce,
  };
};

export default useDebounce;
