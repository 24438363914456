import { Form, Formik } from 'formik';
import { useMutation } from 'react-query';
import dayjs from 'dayjs';

import { CreatePurchaseOrderShipmentPayload } from 'api/purchaseOrders/types';
import { OrderItem, PurchaseOrder } from 'types/order.types';

import { PurcahseOrderSelect } from 'pages/order/fullScreenModals/fields/PurcahseOrderSelect';
import { AdditionalTrackingNumbers } from './AdditionalTrackingNumbers';
import { payloadPurchaseOrdersItems } from 'util/purchaseOrders';
import { createPuchaseOrderShipment } from 'api/purchaseOrders';
import { CreateShipmentTable } from './CreateShipmentTable';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { useOrderStore } from 'stores/OrderStore';

interface Props {
  close: () => void;
}

interface FormikState {
  additionalTrackingNumbers: { TrackingNumber: string; Carrier: string; Eta: Date }[];
  purchaseOrder: PurchaseOrder | null;
  selectedItems: OrderItem[];
  allItems: OrderItem[];
}

export const CreateShipmentForm = ({ close }: Props) => {
  const { order, setOrder } = useOrderStore();

  const initialValues: FormikState = {
    purchaseOrder: null,
    selectedItems: [],
    allItems: [],
    additionalTrackingNumbers: [{ TrackingNumber: '', Carrier: '', Eta: new Date() }]
  };

  const { mutate, isLoading } = useMutation(createPuchaseOrderShipment);

  const onSubmit = (values: FormikState) => {
    if (!values.additionalTrackingNumbers.length) return;

    const payload: CreatePurchaseOrderShipmentPayload = {
      orderID: order.orderID,
      orgID: order.orgID,
      body: {
        purchaseOrderID: values.purchaseOrder?.id || '',
        trackingNumber: values.additionalTrackingNumbers[0].TrackingNumber,
        carrier: values.additionalTrackingNumbers[0].Carrier,
        items: payloadPurchaseOrdersItems(values)
      }
    };

    if (values.additionalTrackingNumbers[0].Carrier === 'faliam') {
      payload.body.eta = dayjs(values.additionalTrackingNumbers[0].Eta).format('YYYY-MM-DD');
    }

    if (values.additionalTrackingNumbers.slice(1).length) {
      payload.body.additionalTrackingNumbers = values.additionalTrackingNumbers.slice(1).map((el) => ({
        ...el,
        Eta: el.Carrier === 'faliam' ? (dayjs(el.Eta).format('YYYY-MM-DD') as any) : undefined
      }));
    }

    mutate(payload, {
      onSuccess: (res) => {
        setOrder(res);
        close();
      }
    });
  };

  return (
    <div className="orderFullScreenModal">
      <div className="paper">
        <div className="paperHeader">
          <h2>Create shipment</h2>
          <CloseIcon className="closeIcon" onClick={close} />
        </div>
        <div className="paperBody">
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            <Form>
              <PurcahseOrderSelect purchaseOrders={order.purchaseOrders || []} />
              <AdditionalTrackingNumbers />
              <CreateShipmentTable order={order} />
              <div className="submitBtnHolder">
                <button className="contained" type="submit" disabled={isLoading}>
                  Create Shipment
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};
