import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useFormik } from 'formik';

import { updateOfficeRepresentatives, updateOrgRepresentatives } from 'api/providers';
import { UpdateOrgRepresentativesPayload } from 'api/providers/types';
import { GpoMember, GpoPartner } from 'api/gpo/types';
import { getOrg } from 'api/orgs';

import { CustomPhoneInput } from 'components/ui/CustomPhoneInput';
import { RepresentativeSelects } from './RepresentativeSelects';
import { SelectOption } from 'components/shared/OfficesSelect';
import { RepresentativeActionForm } from './RepresentativeActionForm';
import { OrgCredential, RepresentativeWithExtension } from 'api/orgs/types';
import { InputField } from 'components/ui/Input';
import { DotsIcon } from 'assets/icons/DotsIcon';
import { Modal } from 'components/ui/Modal';
import { toast } from 'react-toastify';
import { isActiveClass } from 'util/classes';

type Props = {
  member: GpoMember;
  partners: GpoPartner[];
  gpoID: string;
  gpoName?: string;
  close: () => void;
  refetch: () => void;
};

export const RepresentativeCredentialsModal = ({ close, member, gpoID, refetch, partners, gpoName }: Props) => {
  const [provider, setProvider] = useState<SelectOption>();
  const [office, setOffice] = useState<SelectOption>();
  const [showForm, setShowForm] = useState(false);
  const [actionSubmenu, setActionSubmenu] = useState('');
  const [repForUpdate, setRepForUpdate] = useState<RepresentativeWithExtension>();

  const onSuccess = () => {
    refetch();
    refetchOrg();
    close();
  };

  const onError = () => close();

  const { data: org, refetch: refetchOrg } = useQuery(['get-single-org'], () => getOrg(member.orgID));
  const { mutate: updateOrg, isLoading: isLoadingOrg } = useMutation(updateOrgRepresentatives, { onSuccess, onError });
  const { mutate: updateOffice, isLoading: isLoadingOffice } = useMutation(updateOfficeRepresentatives, {
    onSuccess,
    onError
  });

  const nonGpoRepresentatives = useMemo(() => {
    if (org) {
      const providerCredentialsData = org?.credentials.find(
        ({ providerName, gpoConnected }) => providerName === provider?.value && !gpoConnected
      );

      return office?.label === 'Organization' && Boolean(office.value)
        ? providerCredentialsData?.representatives || []
        : providerCredentialsData?.offices?.[office?.value || ''] || [];
    }
  }, [provider, office, org]);

  const gpoRepresentatives = useMemo(() => {
    if (org) {
      const providerCredentialsData =
        org?.credentials
          .filter(({ providerName, gpoConnected }) => providerName === provider?.value && gpoConnected)
          .find(({ gpoName: name }) => name === gpoName) || [];

      return office?.label === 'Organization' && Boolean(office.value)
        ? (providerCredentialsData as OrgCredential)?.representatives || []
        : (providerCredentialsData as OrgCredential)?.offices?.[office?.value || ''] || [];
    }
  }, [provider, office, org, gpoName]);

  const updatedInitialValues =
    gpoRepresentatives?.map(({ email, phone }) => {
      if (phone?.includes('EXT')) {
        const [phoneWithoutExt, extension] = phone.split(' EXT ');
        return { email, phone: phoneWithoutExt, extension };
      }
      return { email, phone };
    }) || [];

  const formik = useFormik<Array<RepresentativeWithExtension>>({
    initialValues: gpoRepresentatives?.length ? updatedInitialValues : [],
    enableReinitialize: true,
    onSubmit: (values) => {
      const contacts = values
        .filter(({ email, phone }) => email || phone)
        .map(({ email, phone, extension }) => ({
          email,
          phone: extension ? `${phone} EXT ${extension}` : phone
        }));

      const payload: UpdateOrgRepresentativesPayload = {
        contacts,
        gpoID,
        orgID: org?.metadata.orgID || '',
        providerName: provider?.value || ''
      };
      office?.label === 'Organization' && Boolean(office.value)
        ? updateOrg(payload)
        : updateOffice({ ...payload, officeID: office?.value || '' });
    }
  });

  const hasProviderAndOffice = Boolean(provider?.value && office?.value);

  return (
    <Modal close={close} open={Boolean(member)} title="Add Representatives" className="addCredentialsModal">
      <form onSubmit={formik.handleSubmit}>
        <div className={`${isActiveClass('scrollable', hasProviderAndOffice)}`}>
          <div className="col">
            {!repForUpdate && !showForm && (
              <RepresentativeSelects
                org={org}
                office={office}
                partners={partners}
                provider={provider}
                setOffice={setOffice}
                setProvider={setProvider}
              />
            )}

            {repForUpdate && (
              <RepresentativeActionForm
                initialValue={repForUpdate}
                close={() => setRepForUpdate(undefined)}
                isLoading={isLoadingOffice || isLoadingOrg}
                onAction={(newRep) => {
                  const contacts = formik.values
                    .filter(({ email, phone }) => email || phone)
                    .map((temp) =>
                      temp.email === repForUpdate?.email && temp.phone?.includes(repForUpdate?.phone || '')
                        ? newRep
                        : temp
                    )
                    .map(({ email, phone, extension }) => ({
                      email,
                      phone: extension ? `${phone} EXT ${extension}` : phone
                    }));

                  const payload: UpdateOrgRepresentativesPayload = {
                    contacts,
                    gpoID,
                    orgID: org?.metadata.orgID || '',
                    providerName: provider?.value || ''
                  };

                  office?.label === 'Organization'
                    ? updateOrg(payload)
                    : updateOffice({ ...payload, officeID: office?.value || '' });
                }}
              />
            )}

            {hasProviderAndOffice && !repForUpdate && !showForm && (
              <>
                <div className="repList">
                  <p>GPO representatives</p>
                  {formik.values?.length ? (
                    formik.values?.map(({ extension, isNew, ...rest }, i) => {
                      const email = rest?.email || '';
                      const phone = rest?.phone || '';
                      return (
                        <div className="repPaper" key={email || '' + phone || '' + i}>
                          <div className="left">
                            <div className="row">
                              <p>{email}</p> {isNew && <span>New</span>}
                            </div>
                            <p>{extension ? `${phone} EXT ${extension}` : phone}</p>
                          </div>
                          <div className="actionMenuWrapper">
                            <DotsIcon
                              onClick={() =>
                                actionSubmenu === email + phone + i
                                  ? setActionSubmenu('')
                                  : setActionSubmenu(email + phone + i)
                              }
                            />

                            {actionSubmenu === email + phone + i && (
                              <ul className="actionMenu">
                                <li className="" onClick={() => setRepForUpdate({ email, phone, extension })}>
                                  Update representative
                                </li>
                                <li
                                  className="delete"
                                  onClick={() => {
                                    formik.setValues(formik.values.filter((rep, index) => i !== index));
                                    setActionSubmenu('');
                                  }}
                                >
                                  Delete representative
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p>No representatives found...</p>
                  )}
                </div>
                <div className="repList">
                  <p>{office?.label === 'Organization' ? 'Organization' : 'Office'} representatives</p>
                  {nonGpoRepresentatives?.length ? (
                    nonGpoRepresentatives?.map((el, i) => {
                      return (
                        <div className="repPaper" key={el?.email || '' + el?.phone + i}>
                          <div className="left">
                            <p>{el?.email}</p>
                            <p>{el?.phone}</p>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p>No representatives found...</p>
                  )}
                </div>
              </>
            )}

            {hasProviderAndOffice && !repForUpdate && !showForm && (
              <button type="button" className="outlined addNew" onClick={() => setShowForm((p) => !p)}>
                Add New Gpo Representative
              </button>
            )}

            {showForm && (
              <RepresentativeActionForm
                onAction={(newRep) => {
                  if (!newRep?.email && !newRep?.phone) return toast.error('Please add phone number or email');
                  formik.setValues([...formik.values, { ...newRep, isNew: true }]);
                  setShowForm(false);
                }}
                close={() => setShowForm(false)}
              />
            )}
          </div>
        </div>
        {!repForUpdate && (
          <button
            type="submit"
            className="submitBtn contained"
            disabled={isLoadingOffice || isLoadingOrg || !hasProviderAndOffice || showForm}
          >
            Save Changes
          </button>
        )}
      </form>
    </Modal>
  );
};
