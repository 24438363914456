import { toast } from 'react-toastify';

import { Order } from 'types/order.types';

import { apiCall, BASE_URL_VARIANTS } from 'api';
import {
  AttachPDFToS3Params,
  GetPDFParams,
  GetProviderToken,
  UpdateOrderPayload,
  UpdateOrderStatusPayload,
  UpdateOverallOrderStatusPayload,
  UpdateShipmentPayload
} from './types';

export const getOrder = async (orgID: string, orderID: string): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  const { data } = await request({
    url: `/v2/organizations/${orgID}/orders/${orderID}`,
    method: 'GET'
  });

  return data;
};

export const getSnapshotOrder = async (orgID: string, orderID: string): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  const { data } = await request({
    url: `/v2/organizations/${orgID}/orders/${orderID}`,
    method: 'GET',
    params: {
      sequenceNumber: 1
    }
  });

  return data;
};

export const updateOrder = async ({ orgID, orderID, body }: UpdateOrderPayload): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    const { data } = await request({
      url: `/v2/organizations/${orgID}/orders/${orderID}`,
      method: 'PATCH',
      data: body
    });

    toast('The order has been updated.', { type: 'success' });

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

export const updateOrderStatus = async ({ orgID, orderID, body }: UpdateOrderStatusPayload): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    const { data } = await request({
      url: `/v2/organizations/${orgID}/orders/${orderID}/approval`,
      method: 'POST',
      data: body
    });

    toast('The order status has been updated.', { type: 'success' });

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

export const updateOverallOrderStatus = async ({
  orgID,
  orderID,
  body
}: UpdateOverallOrderStatusPayload): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    const { data } = await request({
      url: `/v2/organizations/${orgID}/orders/${orderID}/csoverride`,
      method: 'POST',
      data: body
    });

    toast('The order status has been updated.', { type: 'success' });

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

export const getProviderToken = async ({ orderID, provider, type }: GetProviderToken) => {
  const request = await apiCall(BASE_URL_VARIANTS.PROCUREMENT);

  try {
    const { data } = await request({
      url: `/orders/${orderID}/${type}`,
      method: 'GET',
      params: { provider }
    });

    toast('The token has been received successfully.', { type: 'success' });

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

export const attachPDFtoS3 = async ({ orgID, orderID, docID, file }: AttachPDFToS3Params) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    const { data } = await request({
      url: `/v2/organizations/${orgID}/orders/${orderID}/documents/${docID}/filename/${file.name}`,
      method: 'PUT'
    });

    const formData = new FormData();
    formData.append('file', file);

    await fetch(data.accessURL, { method: 'PUT', body: file.type.includes('pdf') ? formData : file });
    toast('File has been uploaded successfully.', { type: 'success' });
  } catch (error) {}
};

export const getPDF = async ({ orgID, orderID, docID, filename }: GetPDFParams) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    const { data } = await request({
      url: `/v2/organizations/${orgID}/orders/${orderID}/documents/${docID}/filename/${filename}`,
      method: 'GET'
    });
    const res = await fetch(data.accessURL, { method: 'GET' });
    const type = filename?.includes('pdf') ? 'pdf' : 'image';
    const pdf = await res.blob();

    return {
      type,
      file: type === 'pdf' ? pdf : res.url
    };
  } catch (error) {}
};

export const deletePDF = async ({ orgID, orderID, docID, filename }: GetPDFParams) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    await request({
      url: `/v2/organizations/${orgID}/orders/${orderID}/documents/${docID}/filename/${filename}`,
      method: 'DELETE'
    });

    toast('File has been deleted successfully.', { type: 'success' });
  } catch (error) {}
};

export const updateShipment = async ({ orgID, orderID, shipmentID, body }: UpdateShipmentPayload): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    const { data } = await request({
      url: `/v2/organizations/${orgID}/orders/${orderID}/shipments/${shipmentID}`,
      method: 'PATCH',
      data: body
    });

    toast.success('The shipment has been updated.');

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};
