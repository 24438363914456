import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Org } from 'api/orgs/types';

import { Payment } from 'types/payment.types';

import { StripeForm } from './StripeForm';

export interface StripeProps {
  org: Org;
  officeID: string;
  onSuccess: (item: Payment) => void;
  setOfficeID: (officeID: string) => void;
}

const key = process.env.REACT_APP_STRIPE_LOCAL_API_SECRET || '';

export const Stripe = (props: StripeProps) => {
  const stripePromise = loadStripe(key);

  return (
    <Elements stripe={stripePromise}>
      <StripeForm {...props} />
    </Elements>
  );
};
