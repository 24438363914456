import { useCallback, useMemo, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { useMutation } from 'react-query';
import { Calendar } from 'react-calendar';
import dayjs from 'dayjs';

import { Order, ProviderInvoice, PurchaseOrder } from 'types/order.types';
import { OrgOrderItem } from 'types/orgs.types';
import {
  AttachProviderInvoicePayload,
  PurchaseOrderItemPayload,
  UpdateProviderInvoicePayload
} from 'api/purchaseOrders/types';

import { convertOtherCostsToFormikFormat, setOtherCostsToPayload } from 'util/purchaseOrders';
import { OtherCostsField } from 'pages/order/fullScreenModals/fields/OtherCostsField';
import { attachProviderInvoice, updateProviderInvoice } from 'api/purchaseOrders';
import { ProviderInvoiceModalTable } from './ProviderInvoiceModalTable';
import { PdfViewer } from 'components/shared/orders/PDF/PdfViewer';
import { AttachPDF } from 'components/shared/orders/PDF/AttachPDF';
import { getCurrencyValue, roundCurrencyValue } from 'util/mix';
import { CurrencyField } from 'components/ui/CurrencyField';
import { DownArrowIcon } from 'assets/icons/DownArrow';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { Checkbox } from 'components/ui/Checkbox';
import { useOrderStore } from 'stores/OrderStore';
import { Select } from 'components/ui/Select';
import { isActiveClass } from 'util/classes';
import { Modal } from 'components/ui/Modal';
import { formatDate } from 'util/dates';

interface Props {
  refetch: () => void;
  purchaseInvoice: ProviderInvoice | null;
  close: () => void;
}

export interface ItemWithBoxes {
  isCollapsed: boolean;
  isChecked: boolean;
  id: string;
  qty: number;
  item: OrgOrderItem;
  boxes: {
    status: string;
    price: number;
    id: string;
    isChecked: boolean;
  }[];
}

export interface ProviderInvoiceFormikState {
  purchaseOrderID: string;
  provider: string;
  dueDate: Date;
  referenceID: string;
  shippingCosts: number | string;
  taxCosts: number | string;
  otherCosts: Array<{ label: string; price: string | number }>;
  billedToFaliam?: boolean;
  customerVisible?: boolean;
  isFaliamPlaceholder?: boolean;
  supplierID: string;
  supplierName: string;
}

export const ProviderInvoiceForm = ({ purchaseInvoice, close, refetch }: Props) => {
  const [itemsWithBoxes, setItemsWithBoxes] = useState<ItemWithBoxes[]>([]);
  const [isOpenProvidersSelect, setIsOpenProvidersSelect] = useState(false);
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);

  const { order, setOrder } = useOrderStore();
  const { mutate: createMutate, isLoading: isLoadingCreate } = useMutation(attachProviderInvoice);
  const { mutate: updateMutate, isLoading: isLoadingUpdate } = useMutation(updateProviderInvoice);

  const initialValues: ProviderInvoiceFormikState = {
    purchaseOrderID: '', // deprecated
    provider: purchaseInvoice?.provider || '',
    dueDate: purchaseInvoice?.due ? new Date(purchaseInvoice?.due) : new Date(),
    referenceID: purchaseInvoice?.referenceID || '',
    shippingCosts: purchaseInvoice?.shippingCosts ? getCurrencyValue(purchaseInvoice.shippingCosts) : '',
    taxCosts: purchaseInvoice?.tax ? getCurrencyValue(purchaseInvoice.tax) : '',
    otherCosts: convertOtherCostsToFormikFormat(purchaseInvoice?.otherCosts),
    billedToFaliam: purchaseInvoice?.billedToFaliam || false,
    customerVisible: purchaseInvoice ? purchaseInvoice?.customerVisible : true,
    isFaliamPlaceholder: purchaseInvoice?.isFaliamPlaceholder || false,
    supplierID: '',
    supplierName: ''
  };

  const getPurchasePayload = (values: ProviderInvoiceFormikState) => {
    const payload: any = {};

    if (values.shippingCosts) payload.shippingCosts = roundCurrencyValue(values.shippingCosts);
    if (values.taxCosts) payload.taxCosts = roundCurrencyValue(values.taxCosts);
    if (values.otherCosts.length && values.otherCosts[0].label)
      payload.otherCosts = setOtherCostsToPayload(values.otherCosts);

    return payload;
  };

  const formatItems = (provider?: string) => {
    const groupedByPrice = itemsWithBoxes
      .filter((e) => e.isChecked)
      .reduce((result: any, item: ItemWithBoxes) => {
        item.boxes
          .filter((b) => b.isChecked)
          .forEach((box) => {
            const { price } = box;
            const itemID = `${item.item.item.objectID}:${item.item.price.provider}-${price}`;

            result[itemID] = result[itemID] || { price, items: [], qty: 0 };
            result[itemID].items.push({ item, box });
            result[itemID].qty = result[itemID].items.length;
          });

        return result;
      }, {});

    const res: PurchaseOrderItemPayload[] = Object.values(groupedByPrice).map((item: any) => {
      const firstItem = item.items[0].item;
      return {
        price: item.price,
        quantity: item.qty,
        variantID: firstItem.item.item.objectID,
        provider: provider || firstItem.item.price.provider
      };
    });

    return res;
  };

  const onCreateProviderInvoice = (values: ProviderInvoiceFormikState) => {
    const supplierID = order.orderOverview.StackInfo.filter((el) => el.SupplierName === values.provider)?.[0]
      ?.SupplierID;

    const payload: AttachProviderInvoicePayload = {
      orderID: order.orderID,
      orgID: order.orgID,
      body: {
        purchaseOrderID: values.purchaseOrderID,
        dueDate: dayjs(values.dueDate).format('YYYY-MM-DD'),
        items: formatItems(),
        isFaliamPlaceholder: values.isFaliamPlaceholder,
        customerVisible: values.customerVisible,
        billedToFaliam: values.billedToFaliam,
        supplierName: values?.provider,
        supplierID,
        ...getPurchasePayload(values)
      }
    };
    if (values.referenceID) payload.body.referenceID = values.referenceID;

    createMutate(payload, { onSuccess });
  };

  const onUpdateProviderInvoice = (values: ProviderInvoiceFormikState) => {
    if (!purchaseInvoice) return;

    const payload: UpdateProviderInvoicePayload = {
      orderID: order.orderID,
      orgID: order.orgID,
      invoiceID: purchaseInvoice.id,
      body: {
        dueDate: dayjs(values.dueDate).format('YYYY-MM-DD'),
        ...getPurchasePayload(values),
        items: formatItems(values.provider),
        billedToFaliam: values.billedToFaliam,
        customerVisible: values.customerVisible,
        isFaliamPlaceholder: values.isFaliamPlaceholder
      }
    };
    if (values.referenceID) payload.body.referenceID = values.referenceID;

    updateMutate(payload, { onSuccess });
  };

  const onSubmit = (values: ProviderInvoiceFormikState) => {
    if (purchaseInvoice) return onUpdateProviderInvoice(values);

    onCreateProviderInvoice(values);
  };

  const onSuccess = (res: Order) => {
    setOrder(res);
    close();
  };

  const itemsTotal = useMemo(() => {
    return (
      itemsWithBoxes
        .filter((e) => e.isChecked)
        .reduce((sum, item) => {
          const price = item.boxes.filter((b) => b.isChecked).reduce((sum, box) => sum + box.price, 0);

          return sum + price;
        }, 0) / 100
    );
  }, [itemsWithBoxes]);

  const totalInvoicePrice = useCallback(
    (values: ProviderInvoiceFormikState) => {
      const shippingCosts = Number(values.shippingCosts) || 0;
      const taxCosts = Number(values.taxCosts) || 0;
      const otherCosts = values.otherCosts.reduce((sum, item) => sum + Number(item.price), 0);
      const total = itemsTotal + shippingCosts + taxCosts + otherCosts;

      return Number(total.toFixed(2));
    },
    [itemsTotal]
  );

  const renderBadgeByProvider = (item: PurchaseOrder) => {
    const { provider, items } = item;

    const allOrderedItems = order.providerInvoices.reduce((acc, curr) => [...acc, ...curr.items], [] as OrgOrderItem[]);
    const supplierProviderItems = allOrderedItems.filter((item) => item.price.provider === provider);

    const supplierItems = items.filter((item) => item.price.provider === provider);

    if (supplierProviderItems.length >= supplierItems.length)
      return <span className="badge success">Fully Covered</span>;
    return (
      allOrderedItems.some((el) => el.price.provider === provider) && (
        <span className="badge secondary">Partially Covered</span>
      )
    );
  };

  return (
    <div className="orderFullScreenModal">
      <div className="paper">
        <div className="paperHeader">
          <h2>{purchaseInvoice ? 'Update' : 'Create'} Provider Invoice</h2>
          <CloseIcon className="closeIcon" onClick={close} />
        </div>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, setFieldValue, setValues }) => (
            <Form>
              <div className={`paperBody ${Object.values(purchaseInvoice?.files || {}).length ? 'gridX2' : ''}`}>
                {Object.values(purchaseInvoice?.files || {}).length ? (
                  <PdfViewer docID={purchaseInvoice?.id || ''} files={purchaseInvoice?.files || {}} refetch={refetch} />
                ) : null}
                <div>
                  <div className="flexAlign gg8">
                    <div
                      className="flexAlign gg8 cur-pointer"
                      onClick={() => setFieldValue('billedToFaliam', !values.billedToFaliam)}
                    >
                      <label className="cur-pointer">Paid By Faliam</label>
                      <Checkbox isEnabled={values.billedToFaliam || false} />
                    </div>
                    <div
                      className="flexAlign gg8 cur-pointer"
                      onClick={() => setFieldValue('customerVisible', !values.customerVisible)}
                    >
                      <label className="cur-pointer">Visible To Customer</label>
                      <Checkbox isEnabled={values.customerVisible || false} />
                    </div>
                    <div
                      className="flexAlign gg8 cur-pointer"
                      onClick={() => setFieldValue('isFaliamPlaceholder', !values.isFaliamPlaceholder)}
                    >
                      <label className="cur-pointer">Placeholder</label>
                      <Checkbox isEnabled={values.isFaliamPlaceholder || false} />
                    </div>
                  </div>
                  <div className="fielsGroup">
                    {!purchaseInvoice && (
                      <div className="field">
                        <label>Provider</label>
                        <Select
                          className="regular suppliersSelect"
                          isOpen={isOpenProvidersSelect}
                          setIsOpen={setIsOpenProvidersSelect}
                          options={
                            <ul>
                              {order.purchaseOrders?.map((item) => (
                                <li
                                  key={item.id}
                                  onClick={() => {
                                    setValues({ ...values, provider: item.provider, purchaseOrderID: item.id });
                                    setIsOpenProvidersSelect(false);
                                  }}
                                  className={isActiveClass('', values.provider === item.id)}
                                >
                                  {item.provider}
                                  {renderBadgeByProvider(item)}
                                </li>
                              ))}
                            </ul>
                          }
                        >
                          <p>{values.provider}</p>
                          <DownArrowIcon />
                        </Select>
                      </div>
                    )}
                    <div className="field">
                      <label>Invoice Number</label>
                      <Field type="text" name="referenceID" />
                    </div>
                    <div className="field">
                      <label>Due Date</label>
                      <div className="fakeField" onClick={() => setIsOpenCalendar(true)}>
                        {formatDate(values.dueDate)}
                      </div>
                      {isOpenCalendar && (
                        <Modal
                          open={true}
                          close={() => setIsOpenCalendar(false)}
                          title="Select Date"
                          className="ordersDatePicker"
                        >
                          <Calendar
                            onChange={(dueDate: Date) => {
                              setFieldValue('dueDate', dueDate);
                              setIsOpenCalendar(false);
                            }}
                            value={values.dueDate}
                          />
                        </Modal>
                      )}
                    </div>
                    <CurrencyField name="shippingCosts" label="Shipping" />
                    <CurrencyField name="taxCosts" label="Tax" />
                    <div className="x2Fields">
                      <CurrencyField name="itemSubtotal" label="Item Subtotal" value={itemsTotal} readOnly />
                      <CurrencyField
                        name="invoiceTotal"
                        label="Invoice Total"
                        value={totalInvoicePrice(values)}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="otherCostsRow">
                    <OtherCostsField />
                  </div>
                  {purchaseInvoice && <AttachPDF docID={purchaseInvoice.id} refetch={refetch} />}
                </div>
              </div>
              <ProviderInvoiceModalTable
                itemsWithBoxes={itemsWithBoxes}
                setItemsWithBoxes={setItemsWithBoxes}
                purchaseInvoice={purchaseInvoice}
              />
              <div className="submitBtnHolder">
                <button className="contained" type="submit" disabled={isLoadingCreate || isLoadingUpdate}>
                  {purchaseInvoice ? 'Update' : 'Create'} Provider Invoice
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
