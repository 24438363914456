import { useMemo } from 'react';

import { Order, OrderItem } from 'types/order.types';

interface Props {
  order: Order;
}

interface Output {
  [key: string]: {
    [key: string]: Array<{
      backOrder: OrderItem['backOrder'];
      cancelled: OrderItem['cancelled'];
      item: OrderItem['item'];
      price: OrderItem['price'];
      quantity: OrderItem['quantity'];
      affectedQty: number;
    }>;
  };
}

const keys = [
  'NeedsPurchasing',
  'NeedsPurchaseConfirmation',
  'NeedsProviderInvoice',
  'NeedsShipment',
  'NeedsDelivery',
  'NeedsReconciliation',
  'NeedsFaliamInvoice',
  'NeedsPayment'
];

export const useMissingOrderItems = ({ order }: Props) => {
  const missingOrderItems: Output = useMemo(() => {
    const { items, orderOverview } = order;

    const res = keys.reduce((acc, key) => {
      acc[key] = items
        .filter((_, i) => Object.keys((orderOverview as any)[key]).includes(String(i)))
        .map((el) => ({
          ...el,
          affectedQty: Object.values((orderOverview as any)[key]).reduce((acc: any, val: any) => acc + val, 0)
        }));
      return acc;
    }, {} as { [key: string]: any[] });

    const grouped = Object.keys(res).reduce((acc, key) => {
      res[key].forEach((item) => {
        const provider = item.price.provider;
        if (!acc[provider]) acc[provider] = {};
        if (!acc[provider][key]) acc[provider][key] = [];

        acc[provider][key].push(item);
      });
      return acc;
    }, {} as { [key: string]: any });

    return grouped;
  }, [order]);

  return {
    missingOrderItems,
    missingOrderItemsLength: Object.keys(missingOrderItems).length
  };
};
