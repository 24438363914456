import { useRefinementList } from 'react-instantsearch-hooks';

import { SelectInput } from 'components/ui/SelectInput';
import { InputField } from 'components/ui/Input';

interface Props {
  formik: any;
}

export const UomPart = ({ formik }: Props) => {
  const { items } = useRefinementList({
    attribute: 'uom',
    limit: 100000,
    sortBy: ['name:asc']
  });

  return (
    <div className="paper">
      <div className="paperHeader">
        <h2>Uom</h2>
      </div>
      <div className="paperBody">
        <SelectInput formik={formik} label="Uom" name="uom" options={items} />
        <InputField
          formik={formik}
          name="conversionFactor"
          label="Conversion Factor"
          placeholder="Conversion Factor"
          type="number"
        />
      </div>
    </div>
  );
};
