import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { Modal } from 'components/ui/Modal';
import { JobType, fetchSingleJob } from 'api/jobs';
import { LoaderIcon } from 'assets/icons/LoaderIcon';

type Props = {
  jobId: string;
  close: () => void;
};

export const SingleJobModal = ({ jobId, close }: Props) => {
  const { data, isLoading } = useQuery(['inventoryUploadJobs', jobId], () => fetchSingleJob(JobType.InventoryUpload, jobId));

  return (
    <Modal close={close} open={Boolean(jobId)} title="Inventory Upload Preview" className="singleJobModal">
      {isLoading ? (
        <LoaderIcon />
      ) : (
        <>
          <div className="field">
            <label>ID</label>
            <p>{data?.id}</p>
          </div>
          <div className="field">
            <label>Status</label>
            <p>{data?.status}</p>
          </div>
          <div className="field">
            <label>Start</label>
            <p>{dayjs(data?.start).format('YYYY-MM-DD')}</p>
          </div>
          <div className="field">
            <label>End</label>
            <p>{data?.end ? dayjs(data?.end).format('YYYY-MM-DD') : 'Not finished yet'}</p>
          </div>
          <div className="field">
            <label>Total Items</label>
            <p>{data?.totalItems}</p>
          </div>
          <div className="field">
            <label>Finished Items</label>
            <p>{data?.finishedItems}</p>
          </div>
          <div className="field">
            <label>Succeeded Items</label>
            <p>{data?.succeededItems}</p>
          </div>
          <div className="field">
            <label>Failed Items</label>
            <p>{data?.failedItems}</p>
          </div>
          <div className="field">
            <label>User Email</label>
            <p>{data?.userEmail || 'Unkown'}</p>
          </div>
          {data?.resultsFile ? (
            <a href={data?.resultsFile} className="contained" download target="_blank" rel="noreferrer">
              Download a file
            </a>
          ) : null}
        </>
      )}
    </Modal>
  );
};
