import { useFormikContext } from 'formik';

import { Office } from 'types/offices.types';

import { isActiveClass } from 'util/classes';
import { formatOffice, formatOfficeAddress } from 'util/offices';

interface Props {
  offices: Office[];
}

export const Offices = ({ offices }: Props) => {
  const { values, setFieldValue }: any = useFormikContext();

  const onSelect = (officeID: string) => {
    if (values.offices.includes(officeID)) {
      setFieldValue(
        'offices',
        values.offices.filter((el: string) => el !== officeID)
      );
    } else {
      setFieldValue('offices', [...values.offices, officeID]);
    }
  };

  return (
    <ul className="offices">
      {offices.map((office) => (
        <li
          key={office.officeID}
          onClick={() => onSelect(office.officeID)}
          className={isActiveClass('', values.offices.includes(office.officeID))}
        >
          <h2>{formatOfficeAddress(office)}</h2>
          <p>{formatOffice(office)}</p>
        </li>
      ))}
    </ul>
  );
};
