interface Props {
  tip?: any;
  content: string | number;
  children?: any;
  className?: string;
}

export const TableCellTooltip = ({
  tip,
  content,
  children,
  className = '',
}: Props) => (
  <div className={`tableCell ${className}`}>
    <span data-tip={tip || content}>{children || content}</span>
  </div>
);
