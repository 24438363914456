/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';

import { AlgoliaHit, Hit, Supplier } from 'types/algolia.types';
import { Org } from 'api/orgs/types';

import { useProvidersStrore } from 'stores/ProvidersStore';
import { useSupplierPrice } from './useSupplierPrice';
import { groupAlgoliaSuppliers } from 'util/hits';
import { useSupplierKind } from './useSupplierKind';

interface Props {
  hits: AlgoliaHit[];
  org?: Org;
}

export const useAlgoliaHits = ({ hits, org }: Props) => {
  const [items, setItems] = useState<Hit[]>([]);
  const { providers } = useProvidersStrore();
  const ids = hits.map((el) => el.objectID);
  const {
    isConnected,
    isPricePending,
    displayPrice,
    price,
    prices,
    findPriceVaraintID,
    findPricePromotions,
    refetchPrices
  } = useSupplierPrice({
    ids,
    org
  });
  const { getSupplierKind } = useSupplierKind();

  useEffect(() => {
    if (hits.length) formatHits();
    else setItems([]);
  }, [hits, org, providers, prices]);

  const formatHits = () => {
    const res: Hit[] = hits.map((hit) => ({
      ...hit,
      suppliers: getSuppliers(hit)
    }));
    setItems(res);
  };

  const getSuppliers = (hit: AlgoliaHit) => {
    const res: Supplier[] = groupAlgoliaSuppliers(hit).map((supplier) => {
      const { partner, sku, stock, sellingPrice, buyingPrice, customSellingPrice } = supplier;
      const kind = getSupplierKind(partner);

      return {
        sku,
        kind,
        stock,
        name: partner,
        connected: isConnected(kind, partner),
        pricePending: isPricePending(hit.objectID, kind, partner),
        price: price(hit.objectID, partner, sellingPrice, customSellingPrice),
        customSellingPrice,
        sellingPrice,
        buyingPrice,
        displayPrice: displayPrice(hit.objectID, kind, partner, sellingPrice, customSellingPrice),
        variantID: findPriceVaraintID(hit.objectID, partner),
        promotions: findPricePromotions(hit.objectID, partner)
      };
    });

    return orderBy(res, (el) => el.price, 'desc');
  };

  return { hitsItems: items, prices, refetchPrices };
};
