import { CheckIcon } from 'assets/icons/CheckIcon';
import { isActiveClass } from 'util/classes';

interface Props {
  isEnabled: boolean;
  onChange?: () => void;
}

export const Checkbox = ({ isEnabled, onChange }: Props) => (
  <div className={isActiveClass('checkbox', isEnabled)} onClick={onChange}>
    <CheckIcon />
  </div>
);
