import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import orderBy from 'lodash/orderBy';

import { GpoAll } from 'api/gpo/types';

import { getGpos } from 'api/gpo';
import { gposKeys } from 'api/gpo/queries';
import useTableData from 'hooks/useTableData';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { Table } from 'components/shared/table';
import { Hero } from './Hero';

export const GpoPage = () => {
  const [data, setData] = useState<GpoAll[]>([]);

  const { data: allGPOs, refetch } = useQuery(gposKeys.all, getGpos);
  const { meta, tableData } = useTableData({ allData: data });

  const [search, setSearch] = useState('');

  useEffect(() => {
    setData(allGPOs || []);
  }, [allGPOs]);

  return (
    <div className="gpoPageWrapper">
      <Hero refetch={refetch} search={search} setSearch={setSearch} />
      <Table
        name="AllGpos"
        title="GPOs"
        nav={['Image', 'Name', 'ID']}
        searchKeys={['name']}
        meta={meta}
        setData={setData}
        allData={allGPOs || []}
        content={
          <div className="tableBody">
            {orderBy(tableData, ['name'], ['asc']).map((item: GpoAll) => (
              <Link key={item.id} to={`/gpo/${item.id}`} className="tableRow">
                <div className="tableCell">
                  <div className="imgBox">
                    <img src={item.image} alt={item.name} />
                  </div>
                </div>
                <TableCellTooltip content={item.name} />
                <TableCellTooltip content={item.id} />
              </Link>
            ))}
          </div>
        }
      />
    </div>
  );
};
