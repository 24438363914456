import { useState } from 'react';
import { tableSliceData } from 'util/mix';
import { TableMetaTypes } from 'types/table.types';

interface Props {
  allData: any[];
}

const useTableData = ({ allData }: Props) => {
  const [perPage, setPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const tableData: any[] = tableSliceData(allData, currentPage, perPage);

  const meta: TableMetaTypes = {
    currentPage,
    perPage,
    setCurrentPage,
    setPerPage,
    allData
  };

  return {
    meta,
    tableData
  };
};

export default useTableData;
