import { useHits } from 'react-instantsearch-hooks';
import { useMutation } from 'react-query';

import { EditIcon } from 'assets/icons/EditIcon';
import { LoadIcon } from 'assets/icons/LoadIcon';
import { fetchProduct } from 'api/products';
import { formatDate } from 'util/dates';
import { formatUom } from 'util/mix';
import { TablePagination } from '../algoliaTable/TablePagination';

interface Props {
  openEditModal: (product: any) => void;
}

export const ProductsList = ({ openEditModal }: Props) => {
  const { hits } = useHits();
  const { mutate, isLoading } = useMutation(fetchProduct);

  return (
    <>
      <div className="productsList">
        <div className="productsListRow head">
          <div className="cell">Product</div>
          <div className="cell">Manufacturer</div>
          <div className="cell">Category</div>
          <div className="cell">UOM</div>
          <div className="cell">Purchased</div>
          <div className="cell">Created At</div>
          <div className="cell">Unavailable</div>
          <div className="cell">Actions</div>
        </div>
        {hits.length ? (
          hits.map((hit: any) => (
            <div key={hit.objectID} className="productsListRow">
              <div className="cell productName">
                <div
                  className="productImg"
                  style={{ background: `url(${hit.images[0]}) center center / cover no-repeat` }}
                />
                <div className="info">
                  <p>{hit.title}</p>
                  <span>{hit.objectID}</span>
                </div>
              </div>
              <div className="cell">
                <div className="info">
                  <p>{hit.manufacturer}</p>
                  <span>{hit.manufacturerCode}</span>
                </div>
              </div>
              <div className="cell">{hit['categories.lvl0']}</div>
              <div className="cell">{formatUom(hit, false)}</div>
              <div className="cell">{(hit.purchased || 0).toLocaleString()}</div>
              <div className="cell">
                <div className="badge-rounded">{formatDate(hit.created)}</div>
              </div>
              <div className="cell center">
                <div className={`badge-rounded ${hit.unavailable ? 'danger' : 'success'}`}>
                  {hit.unavailable ? 'Unavailable' : 'Available'}
                </div>
              </div>
              <div className="cell center">
                {isLoading ? (
                  <LoadIcon />
                ) : (
                  <EditIcon onClick={() => mutate(hit.objectID, { onSuccess: (res) => openEditModal(res) })} />
                )}
              </div>
            </div>
          ))
        ) : (
          <p className="noResults">No matching records found</p>
        )}
      </div>
      <div className="pagination-wrapper">
        <TablePagination />
      </div>
    </>
  );
};
