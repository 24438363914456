import { BASE_URL_VARIANTS, apiCall, getBaseURL } from 'api';
import {
  GpoHealthSnapsotsPayload,
  HealthCsvFileType,
  OrgHealthSnapshotResponse,
  OrgHealthSnapsotsPayload,
  HelathSnapshot
} from './types';
import axios from 'axios';

const downloadFileResponse = (data: any, type: HealthCsvFileType) => {
  const href = URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', `${type}.csv`);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const getOrgHealthSpanshots = async ({ orgID, providerName }: OrgHealthSnapsotsPayload) => {
  try {
    const request = await apiCall(BASE_URL_VARIANTS.RETAIL_SNAPSHOT);

    const { data } = await request<Array<HelathSnapshot>>({
      url: `/orgs/${orgID}/providers/${providerName}/snapshots/health`,
      method: 'GET'
    });

    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getOrgHealthSnapshot = async ({
  orgID,
  providerName,
  snapshotID
}: OrgHealthSnapsotsPayload & { snapshotID: string }) => {
  try {
    const request = await apiCall(BASE_URL_VARIANTS.RETAIL_SNAPSHOT);

    const { data } = await request<OrgHealthSnapshotResponse>({
      url: `/orgs/${orgID}/providers/${providerName}/snapshots/health/${snapshotID}/summary`,
      method: 'GET'
    });

    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const downloadSummaryFile = async ({
  orgID,
  providerName,
  snapshotID,
  type
}: OrgHealthSnapsotsPayload & {
  snapshotID: string;
  type: HealthCsvFileType;
}) => {
  try {
    const baseURL = getBaseURL(BASE_URL_VARIANTS.RETAIL_SNAPSHOT)
    const url = `${baseURL}/orgs/${orgID}/providers/${providerName}/snapshots/health/${snapshotID}/${type}`;

    const { data } = await axios({ url, method: 'GET', responseType: 'blob' });

    downloadFileResponse(data, type);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getGpoHealthSpanshots = async ({ gpoID, providerName }: GpoHealthSnapsotsPayload) => {
  try {
    const request = await apiCall(BASE_URL_VARIANTS.RETAIL_SNAPSHOT);

    const { data } = await request<Array<HelathSnapshot>>({
      url: `/gpos/${gpoID}/providers/${providerName}/snapshots/health`,
      method: 'GET'
    });

    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getGpoHealthSnapshot = async ({
  gpoID,
  providerName,
  snapshotID
}: GpoHealthSnapsotsPayload & { snapshotID: string }) => {
  try {
    const request = await apiCall(BASE_URL_VARIANTS.RETAIL_SNAPSHOT);

    const { data } = await request<OrgHealthSnapshotResponse>({
      url: `/gpos/${gpoID}/providers/${providerName}/snapshots/health/${snapshotID}/summary`,
      method: 'GET'
    });

    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const downloadGpoSummaryFile = async ({
  gpoID,
  providerName,
  snapshotID,
  type
}: GpoHealthSnapsotsPayload & {
  snapshotID: string;
  type: HealthCsvFileType;
}) => {
  try {
    const baseURL = getBaseURL(BASE_URL_VARIANTS.RETAIL_SNAPSHOT)
    const url = `${baseURL}/gpos/${gpoID}/providers/${providerName}/snapshots/health/${snapshotID}/${type}`;

    const { data } = await axios({ url, method: 'GET', responseType: 'blob' });

    downloadFileResponse(data, type);
  } catch (err) {
    return Promise.reject(err);
  }
};
