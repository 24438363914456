import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

import { Office } from 'types/offices.types';
import { Org } from 'api/orgs/types';

import { DownArrowIcon } from 'assets/icons/DownArrow';
import { formatOffice } from 'util/offices';
import { isActiveClass } from 'util/classes';
import { searchByKeys } from 'util/mix';
import { Select } from 'components/ui/Select';

interface Props {
  isPreview: boolean;
  org: Org;
}

export const OfficeSelectField = ({ isPreview, org }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [allOffices, setAllOffices] = useState<Office[]>([]);

  const { values, setFieldValue }: any = useFormikContext();

  useEffect(() => {
    setAllOffices(org.offices || []);
  }, [org.offices]);

  useEffect(() => {
    const res = org.offices.find((el) => el.officeID === values.officeID);
    if (res) setInputValue(formatOffice(res));
  }, [org.offices, values.officeID]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const res = searchByKeys(officeSearchKeys, org.offices, value);
    setAllOffices(res);
    setInputValue(value);
  };

  return (
    <div className={isActiveClass('field officeField', isPreview)}>
      <label>Office Name</label>
      <Select
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        className="regular withSearch gray"
        options={
          allOffices.length ? (
            <ul className="customScrollbar">
              {allOffices.map((item) => (
                <li
                  key={item.officeID}
                  onClick={() => {
                    setFieldValue('officeID', item.officeID);
                    setIsOpen(false);
                  }}
                >
                  {formatOffice(item)}
                </li>
              ))}
            </ul>
          ) : (
            <p className="noResults">No results...</p>
          )
        }
      >
        <input type="text" value={inputValue} onChange={onChange} />
        {!isPreview && <DownArrowIcon />}
      </Select>
    </div>
  );
};

export const officeSearchKeys = [
  'route',
  'locality',
  'state',
  'zip',
  'streetNum',
  'name',
];
