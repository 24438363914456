import { useState } from 'react';
import { useRefinementList } from 'react-instantsearch-hooks';

import { DownArrowIcon } from 'assets/icons/DownArrow';
import { Select } from 'components/ui/Select';
import { isActiveClass } from 'util/classes';

export const DeliveryStatuses = () => {
  const [open, setOpen] = useState(false);
  const { items, refine } = useRefinementList({
    attribute: 'status'
  });
  const values: any = items.filter(({ isRefined }) => isRefined) || {};

  return (
    <Select
      isOpen={open}
      setIsOpen={setOpen}
      className="regular"
      options={
        <ul>
          {items.map((status) => (
            <li
              key={status.value}
              className={isActiveClass('', status.isRefined)}
              onClick={() => {
                refine(status.value);
                setOpen(false);
              }}
            >
              {status.label}
            </li>
          ))}
        </ul>
      }
    >
      {values.map((el: any) => el.label).join(', ') || 'Customer Status'}
      <DownArrowIcon />
    </Select>
  );
};
