import { usePagination } from 'react-instantsearch-hooks';
import { DownArrowIcon } from 'assets/icons/DownArrow';
import { isActiveClass } from 'util/classes';

export const TablePagination = () => {
  const { pages, currentRefinement, refine, isLastPage, isFirstPage } = usePagination();

  const showNextPage = () => !isLastPage && handleSetPage(currentRefinement + 1);
  const showPrevPage = () => !isFirstPage && handleSetPage(currentRefinement - 1);
  const handleSetPage = (page: number) => currentRefinement !== page && refine(page);

  return (
    <div className="tablePagination">
      <button className="arrow prev" onClick={showPrevPage}>
        <DownArrowIcon />
      </button>
      {pages.map((page) => (
        <button key={page} onClick={() => refine(page)} className={isActiveClass('item', page === currentRefinement)}>
          {page + 1}
        </button>
      ))}
      <button className="arrow next" onClick={showNextPage}>
        <DownArrowIcon />
      </button>
    </div>
  );
};
