/* eslint-disable react-hooks/exhaustive-deps */
import { useQueryParams } from 'hooks/useSearchQuery';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { HealthSelects } from '../org';
import { useQuery } from 'react-query';
import { getGpos } from 'api/gpo';
import ReactSelect from 'react-select';
import { SingleGpo } from 'api/gpo/types';
import { HelathSnapshot } from 'api/health/types';

type Props = {
  gpo?: SingleGpo;
  setSelectedSnapshots: Dispatch<SetStateAction<HelathSnapshot | null>>;
};

export const GpoHealthSelects = ({ gpo, setSelectedSnapshots }: Props) => {
  const { getQueryParam, updateCustomParams } = useQueryParams();

  const [selects, setSelects] = useState<HealthSelects>({ gpo: null, provider: null });

  const gpoID = getQueryParam('gpoID') || '';
  const providerName = getQueryParam('providerName') || '';

  const { data: allGpos } = useQuery(['all-gpos'], getGpos);

  const connectedProviders = gpo?.partners || [];

  const gpoInitial = () => {
    const org = allGpos?.find(({ id }) => id === gpoID);
    return org ? { label: org?.name, value: org?.id } : null;
  };

  const providerIniital = () => {
    const provider = connectedProviders.find((provider) => provider.providerName === providerName);
    return provider ? { label: provider?.providerName, value: provider?.providerName } : null;
  };

  useEffect(() => {
    if (gpoID && allGpos) setSelects((p) => ({ ...p, gpo: gpoInitial() }));
  }, [allGpos]);

  useEffect(() => {
    if (providerName && gpo) setSelects((p) => ({ ...p, provider: providerIniital() }));
  }, [gpo]);

  return (
    <div className="healthSelects">
      <div className="group">
        <p>GPO</p>
        <ReactSelect
          className="select"
          placeholder="Select GPO"
          options={allGpos?.map((org) => ({ label: org.name, value: org.id } || []))}
          onChange={(org: any) => {
            setSelects({ org, provider: null });
            updateCustomParams({ gpoID: org.value }, ['providerName', 'snapshotID']);
            setSelectedSnapshots(null);
          }}
          value={selects.gpo}
        />
      </div>

      <div className="group">
        <p>Provider</p>
        <ReactSelect
          className="select"
          placeholder="Select Provider"
          options={connectedProviders.map(({ providerName }) => ({
            label: providerName,
            value: providerName
          }))}
          onChange={(provider: any) => {
            setSelects((p) => ({ ...p, provider }));
            updateCustomParams({ providerName: provider.value }, ['snapshotID']);
            setSelectedSnapshots(null);
          }}
          value={selects.provider}
        />
      </div>
    </div>
  );
};
