import { DownArrowIcon } from 'assets/icons/DownArrow';
import { Select } from 'components/ui/Select';
import { useEffect, useState } from 'react';

import { useConfigure } from 'react-instantsearch-hooks';
import { isActiveClass } from 'util/classes';

interface Props {
  payment: string | null;
  setPayment: React.Dispatch<React.SetStateAction<string | null>>;
}

export const PaymentStatuses = ({ payment, setPayment }: Props) => {
  const [open, setOpen] = useState(false);
  const [facetFilters, setFacetFilters] = useState<string[]>([]);

  useConfigure({ facetFilters });

  useEffect(() => {
    if (!payment) setFacetFilters([]);
  }, [payment]);

  const onChange = (value: string) => {
    if (value === payment) {
      setPayment(null);
    } else {
      if (value === 'paid') {
        setFacetFilters(['invoice:false', 'orderPaid:true']);
      } else {
        setFacetFilters(['invoice:true', 'orderPaid:false']);
      }

      setPayment(value);
      setOpen(false);
    }
  };

  return (
    <Select
      isOpen={open}
      setIsOpen={setOpen}
      className="regular"
      options={
        <ul>
          {items.map((item) => (
            <li
              key={item.value}
              className={isActiveClass('', item.value === payment)}
              onClick={() => onChange(item.value)}
            >
              {item.label}
            </li>
          ))}
        </ul>
      }
    >
      {payment || 'Payment status'}
      <DownArrowIcon />
    </Select>
  );
};

const items = [
  { value: 'paid', label: 'Paid' },
  { value: 'unpaid', label: 'Unpaid' },
];
