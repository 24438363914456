import { useFormikContext } from 'formik';

interface Props {
  name: string;
}

export const FieldError = ({ name }: Props) => {
  const { touched, errors }: { touched: any; errors: any } = useFormikContext();

  return (
    <>
      {errors[name] && touched[name] && (
        <p className="fieldError">{errors[name]}</p>
      )}
    </>
  );
};
