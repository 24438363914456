import { Office } from 'types/offices.types';
import { Modal } from 'components/ui/Modal';
import { OrgOfficePayload, OrgOfficeUser, OrgUser } from 'api/orgs/types';
import { useMutation } from 'react-query';
import { createOrgOffice, updateOrgOffice } from 'api/orgs';
import { Field, Formik, Form } from 'formik';
import { officeSchema } from 'util/validationSchemas';
import { FieldError } from 'components/shared/FormikError';
import GoogleField from 'components/shared/GoogleField';
import { StateSelect } from 'components/shared/StateSelect';
import { getOfficeUsers } from 'util/offices';

interface Props {
  open: boolean;
  close: () => void;
  orgID: string;
  refetchOrg: () => void;
  office?: Office;
  users?: OrgUser[];
  officeUsers?: OrgOfficeUser[];
}

export const OfficeFormModal = ({ open, close, orgID, refetchOrg, office, officeUsers, users }: Props) => {
  const { mutate: storeOffice, isLoading } = useMutation((body: OrgOfficePayload) => createOrgOffice(orgID, body));
  const { mutate: updateOffice, isLoading: isLoadingUpdate } = useMutation((body: any) =>
    updateOrgOffice(orgID, office?.officeID || '', body)
  );

  const allOficeUsers = getOfficeUsers(office?.officeID || '', users || [], officeUsers || []);

  const onSuccess = () => {
    close();
    refetchOrg();
  };

  const onSubmit = (values: any) => {
    const payload = { ...values };
    delete payload.businessAddress;
    payload.streetNum = String(payload.streetNum);
    if (payload.route === '') payload.route = payload.name;

    if (office) {
      updateOffice(payload, { onSuccess });
      return;
    }

    storeOffice(payload, { onSuccess });
  };

  return (
    <Modal
      open={open}
      close={close}
      title={office ? `Update ${office.name}` : 'Add new Office'}
      className="addNewOfficeModal"
    >
      <Formik
        initialValues={{
          name: office?.name || '',
          streetNum: office?.streetNum || '',
          route: office?.route || '',
          locality: office?.locality || '',
          state: office?.state || '',
          zip: office?.zip || '',
          lat: office?.lat || 0,
          lng: office?.lng || 0,
          unit: office?.unit || '',
          country: 'US',
          businessAddress: office ? `${office.streetNum} ${office.route}` : ''
        }}
        onSubmit={onSubmit}
        validationSchema={officeSchema}
      >
        {({ setValues, values }) => (
          <Form>
            <div className="fields">
              <div className="field">
                <label>Office name</label>
                <Field name="name" required />
                <FieldError name="name" />
              </div>
              <GoogleField
                name="businessAddress"
                onChange={(obj: any) => {
                  if (obj.businessAddress !== '') setValues({ ...obj, name: values.name });
                  else
                    setValues({
                      ...obj,
                      businessAddress: values.businessAddress
                    });
                }}
              />
              <div className="field">
                <label>City</label>
                <Field name="locality" />
                <FieldError name="locality" />
              </div>
              <StateSelect value={values.state} />
              <div className="field">
                <label>Zipcode</label>
                <Field name="zip" />
                <FieldError name="zip" />
              </div>
              <div className="field">
                <label>Unit</label>
                <Field name="unit" />
                <FieldError name="unit" />
              </div>
              {office ? (
                <div className="field">
                  <label>Office Users</label>
                  {allOficeUsers.map((user) => (
                    <div key={user.userID}>{user.email + ',' + user.role}</div>
                  ))}
                </div>
              ) : null}
            </div>
            <button type="submit" className="contained" disabled={isLoading || isLoadingUpdate}>
              {office ? 'Save changes' : 'Add Office'}
            </button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
