import { InstantSearch } from 'react-instantsearch-hooks';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ProductPayload, ProductSKU } from 'api/products/types';

import { algoliaKeys, searchClient } from 'lib/algolia/aloglia-config';
import { getCurrencyValue, roundCurrencyValue } from 'util/mix';
import { createProduct, updateProduct } from 'api/products';
import { SkusPart, emptySku, validStatuses } from './SkusPart';
import { GeneralPart } from './GeneralPart';
import { ImagesPart } from './ImagesPart';
import { UomPart } from './UomPart';

interface Props {
  product?: ProductPayload;
  onClose: () => void;
}

export type ProductForm = {
  variantID: string;
  description: string;
  title: string;
  uom: string;
  conversionFactor: number;
  images: string[];
  subcategory: string;
  manufacturer: string;
  manufacturerCode: string;
  unavailable: boolean;
  skus: ProductSKU[];
};

export const ProductFormModal = ({ product, onClose }: Props) => {
  const { mutate: createMutate, isLoading: createIsLoading } = useMutation(createProduct);
  const { mutate: updateMutate, isLoading: updateIsLoading } = useMutation(updateProduct);
  const isLoading = createIsLoading || updateIsLoading;
  const formik = useFormik({
    initialValues: {
      title: product?.title || '',
      description: product?.description.replace(/<!---->/g, '') || '',
      uom: product?.uom || '',
      conversionFactor: product?.conversionFactor || 1,
      manufacturer: product?.manufacturer || '',
      manufacturerCode: product?.manufacturerCode || '',
      unavailable: product ? product.unavailable : false,
      images: product?.images || [],
      subcategory: product?.['categories.lvl1'] || '',
      skus: product?.skus.map((s) => ({
        ...s,
        buyingPrice: s.buyingPrice ? getCurrencyValue(s.buyingPrice) : 0,
        sellingPrice: s.sellingPrice ? getCurrencyValue(s.sellingPrice) : 0
      })) || [emptySku]
    } as ProductForm,
    validationSchema: Yup.object().shape({
      description: Yup.string().required(),
      title: Yup.string().required(),
      uom: Yup.string().required(),
      conversionFactor: Yup.number().required(),
      images: Yup.array().of(Yup.string().url()),
      subcategory: Yup.string().required(),
      manufacturer: Yup.string().required(),
      manufacturerCode: Yup.string().required(),
      unavailable: Yup.boolean().required(),
      skus: Yup.array()
        .of(
          Yup.object().shape({
            provider: Yup.string().required(),
            sku: Yup.string().required(),
            title: Yup.string().optional(),
            url: Yup.string().optional().url(),
            status: Yup.string()
              .nullable()
              .oneOf([...validStatuses, null])
          })
        )
        .required()
        .min(1)
    }),
    onSubmit: (values, { resetForm, setErrors }) => {
      // Convert form to API body
      const body: ProductPayload = {
        'categories.lvl0': values.subcategory.split('>')[0].trim(),
        'categories.lvl1': values.subcategory,
        skus: values.skus.map((s) => ({
          provider: s.provider,
          sku: s.sku,
          title: s.title,
          url: s.url,
          status: s.status,
          buyingPrice: roundCurrencyValue(s.buyingPrice),
          sellingPrice: roundCurrencyValue(s.sellingPrice),
          createdAt: s.createdAt,
          updatedAt: s.updatedAt
        })),
        variantID: product?.variantID || '',
        description: values.description,
        title: values.title,
        uom: values.uom,
        conversionFactor: values.conversionFactor,
        images: values.images,
        manufacturer: values.manufacturer,
        manufacturerCode: values.manufacturerCode,
        unavailable: values.unavailable
      };

      // If product was passed into the component, we're just updating
      if (product) {
        return updateMutate(
          { variantID: product.variantID, body },
          {
            onSuccess: () => {
              resetForm();
              onClose();
            },
            onError: (err: any) => setErrors({ title: new Error(err).message })
          }
        );
      }

      // Otherwise, we're creating a new product
      createMutate(body, {
        onSuccess: () => {
          resetForm();
          onClose();
        },
        onError: (err: any) => setErrors({ title: new Error(err).message })
      });
    }
  });

  return (
    <form className="productFormModal" onSubmit={formik.handleSubmit}>
      <div className="modalHero">
        <h2>{product ? 'Update' : 'Create'} Product</h2>
        <div className="right">
          <button type="button" className="outlined lg" onClick={onClose} disabled={isLoading}>
            Discard changes
          </button>
          <button type="submit" className="contained lg" disabled={isLoading}>
            Publish product
          </button>
        </div>
      </div>
      <InstantSearch indexName={algoliaKeys.indexName.regular} searchClient={searchClient}>
        <div className="modalOuter">
          <div className="leftSide">
            <GeneralPart formik={formik} />
            <SkusPart formik={formik} />
          </div>
          <div className="rightSide">
            <ImagesPart formik={formik} />
            <UomPart formik={formik} />
          </div>
        </div>
      </InstantSearch>
    </form>
  );
};
