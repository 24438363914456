import { Form, Formik, Field } from 'formik';
import { useMutation } from 'react-query';

import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { UpdateOrderPayload } from 'api/orders/types';

import { useOrderStore } from 'stores/OrderStore';
import { updateOrder } from 'api/orders';
import { DragIcon } from 'assets/icons/DragIcon';

interface Props {
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

interface FormikState {
  customNote: string;
}

export const CustomNote = ({ dragHandleProps }: Props) => {
  const { order, setOrder } = useOrderStore();

  const initialState: FormikState = {
    customNote: order.customNote || ''
  };

  const { mutate, isLoading } = useMutation(updateOrder);

  const onSubmit = ({ customNote }: FormikState) => {
    const payload: UpdateOrderPayload = {
      orgID: order.orgID,
      orderID: order.orderID,
      body: { customNote }
    };

    mutate(payload, { onSuccess: (res) => setOrder(res) });
  };

  return (
    <div className="paper orderNote">
      <div className="paperHeader">
        <h2>
          <span {...dragHandleProps}>
            <DragIcon />
          </span>
          Office Users Customer Note
        </h2>
      </div>
      <div className="paperBody">
        <Formik initialValues={initialState} onSubmit={onSubmit}>
          <Form>
            <div className="field">
              <label htmlFor="customNote">Customer Note</label>
              <Field as="textarea" id="customNote" name="customNote" placeholder="Write note..." required />
            </div>
            <button type="submit" className="contained lg" disabled={isLoading}>
              Save changes
            </button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};
