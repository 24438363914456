import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useFormikContext } from 'formik';
import groupBy from 'lodash/groupBy';
import dayjs from 'dayjs';

import { CreateBackOrderPayload, DeleteBackOrderPayload } from 'api/purchaseOrders/types';
import { FaliamInvoice, Order, OrderItem, OrderOverviewStackInfo } from 'types/order.types';
import { UpdateOrderPayload } from 'api/orders/types';
import { OrgOrderItem } from 'types/orgs.types';

import { formatStackInfoStatus, getStackInfoItem, getStackInfoStatusColor } from 'util/stackInfoUtil';
import { EditPrice } from 'pages/order/items/editOrderItemsModal/EditPrice';
import { createBackOrder, deleteBackOrder } from 'api/purchaseOrders';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { NestedTable } from 'components/shared/table/NestedTable';
import { convertPrice, orderSupplierPrice } from 'util/prices';
import { FaliamInvoiceFormikState } from './FaliamInvoiceForm';
import { PlusCircleIcon } from 'assets/icons/PlusCircleIcon';
import { DownArrowIcon } from 'assets/icons/DownArrow';
import { Checkbox } from 'components/ui/Checkbox';
import { useOrderStore } from 'stores/OrderStore';
import { Table } from 'components/shared/table';
import { getOrderedItem } from 'util/orders';
import { updateOrder } from 'api/orders';
import { orderBy } from 'lodash';

interface Props {
  checkedBoxes: { [key: string]: number };
  faliamInvoice: FaliamInvoice | null;
  setCheckedBoxes: (checkedBoxes: { [key: string]: number }) => void;
  getNumberOfQtyPerItem: (item: OrgOrderItem) => any;
}

interface Box {
  id: string;
  quantity: number;
  status: string;
  price: number;
  orderedPrice: number;
}

export const FaliamInvoiceModalTable = ({
  checkedBoxes,
  faliamInvoice,
  setCheckedBoxes,
  getNumberOfQtyPerItem
}: Props) => {
  const { order, setOrder } = useOrderStore();

  const [stackInfo, setStackInfo] = useState<OrderOverviewStackInfo[]>(order.orderOverview.StackInfo);
  const [collapsed, setCollapsed] = useState<string[]>([]);
  const [itemForEditPrice, setItemForEditPrice] = useState<OrgOrderItem | null>(null);
  const [itemForEditDefaultPrice, setItemForEditDefaultPrice] = useState<OrgOrderItem | null>(null);

  const { values, setFieldValue }: any = useFormikContext();
  const { allItems, items }: FaliamInvoiceFormikState = values;
  const { mutate: createBackOrderMutate } = useMutation(createBackOrder);
  const { mutate: deleteBackOrderMutate } = useMutation(deleteBackOrder);
  const { mutate: updateOrderMutate } = useMutation(updateOrder);

  useEffect(() => {
    setStackInfo(order.orderOverview.StackInfo);
  }, [order.orderOverview]);

  useEffect(() => {
    const allOrderedItems = order.faliamInvoices
      .reduce((acc, curr) => [...acc, ...curr.items], [] as OrgOrderItem[])
      .filter((el) => el.price.provider === values.provider);

    const items: OrgOrderItem[] = faliamInvoice
      ? faliamInvoice.items
      : allOrderedItems.length
      ? allOrderedItems
      : values.items;

    const obj: any = {};

    items.forEach((item) => {
      const arr = Array.from({ length: item.quantity }, (_, i) => i + 1);
      arr.forEach((_, i) => (obj[`${item.item.objectID}:${i}`] = 1));
    });

    setCheckedBoxes(obj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faliamInvoice, values.invoicesIDS]);

  const toggleCollapse = (id: string) =>
    setCollapsed((p) => (p.includes(id) ? p.filter((el) => el !== id) : [...p, id]));

  const isItemChecked = (item: OrgOrderItem) =>
    items.some((el) => el.item.objectID === item.item.objectID && el.price.provider === item.price.provider);

  const toggleItem = (item: OrgOrderItem) => {
    if (isItemChecked(item)) {
      const res = items.filter(
        (el) => el.item.objectID !== item.item.objectID || el.price.provider !== item.price.provider
      );

      setCheckedBoxes(
        Object.entries(checkedBoxes).reduce((acc, [key, val]) => {
          if (key.includes(item.item.objectID)) {
            return acc;
          }

          return { ...acc, [key]: val };
        }, {})
      );

      setFieldValue('items', res);
    } else {
      const arr = Array.from({ length: item.quantity }, (_, i) => i + 1);
      const obj: any = {};

      arr.forEach((_, i) => (obj[`${item.item.objectID}:${i}`] = 1));

      setCheckedBoxes({ ...checkedBoxes, ...obj });
      setFieldValue('items', [...items, item]);
    }
  };

  const toggleBox = (id: string) => {
    if (checkedBoxes[id]) {
      const res = { ...checkedBoxes };
      delete res[id];
      setCheckedBoxes(res);
    } else {
      setCheckedBoxes({ ...checkedBoxes, [id]: 1 });
    }
  };

  const getBoxesPerItem = (item: OrgOrderItem): Box[] => {
    const { objectID: variantID } = item.item;
    const { BackOrders = {}, ItemStatusCount = {} } = getStackInfoItem(stackInfo, variantID) || {};
    const totalBackOrders = Object.values(BackOrders).reduce((acc, val) => acc + val, 0);

    const hasBackorder = ItemStatusCount.ITEM_BACKORDERED;

    const makeBox = (status: string): Box => ({
      id: variantID,
      quantity: item.quantity,
      status,
      price: orderSupplierPrice(item.price),
      orderedPrice: orderSupplierPrice(getOrderedItem(order.items, item)?.price)
    });
    const boxes = Object.entries(ItemStatusCount).flatMap(([status, count]) =>
      Array.from({ length: count - (hasBackorder ? 0 : totalBackOrders) }, () => makeBox(status))
    );

    if (!hasBackorder && totalBackOrders) {
      const backorderedBoxes = Array.from({ length: totalBackOrders }, () => makeBox('ITEM_BACKORDERED'));
      boxes.push(...backorderedBoxes);
    }

    return boxes;
  };

  const calcLeftQtyPerItem = (item: OrgOrderItem) => {
    const orderedQty = getOrderedItem(order.items, item)?.quantity || 0;
    const activeQty = getNumberOfQtyPerItem(item) || 0;

    return orderedQty - activeQty;
  };

  const onCreateBackOrder = (item: OrgOrderItem) => {
    const newItem = {
      expectedDeliveryDate: dayjs().format('YYYY-MM-DD'),
      variantID: item.item.objectID,
      provider: item.price.provider,
      quantity: 1
    };

    const payload: CreateBackOrderPayload = {
      orgID: order.orgID,
      orderID: order.orderID,
      body: {
        items: [newItem]
      }
    };

    createBackOrderMutate(payload, { onSuccess });
  };

  const onDeleteBackOrder = (item: OrgOrderItem) => {
    const ordredItem = getOrderedItem(order.items, item);

    const payload: DeleteBackOrderPayload = {
      orgID: order.orgID,
      orderID: order.orderID,
      backOrderID: ordredItem?.backOrder?.id || ''
    };

    deleteBackOrderMutate(payload, { onSuccess });
  };

  const onUpdateOrder = (items: OrgOrderItem[]) => {
    const payload: UpdateOrderPayload = {
      orgID: order.orgID,
      orderID: order.orderID,
      body: {
        items: items.map((item) => ({
          variantID: item.item.objectID,
          providerName: item.price.provider,
          quantity: item.quantity,
          sellingPrice: item.price.sellingPrice,
          buyingPrice: item.price.buyingPrice,
          customSellingPrice: item.price.customSellingPrice
        }))
      }
    };

    updateOrderMutate(payload, { onSuccess });
  };

  const onAddNewStackInfo = (item: OrgOrderItem) => {
    const cloned = [...stackInfo];
    const i = cloned.findIndex((el) => el.VariantID === item.item.objectID);
    cloned[i] = {
      ...cloned[i],
      ItemStatusCount: {
        ...cloned[i].ItemStatusCount,
        [Object.keys(cloned[i].ItemStatusCount)[0]]: Object.values(cloned[i].ItemStatusCount)[0] + 1
      }
    };

    setStackInfo(cloned);
  };

  const onSuccess = (res: Order) => setOrder(res);

  const isRowCovered = (item: OrgOrderItem) => {
    const allFaliamItems: OrderItem[] = order.faliamInvoices.reduce((acc: any, curr) => [...acc, ...curr.items], []);

    return allFaliamItems.some((el) => {
      return (
        el.item.objectID === item.item.objectID &&
        el.price.provider === item.price.provider &&
        el.quantity >= item.quantity
      );
    });
  };

  return (
    <>
      <div className="selectAllCheckbox">
        <Checkbox
          isEnabled={allItems.length > 0 && allItems.every((el) => isItemChecked(el))}
          onChange={() => {
            if (allItems.length > 0 && allItems.every((el) => isItemChecked(el))) {
              setFieldValue('items', []);
              setCheckedBoxes({});
            } else {
              const obj: any = {};

              allItems.forEach((item) => {
                const arr = Array.from({ length: item.quantity }, (_, i) => i + 1);
                arr.forEach((_, i) => (obj[`${item.item.objectID}:${i}`] = 1));
              });

              setFieldValue('items', allItems);
              setCheckedBoxes(obj);
            }
          }}
        />
        <p>{allItems.length > 0 && allItems.every((el) => isItemChecked(el)) ? 'Unselect all' : 'Select all'}</p>
      </div>
      <Table
        name="ProvierInvoiceModal"
        title={
          allItems.length
            ? `${Object.keys(groupBy(allItems, (e) => e.price.provider)).join(', ')} (${allItems.length} items)`
            : 'Select Provider'
        }
        nav={[
          'Select',
          'Item',
          'Seller SKU',
          'MFR Code',
          'QTY Ordered',
          'QTY Left',
          'DFUR',
          'DFIT',
          'SIUR',
          'SIIT',
          ''
        ]}
        allData={values.allItems || []}
        content={
          <div className="tableBody nested">
            {orderBy(values.allItems, (e) => isRowCovered(e)).map((el: OrgOrderItem) => {
              const id = `${el.item.objectID}:${el.price.provider}`;
              const orderedItem = getOrderedItem(order.items, el);
              const buyingPrice = el.price.buyingPrice || 0;
              const activePrice = orderedItem?.price?.customSellingPrice || orderedItem?.price?.sellingPrice || 0;

              return (
                <div key={id} className="groupWithChildren">
                  <div className={`tableRow ${isRowCovered(el) ? 'successRow' : 'dangerRow'}`}>
                    <div className="tableCell">
                      <Checkbox isEnabled={isItemChecked(el)} onChange={() => toggleItem(el)} />
                    </div>
                    <TableCellTooltip content={el.item.title} />
                    <TableCellTooltip content={el.price.sku} />
                    <TableCellTooltip content={el.item.manufacturerCode} />
                    <TableCellTooltip content={orderedItem?.quantity || 1} />
                    <TableCellTooltip content={calcLeftQtyPerItem(el)} />
                    <TableCellTooltip content={convertPrice(activePrice)} />
                    <TableCellTooltip content={convertPrice(activePrice * (orderedItem?.quantity || 1))} />
                    <TableCellTooltip content={convertPrice(buyingPrice)} />
                    <TableCellTooltip content={convertPrice(buyingPrice * (orderedItem?.quantity || 1))} />
                    <button
                      type="button"
                      className="outlined lg editBtn"
                      onClick={() => setItemForEditDefaultPrice(orderedItem)}
                    >
                      Edit DFUR
                    </button>
                    <button type="button" className="outlined lg editBtn" onClick={() => setItemForEditPrice(el)}>
                      Edit SIUR
                    </button>

                    <div className="tableCell">
                      <div className="tableDownArrow" onClick={() => toggleCollapse(id)}>
                        <DownArrowIcon />
                      </div>
                    </div>
                  </div>
                  {collapsed.includes(id) && (
                    <NestedTable
                      className="nestedTableProvierInvoiceModal"
                      nav={['Select', 'Box #', 'Box Status', 'DFUR', 'SIUR', 'On BO']}
                    >
                      {getBoxesPerItem(el).map((box, itemIndex) => {
                        const boxID = `${box.id}:${itemIndex}`;

                        return (
                          <div key={boxID} className="tableRow">
                            <div className="tableCell">
                              <Checkbox
                                isEnabled={checkedBoxes[boxID] ? true : false}
                                onChange={() => toggleBox(boxID)}
                              />
                            </div>
                            <TableCellTooltip content={`${itemIndex + 1}.`} />
                            <div className="tableCell">
                              <span style={{ color: getStackInfoStatusColor(box.status) }}>
                                {formatStackInfoStatus(box.status)}
                              </span>
                            </div>
                            <TableCellTooltip content={convertPrice(activePrice)} />
                            <TableCellTooltip content={convertPrice(buyingPrice)} />
                            <div className="tableCell backOrderCell">
                              <span
                                onClick={() => {
                                  if (box.status === 'ITEM_BACKORDERED') onDeleteBackOrder(el);
                                  else onCreateBackOrder(el);
                                }}
                              >
                                {box.status === 'ITEM_BACKORDERED' ? 'Yes' : 'No'}
                                <DownArrowIcon />
                              </span>
                            </div>
                          </div>
                        );
                      })}
                      <div className="tableRow fullWidthRow">
                        <div className="tableCell">
                          <span onClick={() => onAddNewStackInfo(el)}>
                            Add Another Box
                            <PlusCircleIcon />
                          </span>
                        </div>
                      </div>
                    </NestedTable>
                  )}
                </div>
              );
            })}
          </div>
        }
      />
      {itemForEditPrice && (
        <EditPrice
          order={itemForEditPrice}
          items={items}
          setItems={(res) => setFieldValue('items', res)}
          close={() => setItemForEditPrice(null)}
          onlyBuyingPrice
        />
      )}
      {itemForEditDefaultPrice && (
        <EditPrice
          order={itemForEditDefaultPrice}
          items={order.items}
          setItems={onUpdateOrder}
          close={() => setItemForEditDefaultPrice(null)}
          onlySellingPrice
        />
      )}
    </>
  );
};
