import { CreateUserPayload, UpdateUserPayload, User } from './types';

import { apiCall, BASE_URL_VARIANTS } from 'api/index';

export const listAdminUserGroups = async (): Promise<string[]> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);
  const { data } = await request({
    url: `/settings/user-groups`,
    method: 'GET'
  });
  return data
};

export const listAdminUsers = async (): Promise<User[]> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);
  const { data } = await request({
    url: `/settings/users`,
    method: 'GET'
  });
  return data
  return [];
};
export const createAdminUser = async (body: CreateUserPayload) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);
  await request({
    data: body,
    url: `/settings/users`,
    method: 'POST'
  });
};

export const updateAdminUser = async (body: UpdateUserPayload) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);
  await request({
    data: body,
    url: `/settings/users/${body.id}`,
    method: 'PATCH'
  });
};

export const deleteAdminUser = async (id: string) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);
  await request({ url: `/settings/users/${id}`, method: 'DELETE' });
};
