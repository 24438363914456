import { useState } from 'react';
import { useMutation } from 'react-query';

import { UploadFileIcon } from 'assets/icons/UploadFileIcon';
import { CheckIcon } from 'assets/icons/CheckIcon';
import { AlertIcon } from 'assets/icons/AlertIcon';
import { FileIcon } from 'assets/icons/FileIcon';
import { getGpoPartnerPricesURL } from 'api/gpo';
import { convertFileSize } from 'util/convertFileSize';
import { isActiveClass } from 'util/classes';
import { Modal } from 'components/ui/Modal';

interface Props {
  gpoID: string;
  providerName: string;
  close: () => void;
  refetch: () => void;
}

const isFileSizeValid = (size: number) => size <= 50000000;

export const UploadCSVModal = ({ gpoID, providerName, close, refetch }: Props) => {
  const [file, setFile] = useState<any>(null);
  const { mutate, isLoading } = useMutation(getGpoPartnerPricesURL);

  const onUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = e.target.files;
    if (uploadedFiles?.length) {
      const singleFile = uploadedFiles[0];
      if (singleFile.type === 'text/csv') setFile(singleFile);
    }
  };

  const onSubmit = () => {
    mutate(
      { id: gpoID, provider: providerName, file },
      {
        onSuccess: () => {
          close();
          refetch();
        }
      }
    );
  };

  return (
    <Modal open={true} close={close} title={`Upload CSV - ${providerName}`} className="uploadCSVModal">
      <div className="dropzone">
        <UploadFileIcon />
        <h4>Click to upload or drag &amp; drop</h4>
        <p>Maximum file size 50 MB</p>
        <input type="file" title="" accept=".csv" onChange={onUploadFile} />
      </div>
      {file && (
        <div className="files customScrollbar">
          <div className={isActiveClass('file', !isFileSizeValid(file.size), 'invalid')}>
            <div className="left">
              <div className="icon">
                <FileIcon />
              </div>
              <div className="info">
                <p>{isFileSizeValid(file.size) ? file.name : 'Your file is too big.'}</p>
                <span>{convertFileSize(file.size)}</span>
                <div className="buffer" />
              </div>
            </div>
            <div className="right">
              <div className="icon">{isFileSizeValid(file.size) ? <CheckIcon /> : <AlertIcon />}</div>
              <span>{isFileSizeValid(file.size) ? 100 : 0} %</span>
            </div>
          </div>
        </div>
      )}
      <div className="buttons">
        <button className="contained" onClick={onSubmit} disabled={isLoading}>
          Attach file
        </button>
        <button className="outlined" onClick={close}>
          Cancel
        </button>
      </div>
    </Modal>
  );
};
