import { TablePagination } from './TablePagination';
import { TablePerPage } from './TablePerPage';

interface Props {
  totalHits: number;
}

export const TableLegend = ({ totalHits }: Props) => (
  <div className="tableLegend">
    <TablePerPage totalHits={totalHits} />
    <TablePagination />
  </div>
);
