import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import findIndex from 'lodash/findIndex';
import orderBy from 'lodash/orderBy';

import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { OrderItem } from 'types/order.types';

import { TableCellTooltip } from 'components/shared/table/TableCell';
import { NestedTable } from 'components/shared/table/NestedTable';
import { convertPrice, orderSupplierPrice } from 'util/prices';
import { useSupplierKind } from 'hooks/useSupplierKind';
import { DownArrowIcon } from 'assets/icons/DownArrow';
import { useOrderStore } from 'stores/OrderStore';
import { groupOrdersBySupplier } from 'util/mix';
import { Table } from 'components/shared/table';
import { ordersKeys } from 'api/orders/queries';
import { getSnapshotOrder } from 'api/orders';
import useTableData from 'hooks/useTableData';
import { DragIcon } from 'assets/icons/DragIcon';

interface Props {
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

type GroupType = {
  supplier: string;
  items: OrderItem[];
  total: number;
  qty: number;
};

export const SnapshotOrderTable = ({ dragHandleProps }: Props) => {
  const [data, setData] = useState<GroupType[]>([]);
  const [collapsed, setCollapsed] = useState<string[]>([]);

  const {
    order: { orderID, orgID }
  } = useOrderStore();
  const { getSupplierKind } = useSupplierKind();
  const { meta, tableData } = useTableData({ allData: data });
  const toggleCollapse = (id: string) => setCollapsed((p) => (p.includes(id) ? p.filter((e) => e !== id) : [...p, id]));

  const { data: order } = useQuery(ordersKeys.snapshotOrder(orgID, orderID), () => getSnapshotOrder(orgID, orderID));

  useEffect(() => {
    if (order) {
      const grouped: GroupType[] = groupOrdersBySupplier(order.items).map(([supplier, items]) => ({
        supplier,
        items,
        qty: items.reduce((acc, curr) => acc + curr.quantity, 0),
        total: items.reduce((acc, curr) => acc + orderSupplierPrice(curr.price) * curr.quantity, 0)
      }));
      setData(orderBy(grouped, (e) => e.supplier.toLowerCase()));
    }
  }, [order]);

  return (
    <Table
      name="OrderSnapshot"
      title={
        <>
          <span {...dragHandleProps}>
            <DragIcon />
          </span>
          Time of Order Snapshot
        </>
      }
      setData={setData}
      nav={[
        '#',
        'Seller',
        'Partner Type',
        '# Items',
        'QTY',
        'Est. Subtotal',
        'Est. Tax',
        'Est. Shipping',
        'Est. Total',
        ''
      ]}
      meta={meta}
      allData={data}
      content={
        <div className="tableBody nested">
          {tableData.map((el: GroupType) => {
            return (
              <div key={el.supplier} className="groupWithChildren">
                <div className="tableRow">
                  <TableCellTooltip content={findIndex(data, (i) => i.supplier === el.supplier) + 1 + '.'} />
                  <TableCellTooltip content={el.supplier} />
                  <TableCellTooltip content={getSupplierKind(el.supplier) === 'PARTNER' ? 'Direct' : 'Procurement'} />
                  <TableCellTooltip content={el.items.length} />
                  <TableCellTooltip content={el.qty} />
                  <TableCellTooltip content="" />
                  <TableCellTooltip content="" />
                  <TableCellTooltip content="" />
                  <TableCellTooltip content={convertPrice(el.total)} />
                  <div className="tableCell">
                    <div className="tableDownArrow" onClick={() => toggleCollapse(el.supplier)}>
                      <DownArrowIcon />
                    </div>
                  </div>
                </div>
                {collapsed.includes(el.supplier) && (
                  <NestedTable
                    className="nestedTableOrderSnapshot"
                    nav={['# Item', 'Seller SKU', 'DFUR', 'QTY', 'DFIT', 'Item']}
                  >
                    {el.items.map((item, itemIndex) => (
                      <div key={item.item.objectID} className="tableRow">
                        <TableCellTooltip content={`${itemIndex + 1}.`} />
                        <TableCellTooltip content={item.price.sku} />
                        <TableCellTooltip content={convertPrice(orderSupplierPrice(item.price))} />
                        <TableCellTooltip content={item.quantity} />
                        <TableCellTooltip content={convertPrice(orderSupplierPrice(item.price) * item.quantity)} />
                        <TableCellTooltip content={item.item.title} />
                      </div>
                    ))}
                  </NestedTable>
                )}
              </div>
            );
          })}
          <div className="tableRow totalRow">
            <div className="tableCell" />
            <div className="tableCell" />
            <div className="tableCell" />
            <div className="tableCell" />
            <div className="tableCell" />
            <TableCellTooltip content={convertPrice(order?.subtotal || 0)} />
            <TableCellTooltip content={convertPrice(order?.tax || 0)} />
            <TableCellTooltip content={convertPrice(order?.shippingCosts || 0)} />
            <TableCellTooltip content={convertPrice(order?.total || 0)} />
          </div>
        </div>
      }
    />
  );
};
