import { Field, Form, Formik } from 'formik';
import { useMutation } from 'react-query';

import { ConfirmPurchaseOrderPayload } from 'api/purchaseOrders/types';
import { PurchaseOrder } from 'types/order.types';

import { confirmPurchaseOrder, updatePurchaseOrderConfirmation } from 'api/purchaseOrders';
import { FullScreenModal } from 'pages/order/fullScreenModals';
import { useOrderStore } from 'stores/OrderStore';

interface Props {
  purchaseOrder: PurchaseOrder;
  close: () => void;
}

interface FormikState {
  referenceID: string;
}

export const ConfirmPurchaseOrderForm = ({ purchaseOrder, close }: Props) => {
  const { order, setOrder } = useOrderStore();
  const { id, confirmed, referenceID } = purchaseOrder;
  const initialValues: FormikState = { referenceID: referenceID || '' };
  const title = `${confirmed ? 'Update' : 'Add'} Provider Order #`;

  const { mutate, isLoading } = useMutation(confirmed ? updatePurchaseOrderConfirmation : confirmPurchaseOrder);

  const onSubmit = (values: FormikState) => {
    const payload: ConfirmPurchaseOrderPayload = {
      orderID: order.orderID,
      orgID: order.orgID,
      purchaseOrderID: id,
      body: {}
    };

    if (values.referenceID) payload.body.referenceID = values.referenceID;

    mutate(payload, {
      onSuccess: (res) => {
        setOrder(res);
        close();
      }
    });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <Form>
        <FullScreenModal close={close} button={{ text: title, isLoading }} hiddenOrders>
          <div className="paper">
            <div className="paperHeader">
              <h2>{title}</h2>
            </div>
            <div className="paperBody">
              <div className="field">
                <label>Seller order number</label>
                <Field type="text" name="referenceID" />
              </div>
            </div>
          </div>
        </FullScreenModal>
      </Form>
    </Formik>
  );
};
