import { useState } from 'react';
import { useSearchBox } from 'react-instantsearch-hooks';

import { SearchIcon } from 'assets/icons/SearchIcon';
import useDebounce from 'hooks/useDebounce';

export const TableSearch = () => {
  const [inputValue, setInputValue] = useState('');
  const { refine } = useSearchBox();
  const { onDebounce } = useDebounce();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    onDebounce(value, refine);
  };

  return (
    <div className="tableSearch">
      <SearchIcon />
      <input value={inputValue} onChange={onChange} placeholder="Search" />
    </div>
  );
};
