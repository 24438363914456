import { toast } from 'react-toastify';

import { CreateUserPayload, ImpersonateUserPayload, UpdateUserPayload, User } from './types';

import { apiCall, BASE_URL_VARIANTS } from 'api/index';

export const createUser = async (body: CreateUserPayload) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);
  await request({
    data: body,
    url: `/users`,
    method: 'POST'
  });

  toast('User has been updated created.', { type: 'success' });
};
export const getUser = async (id: string): Promise<User> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  const { data } = await request({
    url: `/users/${id}`,
    method: 'GET'
  });
  return data;
};

export const updateUser = async (id: string, body: UpdateUserPayload) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    await request({
      data: body,
      url: `/users/${id}`,
      method: 'PATCH'
    });

    toast('User has been updated.', { type: 'success' });
  } catch (error) {}
};

export const postUserImpersonate = async (body: ImpersonateUserPayload): Promise<string> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL_AUTH);

  try {
    const { data } = await request({
      data: body,
      url: `/sessions`,
      method: 'POST'
    });

    return data.url;
  } catch (error) {
    localStorage.clear();
    window.location.reload();

    throw error;
  }
};

export const deleteUser = async (id: string) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    await request({ url: `/users/${id}`, method: 'DELETE' });

    toast('User has been deleted.', { type: 'success' });
  } catch (error) {
    toast('Error deleting user', { type: 'error' });
  }
};
