interface Props {
  quantity: number;
  onUpdateQuantity: (quantity: number) => void;
}

export const Counter = ({ quantity, onUpdateQuantity }: Props) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = Number(e.target.value);
    onUpdateQuantity(newQuantity);
  };

  const increment = () => {
    const newQuantity = quantity + 1;
    onUpdateQuantity(newQuantity);
  };

  const decrement = () => {
    if (quantity <= 0) return;
    const newQuantity = quantity - 1;
    onUpdateQuantity(newQuantity);
  };

  return (
    <div className="counter">
      <div className="minus" onClick={decrement} />
      <input type="number" min={0} value={quantity} onChange={onChange} />
      <div className="plus" onClick={increment} />
    </div>
  );
};
