const DownloadIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" version="1" viewBox="0 0 64 64">
    <path
      d="M297 543c-3-21-9-73-13-115l-7-78h-77l56-77c30-43 59-78 64-78s34 35 64 78l56 77h-77l-7 78c-12 130-17 152-36 152-12 0-19-11-23-37z"
      transform="matrix(.1 0 0 -.1 0 64)"
    ></path>
    <path
      d="M80 160V80h480v80c0 73-2 80-20 80s-20-7-20-60v-60H120v60c0 53-2 60-20 60s-20-7-20-80z"
      transform="matrix(.1 0 0 -.1 0 64)"
    ></path>
  </svg>
);

export default DownloadIcon;
