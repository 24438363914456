import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import findIndex from 'lodash/findIndex';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

import { DeleteProviderInvoicePayload } from 'api/purchaseOrders/types';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { Order, ProviderInvoice } from 'types/order.types';
import { HandleCellOrders } from 'types/table.types';

import { TableDropdownCell } from 'components/shared/table/TableDropdownCell';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { getBackOrderItems, totalOrderItemsQty } from 'util/orders';
import { AttachPDF } from 'components/shared/orders/PDF/AttachPDF';
import { NestedTable } from 'components/shared/table/NestedTable';
import { ProviderInvoiceForm } from './form/ProviderInvoiceForm';
import { deleteProviderInvoice } from 'api/purchaseOrders';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { DownArrowIcon } from 'assets/icons/DownArrow';
import { getOrderItemStatus } from 'util/ordersItems';
import { useOrderStore } from 'stores/OrderStore';
import { DragIcon } from 'assets/icons/DragIcon';
import { Table } from 'components/shared/table';
import useTableData from 'hooks/useTableData';
import { convertPrice } from 'util/prices';
import { formatDate } from 'util/dates';
import { getPDF } from 'api/orders';

interface Props {
  refetch: () => void;
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

export const ProvidersInvoicesTable = ({ refetch, dragHandleProps }: Props) => {
  const { order, setOrder } = useOrderStore();
  const { orgID, orderID, providerInvoices } = order;

  const [invoiceForUpdate, setInvoiceForUpdate] = useState<ProviderInvoice | null>(null);
  const [invoiceForCreate, setInvoiceForCreate] = useState(false);
  const [collapsed, setCollapsed] = useState<string[]>([]);
  const [data, setData] = useState<ProviderInvoice[]>([]);
  const [cellID, setCellID] = useState('');

  useEffect(() => {
    setData(orderBy(providerInvoices, 'created'));
  }, [providerInvoices]);

  const { meta, tableData } = useTableData({ allData: data });
  const { mutate: deleteInvoice } = useMutation(deleteProviderInvoice);

  const dotsRef = useRef(null);
  useOutsideClick(dotsRef, () => cellID && setCellID(''));

  const onSuccess = (res: Order) => {
    setOrder(res);
    setCellID('');
  };

  const onDelete = () => {
    const payload: DeleteProviderInvoicePayload = {
      orderID: order.orderID,
      orgID: order.orgID,
      invoiceID: cellID
    };

    deleteInvoice(payload, { onSuccess });
  };

  const handleCellOrders = ({ cell, direction }: HandleCellOrders) => {
    let res: any[] = [];

    switch (cell) {
      case 'Seller':
        res = orderBy(providerInvoices, 'provider', direction);
        break;
      case 'Invoice #':
        res = orderBy(providerInvoices, 'referenceID', direction);
        break;
      case 'Tied Order #':
        res = orderBy(providerInvoices, (e) => formatDate(e.due), direction);
        break;
      case '# Items':
        res = orderBy(providerInvoices, (e) => e.items.length, direction);
        break;
      case 'Subtotal':
        res = orderBy(providerInvoices, 'subtotal', direction);
        break;
      case 'Shipping':
        res = orderBy(providerInvoices, 'shippingCosts', direction);
        break;
      case 'Tax':
        res = orderBy(providerInvoices, 'tax', direction);
        break;
      case 'Total':
        res = orderBy(providerInvoices, 'total', direction);
        break;
      case '# BO Items':
        res = orderBy(providerInvoices, 'total', direction);
        break;
      default:
        res = data;
        break;
    }

    setData(res);
  };

  const toggleCollapse = (id: string) =>
    setCollapsed((p) => (p.includes(id) ? p.filter((el) => el !== id) : [...p, id]));

  const onDownloadPDF = async (el: ProviderInvoice) => {
    try {
      const filename = Object.values(el.files)[0]?.Filename || '';
      const res = await getPDF({ docID: el.id, orgID, orderID, filename });
      if (!res) return;

      if (res.type === 'image') {
        const a = document.createElement('a');
        a.href = res.file as string;
        a.target = '_blank';
        a.download = filename;
        a.click();
        return;
      } else {
        const blobUrl = URL.createObjectURL(res.file as any);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = filename;
        a.click();
      }
    } catch (error) {}
  };

  return (
    <>
      <Table
        name="ProvidersInvoices"
        title={
          <>
            <span {...dragHandleProps}>
              <DragIcon />
            </span>
            Providers Invoices
          </>
        }
        nav={[
          '#',
          'Seller',
          'Invoice #',
          'Tied Order #',
          '# Items',
          'Qty',
          'Subtotal',
          'Shipping',
          'Tax',
          'Total',
          '# BO Items',
          'Attachment',
          ''
        ]}
        meta={meta}
        allData={data}
        handleCellOrders={handleCellOrders}
        headerContent={
          <div className="btns">
            <button className="outlined lg" onClick={() => setInvoiceForCreate(true)}>
              Create Provider Invoice
            </button>
          </div>
        }
        content={
          <div className="tableBody nested">
            {tableData.map((el: ProviderInvoice) => (
              <div key={el.id} className="groupWithChildren">
                <div className="tableRow">
                  <TableCellTooltip content={findIndex(data, (fi) => fi.id === el.id) + 1 + '.'} />{' '}
                  <TableCellTooltip content={el.provider} />
                  <TableCellTooltip content={el.referenceID} />
                  <TableCellTooltip
                    content={order.purchaseOrders?.find((po) => po.provider === el.provider)?.referenceID || ''}
                  />
                  <TableCellTooltip content={uniqBy(el.items, (e) => e.item.objectID).length} />
                  <TableCellTooltip content={totalOrderItemsQty(el.items)} />
                  <TableCellTooltip content={convertPrice(el.subtotal)} />
                  <TableCellTooltip content={convertPrice(el.shippingCosts)} />
                  <TableCellTooltip content={convertPrice(el.tax)} />
                  <TableCellTooltip content={convertPrice(el.total)} />
                  <TableCellTooltip content={getBackOrderItems(el.items).length} />
                  <div className="tableCell">{el.files ? 'Yes' : <AttachPDF refetch={refetch} docID={el.id} />}</div>
                  <TableDropdownCell id={el.id} active={cellID} onClick={setCellID} ref={dotsRef}>
                    {el.files && <li onClick={() => onDownloadPDF(el)}>Download Provider Invoice</li>}
                    <li onClick={() => setInvoiceForUpdate(el)}>Update provider invoice</li>
                    <li className="danger" onClick={onDelete}>
                      Delete provider invoice
                    </li>
                  </TableDropdownCell>
                  <div className="tableCell">
                    <div className="tableDownArrow" onClick={() => toggleCollapse(el.id)}>
                      <DownArrowIcon />
                    </div>
                  </div>
                </div>
                {collapsed.includes(el.id) && (
                  <NestedTable
                    className="tableProvidersInvoicesItems"
                    nav={['# Item', 'Seller SKU', 'SIUR', 'Qty', 'SIIT', 'Item Status', 'Item']}
                  >
                    {el.items.map((item, itemIndex) => {
                      const itemStatus = getOrderItemStatus(order, item.item.objectID);
                      return (
                        <div key={item.item.objectID} className="tableRow">
                          <TableCellTooltip content={`${itemIndex + 1}.`} />
                          <TableCellTooltip content={item.price.sku} />
                          <TableCellTooltip content={convertPrice(item.price.sellingPrice)} />
                          <TableCellTooltip content={item.quantity} />
                          <TableCellTooltip content={convertPrice((item.price.buyingPrice || 0) * item.quantity)} />
                          <TableCellTooltip className={`orderItemStatus ${itemStatus}`} content={itemStatus} />
                          <TableCellTooltip content={item.item.title} />
                        </div>
                      );
                    })}
                  </NestedTable>
                )}
              </div>
            ))}
            <div className="tableRow totalRow">
              <div className="tableCell" />
              <div className="tableCell" />
              <div className="tableCell" />
              <div className="tableCell" />
              <div className="tableCell" />
              <div className="tableCell" />
              <TableCellTooltip content={convertPrice(data.reduce((acc, e) => acc + e.subtotal, 0))} />
              <TableCellTooltip content={convertPrice(data.reduce((acc, e) => acc + e.shippingCosts, 0))} />
              <TableCellTooltip content={convertPrice(data.reduce((acc, e) => acc + e.tax, 0))} />
              <TableCellTooltip content={convertPrice(data.reduce((acc, e) => acc + e.total, 0))} />
              <div className="tableCell" />
              <div className="tableCell" />
              <div className="tableCell" />
            </div>
          </div>
        }
      />
      {(invoiceForCreate || invoiceForUpdate) && (
        <ProviderInvoiceForm
          refetch={refetch}
          purchaseInvoice={invoiceForUpdate}
          close={() => {
            setInvoiceForCreate(false);
            setInvoiceForUpdate(null);
          }}
        />
      )}
    </>
  );
};
