import { OrderOverviewStackInfo } from 'types/order.types';

export const getStackInfoStatusColor = (status: string) => {
  switch (status) {
    case 'ITEM_PENDING':
    case 'ITEM_PROCESSING':
    case 'PARTIALLY_DELIVERED':
    case 'PENDING':
    case 'PROCESSING':
      return '#dcc709';
    case 'ITEM_ORDERED':
    case 'ITEM_SHIPPED':
    case 'ITEM_DELIVERED':
    case 'ORDERED':
    case 'SHIPPED':
    case 'DELIVERED':
      return '#23b95e';
    case 'ITEM_N/A':
    case 'ITEM_CANCELLED':
    case 'N/A':
    case 'CANCELLED':
      return '#676c88';
    case 'ITEM_BACKORDERED':
    case 'BACKORDERED':
      return '#ef5628';
    default:
      return '#fff';
  }
};

export const formatStackInfoStatus = (status: string) => {
  const el = status.replace('ITEM_', '').toLowerCase();

  return el.charAt(0).toUpperCase() + el.slice(1);
};

export const getStackInfoItem = (stackInfo: OrderOverviewStackInfo[], variantID: string) =>
  stackInfo.find(({ VariantID }) => VariantID === variantID);
