import { useState } from 'react';
import { CSVImporter } from "csv-import-react";

import { Org, UpdateFormularyItemPayload, UpdateFormularyItemResponse } from 'api/orgs/types';
import { Formulary, UploadData } from 'types/tableflow.types';
import { User } from 'util/session';

import { CSVDownload } from 'components/shared/CSVDownload';
import { updateOrgOfficeFormularyItems } from 'api/orgs';
import ReactSelect from 'components/ui/ReactSelect';
import { debounce } from 'lodash';

interface Props {
  user?: User;
  org: Org;
}

export const UploadOfficeFormulary = ({ org, user }: Props) => {
  const [issues, setIssues] = useState<UpdateFormularyItemResponse[]>([]);
  const [selected, setSelected] = useState<any>(null);

  const officesOptions = org.offices.map((office) => ({ value: office.officeID, label: office.name }));

  const formattedData = issues.map((issue) => ({
    providerName: issue.providerName,
    error: issue.error,
    sku: issue.sku
  }));

  const dataHandler = async (data: UploadData<Formulary>): Promise<void> => {
    setSelected(null)
    setIssues([]);

    if (data.error) return alert(data.error);

    let body: UpdateFormularyItemPayload[] = [];
    const issues: UpdateFormularyItemResponse[] = [];

    let i = -1;
    for (const row of data.rows) {
      i++;
      body.push({
        providerName: row.values.supplier,
        sku: row.values.sku,
        op: 'create'
      });

      // Update formulary every 1000 rows or at the end of the file
      if (body.length === 1000 || i === data.rows.length - 1) {
        const res = await updateOrgOfficeFormularyItems({
          orgID: org.metadata.orgID,
          officeID: selected.value,
          items: body
        });
        if (res.length > 0) issues.push(...res);
        body = [];
      }
    }

    setIssues(issues);
  };

  const onComplete: any = debounce(dataHandler, 500);

  return (
    <>
      <div style={{ width: 220 }}>
        <ReactSelect
          placeholder="Upload Office Formulary"
          options={officesOptions}
          value={selected}
          onChange={(value) => setSelected(value)}
        />
      </div>
      <CSVImporter
        isModal={true}
        modalIsOpen={selected ? true : false}
        modalOnCloseTriggered={() => setSelected(null)}
        modalCloseOnOutsideClick={true}
        primaryColor="#36bebc"
        onComplete={onComplete}
        template={{
          columns: [
            {
              "id": "a630a7bd-f50b-4edb-8257-0e50548f278a",
              "name": "Supplier",
              "key": "supplier",
              "required": true,
              "data_type": "string",
              "description": "",
              "suggested_mappings": []
            },
            {
              "id": "a719a03b-a091-4802-82cd-46c532cd4427",
              "name": "SKU",
              "key": "sku",
              "required": true,
              "data_type": "string",
              "description": "",
              "suggested_mappings": []
            }
          ]
        }}
      />
      {issues.length ? <CSVDownload data={formattedData} filename="office_formulary_upload_issues.csv" /> : null}
    </>
  );
};
