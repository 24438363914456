import { useState } from 'react';
import { InstantSearch } from 'react-instantsearch-hooks';

import { adminSearchClient, algoliaKeys } from 'lib/algolia/aloglia-config';
import { NewOrganizationModal } from './NewOrganizationModal';
import { OrganizationsTable } from './OrganizationsTable';

export const OrganizationsPage = () => {
  const [isOpenNewOrgModal, setIsOpenNewOrgModal] = useState(false);
  const toggleNewOrgModal = () => setIsOpenNewOrgModal((prev) => !prev);

  return (
    <div className="orgsPage">
      <div className="flexBetween">
        <h1 className="pageTitle">Organizations</h1>
        <button className="outlined lg auto-width" onClick={toggleNewOrgModal}>
          Create Organization
        </button>
      </div>
      <InstantSearch
        searchClient={adminSearchClient}
        indexName={algoliaKeys.indexName.organizations}
      >
        <OrganizationsTable />
      </InstantSearch>
      <NewOrganizationModal
        open={isOpenNewOrgModal}
        close={toggleNewOrgModal}
      />
    </div>
  );
};
