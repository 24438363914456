import { BASE_URL_VARIANTS, apiCall } from 'api';
import { AutoCartQueueJobsResponse, Job, JobsInResponse } from './types';
import { toast } from 'react-toastify';

export enum JobType {
  SKUUpload = 'sku-upload',
  CatalogUpload = 'catalog-upload',
  InventoryUpload = 'inventory-upload'
}
export const fetchJobs = async (j: JobType) => {
  try {
    const request = await apiCall(BASE_URL_VARIANTS.RETAIL);
    const { data } = await request<JobsInResponse[]>({ url: `/jobs/${j}`, method: 'GET' });
    return data;
  } catch (error: any) {
    throw toast(error?.message || 'Something went wrong with fetching jobs', { type: 'error' });
  }
};

export const fetchSingleJob = async (j: JobType, executionID: string) => {
  try {
    const request = await apiCall(BASE_URL_VARIANTS.RETAIL);
    const { data } = await request<Job>({ url: `/jobs/${j}/${executionID}`, method: 'GET' });
    return data;
  } catch (error: any) {
    throw toast(error?.message || 'Something went wrong with fetching single job', { type: 'error' });
  }
};

export const fethAutoCartQueueJobs = async () => {
  try {
    const request = await apiCall(BASE_URL_VARIANTS.PROCUREMENT);
    const { data } = await request<AutoCartQueueJobsResponse>({ url: `/orderqueue`, method: 'GET' });
    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const skipOrder = async (payload: { orderID: string; provider: string }) => {
  try {
    const request = await apiCall(BASE_URL_VARIANTS.PROCUREMENT);
    const { data } = await request({ url: `/orderqueue/skiporder`, method: 'POST', data: payload });
    toast.success(`Order ${payload.orderID} has been skipped!`);
    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};
