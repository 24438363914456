/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useHits, useSearchBox } from 'react-instantsearch-hooks';
import orderBy from 'lodash/orderBy';

import { AlgoliaHit, Hit, Supplier } from 'types/algolia.types';
import { ProductItem } from 'types/items.types';
import { MoreItemsProps } from './';

import { DownArrowIcon } from 'assets/icons/DownArrow';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { useSupplierLogo } from 'hooks/useSupplierLogo';
import { useAlgoliaHits } from 'hooks/useAlgoliaHits';
import { isActiveClass } from 'util/classes';
import { convertPrice } from 'util/prices';
import { formatUom, showLastPurchased } from 'util/mix';
import { Checkbox } from 'components/ui/Checkbox';
import { useHistoryItemsStore } from 'stores/HistoryItemsStore';

export type MoreItemsState = ProductItem & {
  supplier: Supplier;
};

export const AlgoliaHits = ({ org, addedItems, setAddedItems, callback, isFormulary = false }: MoreItemsProps) => {
  const [activeSupplierDropdown, setActiveSupplierDropdown] = useState('');
  const [selectedItems, setSelectedItems] = useState<MoreItemsState[]>([]);
  const { getSupplierLogo } = useSupplierLogo();
  const { historyItems } = useHistoryItemsStore();

  useEffect(() => {
    setSelectedItems(addedItems);
  }, []);

  const { hits }: any = useHits();
  const { refine, query } = useSearchBox();
  const { hitsItems } = useAlgoliaHits({ hits, org });

  const toggleDropdown = (id: string) => setActiveSupplierDropdown((prev) => (prev === id ? '' : id));

  const isHitAdded = (id: string) => addedItems.some((el) => el.objectID === id);

  const isSupplierSelected = ({ objectID }: Hit, { name }: Supplier) =>
    selectedItems.some((el) => el.objectID === objectID && el.supplier.name === name);

  const isSupplierValid = (supplier: Supplier) => {
    if (isFormulary) return true;
    const { connected, pricePending, stock, price } = supplier;
    if (connected && !pricePending && stock && price > 0) return true;
    return false;
  };

  const dropdownPlaceholder = (id: string) => {
    if (selectedItems.some((el) => el.objectID === id)) {
      const hits = orderBy(
        selectedItems.filter((el) => el.objectID === id),
        (el) => el.supplier.price,
        'desc'
      );

      return (
        <div>
          {hits.map((hit) => (
            <p key={`${hit.objectID}${hit.supplier.name}`}>
              {hit.supplier.name}:{' '}
              {hit.supplier.price > 0 ? `${convertPrice(hit.supplier.price)} ${formatUom(hit)}` : 'Price Unavailable'}
            </p>
          ))}
        </div>
      );
    }

    return 'Select Supplier';
  };

  const handleSelectedItems = (hit: Hit, supplier: Supplier) => {
    if (isSupplierSelected(hit, supplier)) {
      setSelectedItems((prev) =>
        prev.filter((el) => el.objectID !== hit.objectID || el.supplier.name !== supplier.name)
      );
    } else setSelectedItems((prev) => [...prev, { ...hit, supplier }]);
  };

  const handleAddedItems = (hit: Hit, supplier: Supplier) => {
    const addedItem = addedItems.find((el) => el.objectID === hit.objectID && el.supplier.name === supplier.name);

    if (addedItem) {
      const allItems = addedItems.filter((el) => el.objectID !== hit.objectID || el.supplier.name !== supplier.name);
      setAddedItems(allItems);
      callback(allItems, [addedItem], 'delete');
    } else if (isHitAdded(hit.objectID)) {
      const el = { ...hit, supplier };
      const allItems = [...addedItems, el];
      setAddedItems(allItems);
      callback(allItems, [el], 'create');
    }
  };

  const onSelectSupplier = (hit: Hit, supplier: Supplier) => {
    handleSelectedItems(hit, supplier);
    handleAddedItems(hit, supplier);
  };

  const onSubmit = (hit: AlgoliaHit) => {
    if (isHitAdded(hit.objectID)) return;

    const hits = selectedItems.filter((item) => item.objectID === hit.objectID);
    if (hits.length) {
      const allItems = [...addedItems, ...hits];
      setAddedItems(allItems);
      callback(allItems, hits, 'create');
      setActiveSupplierDropdown('');
    }
  };

  return (
    <div className={isActiveClass('algoliaHits', query || isFormulary ? true : false)}>
      <div className="legend">
        <p>Products</p>
        <CloseIcon onClick={() => refine('')} />
      </div>
      <div className="hits customScrollbar">
        {hitsItems.map((hit) => (
          <div key={hit.objectID} className="hit">
            <div className="left">
              <div className="hitPhoto">
                <img src={hit.images[0]} alt={hit.title} />
              </div>
              <div>
                <h2>{hit.title}</h2>
                <div className="infoRow">
                  <span>MFR Code: {hit.manufacturerCode}</span>·<span>{formatUom(hit, false)}</span>
                </div>
                <div className="infoRow">
                  <span>{showLastPurchased(historyItems, hit.objectID, hit.uom)}</span>
                </div>
                <div
                  className={isActiveClass(
                    'supplierDropdown',
                    selectedItems.some((el) => el.objectID === hit.objectID)
                  )}
                >
                  <div className="supplierDropdownPreview" onClick={() => toggleDropdown(hit.objectID)}>
                    {dropdownPlaceholder(hit.objectID)}
                    <DownArrowIcon />
                  </div>
                  <div className={isActiveClass('supplierDropdownOptions', hit.objectID === activeSupplierDropdown)}>
                    <ul className="customScrollbar">
                      {hit.suppliers.map((supplier) => (
                        <li key={`${hit.objectID}${supplier.name}`} className="supplier">
                          <div>
                            {isSupplierValid(supplier) && (
                              <Checkbox
                                isEnabled={isSupplierSelected(hit, supplier)}
                                onChange={() => onSelectSupplier(hit, supplier)}
                              />
                            )}
                          </div>
                          <div className="supplierLogo">
                            <img src={getSupplierLogo(supplier.name)} alt={supplier.name} />
                          </div>
                          <div className="sku">
                            <span>SKU:</span>
                            <span>{supplier.sku}</span>
                          </div>
                          <div className="badgeHolder">
                            <div className={`badge ${supplier.stock ? 'success' : 'secondary'}`}>
                              {supplier.stock ? 'In Stock' : 'Out Of Stock'}
                            </div>
                          </div>
                          <div className="price">{supplier.displayPrice}</div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <button
              className={isActiveClass('contained lg auto-width', isHitAdded(hit.objectID))}
              onClick={() => onSubmit(hit)}
            >
              {isHitAdded(hit.objectID) ? 'Added' : 'Add'} Product
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
