export const UsersIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="#080C2D"
      d="M11.07 7.365a3.78 3.78 0 00.5-2.294c-.12-1.189-.783-2.24-1.869-2.96l-.736 1.111c.746.495 1.2 1.2 1.278 1.983a2.46 2.46 0 01-.714 1.99l-.795.795 1.079.317c2.82.826 2.854 3.664 2.854 3.692H14c0-1.192-.637-3.523-2.93-4.634z"
    ></path>
    <path
      fill="#080C2D"
      d="M6.333 8A2.67 2.67 0 009 5.332a2.67 2.67 0 00-2.667-2.667 2.67 2.67 0 00-2.666 2.667 2.67 2.67 0 002.667 2.666zm0-4c.736 0 1.334.597 1.334 1.333 0 .735-.598 1.333-1.333 1.333A1.335 1.335 0 015 5.333c0-.736.598-1.334 1.333-1.334zm1 4.666h-2c-2.206 0-4 1.794-4 4v.667h1.334v-.667a2.67 2.67 0 012.667-2.667h2A2.67 2.67 0 0110 12.666v.667h1.334v-.667c0-2.206-1.794-4-4-4z"
    ></path>
  </svg>
);
