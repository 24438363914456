import { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { useMutation } from 'react-query';

import { UpdateOverallOrderStatusPayload } from 'api/orders/types';

import { updateOverallOrderStatus } from 'api/orders';
import { DownArrowIcon } from 'assets/icons/DownArrow';
import { useOrderStore } from 'stores/OrderStore';
import { Select } from 'components/ui/Select';
import { isActiveClass } from 'util/classes';
import { Modal } from 'components/ui/Modal';

interface Props {
  close: () => void;
}

const statuses = [
  'pending',
  'declined',
  'canceled',
  'confirmed',
  'shipped',
  'delivered',
  'refunded',
  'returned',
  'placed',
  'partially shipped',
  'partially delivered',
  'partially placed'
];

export const CustomerStatusModal = ({ close }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const { order, setOrder } = useOrderStore();
  const { mutate, isLoading } = useMutation(updateOverallOrderStatus);

  const onSubmit = (values: any) => {
    const payload: UpdateOverallOrderStatusPayload = {
      orderID: order.orderID,
      orgID: order.orgID,
      body: values
    };

    mutate(payload, {
      onSuccess: (res) => {
        setOrder(res);
        close();
      }
    });
  };

  return (
    <Modal open={true} close={close} title="Change Customer Status" className="changeOrderStatusModal">
      <Formik
        initialValues={{
          status: order.status,
          description: ''
        }}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Select
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              className="regular"
              options={
                <ul>
                  {statuses.map((s) => (
                    <li
                      key={s}
                      className={isActiveClass('', values.status === s)}
                      onClick={() => {
                        setFieldValue('status', s);
                        setIsOpen(false);
                      }}
                    >
                      {s}
                    </li>
                  ))}
                </ul>
              }
            >
              {values.status}
              <DownArrowIcon />
            </Select>
            <div className="field">
              <label>Description</label>
              <Field as="textarea" type="number" name="description" placeholder="Write a reason..." required />
            </div>
            <button type="submit" className="contained" disabled={isLoading}>
              Save changes
            </button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
