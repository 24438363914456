import { useState } from 'react';
import { useFormikContext } from 'formik';
import ReactTooltip from 'react-tooltip';

import { OrderItem } from 'types/order.types';

import { formatUom, groupOrdersBySupplier } from 'util/mix';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { TableHeader } from 'components/shared/table/TableHeader';
import { TableBody } from 'components/shared/table/TableBody';
import { Checkbox } from 'components/ui/Checkbox';
import { Counter } from 'components/ui/Counter';
import { useOrderStore } from 'stores/OrderStore';
import { convertPrice, orderSupplierPrice } from 'util/prices';
import { EditPrice } from '../items/editOrderItemsModal/EditPrice';
import { orderBy } from 'lodash';

interface Props {
  canUpdatePrice: boolean;
  variant?: string;
}

export const Items = ({ canUpdatePrice, variant }: Props) => {
  const { values, setFieldValue }: any = useFormikContext();
  const { allItems, selectedItems } = values;
  const isAllSelected = allItems.length === selectedItems.length;
  const { order } = useOrderStore();
  const orginialItems: OrderItem[] = order ? order.items : [];
  const [itemForEditPrice, setItemForEditPrice] = useState<OrderItem | null>(null);

  const onUpdateQuantity = (supplier: string, item: OrderItem['item'], quantity: number) => {
    const cloned = [...allItems];
    const i = cloned.findIndex((el) => el.item.objectID === item.objectID && el.price.provider === supplier);
    cloned[i] = { ...cloned[i], quantity };
    setFieldValue('allItems', cloned);
  };

  const isItemSelected = (item: OrderItem) =>
    selectedItems.some(
      (el: OrderItem) => el.item.objectID === item.item.objectID && el.price.provider === item.price.provider
    );

  const toggleSelectItem = (item: OrderItem) => {
    if (isItemSelected(item)) {
      const res = selectedItems.filter(
        (el: OrderItem) => el.item.objectID !== item.item.objectID || el.price.provider !== item.price.provider
      );
      setFieldValue('selectedItems', res);
    } else {
      setFieldValue('selectedItems', [...selectedItems, item]);
    }
  };

  const findOriginalItem = (item: OrderItem) =>
    orginialItems.find((el) => el.item.objectID === item.item.objectID && el.price.provider === item.price.provider);

  const isRowCovered = (item: OrderItem) => {
    if (variant !== 'purchaseOrder') return 'tableRow';

    const allPurchaseOrders: OrderItem[] =
      order.purchaseOrders?.reduce((acc: any, curr) => [...acc, ...curr.items], []) || [];

    return allPurchaseOrders.some((el) => {
      return (
        el.item.objectID === item.item.objectID &&
        el.price.provider === item.price.provider &&
        el.quantity >= item.quantity
      );
    })
      ? 'tableRow successRow'
      : 'tableRow dangerRow';
  };

  return (
    <div className="fullScreenItemsTables">
      {allItems.length ? (
        <div className="flexRow">
          <Checkbox
            isEnabled={isAllSelected}
            onChange={() => setFieldValue('selectedItems', isAllSelected ? [] : allItems)}
          />
          <p>{isAllSelected ? 'Deselect' : 'Select'} All Items</p>
        </div>
      ) : null}
      {groupOrdersBySupplier(allItems).map(([supplier, items]) => {
        const orderItems = variant === 'purchaseOrder' ? orderBy(items, (e) => isRowCovered(e)) : items;

        return (
          <div className="fullScreenItemsGroup" key={supplier}>
            <div className="head">
              <p>
                {supplier} ({items.length} items)
              </p>
            </div>
            <div className="tableWrapper">
              <div className="table tableCOitems">
                <div className="tableHolder">
                  <TableHeader
                    nav={[
                      'Select',
                      'Item',
                      'Price',
                      'Seller SKU',
                      'MFR Code',
                      'Packaging',
                      'Qty Ordered',
                      'Qty Left',
                      'Qty Applied'
                    ]}
                  />
                  <TableBody
                    data={items}
                    content={
                      <div className="tableBody">
                        {orderItems.map((el) => (
                          <div key={el.item.objectID} className={isRowCovered(el)}>
                            <div className="tableCell">
                              <Checkbox isEnabled={isItemSelected(el)} onChange={() => toggleSelectItem(el)} />
                            </div>
                            <TableCellTooltip content={el.item.title} />
                            <div className={`tableCell ${canUpdatePrice ? 'canUpdatePrice' : ''}`}>
                              <span>{convertPrice(orderSupplierPrice(el.price))}</span>
                              {canUpdatePrice && (
                                <button type="button" className="outlined lg" onClick={() => setItemForEditPrice(el)}>
                                  Edit Price
                                </button>
                              )}
                            </div>
                            <TableCellTooltip content={el.price.sku} />
                            <TableCellTooltip content={el.item.manufacturerCode} />
                            <TableCellTooltip content={formatUom(el.item, false)} />
                            <TableCellTooltip content={findOriginalItem(el)?.quantity || 1} />
                            <TableCellTooltip content={(findOriginalItem(el)?.quantity || 1) - el.quantity} />
                            <div className="tableCell">
                              <Counter
                                quantity={el.quantity}
                                onUpdateQuantity={(qty) => onUpdateQuantity(supplier, el.item, qty)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    }
                  />
                </div>
              </div>
              <ReactTooltip place="bottom" />
            </div>
          </div>
        );
      })}
      {itemForEditPrice && (
        <EditPrice
          order={itemForEditPrice}
          items={allItems}
          setItems={(res) => setFieldValue('allItems', res)}
          close={() => setItemForEditPrice(null)}
        />
      )}
    </div>
  );
};
