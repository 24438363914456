import { downloadGpoSummaryFile, downloadSummaryFile } from 'api/health';
import { HealthCsvFileType, OrgHealthSnapshotResponse } from 'api/health/types';
import DownloadIcon from 'assets/icons/DownloadIcon';
import { LoaderIcon } from 'assets/icons/LoaderIcon';
import { useQueryParams } from 'hooks/useSearchQuery';
import { useMutation } from 'react-query';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import { isActiveClass } from 'util/classes';

type Props = {
  id: string;
  providerName: string;
  data?: OrgHealthSnapshotResponse;
  isLoading: boolean;
  type: 'org' | 'gpo';
};

const COLORS = [
  { key: 'total', color: '#272727' },
  { key: 'fresh', color: '#23b95e' },
  { key: 'stale', color: '#ff9532' },
  { key: 'missing', color: '#ef5628' }
];

export const HealthPieChart = ({ id, providerName, data, isLoading, type }: Props) => {
  const { getQueryParam } = useQueryParams();

  const snapshotID = getQueryParam('snapshotID') || '';

  const formated = Object.entries(data || {}).map(([key, value]) => ({ name: key, value }));
  const dataForChart = formated.filter(({ name }) => name !== 'total');

  const { mutate: downloadOrg, isLoading: isLoadingMutateOrg } = useMutation(downloadSummaryFile);
  const { mutate: downloadGpo, isLoading: isLoadingMutateGpo } = useMutation(downloadGpoSummaryFile);

  return (
    <div className={isActiveClass('pieChart', Boolean(data) || isLoading, 'paper')}>
      {isLoading && <LoaderIcon />}

      {data && (
        <div className="pieChart">
          <PieChart width={250} height={250}>
            <Pie
              data={dataForChart}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {dataForChart?.map((_: any, index: any) => (
                <Cell key={`cell-${index}`} fill={COLORS.find(({ key }) => key === _.name)?.color} />
              ))}
            </Pie>
            <Tooltip
              content={({ active, payload }) =>
                active && payload && payload.length ? (
                  <div className="piechartTooltip paper">
                    <p>{payload[0].name}:</p>
                    <p>{payload[0].value}</p>
                  </div>
                ) : null
              }
            />
          </PieChart>
          <ul className="chartLegend">
            {formated.map(({ name, value }) => {
              const color = COLORS.find(({ key }) => key === name)?.color || '';
              const percent = (value / data?.total) * 100;
              return (
                <li key={name}>
                  <button
                    type="button"
                    disabled={isLoadingMutateOrg || isLoadingMutateGpo}
                    onClick={() => {
                      const docType = name as HealthCsvFileType;
                      type === 'org'
                        ? downloadOrg({ orgID: id, providerName, snapshotID, type: docType })
                        : downloadGpo({ gpoID: id, providerName, snapshotID, type: docType });
                    }}
                  >
                    <DownloadIcon />
                  </button>
                  <div style={{ backgroundColor: color }} className="box" />
                  <p>{name}</p>
                  <p>
                    {value}
                    {name !== 'total' && ` (${percent.toFixed(2)}%)`}
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
