import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { signIn, confirmSignIn } from 'aws-amplify/auth';
import { useEffect, useState } from 'react';

enum AuthErrors {
  NEW_PASSWORD_REQUIRED = 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
}

export const Login = () => {
  // Sign in functions
  const signInMutation = useMutation(signIn);
  const loginFormik = useFormik({
    initialValues: { email: '', password: '' },
    onSubmit: ({ email, password }) => {
      signInMutation.mutate({ username: email, password });
    }
  });
  useEffect(() => {
    if (signInMutation.isError) {
      toast.error(`An error occurred: ${signInMutation.error}`);
      return;
    }
  }, [signInMutation.isError]);
  useEffect(() => {
    if (signInMutation.data?.nextStep.signInStep == AuthErrors.NEW_PASSWORD_REQUIRED) {
      setIsResetPassword(true);
    }
  }, [signInMutation.data]);

  // Confirmation functions
  const [isResetPassword, setIsResetPassword] = useState(false);
  const confirmMutation = useMutation(confirmSignIn);
  const confirmFormik = useFormik({
    initialValues: { password: '' },
    onSubmit: ({ password }) => {
      confirmMutation.mutate({ challengeResponse: password });
    }
  });
  useEffect(() => {
    if (confirmMutation.isError) {
      toast.error(`An error occurred: ${confirmMutation.error}`);
      return;
    }
  }, [confirmMutation.isError]);

  // Ask to confirm password if user is in FORCE_CHANGE_PASSWORD state
  if (isResetPassword) {
    return (
      <div className="loginPage">
        <form onSubmit={confirmFormik.handleSubmit}>
          <div className="input-field">
            <label>New Password</label>
            <input
              type="password"
              name="password"
              id="password"
              value={confirmFormik.values.password}
              onChange={confirmFormik.handleChange}
              placeholder="Enter your new password"
            />
          </div>

          <button className="contained lg" type="submit" disabled={confirmMutation.isLoading}>
            Confirm
          </button>
        </form>
      </div>
    );
  }

  // Normal login page - Nije nenormalan
  return (
    <div className="loginPage">
      <form onSubmit={loginFormik.handleSubmit}>
        <h1>Login</h1>
        <h2>Enter your email and password to login to your account.</h2>
        <div className="input-field">
          <label>Email</label>
          <input
            type="email"
            name="email"
            id="email"
            value={loginFormik.values.email}
            onChange={loginFormik.handleChange}
            placeholder="Enter your email address"
          />
        </div>

        <div className="input-field">
          <label>Password</label>
          <input
            type="password"
            name="password"
            id="password"
            value={loginFormik.values.password}
            onChange={loginFormik.handleChange}
            placeholder="Enter your password"
          />
        </div>

        <button className="contained lg" type="submit" disabled={signInMutation.isLoading}>
          Login
        </button>
      </form>
    </div>
  );
};
