import { useState } from 'react';
import { Form, Formik } from 'formik';
import { useMutation } from 'react-query';
import { Calendar } from 'react-calendar';
import dayjs from 'dayjs';

import { UpdateBackOrderPayload } from 'api/purchaseOrders/types';
import { OrderItem } from 'types/order.types';

import { CustomerPreferenceStatus } from './CustomerPreferenceStatus';
import { FullScreenModal } from 'pages/order/fullScreenModals';
import { updateBackOrder } from 'api/purchaseOrders';
import { useOrderStore } from 'stores/OrderStore';
import { Modal } from 'components/ui/Modal';
import { formatDate } from 'util/dates';

interface Props {
  item: OrderItem;
  close: () => void;
}

interface FormikState {
  expectedDeliveryDate: Date;
  customerPreference: 'WAIT' | 'CANCEL';
}

export const UpdateBackOrder = ({ item, close }: Props) => {
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);

  const initialValues: FormikState = {
    expectedDeliveryDate: new Date(),
    customerPreference: 'WAIT'
  };

  const { order, setOrder } = useOrderStore();
  const { mutate, isLoading } = useMutation(updateBackOrder);

  const onSubmit = (values: FormikState) => {
    if (!item.backOrder) return;

    const date = dayjs(values.expectedDeliveryDate).format('YYYY-MM-DD');
    const payload: UpdateBackOrderPayload = {
      orderID: order.orderID,
      orgID: order.orgID,
      backOrderID: item.backOrder?.id,
      body: {
        customerPreference: values.customerPreference,
        expectedDeliveryDate: date
      }
    };

    mutate(payload, {
      onSuccess: (res) => {
        setOrder(res);
        close();
      }
    });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, setFieldValue }) => (
        <Form>
          <FullScreenModal close={close} button={{ text: 'Update Backorder', isLoading }} hiddenOrders>
            <div className="paper">
              <div className="paperHeader">
                <h2>Update Backorder</h2>
              </div>
              <div className="paperBody">
                <CustomerPreferenceStatus />
                <div className="field">
                  <label>Expected Delivery Date</label>
                  <div className="fakeField" onClick={() => setIsOpenCalendar(true)}>
                    {formatDate(values.expectedDeliveryDate)}
                  </div>
                  {isOpenCalendar && (
                    <Modal
                      open={true}
                      close={() => setIsOpenCalendar(false)}
                      title="Select Date"
                      className="ordersDatePicker"
                    >
                      <Calendar
                        onChange={(date: Date) => {
                          setFieldValue('expectedDeliveryDate', date);
                          setIsOpenCalendar(false);
                        }}
                        value={values.expectedDeliveryDate}
                      />
                    </Modal>
                  )}
                </div>
              </div>
            </div>
          </FullScreenModal>
        </Form>
      )}
    </Formik>
  );
};
