import { useFormikContext } from 'formik';
import ReactTooltip from 'react-tooltip';

import { Order, OrderItem } from 'types/order.types';

import { TableCellTooltip } from 'components/shared/table/TableCell';
import { TableHeader } from 'components/shared/table/TableHeader';
import { convertPrice, orderSupplierPrice } from 'util/prices';
import { TableBody } from 'components/shared/table/TableBody';
import { formatUom, groupOrdersBySupplier } from 'util/mix';
import { Checkbox } from 'components/ui/Checkbox';
import { Counter } from 'components/ui/Counter';
import { orderBy } from 'lodash';

interface Props {
  order: Order;
}

export const CreateShipmentTable = ({ order }: Props) => {
  const { values, setFieldValue }: any = useFormikContext();
  const { allItems, selectedItems } = values;
  const isAllSelected = allItems.length === selectedItems.length;

  const onUpdateQuantity = (supplier: string, item: OrderItem['item'], quantity: number) => {
    const cloned = [...allItems];
    const i = cloned.findIndex((el) => el.item.objectID === item.objectID && el.price.provider === supplier);
    cloned[i] = { ...cloned[i], quantity };
    setFieldValue('allItems', cloned);
  };

  const isItemSelected = (item: OrderItem) =>
    selectedItems.some(
      (el: OrderItem) => el.item.objectID === item.item.objectID && el.price.provider === item.price.provider
    );

  const toggleSelectItem = (item: OrderItem) => {
    if (isItemSelected(item)) {
      const res = selectedItems.filter(
        (el: OrderItem) => el.item.objectID !== item.item.objectID || el.price.provider !== item.price.provider
      );
      setFieldValue('selectedItems', res);
    } else {
      setFieldValue('selectedItems', [...selectedItems, item]);
    }
  };

  const getQtyByItem = (el: OrderItem) => {
    const { items, orderOverview } = order;
    const needsShipment = orderOverview.NeedsShipment || {};
    const itemsWithLeftQty = items.map((item, i) => ({
      id: item.item.objectID,
      provider: item.price.provider,
      qty: item.quantity,
      qtyLeft: needsShipment[i] - el.quantity,
      isShipped: needsShipment[i] ? false : true
    }));
    const item = itemsWithLeftQty.find((item) => item.id === el.item.objectID && item.provider === el.price.provider);

    return item;
  };

  return (
    <div className="fullScreenItemsTables">
      {allItems.length ? (
        <div className="flexRow m16">
          <Checkbox
            isEnabled={isAllSelected}
            onChange={() => setFieldValue('selectedItems', isAllSelected ? [] : allItems)}
          />
          <p>{isAllSelected ? 'Deselect' : 'Select'} All Items</p>
        </div>
      ) : null}
      {groupOrdersBySupplier(allItems).map(([supplier, items]) => (
        <div className="fullScreenItemsGroup" key={supplier}>
          <div className="head pb12">
            <p>
              {supplier} ({items.length} items)
            </p>
          </div>
          <div className="tableWrapper">
            <div className="table tableCreateShipments">
              <div className="tableHolder">
                <TableHeader
                  nav={[
                    'Select',
                    'Item',
                    'Price',
                    'Seller SKU',
                    'MFR Code',
                    'Packaging',
                    'Qty Ordered',
                    'Qty Left',
                    'Qty Applied'
                  ]}
                />
                <TableBody
                  data={items}
                  content={
                    <div className="tableBody">
                      {orderBy(items, (e) => getQtyByItem(e)?.isShipped).map((el) => {
                        const item = getQtyByItem(el);

                        return (
                          <div
                            key={el.item.objectID}
                            className={`tableRow ${item?.isShipped ? 'successRow' : 'dangerRow'}`}
                          >
                            <div className="tableCell">
                              <Checkbox isEnabled={isItemSelected(el)} onChange={() => toggleSelectItem(el)} />
                            </div>
                            <TableCellTooltip content={el.item.title} />
                            <div className="tableCell">
                              <span>{convertPrice(orderSupplierPrice(el.price))}</span>
                            </div>
                            <TableCellTooltip content={el.price.sku} />
                            <TableCellTooltip content={el.item.manufacturerCode} />
                            <TableCellTooltip content={formatUom(el.item, false)} />
                            <TableCellTooltip content={item?.qty || 0} />
                            <TableCellTooltip content={item?.qtyLeft || 0} />
                            <div className="tableCell">
                              <Counter
                                quantity={el.quantity}
                                onUpdateQuantity={(qty) => onUpdateQuantity(supplier, el.item, qty)}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  }
                />
              </div>
            </div>
            <ReactTooltip place="bottom" />
          </div>
        </div>
      ))}
    </div>
  );
};
