import { useState } from 'react';
import Calendar from 'react-calendar';
import { useSearchBox } from 'react-instantsearch-hooks';

import { Modal } from 'components/ui/Modal';
import { formatDate } from 'util/dates';
import dayjs from 'dayjs';

interface Props {
  date: Date | null;
  setDate: React.Dispatch<React.SetStateAction<Date | null>>;
}

export const DatePicker = ({ date, setDate }: Props) => {
  const [open, setOpen] = useState(false);
  const [localValue, setLocalValue] = useState(new Date());
  const toggleCalendar = () => setOpen((prev) => !prev);
  const { refine } = useSearchBox();

  const onSaveDate = () => {
    setDate(localValue);
    refine(dayjs(localValue).format('YYYY-MM-DD'));
    toggleCalendar();
  };

  return (
    <div className="datePicker">
      <p className="selected" onClick={toggleCalendar}>
        {date === null ? 'Select Date' : `Date: ${formatDate(date)}`}
      </p>
      <Modal
        open={open}
        close={toggleCalendar}
        title="Select Date"
        className="ordersDatePicker"
      >
        <Calendar onChange={setLocalValue} value={localValue} />
        <button className="contained" onClick={onSaveDate}>
          Save
        </button>
      </Modal>
    </div>
  );
};
