export const PDFIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="49"
    height="48"
    fill="none"
    viewBox="0 0 49 48"
    {...props}
  >
    <path
      fill="#000"
      d="M12.5 44h24a4 4 0 004-4V16l-12-12h-16a4 4 0 00-4 4v32a4 4 0 004 4zm14-36l10 10h-10V8zm-10 20h6v-6h4v6h6v4h-6v6h-4v-6h-6v-4z"
    ></path>
  </svg>
);
