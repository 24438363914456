import { useMemo, useState } from 'react';
import orderBy from 'lodash/orderBy';

import { GpoPartner } from 'api/gpo/types';

import { SearchIcon } from 'assets/icons/SearchIcon';
import { useProvidersStrore } from 'stores/ProvidersStore';
import { UploadCSVModal } from './UploadCSVModal';
import { CreateNewPartnershipForm } from './CreateNewPartnershipForm';

interface Props {
  gpoID: string;
  refetch: () => void;
  partners: GpoPartner[];
}

export const Partnerships = ({ partners, gpoID, refetch }: Props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const { providers } = useProvidersStrore();
  const [isOpenAddNewPartnershipForm, setIsOpenAddNewPartnershipForm] = useState(false);
  const [selectedProviderName, setSelectedProviderName] = useState('');

  const [providerDialog, setProviderDialog] = useState('');

  const filteredPartnerships = useMemo(() => {
    return partners.filter((item) => item.providerName.toLowerCase().includes(searchQuery.toLowerCase()));
  }, [partners, searchQuery]);

  return (
    <>
      <div className="gpoTable">
        <div className="gpoTableHero">
          <div className="left">
            <h3>Partnerships</h3>
            <div className="searchInput">
              <input
                type="search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search..."
              />
              <SearchIcon />
            </div>
          </div>
          <button className="outlined lg" onClick={() => setIsOpenAddNewPartnershipForm(true)}>
            Add Partnership
          </button>
        </div>
        <div className="gpoTableBody partners">
          <div className="row headRow">
            <div className="cell">Logo</div>
            <div className="cell">Name</div>
            <div className="cell">Status</div>
            <div className="cell">Contact</div>
            <div className="cell"></div>
          </div>
          {filteredPartnerships.length ? (
            orderBy(filteredPartnerships, ['providerName'], ['asc']).map((partner) => {
              const provider = providers.find((provider) => provider.name === partner.providerName);

              return (
                <div className="row" key={partner.providerID}>
                  <div className="cell">
                    {provider && <img src={provider.image} alt={provider.name} width={50} height={50} />}
                  </div>
                  <div className="cell">{partner.providerName}</div>
                  <div className={`badge ${partner.status === 'CONNECTING' ? 'success' : 'secondary'}`}>
                    {partner.status}
                  </div>
                  <div
                    className="customCell relativeCont"
                    onMouseOver={() => setProviderDialog(partner.providerName)}
                    onMouseLeave={() => setProviderDialog('')}
                  >
                    {partner?.representatives?.length || 0}

                    {providerDialog === partner.providerName && partner?.representatives?.length && (
                      <ul className="paper">
                        {partner?.representatives?.map((el, i) => (
                          <li key={el?.email || '' + el?.phone + i}>
                            <p>{el?.email}</p>
                            <p>{el?.phone}</p>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <p className="noDataParagraph">No partnerships...</p>
          )}
        </div>
      </div>
      {isOpenAddNewPartnershipForm && (
        <CreateNewPartnershipForm id={gpoID} close={() => setIsOpenAddNewPartnershipForm(false)} refetch={refetch} />
      )}
      {selectedProviderName && (
        <UploadCSVModal
          gpoID={gpoID}
          providerName={selectedProviderName}
          close={() => setSelectedProviderName('')}
          refetch={refetch}
        />
      )}
    </>
  );
};
