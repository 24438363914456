import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { JobType, fetchJobs } from 'api/jobs';
import { JobsInResponse } from 'api/jobs/types';

import { TableCellTooltip } from 'components/shared/table/TableCell';
import { SingleJobModal } from './SingleJobModal';
import { User, currentUser } from 'util/session';
import { UploadCatalog } from './UploadCatalog';
import { Table } from 'components/shared/table';
import useTableData from 'hooks/useTableData';
import { queryClient } from 'lib/react-quert';

export const CatalogUploadJobs = () => {
  const { data: allJobsReponse, isLoading } = useQuery(['catalogUploadJobs'], () => fetchJobs(JobType.CatalogUpload));
  const [data, setData] = useState<JobsInResponse[]>();
  const { meta, tableData } = useTableData({ allData: data || [] });

  const [idForModal, setIdForModal] = useState('');

  useEffect(() => {
    if (allJobsReponse) setData(allJobsReponse);
  }, [allJobsReponse]);

  const [activeUser, setActiveUser] = useState<User>();

  useEffect(() => {
    currentUser().then((u) => setActiveUser(u));
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      queryClient.invalidateQueries(['catalogUploadJobs']);
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="jobsTable">
      <div className="paper">
        <Table
          name="Jobs"
          title="Catalog Upload Jobs"
          nav={['Job ID', 'Status', 'Start', 'End', 'File']}
          allData={data || []}
          meta={meta}
          setData={setData}
          isLoading={isLoading}
          headerContent={<div>{activeUser && <UploadCatalog user={activeUser} />}</div>}
          content={
            <div className="tableBody">
              <>
                {tableData.map((job: JobsInResponse) => (
                  <div key={job.id} className="tableRow">
                    <TableCellTooltip content={job.id} />
                    <TableCellTooltip content={job.status} />
                    <TableCellTooltip content={dayjs(job.start).format('YYYY-MM-DD')} />
                    <TableCellTooltip content={dayjs(job.end).format('YYYY-MM-DD')} />
                    <div className="tableCell">
                      {job.resultsFile ? (
                        <a href={job.resultsFile} download target="_blank" rel="noreferrer">
                          Download
                        </a>
                      ) : null}
                    </div>

                    <div className="tableCell clickable" onClick={() => setIdForModal(job.id)}>
                      View More
                    </div>
                  </div>
                ))}
              </>
            </div>
          }
        />
      </div>
      {idForModal && <SingleJobModal jobId={idForModal} close={() => setIdForModal('')} />}
    </div>
  );
};
