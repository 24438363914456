import { useState } from 'react';
import { useRefinementList } from 'react-instantsearch-hooks';

import { DownArrowIcon } from 'assets/icons/DownArrow';
import { Select } from 'components/ui/Select';
import { isActiveClass } from 'util/classes';
import { SearchIcon } from 'assets/icons/SearchIcon';

export const Orgs = () => {
  const [open, setOpen] = useState(false);
  const { items, refine, searchForItems } = useRefinementList({
    attribute: 'orgName'
  });
  const values: any = items.filter(({ isRefined }) => isRefined) || {};

  return (
    <Select
      isOpen={open}
      setIsOpen={setOpen}
      className="regular withSearchInMenu"
      options={
        <div className="inner">
          <div className="searchField">
            <SearchIcon />
            <input type="text" onChange={(e) => searchForItems(e.target.value)} placeholder="Search..." />
          </div>
          <ul>
            {items.length ? (
              items.map((status) => (
                <li
                  key={status.value}
                  className={isActiveClass('', status.isRefined)}
                  onClick={() => {
                    refine(status.value);
                    setOpen(false);
                  }}
                >
                  {status.label}
                </li>
              ))
            ) : (
              <p className="noResults">No results...</p>
            )}
          </ul>
        </div>
      }
    >
      {values.map((el: any) => el.label).join(', ') || 'Org Name'}
      <DownArrowIcon />
    </Select>
  );
};
