import { DownArrowIcon } from 'assets/icons/DownArrow';
import range from 'lodash/range';

import { TableMetaTypes } from 'types/table.types';
import { isActiveClass } from 'util/classes';

export const TablePagination = ({ perPage, currentPage, setCurrentPage, allData }: TableMetaTypes) => {
  const totalCount = allData?.length || 0;
  const pagesCount = Math.ceil(totalCount / perPage);

  const pages = range(1, pagesCount + 1);

  const showNextPage = () => {
    if (currentPage < pages[pages.length - 1]) handleSetPage(currentPage + 1);
  };

  const showPrevPage = () => {
    if (currentPage > 1) handleSetPage(currentPage - 1);
  };

  const handleSetPage = (page: number) => {
    if (currentPage === page) return;
    setCurrentPage(page);
  };

  const items = () => {
    const isLastest = currentPage > pages.length - 3;
    const startIndex = isLastest ? pages.length - 3 : currentPage - 1;
    const lastIndex = isLastest ? pages.length : currentPage + 2;
    return isLonger ? pages.slice(startIndex, lastIndex) : pages;
  };

  const isLonger = pages.length > 5;

  const numbers = totalCount <= perPage ? [1] : items();

  return (
    <div className="tablePagination">
      <button type="button" className="arrow prev" onClick={showPrevPage}>
        <DownArrowIcon />
      </button>
      {isLonger && currentPage > 1 && (
        <button type="button" className="item" onClick={() => handleSetPage(1)}>
          1
        </button>
      )}
      {isLonger && currentPage > 2 && <span>...</span>}
      {numbers.map((page) => (
        <button
          type="button"
          key={page}
          onClick={() => handleSetPage(page)}
          className={isActiveClass('item', page === currentPage)}
        >
          {page}
        </button>
      ))}
      {isLonger && pages.length - 3 > currentPage && <span>...</span>}
      {isLonger && pages.length - 2 > currentPage && (
        <button type="button" className="item" onClick={() => handleSetPage(pages.length)}>
          {pages.length}
        </button>
      )}
      <button type="button" className="arrow next" onClick={showNextPage}>
        <DownArrowIcon />
      </button>
    </div>
  );
};
