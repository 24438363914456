import { QueryKey } from 'react-query';

const orgsKeys = {
  all: ['orgs'] as const,
  org: (id: QueryKey) => [...orgsKeys.all, 'org', id] as const,
  orgOrders: (id: QueryKey) => [...orgsKeys.all, 'orgOrders', id] as const,
  orgCredentials: (id: QueryKey, provider: string) =>
    [...orgsKeys.all, 'orgCredentials', id, provider] as const,
  prices: (id: QueryKey, ids: string[]) =>
    [...orgsKeys.all, 'prices', id, ids] as const,
  officeTaxRate: (id: QueryKey, officeID: string, amount: number) =>
    [...orgsKeys.all, 'officeTaxRate', id, officeID, amount] as const,
  formulary: (orgID: QueryKey) =>
    [...orgsKeys.all, 'formulary', orgID] as const,
  invoices: (orgID: QueryKey) => [...orgsKeys.all, 'invoices', orgID] as const,
  historyItems: (orgID: QueryKey) =>
    [...orgsKeys.all, 'historyItems', orgID] as const,
  draftorders: (orgID: QueryKey) =>
    [...orgsKeys.all, 'draftorders', orgID] as const,
};

export { orgsKeys };
