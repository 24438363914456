export const AdministratorRole = 'Administrator';
export const OfficeManagerRole = 'OfficeManager';
export const PurchasingTeamRole = 'PurchasingTeam';
export const RegionalPurchasingTeamRole = 'RegionalPurchasingTeam';
export const AccountingRole = 'Accounting';
export const roles = [AdministratorRole, OfficeManagerRole, PurchasingTeamRole, AccountingRole];

export const faliamCarriers = [
  { label: 'shippo', value: 'shippo' },
  { label: 'fedex', value: 'fedex' },
  { label: 'ups', value: 'ups' },
  { label: 'usps', value: 'usps' },
  { label: 'ontrac', value: 'ontrac' },
  { label: 'faliam', value: 'faliam' }
];
