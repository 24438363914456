import { OrgHealthSelects } from './OrgHealthSelects';
import { useQuery } from 'react-query';
import { getOrg } from 'api/orgs';
import { useEffect, useState } from 'react';
import { OrgGposTable } from 'pages/organization/gpos/OrgGposTable';
import { SelectState } from 'types/util.types';
import { SnapshotsCalendar } from '../SnapshotsCalendar';
import dayjs from 'dayjs';
import { getOrgHealthSnapshot, getOrgHealthSpanshots } from 'api/health';
import { HelathSnapshot } from 'api/health/types';
import { HealthPieChart } from '../HealthPieChart';
import { useQueryParams } from 'hooks/useSearchQuery';

export type HealthSelects = {
  [key: string]: SelectState | null;
  provider: SelectState | null;
};

export const formatDate = (date: Date) => dayjs(date).format('YYYY-MM-DD');

export const OrgHealthPage = () => {
  const { getQueryParam } = useQueryParams();

  const [selectedSnapshots, setSelectedSnapshots] = useState<HelathSnapshot | null>(null);

  const orgID = getQueryParam('orgID') || '';
  const providerName = getQueryParam('providerName') || '';
  const snapshotID = getQueryParam('snapshotID') || '';

  const hasOrgAndProvider = Boolean(orgID) && Boolean(providerName);

  const { data: org } = useQuery(['single-org-resp', orgID], () => getOrg(orgID));

  const { data: snapshots, isLoading } = useQuery(
    ['org-snapshots', orgID, providerName],
    () => getOrgHealthSpanshots({ orgID, providerName }),
    { enabled: hasOrgAndProvider }
  );

  const { data: snapshot, isLoading: isLoadingSnapshot } = useQuery(
    ['snapshot', snapshotID],
    () => getOrgHealthSnapshot({ orgID, providerName, snapshotID }),
    { enabled: hasOrgAndProvider && Boolean(snapshotID) }
  );

  useEffect(() => {
    if (snapshotID) setSelectedSnapshots(snapshots?.find(({ snapshots }) => snapshots.includes(snapshotID)) || null);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [snapshots]);

  return (
    <div className="healthPage">
      <OrgHealthSelects org={org} setSelectedSnapshots={setSelectedSnapshots} />
      <OrgGposTable gpos={org?.gpos || []} href="/health/gpo" />

      <div className="cols">
        <SnapshotsCalendar
          type="org"
          snapshots={snapshots || []}
          isLoading={isLoading}
          setSelectedSnapshots={setSelectedSnapshots}
          selectedSnapshots={selectedSnapshots}
          hasIDs={hasOrgAndProvider}
        />

        <HealthPieChart
          data={snapshot}
          id={orgID}
          providerName={providerName}
          isLoading={isLoadingSnapshot}
          type="org"
        />
      </div>
    </div>
  );
};
