import { CatalogUploadJobs } from './catalogUpload/CatalogUploadTable';
import { InventoryUploadJobs } from './inventoryUpload/InventoryUploadTable';
import { SKUUploadJobs } from './skuUpload/SKUUploadTable';

export const JobsPage = () => {
  return (
    <div className="jobsPage">
      <SKUUploadJobs />
      <CatalogUploadJobs />
      <InventoryUploadJobs />
    </div>
  );
};
