import { MoreItemsState } from 'components/shared/moreItems/AlgoliaHits';
import { OrderItem } from 'types/order.types';
import { OrgOrderItem } from 'types/orgs.types';
import { orderSupplierPrice } from './prices';

export type FormatedOrderItems = OrgOrderItem & {
  lifetimeQuantity: number;
};

export const numberOfSuppliers = (items: any[]) => {
  const res: any = new Set([]);
  items.forEach(
    ({ price }) => !res.has(price.provider || price.providerName) && res.add(price.provider || price.providerName)
  );
  return res.size;
};

export const fillMoreItemsStateWithOrderItems = (items: OrgOrderItem[]): MoreItemsState[] =>
  items.map(({ item, price }) => ({
    ...item,
    supplier: {
      connected: true,
      pricePending: false,
      displayPrice: String(orderSupplierPrice(price)),
      price: orderSupplierPrice(price),
      name: price.provider,
      sku: price.sku
    }
  }));

export const totalOrderItemsQty = (items: OrderItem[]) => items.reduce((acc, item) => acc + item.quantity, 0);

export const getBackOrderItems = (items: OrderItem[]) => items.filter((el) => el.backOrder) || [];

export const getOrderItemsProviders = (items: OrderItem[]) => {
  const res: any = new Set([]);
  items.forEach(({ price }) => !res.has(price.provider) && res.add(price.provider));
  return Array.from(res);
};

export const missingItemsKeys = [
  'NeedsPurchasing',
  'NeedsPurchaseConfirmation',
  'NeedsProviderInvoice',
  'NeedsShipment',
  'NeedsDelivery',
  'NeedsReconciliation',
  'NeedsFaliamInvoice',
  'NeedsPayment'
];

export const getOrderedItem = (orderItems: OrderItem[], item: OrderItem | OrgOrderItem): OrderItem => {
  const orderedItem: any = orderItems.find((el) => el.item.objectID === item.item.objectID);
  return orderedItem;
};
