import { useEffect, useState } from 'react';
import useTableData from 'hooks/useTableData';
import { Table } from 'components/shared/table';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { Link } from 'react-router-dom';
import { GpoMember } from 'api/gpo/types';

type Props = { members: GpoMember[] };

export const ConnectedOrganizations = ({ members }: Props) => {
  const [data, setData] = useState<GpoMember[]>([]);
  const { meta, tableData } = useTableData({ allData: data });

  useEffect(() => {
    if (members) setData(members);
  }, [members]);

  return (
    <>
      <div className="space" />
      <Table
        name="OrgGpos"
        title="GPO's Organizations"
        nav={['ID', 'Name']}
        allData={members}
        setData={setData}
        meta={meta}
        content={
          <div className="tableBody customScrollbar">
            {tableData.map((member) => (
              <div key={member.orgID} className="tableRow">
                <TableCellTooltip content={member.orgID}>
                  <Link to={`/health/org?orgID=${member.orgID}`} target="_blank" className="link">
                    {member.orgID}
                  </Link>
                </TableCellTooltip>
                <TableCellTooltip content={member.orgName} />
              </div>
            ))}
          </div>
        }
      />
    </>
  );
};
