import { formatUom, groupOrgOrdersBySupplier, showLastPurchased } from 'util/mix';
import { convertPrice, orderItemsTotalPrice, orderSupplierPrice } from 'util/prices';
import { TrashIcon } from 'assets/icons/TrashIcon';
import { useSupplierLogo } from 'hooks/useSupplierLogo';
import { Counter } from 'components/ui/Counter';
import { OrgOrderItem } from 'types/orgs.types';
import { useHistoryItemsStore } from 'stores/HistoryItemsStore';
import { getPromotion } from 'types/promotions.types';

interface Props {
  orderItems: OrgOrderItem[];
  setOrderItems: (items: OrgOrderItem[]) => void;
}

export const OrgOrderItems = ({ orderItems, setOrderItems }: Props) => {
  const { getSupplierLogo } = useSupplierLogo();
  const { historyItems } = useHistoryItemsStore();

  const onUpdateQuantity = (supplier: string, item: OrgOrderItem['item'], quantity: number) => {
    const cloned = [...orderItems];
    const i = cloned.findIndex((el) => el.item.objectID === item.objectID && el.price.provider === supplier);
    cloned[i] = { ...cloned[i], quantity };
    setOrderItems(cloned);
  };

  const onDeleteItem = (supplier: string, item: OrgOrderItem['item']) => {
    const output = orderItems.filter((el) => el.price.provider !== supplier || el.item.objectID !== item.objectID);
    setOrderItems(output);
  };

  return (
    <>
      <div className="suppliersItemsGroup">
        {groupOrgOrdersBySupplier(orderItems).map(([supplier, items]) => (
          <div key={supplier} className="supplierGroup">
            <div className="supplierGroupHeader">
              <div className="flexAlign gg8">
                <div className="supplierLogoBox">
                  <img src={getSupplierLogo(supplier)} alt={supplier} />
                </div>
                <p>
                  {supplier} ({items.length} items)
                </p>
              </div>
              <span>Total · {convertPrice(orderItemsTotalPrice(items))}</span>
            </div>
            <div className="supplierGroupItems">
              {items.map((item) => {
                const promo = item.price.promotion
                  ? getPromotion(item.price.promotion.type, item.price.promotion.data)
                  : null;

                return (
                  <div key={`${supplier}:${item.item.objectID}`} className="item">
                    <div className="itemPhoto">
                      <img src={item.item.images[0]} alt={item.item.title} />
                    </div>
                    <div className="itemContent">
                      <div className="itemDetails">
                        <p>{item.item.title}</p>
                        <span>
                          <b>{convertPrice(orderSupplierPrice(item.price))}</b>
                          {formatUom(item.item)}
                          {promo && <span className="badge secondary">{promo.toString()}</span>}
                        </span>
                        <span>{showLastPurchased(historyItems, item.item.objectID, item.item.uom)}</span>
                      </div>
                      <div className="itemActions">
                        <div className="actions">
                          <button className="rounded" onClick={() => onDeleteItem(supplier, item.item)}>
                            <TrashIcon />
                          </button>
                          <Counter
                            quantity={item.quantity}
                            onUpdateQuantity={(newQuantity) => onUpdateQuantity(supplier, item.item, newQuantity)}
                          />
                          <p className="price">{convertPrice(orderSupplierPrice(item.price) * item.quantity)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
