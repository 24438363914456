import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import orderBy from 'lodash/orderBy';

import { Org } from 'api/orgs/types';

import { deleteOrgOffice } from 'api/orgs';
import { OfficeFormModal } from './OfficeFormModal';
import { Table } from 'components/shared/table';
import useTableData from 'hooks/useTableData';
import { Office } from 'types/offices.types';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { TableDropdownCell } from 'components/shared/table/TableDropdownCell';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { getOfficeUsers } from 'util/offices';

interface Props {
  org: Org;
  refetchOrg: () => void;
  setOfficeID: (id: string) => void;
}

export const OrgOffices = ({ org, refetchOrg, setOfficeID }: Props) => {
  const orgID = org.metadata.orgID;

  const [selectedCell, setSelectedCell] = useState('');
  const [officeToUpdate, setOfficeToUpdate] = useState<Office>();
  const [hoverId, setHoverId] = useState('');

  const [isOpenNewOfficeModal, setIsOpenNewOfficeModal] = useState(false);
  const { mutate: deleteOffice } = useMutation((id: string) => deleteOrgOffice(orgID, id));

  const allSortedData = orderBy(org?.offices, ['name'], ['asc']);

  const [data, setData] = useState(allSortedData);
  const { meta, tableData } = useTableData({ allData: data });

  const dotsRef = useRef(null);
  useOutsideClick(dotsRef, () => selectedCell && setSelectedCell(''));

  useEffect(() => {
    setData(allSortedData);
  }, [org.offices]);

  return (
    <>
      <div className="paper orgOfficesBlock">
        <Table
          name="OrgOffices"
          title="Offices"
          nav={['Office ID', 'Office Name', 'Business address', 'City', 'State', 'Zipcode', 'Unit', 'Office Users', '']}
          allData={allSortedData}
          meta={meta}
          headerContent={
            <button className="outlined lg" onClick={() => setIsOpenNewOfficeModal(true)}>
              Add Office
            </button>
          }
          setData={setData}
          searchKeys={['officeID', 'name']}
          content={
            <div className="tableBody customScrollbar">
              {tableData.map((office: Office) => {
                const officeUsers = getOfficeUsers(office?.officeID || '', org.users || [], org.officeUsers || []);

                return (
                  <div key={office.officeID} className="tableRow">
                    <TableCellTooltip content={office.officeID} />
                    <TableCellTooltip content={office.name} />
                    <TableCellTooltip content={`${office.streetNum} ${office.route}`} />
                    <TableCellTooltip content={office.locality} />
                    <TableCellTooltip content={office.state} />
                    <TableCellTooltip content={office.zip} />
                    <TableCellTooltip content={office.unit || ''} />
                    <div
                      className="tableCell hoverCell"
                      onMouseOver={() => setHoverId(office.officeID)}
                      onMouseLeave={() => setHoverId('')}
                    >
                      {officeUsers.length}
                      {hoverId === office.officeID && officeUsers.length > 0 && (
                        <div className="officeUsersTooltip">
                          <h4 className="title">Office Users in {office.name}</h4>
                          <div className="list">
                            {officeUsers.map((user) => (
                              <div key={user.userID}>{user.email + ',' + user.role}</div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>

                    <TableDropdownCell
                      id={office.officeID}
                      active={selectedCell}
                      onClick={setSelectedCell}
                      ref={dotsRef}
                    >
                      <li onClick={() => setOfficeToUpdate(office)}>Update Office</li>
                      <li onClick={() => setOfficeID(office.officeID)}>Create order using this office</li>
                      <li
                        className="danger"
                        onClick={() => {
                          deleteOffice(office.officeID, { onSuccess: () => refetchOrg() });
                          setSelectedCell('');
                        }}
                      >
                        Delete Office
                      </li>
                    </TableDropdownCell>
                  </div>
                );
              })}
            </div>
          }
        />
      </div>

      <OfficeFormModal
        open={isOpenNewOfficeModal || Boolean(officeToUpdate)}
        close={() => {
          setIsOpenNewOfficeModal(false);
          setOfficeToUpdate(undefined);
        }}
        users={org.users}
        orgID={orgID}
        office={officeToUpdate}
        refetchOrg={refetchOrg}
        officeUsers={org.officeUsers}
      />
    </>
  );
};
