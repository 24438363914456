import { HandleCellOrders } from 'types/table.types';
import { TableHeader } from './TableHeader';

interface Props {
  nav: string[];
  handleCellOrders?: (value: HandleCellOrders) => void;
  children: React.ReactNode;
  className: string;
}

export const NestedTable = ({ nav, handleCellOrders, children, className }: Props) => (
  <div className={`nestedTable ${className}`}>
    <TableHeader nav={nav} handleCellOrders={handleCellOrders} />
    <div className="nestedTableBody">{children}</div>
  </div>
);
