export const HealthIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" version="1" viewBox="0 0 512 512">
    <path
      d="M2480 5111c-56-18-113-54-172-109-323-299-688-477-1118-546-131-21-189-26-385-35-138-7-179-12-217-29-69-30-131-92-164-165l-29-62-3-580c-4-610 3-804 39-1090 52-411 141-725 294-1042 136-281 267-466 490-688 262-262 545-454 911-616C2457 2 2552-20 2670 20c678 229 1218 623 1562 1140 80 120 221 400 272 540 116 318 188 694 216 1130 8 110 11 390 8 755l-3 580-29 62c-33 73-95 135-164 165-38 17-79 22-217 29-196 9-254 14-385 35-429 69-796 248-1118 546-87 80-149 110-237 114-38 2-81 0-95-5zm170-368c411-369 882-564 1485-613 66-5 157-10 202-10h81l7-62c4-35 5-335 2-668-3-476-8-636-21-750-86-749-303-1257-716-1670-250-251-579-457-980-615l-145-57-50 17c-27 10-97 37-155 60C1408 762 890 1424 739 2445c-40 270-49 469-49 1048 0 304 3 568 6 588l6 36 159 6c659 28 1166 223 1609 620 47 42 87 77 90 77s43-35 90-77z"
      transform="matrix(.1 0 0 -.1 0 512)"
    ></path>
    <path
      d="M2190 3671c-19-10-45-36-57-57-22-38-23-48-23-296v-258h-257c-249 0-259-1-297-23-75-43-76-48-76-425 0-369 2-380 64-425 30-21 40-22 298-25l267-3 3-267c4-386-26-362 448-362 367 0 378 2 423 64 21 30 22 40 25 298l3 267 267 3c386 4 362-26 362 450 0 377-1 382-76 425-38 22-48 23-296 23h-258v258c0 248-1 258-23 296-43 75-48 76-427 76-307 0-338-2-370-19zm522-548c4-380-13-363 372-363h256v-300h-256c-284 0-304-4-347-64-21-30-22-40-25-298l-3-268h-298l-3 268c-4 379 13 362-372 362h-256v300h256c284 0 304 4 347 64 21 30 22 40 25 299l3 267h298l3-267z"
      transform="matrix(.1 0 0 -.1 0 512)"
    ></path>
  </svg>
);
