import { useState } from 'react';
import { CSVImporter } from "csv-import-react";

import { Org, UpdateFormularyItemPayload, UpdateFormularyItemResponse } from 'api/orgs/types';
import { Formulary, UploadData } from 'types/tableflow.types';

import { CSVDownload } from 'components/shared/CSVDownload';
import { updateOrgFormularyItems } from 'api/orgs';
import { User } from 'util/session';
import { debounce } from 'lodash';

interface Props {
  user?: User;
  org: Org;
}

export const UploadFormulary = ({ user, org }: Props) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [issues, setIssues] = useState<UpdateFormularyItemResponse[]>([]);


  const formattedData = issues.map((issue) => ({
    providerName: issue.providerName,
    error: issue.error,
    sku: issue.sku
  }));

  const dataHandler = async (data: UploadData<Formulary>): Promise<void> => {
    setIsOpenModal(false)
    setIssues([]);

    if (data.error) return alert(data.error);

    let body: UpdateFormularyItemPayload[] = [];
    const issues: UpdateFormularyItemResponse[] = [];

    let i = -1;
    for (const row of data.rows) {
      i++;
      body.push({
        providerName: row.values.supplier,
        sku: row.values.sku,
        op: 'create'
      });

      // Update formulary every 1000 rows or at the end of the file
      if (body.length === 1000 || i === data.rows.length - 1) {
        const rsp = await updateOrgFormularyItems(org.metadata.orgID, body);
        if (rsp.length > 0) issues.push(...rsp);
        body = [];
      }
    }

    setIssues(issues);
  };

  const onComplete: any = debounce(dataHandler, 500);

  return (
    <>
      <button className="outlined lg" onClick={() => setIsOpenModal(true)}>
        Upload Formulary
      </button>
      <CSVImporter
        isModal={true}
        modalIsOpen={isOpenModal}
        modalOnCloseTriggered={() => setIsOpenModal(false)}
        modalCloseOnOutsideClick={true}
        primaryColor="#36bebc"
        onComplete={onComplete}
        template={{
          columns: [
            {
              "id": "a630a7bd-f50b-4edb-8257-0e50548f278a",
              "name": "Supplier",
              "key": "supplier",
              "required": true,
              "data_type": "string",
              "description": "",
              "suggested_mappings": []
            },
            {
              "id": "a719a03b-a091-4802-82cd-46c532cd4427",
              "name": "SKU",
              "key": "sku",
              "required": true,
              "data_type": "string",
              "description": "",
              "suggested_mappings": []
            }
          ]
        }}
      />
      {issues.length ? <CSVDownload data={formattedData} filename="formulary_upload_issues.csv" /> : null}
    </>
  );
};
