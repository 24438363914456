/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import type { DropResult } from 'react-beautiful-dnd';
import { Order } from 'types/order.types';

import { ProvidersInvoicesTable } from './providersInvoices/ProvidersInvoicesTable';
import { PurchaseOrdersTable } from './purchaseOrders/PurchaseOrdersTable';
import { FaliamInvoicesTable } from './faliamInvoices/FaliamInvoicesTable';
import { getStorageItem, setStorageItem } from 'util/localStorage';
import { SnapshotOrderTable } from './snapshot/SnapshotOrderTable';
import { useHistoryItemsStore } from 'stores/HistoryItemsStore';
import { CsOverrideTable } from './csOverride/CsOverrideTable';
import { BackOrdersTable } from './backOrders/BackOrdersTable';
import { ShipmentTable } from './shipment/ShipmentTable';
import { RequiresOpsAction } from './RequiresOpsAction';
import { SummaryTable } from './summary/SummaryTable';
import { getOrg, getOrgHistoryItems } from 'api/orgs';
import { LoaderIcon } from 'assets/icons/LoaderIcon';
import { useOrderStore } from 'stores/OrderStore';
import { ItemsTable } from './items/ItemsTable';
import { OfficeUsers } from './OfficeUsers';
import { CustomNote } from './CustomNote';
import { getOrder } from 'api/orders';

export const OrderPage = () => {
  const [indexes, setIndexes] = useState(Array.from(Array(12).keys()));

  const { orderID, orgID }: any = useParams();
  const { setHistoryItems } = useHistoryItemsStore();
  const { order, setOrder } = useOrderStore();

  const { status, refetch } = useQuery(['orders-order', orgID, orderID], () => getOrder(orgID, orderID), {
    onSuccess: (data) => setOrder(data),
    enabled: false,
    cacheTime: 0
  });
  const { data: org, refetch: refetchOrg } = useQuery(['orders-org', orgID], () => getOrg(orgID), {
    enabled: false,
    cacheTime: 0
  });
  const { refetch: refetchOrgHistoryItems } = useQuery(
    ['orders-historyItems', orgID],
    () => getOrgHistoryItems(orgID),
    {
      onSuccess: (data) => setHistoryItems(data),
      enabled: false,
      cacheTime: 0
    }
  );

  useEffect(() => {
    if (orgID && orderID) refetch();
  }, [orgID, orderID]);

  useEffect(() => {
    if (orgID) refetchOrg();
  }, [orgID]);

  useEffect(() => {
    if (org) refetchOrgHistoryItems();
  }, [org]);

  useEffect(() => {
    return () => {
      setOrder({} as Order);
      setHistoryItems({});
    };
  }, []);

  useEffect(() => {
    const sectionsIndexes = getStorageItem('sectionsIndexes');
    if (sectionsIndexes) setIndexes(sectionsIndexes);
  }, []);

  const sections = [
    { index: 0, component: (drag: any) => <SummaryTable dragHandleProps={drag} org={org} /> },
    { index: 1, component: (drag: any) => <RequiresOpsAction dragHandleProps={drag} /> },
    { index: 2, component: (drag: any) => <PurchaseOrdersTable dragHandleProps={drag} /> },
    { index: 3, component: (drag: any) => <ShipmentTable dragHandleProps={drag} /> },
    { index: 4, component: (drag: any) => <ProvidersInvoicesTable dragHandleProps={drag} refetch={refetch} /> },
    { index: 5, component: (drag: any) => <BackOrdersTable dragHandleProps={drag} /> },
    { index: 6, component: (drag: any) => <FaliamInvoicesTable dragHandleProps={drag} org={org} refetch={refetch} /> },
    { index: 7, component: (drag: any) => <ItemsTable dragHandleProps={drag} org={org} /> },
    { index: 8, component: (drag: any) => <SnapshotOrderTable dragHandleProps={drag} /> },
    { index: 9, component: (drag: any) => <CsOverrideTable dragHandleProps={drag} /> },
    { index: 10, component: (drag: any) => <OfficeUsers dragHandleProps={drag} org={org} /> },
    { index: 11, component: (drag: any) => <CustomNote dragHandleProps={drag} /> }
  ].sort((a, b) => indexes.indexOf(a.index) - indexes.indexOf(b.index));

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const { source, destination } = result;

    const array = Array.from(indexes);
    const [reorderedSection] = array.splice(source.index, 1);
    array.splice(destination.index, 0, reorderedSection);

    setIndexes(array);
    setStorageItem('sectionsIndexes', array);
  };

  if (status === 'error') return <h1 className="pageTitle center">Order not found</h1>;
  if (status === 'loading') return <LoaderIcon />;
  if (!order.orderID) return null;
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppableSections" type="droppableSectionItem">
        {(provided) => (
          <div className="orderPage" ref={provided.innerRef} {...provided.droppableProps}>
            {sections.map((section, i) => (
              <div key={section.index}>
                <Draggable draggableId={`section-${section.index}`} index={i}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      {section.component(provided.dragHandleProps)}
                    </div>
                  )}
                </Draggable>
              </div>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
