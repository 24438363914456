import { useState } from 'react';
import { TableFlowImporter } from '@tableflow/react';
import { User } from 'util/session';

interface Props {
  user: User;
}
export const UploadInventory = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const metadata = {
    userEmail: props.user.email,
    userID: props.user.id
  };

  return (
    <>
      <button className="outlined lg" onClick={() => setIsOpen(true)}>
        Upload Inventory
      </button>

      <TableFlowImporter
        importerId={process.env.REACT_APP_TABLEFLOW_INVENTORY_IMPORTER_ID!}
        modalIsOpen={isOpen}
        modalOnCloseTriggered={() => setIsOpen(false)}
        darkMode={false}
        metadata={JSON.stringify(metadata)}
      />
    </>
  );
};
