import { useEffect, useState } from 'react';

import { Org } from 'api/orgs/types';

import { RetrieveCredentialsModal } from './RetrieveCredentialsModal';
import { UploadOfficeFormulary } from './UploadOfficeFormulary';
import { UploadFormulary } from './UploadFormulary';
import { User, currentUser } from 'util/session';
import { SettingsModal } from './SettingsModal';
import { UploadPrices } from './UploadPrices';
import { UploadPromotions } from './UploadPromotions';
import { Link } from 'react-router-dom';

interface Props {
  org: Org;
  refetchOrg: () => void;
}

export const Hero = ({ org, refetchOrg }: Props) => {
  const [isOpenRetriveCredModal, setIsOpenRetriveCredModal] = useState(false);
  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState(false);
  const [activeUser, setActiveUser] = useState<User>();

  useEffect(() => {
    currentUser().then((u) => setActiveUser(u));
  }, []);

  return (
    <>
      <div className="orgPageHero">
        <div className="left">
          <p>Org ID: {org?.metadata.orgID}</p>
          <h1 className="pageTitle">{org?.metadata.orgName}</h1>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 8 }}>
          <div className="flexAlign gg8">
            <Link className="outlined lg" target="_blank" to={`/health/org?orgID=${org?.metadata.orgID}`}>
              Organization Health Page
            </Link>
            <button className="outlined lg" onClick={() => setIsOpenRetriveCredModal(true)}>
              Retrieve Credentials
            </button>
            <button className="outlined lg" onClick={() => setIsOpenSettingsModal(true)}>
              Settings
            </button>
          </div>
          <div className="flexAlign gg8">
            <UploadOfficeFormulary org={org} user={activeUser} />
            <UploadFormulary org={org} user={activeUser} />
            <UploadPrices org={org} user={activeUser} />
            <UploadPromotions org={org} user={activeUser} />
          </div>
        </div>
      </div>
      <SettingsModal
        open={isOpenSettingsModal}
        close={() => setIsOpenSettingsModal(false)}
        refetchOrg={refetchOrg}
        org={org}
      />
      {isOpenRetriveCredModal && <RetrieveCredentialsModal close={() => setIsOpenRetriveCredModal(false)} org={org} />}
    </>
  );
};
