import { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Calendar } from 'react-calendar';
import dayjs from 'dayjs';

import { PayFaliamInvoicePayload } from 'api/purchaseOrders/types';

import { formatDate } from 'util/dates';
import { Modal } from 'components/ui/Modal';
import { PayInvoiceSubmitProps } from '.';

interface FormikState {
  date: Date;
  url: string;
  checkNumber: string;
}

interface Props {
  isLoading: boolean;
  onSubmit: (data: PayInvoiceSubmitProps) => void;
}

export const CheckForm = ({ isLoading, onSubmit }: Props) => {
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);

  const initialValues: FormikState = {
    date: new Date(),
    url: '',
    checkNumber: '',
  };

  const handleSubmit = (values: FormikState) => {
    const checkInfo: PayFaliamInvoicePayload['checkInfo'] = {
      url: values.url,
      date: dayjs(values.date).format('YYYY-MM-DD'),
      checkNumber: values.checkNumber,
    };

    onSubmit({ checkInfo });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, setFieldValue }) => (
        <Form className="checkForm">
          <div className="field">
            <label>Check Number</label>
            <Field type="string" name="checkNumber" required />
          </div>
          <div className="field">
            <label>Due Date</label>
            <div className="fakeField" onClick={() => setIsOpenCalendar(true)}>
              {formatDate(values.date)}
            </div>
            {isOpenCalendar && (
              <Modal
                open={true}
                close={() => setIsOpenCalendar(false)}
                title="Select Date"
                className="ordersDatePicker"
              >
                <Calendar
                  onChange={(date: Date) => {
                    setFieldValue('date', date);
                    setIsOpenCalendar(false);
                  }}
                  value={values.date}
                />
              </Modal>
            )}
          </div>
          <div className="field">
            <label>URL</label>
            <Field name="url" />
          </div>
          <button type="submit" className="contained" disabled={isLoading}>
            Pay with check
          </button>
        </Form>
      )}
    </Formik>
  );
};
