export const CloudIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="#000"
      d="M15.79 9.26a5.842 5.842 0 00-5.787-5.094c-2.296 0-4.291 1.343-5.202 3.458C3.01 8.16 1.67 9.85 1.67 11.666a4.171 4.171 0 004.167 4.167h.833v-1.667h-.833a2.503 2.503 0 01-2.5-2.5c0-1.17.999-2.297 2.227-2.513l.484-.085.16-.465c.586-1.71 2.04-2.77 3.795-2.77a4.171 4.171 0 014.167 4.166v.834h.833c.92 0 1.667.747 1.667 1.666 0 .92-.748 1.667-1.667 1.667h-1.666v1.667h1.666a3.337 3.337 0 003.334-3.334 3.34 3.34 0 00-2.547-3.24z"
    ></path>
    <path
      fill="#000"
      d="M10.837 11.667V8.334H9.17v3.333h-2.5l3.333 4.167 3.334-4.167h-2.5z"
    ></path>
  </svg>
);
