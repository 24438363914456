import type { StylesConfig } from 'react-select';

const reactSelectStylesConfig: StylesConfig = {
  clearIndicator: (provided) => ({
    ...provided,
    position: 'absolute',
    right: '-30px', // Adjust as needed
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2, // Ensure it's above other elements
    color: 'red',
    '&:hover': {
      color: 'black'
    }
  }),
  control: (base) => ({
    ...base,
    border: '1px solid #dbdade',
    padding: '10px 12px',
    background: '#fff',
    boxShadow: 'none',
    borderRadius: 8,
    gap: 20,
    '&:hover': {
      borderColor: 'black'
    }
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transform: state.selectProps.menuIsOpen ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%) rotate(0deg)',
    transition: 'all 0.3s ease-in-out',
    position: 'absolute',
    color: 'black',
    padding: 0,
    right: 12,
    top: '50%'
  }),
  input: (base) => ({
    ...base,
    lineHeight: '20px',
    fontSize: '14px',
    color: '#6f6b7d',
    fontWeight: 400,
    padding: 0,
    margin: 0
  }),
  placeholder: (base) => ({
    ...base,
    lineHeight: '20px',
    fontSize: '14px',
    color: '#a8aaae'
  }),
  singleValue: (base) => ({
    ...base,
    color: '#6f6b7d',
    lineHeight: '20px',
    fontSize: '14px',
    fontWeight: 400,
    margin: 0
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 8,
    overflow: 'hidden',
    background: '#fff',
    zIndex: 5,
    border: 0
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    '&.shorter': {
      maxHeight: '150px'
    }
  }),
  option: (provided, state) => ({
    ...provided,
    color: 'black',
    fontSize: '14px',
    lineHeight: '20px',
    background: state.isFocused ? '#d5fbe5' : '#fff',
    opacity: state.isDisabled ? 0.5 : 1,
    cursor: state.isDisabled ? 'not-allowed' : 'pointer'
  }),
  noOptionsMessage: (base) => ({
    ...base,
    lineHeight: '20px',
    fontSize: '14px',
    fontStyle: 'italic',
    color: '#ef5628'
  })
};

export default reactSelectStylesConfig;
