import { Field, Form, Formik } from 'formik';
import { useMutation } from 'react-query';

import { DeleteDraftOrderPayload, DraftOrder } from 'api/orgs/types';

import { deleteOrgDraftOrder } from 'api/orgs';
import { Modal } from 'components/ui/Modal';

type Props = {
  data: DraftOrder;
  onClose: () => void;
  refetch: () => void;
};

export const DeclineDraftOrderModal = ({ data, onClose, refetch }: Props) => {
  const { mutate, isLoading } = useMutation(deleteOrgDraftOrder);

  const onSubmit = (values: any) => {
    const reason = values.reason;
    const payload: DeleteDraftOrderPayload = {
      orgID: data.orgID,
      orderID: data.orderID,
      reason
    };

    mutate(payload, {
      onSuccess: () => {
        refetch();
        onClose();
      }
    });
  };

  return (
    <Modal open close={onClose} title="Decline Draft Order" className="declineDraftOrder">
      <Formik initialValues={{ reason: '' }} onSubmit={onSubmit}>
        <Form>
          <div className="field">
            <label>Password</label>
            <Field as="textarea" name="reason" placeholder="Write a reason..." required />
          </div>
          <button type="submit" className="btn contained" disabled={isLoading}>
            Decline
          </button>
        </Form>
      </Formik>
    </Modal>
  );
};
