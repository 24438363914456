import { Modal } from 'components/ui/Modal';
import { useSupplierLogo } from 'hooks/useSupplierLogo';

interface Props {
  order: any;
  close: () => void;
}

export const OrderItemsSuppliersModal = ({ order, close }: Props) => {
  const { getSupplierLogo } = useSupplierLogo();
  const suppliers = () => {
    const res: Set<any> = new Set([]);
    order.items.forEach(
      ({ price }: any) =>
        !res.has(price.provider || price.providerName) &&
        res.add(price.provider || price.providerName)
    );
    return Array.from(res);
  };

  return (
    <Modal
      open={true}
      close={close}
      title={`Order Suppliers (${order.orderID})`}
      className="orderItemsSuppliersModal"
    >
      {suppliers().map((supplier) => (
        <div key={supplier} className="supplier">
          <img
            src={getSupplierLogo(supplier)}
            alt={supplier}
            title={supplier}
          />
        </div>
      ))}
    </Modal>
  );
};
