import { toast } from 'react-toastify';

import {
  GetGpoPartnerPrices,
  GpoAll,
  SingleGpo,
  StoreGpoMemberPayload,
  StoreGpoPartnerPayload,
  StoreGpoPayload,
  UploadGpoPrices
} from './types';

import { apiCall, BASE_URL_VARIANTS } from 'api';

export const getGpos = async (): Promise<GpoAll[]> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  const { data } = await request({ url: `gpos`, method: 'GET' });

  return data;
};

export const getSingleGpo = async (id: string): Promise<SingleGpo> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  const { data } = await request({ url: `gpos/${id}`, method: 'GET' });

  return data;
};

export const storeGpos = async (body: StoreGpoPayload) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);
  await request({ url: `gpos`, method: 'POST', data: body });

  toast('GPOs created successfully', { type: 'success' });
};

export const storeGpoMember = async ({ id, orgID }: StoreGpoMemberPayload) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);
  await request({ url: `gpos/${id}/organizations/${orgID}/membership`, method: 'POST' });

  toast('Member has been created successfully.', { type: 'success' });
};

export const deleteGpoMember = async ({ id, orgID }: StoreGpoMemberPayload) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);
  await request({ url: `gpos/${id}/organizations/${orgID}/membership`, method: 'DELETE' });

  toast('Member has been removed successfully.', { type: 'success' });
};

export const storeGpoPartner = async ({ id, provider, body }: StoreGpoPartnerPayload) => {
  if (!provider) toast.error('Please select a provider.');
  const request = await apiCall(BASE_URL_VARIANTS.PROCUREMENT);
  await request({ url: `gpos/${id}/providers/${provider}/partnership`, method: 'POST', data: body });

  toast('Partner has been created successfully.', { type: 'success' });
};

export const getGpoPartnerPricesURL = async ({ id, provider, file }: GetGpoPartnerPrices) => {
  try {
    const request = await apiCall(BASE_URL_VARIANTS.PROCUREMENT);
    const { data } = await request({ url: `gpos/${id}/providers/${provider}/prices/url`, method: 'GET' });

    await fetch(data.url, { method: 'PUT', body: file });

    toast('CSV has been uploaded successfully.', { type: 'success' });
  } catch (err: any) {
    toast(err, { type: 'error' });
  }
};

export const uploadGpoPrices = async ({ gpoID, prices }: UploadGpoPrices): Promise<UploadGpoPrices['prices']> => {
  try {
    const request = await apiCall(BASE_URL_VARIANTS.RETAIL);
    const { data } = await request({
      url: `gpos/${gpoID}/prices`,
      method: 'POST',
      data: { prices }
    });

    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};
