import create from 'zustand';

import { OrgHistoryItem } from 'api/orgs/types';

interface State {
  historyItems: OrgHistoryItem;
  setHistoryItems: (historyItems: OrgHistoryItem) => void;
}

const useHistoryItemsStore = create<State>((set) => ({
  historyItems: {},
  setHistoryItems: (historyItems: OrgHistoryItem) => set({ historyItems }),
}));

export { useHistoryItemsStore };
