import ReactSelect from 'react-select';

import { Org } from 'api/orgs/types';
import reactSelectStylesConfig from 'lib/react-select';

export type SelectSetter = React.Dispatch<React.SetStateAction<SelectOption | undefined>>;

export type SelectOption = {
  label: string;
  value: string;
};

type Props = {
  setOffice: SelectSetter;
  office?: SelectOption;
  org?: Org;
  className?: string;
};

export const OfficesSelect = ({ org, setOffice, office, className }: Props) => {
  const defaultOfficeSelectValue = { label: 'Organization', value: org?.metadata.orgID || '' };

  const officesSelectOptions = [
    defaultOfficeSelectValue,
    ...(org?.offices.map(({ name, officeID }) => ({
      value: officeID,
      label: `${name} - ${officeID}`
    })) || [])
  ];

  return (
    <ReactSelect
      options={officesSelectOptions}
      onChange={(option) => setOffice(option as SelectOption)}
      value={office}
      classNames={{ menuList: () => className || '' }}
      styles={reactSelectStylesConfig}
    />
  );
};
