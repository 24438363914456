import { useState } from 'react';
import { useMutation } from 'react-query';

import { useOrderStore } from 'stores/OrderStore';
import { PDFIcon } from 'assets/icons/PDFIcon';
import { attachPDFtoS3 } from 'api/orders';

interface Props {
  refetch: () => void;
  docID: string;
}

export const AttachPDF = ({ docID, refetch }: Props) => {
  const [pdfFile, setPdfFile] = useState<File | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState('');

  const { order } = useOrderStore();
  const { mutate: onAttachToS3, isLoading } = useMutation(attachPDFtoS3);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files?.[0];
    if (!file) return;

    const modifiedName = file.name
      .replace(/ /g, '_')
      .replace(/#/g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '')
      .toLowerCase();
    const modifiedFile = new File([file], modifiedName, { type: file.type });

    if (file?.size && file.size > 30 * 1024 * 1024) {
      setErrorMessage('File size is too big');
      return;
    }

    setErrorMessage('');
    setPdfFile(modifiedFile);

    onAttachToS3(
      {
        orderID: order.orderID,
        orgID: order.orgID,
        docID,
        file: modifiedFile
      },
      {
        onSuccess: () => {
          setTimeout(() => refetch(), 3000);
        }
      }
    );
  };

  return (
    <div className="attachPdfDropdown">
      <input type="file" onChange={onChange} />
      <PDFIcon />
      {isLoading ? (
        <h2>Please wait...</h2>
      ) : (
        <>
          <h3>
            <span className="mobText">Drag & drop to upload</span>
            <span>Click to upload</span> or drag & drop
          </h3>
          <p>Maximum file size 50 MB</p>
          {pdfFile && <p>File name: {pdfFile.name}</p>}

          <div className="errorFieldMsg">{errorMessage}</div>
        </>
      )}
    </div>
  );
};
