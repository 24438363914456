import CurrencyInput from 'react-currency-input-field';
import { Field, useFormikContext } from 'formik';

import { TrashIcon } from 'assets/icons/TrashIcon';

export const OtherCostsField = () => {
  const { values, setValues, setFieldValue }: any = useFormikContext();

  const addRow = () => {
    const otherCosts = [...values.otherCosts, { label: '', price: '' }];
    setValues({ ...values, otherCosts });
  };

  const removeRow = (index: number) => {
    const res = values.otherCosts.filter((_: any, i: number) => index !== i);
    setFieldValue('otherCosts', res);
  };

  return (
    <>
      {values.otherCosts.map((_: any, i: number) => (
        <div className="addNewRow" key={`otherCosts${i}`}>
          <div className="field">
            <label>Other Costs Label</label>
            <Field type="string" name={`otherCosts[${i}].label`} />
          </div>
          <div className="field">
            <label>Other Costs Price</label>
            <CurrencyInput
              name={`otherCosts[${i}].price`}
              value={values.otherCosts[i].price}
              prefix="$"
              decimalsLimit={2}
              decimalScale={2}
              onValueChange={(value: any) => {
                setFieldValue(`otherCosts[${i}].price`, value);
              }}
            />
          </div>
          <button type="button" className="rounded lg">
            <TrashIcon onClick={() => removeRow(i)} />
          </button>
        </div>
      ))}
      <button type="button" className="outlined lg mt24 mlAuto" onClick={addRow}>
        Add new other cost
      </button>
    </>
  );
};
