import { useState } from 'react';
import { useHitsPerPage } from 'react-instantsearch-hooks';

import { DownArrowIcon } from 'assets/icons/DownArrow';
import { isActiveClass } from 'util/classes';
import { Select } from 'components/ui/Select';

interface Props {
  totalHits: number;
}

export const TablePerPage = ({ totalHits }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { items, refine } = useHitsPerPage({
    items: [
      { label: '50', value: 50, default: true },
      { label: '100', value: 100 },
      { label: '250', value: 250 },
      { label: '500', value: 500 }
    ]
  });
  const { value } = items.find(({ isRefined }) => isRefined) || {};

  const onSelect = (value: number) => {
    setIsOpen(false);
    refine(value);
  };

  return (
    <div className="tablePerPage">
      Show
      <Select
        className="regular"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        options={
          <ul>
            {items.map((item) => (
              <li
                key={item.value}
                onClick={() => onSelect(item.value)}
                className={isActiveClass('', value === item.value)}
              >
                {item.label}
              </li>
            ))}
          </ul>
        }
      >
        {value}
        <DownArrowIcon />
      </Select>
      of {totalHits} results per page
    </div>
  );
};
