import { useEffect, useState } from 'react';
import { SupplierFormModal } from './modal';
import { Provider } from 'api/providers/types';
import { getProviders } from 'api/providers';
import { toast } from 'react-toastify';
import { SearchIcon } from 'assets/icons/SearchIcon';
import { PlusIcon } from 'assets/icons/PlusIcon';

export const SuppliersPage = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [allProviders, setallProviders] = useState<Provider[]>([]);
  const [tableData, setTableData] = useState<Provider[]>([]);
  const [search, setSearch] = useState<string>('');

  // Fetch all providers
  useEffect(() => {
    getProviders().then((data) => {
      setallProviders(data);
      setTableData(data);
    }).catch((err) => {
      toast.error('Something went wrong!');
      console.error(err);
    })
  }, []);

  // Update table data based on search
  useEffect(() => {
    if (search) {
      setTableData(allProviders.filter((provider) => provider.name.toLowerCase().includes(search.toLowerCase())));
    } else {
      setTableData(allProviders);
    }
  }, [search, allProviders]);

  // Handle search input change
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <div className="suppliersPage">
      <div className="paper">

        {/* Header */}
        <div className="header">
          <div className="hero">
            <div className="search">
              <SearchIcon />
              <input type="search" value={search} onChange={onChange} placeholder="Search Supliers" />
            </div>
            <div className="flexAlign gg16">
              <button
                className="contained lg"
                style={{ maxWidth: 'fit-content' }}
                onClick={() => setIsOpenModal(true)}
              >
                <PlusIcon width={24} height={24} />
                Add Supplier
              </button>
            </div>
          </div>
        </div>

        {/* Table */}
        <div className="table">
          {/* Table Head */}
          <div className="row head">
            <div className="cell">Image</div>
            <div className="cell">Name</div>
            <div className="cell">Category</div>
            <div className="cell">Supports Offline</div>
            <div className="cell">Supports Online</div>
          </div>

          {/* Table Body */}
          {tableData.map((provider) => (
            <div key={provider.name} className="row">
              <div className="cell">
                <div
                  className="img"
                  style={{ background: `url(${provider.image}) center center / cover no-repeat` }}
                />
              </div>
              <div className="cell">{provider.name}</div>
              <div className="cell">{provider.category}</div>
              <div className="cell">
                <div className="uneditable-checkbox">
                  <input
                    type="checkbox"
                    checked={provider.connections.offline}
                    readOnly
                    disabled
                  />
                </div>
              </div>
              <div className="cell">
                <div className="uneditable-checkbox">
                  <input
                    type="checkbox"
                    checked={provider.connections.online}
                    readOnly
                    disabled
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      {
        isOpenModal && (
          <SupplierFormModal
            onClose={() => setIsOpenModal(false)}
            onSuccess={(data) => {
              setallProviders([...allProviders, data]);
              setSearch(data.name);
            }}
          />
        )
      }
    </div >
  );
};
