import { Form, Formik } from 'formik';

import { OrderItem } from 'types/order.types';

import { convertPrice, orderSupplierPrice } from 'util/prices';
import { formatUom, getCurrencyValue, roundCurrencyValue } from 'util/mix';
import { Modal } from 'components/ui/Modal';
import { CurrencyField } from 'components/ui/CurrencyField';
import { OrgOrderItem } from 'types/orgs.types';

interface Props {
  order: OrderItem | OrgOrderItem;
  close: () => void;
  items: OrderItem[] | OrgOrderItem[];
  setItems: (items: any) => void;
  onlySellingPrice?: boolean;
  onlyBuyingPrice?: boolean;
}

export const EditPrice = ({ order, close, items, setItems, onlySellingPrice, onlyBuyingPrice }: Props) => {
  const { item, price } = order;

  const initialValues = {
    customSellingPrice: price.customSellingPrice ? getCurrencyValue(price.customSellingPrice) : '',
    sellingPrice: price.sellingPrice ? getCurrencyValue(price.sellingPrice) : '',
    buyingPrice: price.buyingPrice ? getCurrencyValue(price.buyingPrice) : ''
  };

  const onSubmit = (values: any) => {
    const payload: OrderItem | OrgOrderItem = { ...order };
    if (!values.customSellingPrice && values.customSellingPrice !== 0) {
      payload.price.customSellingPrice = undefined;
    } else if (values.customSellingPrice !== price.customSellingPrice) {
      payload.price.customSellingPrice = roundCurrencyValue(values.customSellingPrice);
    }
    if (values.sellingPrice !== price.sellingPrice) {
      payload.price.sellingPrice = roundCurrencyValue(values.sellingPrice);
    }
    if (values.buyingPrice !== price.buyingPrice) {
      payload.price.buyingPrice = roundCurrencyValue(values.buyingPrice);
    }

    updateItems(payload);
  };

  const updateItems = (item: OrderItem | OrgOrderItem) => {
    const cloned = [...items];
    const i = cloned.findIndex(
      (el) => el.item.objectID === item.item.objectID && el.price.provider === item.price.provider
    );
    cloned[i] = item;
    setItems(cloned);
    close();
  };

  const renderFields = () => {
    if (onlyBuyingPrice) {
      return (
        <>
          <CurrencyField name="buyingPrice" label="Seller Invoice Unit Rate" />
        </>
      );
    } else if (onlySellingPrice) {
      return (
        <>
          <CurrencyField name="customSellingPrice" label="Custom Displayed Faliam Unit Rate" />
          <CurrencyField name="sellingPrice" label="Displayed Faliam Unit Rate" />
        </>
      );
    } else {
      return (
        <>
          <CurrencyField name="customSellingPrice" label="Custom Selling Price" />
          <CurrencyField name="sellingPrice" label="Selling Price" />
          <CurrencyField name="buyingPrice" label="Buying Price" />
        </>
      );
    }
  };

  return (
    <Modal open={true} close={close} title="Edit price of the item" className="editOrderItemPriceModal">
      <div className="productCard">
        <div className="productCardPhoto">
          <img src={item.images[0]} alt={item.title} />
        </div>
        <div className="productCardDetails">
          <p>{item.title}</p>
          <span>
            <b>{convertPrice(orderSupplierPrice(price))}</b>
            {formatUom(item)}
          </span>
        </div>
      </div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form>
          {renderFields()}
          <button type="submit" className="contained">
            Save changes
          </button>
        </Form>
      </Formik>
    </Modal>
  );
};
