import { useState } from 'react';
import { InstantSearch } from 'react-instantsearch-hooks';

import { ProductPayload } from 'api/products/types';

import { ManufacturerFilter } from 'components/shared/products/filters/ManufacturerFilter';
import { UnavailableFilter } from 'components/shared/products/filters/UnavailableFilter';
import { SubcategoryFilter } from 'components/shared/products/filters/SubcategoryFilter';
import { SupplierFilter } from 'components/shared/products/filters/SupplierFilter';
import { ProductsSearch } from 'components/shared/products/ProductsSearch';
import { ProductsList } from 'components/shared/products/ProductsList';
import { algoliaKeys, searchClient } from 'lib/algolia/aloglia-config';
import { ProductFormModal } from 'components/shared/products/form';
import { PlusIcon } from 'assets/icons/PlusIcon';

export const ProductsPage = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [productForEdit, setProductForEdit] = useState<ProductPayload | undefined>(undefined);

  return (
    <div className="productsPage">
      <div className="paper">
        <InstantSearch indexName={algoliaKeys.indexName.regular} searchClient={searchClient}>
          <div className="paperHeader">
            <div className="productHero">
              <div className="filtersGroup">
                <ProductsSearch />
                <ManufacturerFilter />
                <SupplierFilter />
                <SubcategoryFilter />
                <UnavailableFilter />
                <button
                  className="contained lg"
                  style={{ maxWidth: 'fit-content' }}
                  onClick={() => setIsOpenModal(true)}
                >
                  <PlusIcon width={24} height={24} />
                  Add Product
                </button>
              </div>
            </div>
          </div>
          <ProductsList openEditModal={(p) => setProductForEdit(p)} />
        </InstantSearch>
      </div>
      {(isOpenModal || productForEdit) && (
        <ProductFormModal
          product={productForEdit}
          onClose={() => {
            setIsOpenModal(false);
            setProductForEdit(undefined);
          }}
        />
      )}
    </div>
  );
};
