import { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';

import { storeGpoPartner } from 'api/gpo';
import { Modal } from 'components/ui/Modal';
import { Select } from 'components/ui/Select';
import { useProvidersStrore } from 'stores/ProvidersStore';
import { DownArrowIcon } from 'assets/icons/DownArrow';
import { isActiveClass } from 'util/classes';
import { RepresentativeWithExtension } from 'api/orgs/types';
import PhoneInput from 'react-phone-input-2';
import { TrashIcon } from 'assets/icons/TrashIcon';

interface Props {
  id: string;
  close: () => void;
  refetch: () => void;
}

type FormikState = {
  provider: string;
  username: string;
  password: string;
  contacts: RepresentativeWithExtension[];
};

export const CreateNewPartnershipForm = ({ id, close, refetch }: Props) => {
  const initialRep = { email: '', phone: '', extension: '' };

  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [tab, setTab] = useState<'credentials' | 'email'>('credentials');

  const [newRep, setNewRep] = useState<RepresentativeWithExtension>(initialRep);

  const { providers } = useProvidersStrore();
  const { mutate, isLoading } = useMutation(storeGpoPartner);

  const filteredProviders = useMemo(() => {
    return providers.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
  }, [providers, searchQuery]);

  const onSuccess = () => {
    close();
    refetch();
  };

  const formik = useFormik<FormikState>({
    initialValues: {
      provider: '',
      username: '',
      password: '',
      contacts: []
    },

    onSubmit: (values) => {
      const { provider, password, username } = values;
      const body: any = {};
      if (tab === 'email') {
        const contacts = values.contacts.map(({ email, phone, extension }) => ({
          email,
          phone: extension ? `${phone} EXT ${extension}` : phone
        }));
        body.contacts = contacts;
      } else {
        body.username = username;
        body.password = password;
      }
      const payload = { id, provider, body };
      mutate(payload, { onSuccess });
    }
  });

  const { values, setFieldValue, handleSubmit } = formik;

  const isButtonDisabled =
    tab === 'email'
      ? values.contacts.length
        ? values.contacts.some(({ email, phone }) => !email && !phone)
        : true
      : false;

  return (
    <Modal open={true} close={close} title="Create Partnership" className="createNewPartnership">
      <form onSubmit={handleSubmit}>
        <div className="scrollable">
          <div className="field">
            <label>Provider</label>
            <Select
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              className="regular"
              options={
                filteredProviders.length ? (
                  <ul className="customScrollbar">
                    {filteredProviders.map((item) => (
                      <li
                        key={item.name}
                        onClick={() => {
                          setFieldValue('provider', item.name);
                          setSearchQuery(item.name);
                          setIsOpen(false);
                        }}
                      >
                        {item.name}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="noResults">No search results...</p>
                )
              }
            >
              <input type="text" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
              {<DownArrowIcon />}
            </Select>
          </div>
          <nav>
            <p className={isActiveClass('', tab === 'credentials')} onClick={() => setTab('credentials')}>
              With Credentials
            </p>
            <p className={isActiveClass('', tab === 'email')} onClick={() => setTab('email')}>
              Representatives
            </p>
          </nav>
          {tab === 'email' ? (
            <>
              <div className="added">
                {formik.values.contacts.map((rep, i) => (
                  <div className="paper" key={rep?.email || '' + rep?.phone || '' + i}>
                    <div className="col">
                      {rep?.email && <p>{rep.email}</p>}
                      {rep?.phone && <p>{rep?.extension ? `${rep?.phone} EXT ${rep?.extension}` : rep?.phone}</p>}
                    </div>
                    <TrashIcon
                      onClick={() => {
                        const newContacts = values.contacts.filter((_, index) => index !== i);
                        setFieldValue('contacts', newContacts);
                      }}
                    />
                  </div>
                ))}
              </div>

              <div className="field">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={newRep.email}
                  onChange={(e) => setNewRep((p) => ({ ...p, email: e.target.value }))}
                />

                <PhoneInput
                  country="us"
                  onlyCountries={['us']}
                  inputProps={{ name: 'phone', country: 'us' }}
                  value={newRep.phone}
                  onChange={(phone) => setNewRep((p) => ({ ...p, phone: `+${phone}` }))}
                  specialLabel="Phone number"
                  containerClass="fieldPhoneInput"
                  disableDropdown
                />

                <label> Extension</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Phone number extension"
                  value={newRep.extension}
                  onChange={(e) => setNewRep((p) => ({ ...p, extension: e.target.value }))}
                />

                <button
                  type="button"
                  className="outlined lg wFull addRep"
                  onClick={() => {
                    if (newRep.email || newRep.phone) {
                      setFieldValue('contacts', [...values.contacts, newRep]);
                      setNewRep(initialRep);
                    }
                  }}
                >
                  Add representative
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="field">
                <label>Username</label>
                <input
                  type="text"
                  name="username"
                  value={values.username}
                  onChange={(e) => setFieldValue('username', e.target.value)}
                  required
                />
              </div>
              <div className="field">
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={(e) => setFieldValue('password', e.target.value)}
                  required
                />
              </div>
            </>
          )}
        </div>
        <button type="submit" className="contained mt24" disabled={isLoading || isButtonDisabled}>
          Create Partnership
        </button>
      </form>
    </Modal>
  );
};
