import { apiCall, BASE_URL_VARIANTS } from 'api';
import { ManufacturerConfig} from './types';

export const fetchManufacturers = async (): Promise<ManufacturerConfig> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);
  const { data } = await request({ url: `/manufacturers`, method: 'GET' });
  return data as ManufacturerConfig;
};

export const updateManufacturers = async (body: ManufacturerConfig): Promise<ManufacturerConfig> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);
  const { data } = await request({ url: `/manufacturers`, method: 'PATCH', data: body });
  return data as ManufacturerConfig;
};