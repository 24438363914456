import { useInstantSearch } from 'react-instantsearch-hooks';
import { Link } from 'react-router-dom';

import { AlgoliaTableProps } from 'types/table.types';
import { AlgoliaUser } from 'types/algolia.types';

import { TableCellTooltip } from 'components/shared/table/TableCell';
import { AlgoliaTable } from 'components/shared/algoliaTable';
import { formatDate } from 'util/dates';
import { TrashIcon } from 'assets/icons/TrashIcon';
import { useState } from 'react';
import { Modal } from 'components/ui/Modal';
import { useMutation } from 'react-query';
import { deleteUser } from 'api/users';

interface Props {
  setActiveUser: React.Dispatch<React.SetStateAction<AlgoliaUser | null>>;
}

export const UsersTable = ({ setActiveUser }: Props) => {
  const { refresh, results } = useInstantSearch();

  const hits = results.hits;

  const [userForDelete, setUserForDelete] = useState<AlgoliaUser | null>(null);
  const { mutate, isLoading } = useMutation(deleteUser, {
    onSuccess: () => {
      setUserForDelete(null);
      setTimeout(() => refresh(), 1500);
    }
  });

  const table: AlgoliaTableProps = {
    name: 'Users',
    nav: ['Date Created', 'User #', 'Name', 'Email', 'Phone', 'Impersonate'],
    hits,
    totalHits: results?.nbHits,
    content: (
      <div className="tableBody">
        {hits.map((user: AlgoliaUser) => (
          <div key={user.objectID} className="tableRow">
            <TableCellTooltip content={formatDate(user.created)} />
            <TableCellTooltip content={user.userID}>
              <Link to={`/user/${user.userID}`} className="link">
                {user.userID}
              </Link>
            </TableCellTooltip>
            <TableCellTooltip content={user.name} />
            <TableCellTooltip content={user.email}>
              <a href={`mailto: ${user.email}`}>{user.email}</a>
            </TableCellTooltip>
            <TableCellTooltip content={user.phone}>
              <a href={`tel: ${user.phone}`}>{user.phone}</a>
            </TableCellTooltip>
            <div className="tableCell">
              <button className="contained xs" onClick={() => setActiveUser(user)}>
                Impersonate
              </button>
            </div>
            <div className="tableCell delete">
              <button onClick={() => setUserForDelete(user)}>
                <TrashIcon />
              </button>
            </div>
          </div>
        ))}
      </div>
    )
  };

  return (
    <div>
      <AlgoliaTable {...table} />
      {userForDelete && (
        <Modal
          open={Boolean(userForDelete)}
          close={() => setUserForDelete(null)}
          title={`Delete user`}
          className="gpoAlertModal"
        >
          <h2>Are you sure you want to delete user {userForDelete?.email}</h2>
          <div className="buttons">
            <button className="contained danger" disabled={isLoading} onClick={() => mutate(userForDelete?.userID)}>
              Delete user
            </button>
            <button className="contained cancel" onClick={() => setUserForDelete(null)}>
              Cancel
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};
