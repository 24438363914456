import { Field, Form, Formik } from 'formik';
import { useMutation } from 'react-query';
import orderBy from 'lodash/orderBy';

import { Order, OrderItem, PurchaseOrder } from 'types/order.types';
import {
  ConfirmPurchaseOrderPayload,
  CreatePurchaseOrderPayload,
  UpdatePurchaseOrderPayload
} from 'api/purchaseOrders/types';

import { confirmPurchaseOrder, createPurchaseOrder, updatePurchaseOrder } from 'api/purchaseOrders';
import { convertOtherCostsToFormikFormat, getPurchasePayload } from 'util/purchaseOrders';
import { OtherCostsField } from '../../fullScreenModals/fields/OtherCostsField';
import { CurrencyField } from 'components/ui/CurrencyField';
import { FullScreenModal } from '../../fullScreenModals';
import { ProvidersSelect } from './ProvidersSelect';
import { useOrderStore } from 'stores/OrderStore';
import { getCurrencyValue } from 'util/mix';

interface Props {
  providers: string[];
  purchaseOrder: PurchaseOrder | null;
  allItems: OrderItem[];
  close: () => void;
}

interface FormikState {
  provider: string;
  shippingCosts: number | string;
  taxCosts: number | string;
  otherCosts: Array<{ label: string; price: string | number }>;
  allItems: OrderItem[];
  selectedItems: OrderItem[];
  referenceID: string;
}

export const PurchaseOrderForm = ({ providers, purchaseOrder, allItems, close }: Props) => {
  const { order, setOrder } = useOrderStore();

  const itemsBySupplier = (provider: string) => allItems.filter((el: any) => el.price.provider === provider);

  const provider = purchaseOrder?.provider || providers[0];
  const initialValues: FormikState = {
    provider,
    shippingCosts: purchaseOrder?.shippingCosts ? getCurrencyValue(purchaseOrder.shippingCosts) : '',
    taxCosts: purchaseOrder?.tax ? getCurrencyValue(purchaseOrder.tax) : '',
    otherCosts: convertOtherCostsToFormikFormat(purchaseOrder?.otherCosts),
    selectedItems: itemsBySupplier(provider),
    allItems: itemsBySupplier(provider),
    referenceID: ''
  };

  const { mutate: store, isLoading: isLoadingStore } = useMutation(createPurchaseOrder);
  const { mutate: update, isLoading: isLoadingUpdate } = useMutation(updatePurchaseOrder);
  const { mutate: confirmPO } = useMutation(confirmPurchaseOrder);

  const onSuccess = (res: Order) => {
    setOrder(res);
    close();
  };

  const onSubmit = (values: FormikState) => {
    if (!values.selectedItems.length) return;

    if (purchaseOrder) onUpdate(values);
    else onCreate(values);
  };

  const onCreate = (values: FormikState) => {
    const payload: CreatePurchaseOrderPayload = {
      orderID: order.orderID,
      orgID: order.orgID,
      body: {
        provider: values.provider,
        ...getPurchasePayload(values)
      }
    };

    store(payload, {
      onSuccess: (res) => {
        if (!res.purchaseOrders) return;

        if (values.referenceID) {
          const confirmPOpayload: ConfirmPurchaseOrderPayload = {
            orderID: order.orderID,
            orgID: order.orgID,
            purchaseOrderID: orderBy(res.purchaseOrders, 'created', 'desc')[0].id,
            body: { referenceID: values.referenceID }
          };

          confirmPO(confirmPOpayload, { onSuccess });
        } else {
          onSuccess(res);
        }
      }
    });
  };

  const onUpdate = (values: FormikState) => {
    if (!purchaseOrder) return;

    const payload: UpdatePurchaseOrderPayload = {
      orderID: order.orderID,
      orgID: order.orgID,
      purchaseOrderID: purchaseOrder.id,
      body: getPurchasePayload(values)
    };

    update(payload, { onSuccess });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <Form>
        <FullScreenModal
          close={close}
          button={{
            text: `${purchaseOrder ? 'Update' : 'Create'} Purchase Order`,
            isLoading: isLoadingStore || isLoadingUpdate
          }}
          showingTotal={purchaseOrder ? true : false}
          variant="purchaseOrder"
        >
          <div className="paper">
            <div className="paperHeader">
              <h2>{purchaseOrder ? 'Update' : 'Create'} Purchase Order</h2>
            </div>
            <div className="paperBody">
              {!purchaseOrder && (
                <div className="fieldsRow">
                  <ProvidersSelect providers={providers} items={(p) => itemsBySupplier(p)} variant="purchaseOrders" />
                  <div className="field">
                    <label>Confirmation Number (Seller Order #)</label>
                    <Field type="text" name="referenceID" />
                  </div>
                </div>
              )}
              {purchaseOrder && (
                <>
                  <CurrencyField name="shippingCosts" label="Shipping" />
                  <CurrencyField name="taxCosts" label="Tax" />
                  <OtherCostsField />
                </>
              )}
            </div>
          </div>
        </FullScreenModal>
      </Form>
    </Formik>
  );
};
