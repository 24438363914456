import { Org, OrgHistoryItem } from 'api/orgs/types';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';
import dayjs from 'dayjs';

import { OrgOrderItem } from 'types/orgs.types';
import { OrderItem } from 'types/order.types';

export const searchByKeys = (searchKeys: string[], data: any[], value: string) => {
  const output = data.filter((item) =>
    searchKeys.some((key) => {
      const nestedValue = get(item, key);
      return nestedValue !== undefined && String(nestedValue).toLowerCase().includes(value.toLowerCase());
    })
  );

  return output;
};

export const tableSliceData = (data: any[], currentPage: number, perPage: number) => {
  const start = (currentPage - 1) * perPage;
  const end = start + perPage;

  return data.slice(start, end);
};

export const formatProductHref = (id: string, title: string) => {
  const link = 'https://www.faliam.com';
  const encoded = encodeURIComponent(title).replace(/-/g, '_').replace(/%20/g, '-');

  return `${link}/product/${encoded}/${encodeURIComponent(id)}`;
};

export const groupOrgOrdersBySupplier = (items: OrgOrderItem[]) =>
  orderBy(Object.entries(groupBy(items, (item) => item.price.provider)), (el) => el[0].toLowerCase());

export const groupOrdersBySupplier = (items: OrderItem[]) =>
  orderBy(Object.entries(groupBy(items, (item) => item.price.provider)), (el) => el[0].toLowerCase());

export const formatUom = (data: any, withCircle = true) =>
  `${withCircle ? ' ·' : ''} ${data.uom}/${data.conversionFactor}`;

export const getConnectionStatus = (org: Org, name: string) => {
  const cred = org.credentials?.find((el) => el.providerName === name)?.status;

  if (cred) return cred;
  return 'DISCONNECTED';
};

export const getConnectionContact = (org: Org, name: string) => {
  // If not connected, return '-'
  if (getConnectionStatus(org, name) === 'DISCONNECTED') return '-';

  const res = org.credentials?.find((el) => el.providerName === name);

  // TODO: Change this to be a list of contacts and dispaly the list inside the table!!!
  const email = res?.representatives?.[0]?.email;
  const phone = res?.representatives?.[0]?.phone;

  if (!res) return 'ONLINE';

  return email ? email : phone || '';
};

export const getConnectionStatusOrder = (org: Org, name: string) => {
  switch (getConnectionStatus(org, name)) {
    case 'CONNECTED':
      return 1;
    case 'CONNECTING':
      return 2;
    case 'DISCONNECTED':
      return 3;
  }
};

export const getCurrencyValue = (value: string | number) => Math.round(Number(value)) / 100;

export const roundCurrencyValue = (value: string | number) => Math.round(Number(value) * 100);

export const showLastPurchased = (itemsHistory: OrgHistoryItem, id: string, uom: string) => {
  const el = itemsHistory[id];

  if (!el) return null;

  return `Last Purchased: ${el.orderCount} ${
    uom === 'Each' ? (el.orderCount > 1 ? 'Pieces' : 'Piece') : uom
  } on ${dayjs(el.lastOrdered).format('MM/DD/YYYY')}`;
};
