import { Form, Formik } from 'formik';
import { useMutation } from 'react-query';

import { ItemWithBoxes } from './ProviderInvoiceForm';
import { UpdateOrderPayload } from 'api/orders/types';
import { OrgOrderItem } from 'types/orgs.types';

import { formatUom, getCurrencyValue, roundCurrencyValue } from 'util/mix';
import { convertPrice, orderSupplierPrice } from 'util/prices';
import { CurrencyField } from 'components/ui/CurrencyField';
import { useOrderStore } from 'stores/OrderStore';
import { Modal } from 'components/ui/Modal';
import { updateOrder } from 'api/orders';

interface Props {
  item: OrgOrderItem;
  itemsWithBoxes: ItemWithBoxes[];
  box?: ItemWithBoxes['boxes'][0];
  setItemsWithBoxes: React.Dispatch<React.SetStateAction<ItemWithBoxes[]>>;
  onlySellingPrice?: boolean;
  close: () => void;
}

export const EditBoxPriceModal = ({ item, box, itemsWithBoxes, setItemsWithBoxes, close, onlySellingPrice }: Props) => {
  const { order, setOrder } = useOrderStore();
  const { mutate, isLoading } = useMutation(updateOrder);

  const initialValues = {
    customSellingPrice: item.price.customSellingPrice ? getCurrencyValue(item.price.customSellingPrice) : '',
    sellingPrice: item.price.sellingPrice ? getCurrencyValue(item.price.sellingPrice) : '',
    buyingPrice: box
      ? getCurrencyValue(box.price)
      : item.price.buyingPrice
      ? getCurrencyValue(item.price.buyingPrice)
      : ''
  };

  const onSubmit = (values: any) => {
    const payload: OrgOrderItem = { ...item };

    const { customSellingPrice, sellingPrice, buyingPrice } = values;
    if (box) return onUpdateBox(roundCurrencyValue(buyingPrice));
    const { price } = payload;

    if (!customSellingPrice && customSellingPrice !== 0) payload.price.customSellingPrice = undefined;
    else if (customSellingPrice !== price.customSellingPrice)
      payload.price.customSellingPrice = roundCurrencyValue(customSellingPrice);
    if (sellingPrice !== price.sellingPrice) payload.price.sellingPrice = roundCurrencyValue(sellingPrice);
    if (buyingPrice !== price.buyingPrice) payload.price.buyingPrice = roundCurrencyValue(buyingPrice);
    if (onlySellingPrice) return onUpdateOrdredItem(payload);
    onUpdateItem(payload);
  };

  const onUpdateOrdredItem = (payload: OrgOrderItem) => {
    const items = order.items.map((item) => {
      if (item.item.objectID === payload.item.objectID && item.price.provider === payload.price.provider)
        return { ...item, price: payload.price };

      return item;
    });

    const res: UpdateOrderPayload = {
      orgID: order.orgID,
      orderID: order.orderID,
      body: {
        items: items.map((item) => ({
          variantID: item.item.objectID,
          providerName: item.price.provider,
          quantity: item.quantity,
          customSellingPrice: item.price.customSellingPrice,
          sellingPrice: item.price.sellingPrice,
          buyingPrice: item.price.buyingPrice
        }))
      }
    };

    mutate(res, {
      onSuccess: (res) => {
        setOrder(res);
        close();
      }
    });
  };

  const onUpdateItem = (payload: OrgOrderItem) => {
    const res = itemsWithBoxes.map((item) => {
      if (item.item.item.objectID === payload.item.objectID && item.item.price.provider === payload.price.provider)
        return {
          ...item,
          item: payload,
          boxes: item.boxes.map((box) => ({ ...box, price: payload.price.buyingPrice || 0 }))
        };

      return item;
    });

    setItemsWithBoxes(res);
    close();
  };

  const onUpdateBox = (price: number) => {
    const res: ItemWithBoxes[] = itemsWithBoxes.map((el) => {
      if (el.item.item.objectID === item.item.objectID && el.item.price.provider === item.price.provider) {
        return {
          ...el,
          boxes: el.boxes.map((b) => {
            if (b.id === box?.id) return { ...b, price };

            return b;
          })
        };
      }

      return el;
    });

    setItemsWithBoxes(res);
    close();
  };

  return (
    <Modal open={true} close={close} title="Edit price of the box" className="editOrderItemPriceModal">
      <div className="productCard">
        <div className="productCardPhoto">
          <img src={item.item.images[0]} alt={item.item.title} />
        </div>
        <div className="productCardDetails">
          <p>{item.item.title}</p>
          <span>
            <b>{convertPrice(orderSupplierPrice(item.price))}</b>
            {formatUom(item.item)}
          </span>
        </div>
      </div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form>
          {onlySellingPrice ? (
            <>
              <CurrencyField name="customSellingPrice" label="Custom Displayed Faliam Unit Rate" />
              <CurrencyField name="sellingPrice" label="Displayed Faliam Unit Rate" />
            </>
          ) : (
            <CurrencyField name="buyingPrice" label="Seller Invoice Unit Rate" />
          )}
          <button type="submit" className="contained" disabled={isLoading}>
            Save changes
          </button>
        </Form>
      </Formik>
    </Modal>
  );
};
