import { useState } from 'react';
import { useFormikContext } from 'formik';

import { DownArrowIcon } from 'assets/icons/DownArrow';
import { isActiveClass } from 'util/classes';
import { Select } from 'components/ui/Select';
import { OrderItem } from 'types/order.types';
import { useOrderStore } from 'stores/OrderStore';
import { OrgOrderItem } from 'types/orgs.types';

interface Props {
  providers: string[];
  items: (provider: string) => OrderItem[];
  isMultipleSelect?: boolean;
  onChange?: (provider: string) => void;
  variant?: string;
  allItems?: any[];
}

export const ProvidersSelect = ({ providers, items, isMultipleSelect = true, onChange, variant, allItems }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { order } = useOrderStore();

  const { values, setValues }: any = useFormikContext();
  const onClick = (provider: string) => {
    if (onChange) {
      onChange(provider);
      setIsOpen(false);

      return;
    }

    const newItems = items(provider);
    setValues({
      ...values,
      provider,
      allItems: newItems,
      selectedItems: isMultipleSelect ? newItems : []
    });
    setIsOpen(false);
  };

  const renderBadgeByProvider = (provider: string) => {
    if (variant === 'purchaseOrders') {
      const allPurchaseOrdersItems = (order.purchaseOrders || []).reduce(
        (acc, curr) => [...acc, ...curr.items],
        [] as OrgOrderItem[]
      );
      const supplierPurchaseOrdersItems = allPurchaseOrdersItems.filter((item) => item.price.provider === provider);
      const supplierItems = values.allItems.filter((item: OrgOrderItem) => item.price.provider === provider);

      if (!supplierPurchaseOrdersItems.length) return null;
      if (supplierPurchaseOrdersItems.length >= supplierItems.length)
        return <span className="badge success">Fully Covered</span>;
      return (
        allPurchaseOrdersItems.some((el) => el.price.provider === provider) && (
          <span className="badge secondary">Partially Covered</span>
        )
      );
    } else if (variant === 'backedOrder') {
      const allBackorderedItems = order.items.filter((el) => el.backOrder);

      const supplierBackorderedItems = allBackorderedItems.filter((item) => item.price.provider === provider);
      const supplierItems = allItems?.filter((item: OrgOrderItem) => item.price.provider === provider) || [];

      if (!supplierBackorderedItems.length) return null;
      if (supplierBackorderedItems.length >= supplierItems.length)
        return <span className="badge success">Fully Covered</span>;
      return (
        allBackorderedItems.some((el) => el.price.provider === provider) && (
          <span className="badge secondary">Partially Covered</span>
        )
      );
    }

    return null;
  };

  return (
    <div className="field">
      <label>Provider</label>
      <Select
        className="regular"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        options={
          <ul>
            {providers.map((provider) => (
              <li
                key={provider}
                onClick={() => onClick(provider)}
                className={isActiveClass('flexAlign gg16', values.provider === provider)}
              >
                {provider}
                {renderBadgeByProvider(provider)}
              </li>
            ))}
          </ul>
        }
      >
        <p>{values.provider}</p>
        <DownArrowIcon />
      </Select>
    </div>
  );
};
