import { useState } from 'react';

import { Payment } from 'types/payment.types';

import { isActiveClass } from 'util/classes';
import { Modal } from 'components/ui/Modal';
import { Cards } from './Cards';
import { CheckForm } from './CheckForm';
import { PayFaliamInvoicePayload } from 'api/purchaseOrders/types';

export interface PayInvoiceSubmitProps {
  paymentID?: string;
  checkInfo?: PayFaliamInvoicePayload['checkInfo'];
}

interface Props {
  close: () => void;
  payments: Payment[];
  onSubmit: (props: PayInvoiceSubmitProps) => void;
  isLoading: boolean;
}

export const SelectPayment = ({
  close,
  payments,
  onSubmit,
  isLoading,
}: Props) => {
  const [activeTab, setActiveTab] = useState(1);
  const [activePayment, setActivePayment] = useState(
    payments?.[0]?.paymentID || ''
  );

  return (
    <Modal
      open={true}
      close={close}
      title="Choose Payment Method"
      className="selectPaymentModal"
    >
      <div className="tabs">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={isActiveClass('', activeTab === tab.id)}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      {activeTab === 1 ? (
        <Cards
          activePayment={activePayment}
          setActivePayment={setActivePayment}
          payments={payments}
          isLoading={isLoading}
          onSubmit={onSubmit}
        />
      ) : (
        <CheckForm isLoading={isLoading} onSubmit={onSubmit} />
      )}
    </Modal>
  );
};

const tabs = [
  { id: 1, label: 'Pay with card' },
  { id: 2, label: 'Pay with check' },
];
