import { updateOrg } from 'api/orgs';
import { Org, OrgMetadata } from 'api/orgs/types';
import { Modal } from 'components/ui/Modal';
import { useState } from 'react';
import { useMutation } from 'react-query';

import { isActiveClass } from 'util/classes';

interface Props {
  open: boolean;
  close: () => void;
  org: Org;
  refetchOrg: () => void;
}

export const SettingsModal = ({ open, close, org, refetchOrg }: Props) => {
  const { featureFlags, orgID } = org.metadata;
  const [flags, setFlags] = useState({
    enableInvoices: featureFlags.enableInvoices,
    enableProcurementOrdering: featureFlags.enableProcurementOrdering,
  });

  const { mutate } = useMutation((body: OrgMetadata) => updateOrg(orgID, body));

  const toggle = (name: 'enableInvoices' | 'enableProcurementOrdering') => {
    const payload = { ...flags, [name]: !flags[name] };
    const body = { ...org.metadata, featureFlags: payload };
    setFlags(payload);
    mutate(body, { onSuccess: refetchOrg });
  };

  return (
    <Modal
      open={open}
      close={close}
      title="Settings"
      className="orgSettingsModal"
    >
      <label>FEATURE FLAGS</label>
      <div className="flexAlign gg8 mb24">
        <div className="switchBtn" onClick={() => toggle('enableInvoices')}>
          <p>Invoicing</p>
          <div className={isActiveClass('buffer', flags.enableInvoices)} />
        </div>
        <div
          className="switchBtn"
          onClick={() => toggle('enableProcurementOrdering')}
        >
          <p>Procurement Ordering</p>
          <div
            className={isActiveClass('buffer', flags.enableProcurementOrdering)}
          />
        </div>
      </div>
      <button className="outlined wFull" onClick={close}>
        Cancel
      </button>
    </Modal>
  );
};
