import Calendar from 'react-calendar';
import { Dispatch, SetStateAction } from 'react';

import { sortBy } from 'lodash';
import { formatDate } from './org';
import { isActiveClass } from 'util/classes';
import { LoaderIcon } from 'assets/icons/LoaderIcon';
import { HelathSnapshot } from 'api/health/types';
import { useQueryParams } from 'hooks/useSearchQuery';

type Props = {
  snapshots: Array<HelathSnapshot>;
  setSelectedSnapshots: Dispatch<SetStateAction<HelathSnapshot | null>>;
  selectedSnapshots: HelathSnapshot | null;
  isLoading: boolean;
  hasIDs: boolean;
  type: 'org' | 'gpo';
};

export const SnapshotsCalendar = ({
  snapshots,
  setSelectedSnapshots,
  selectedSnapshots,
  isLoading,
  hasIDs,
  type
}: Props) => {
  const { updateQueryParam, deleteQueryParam } = useQueryParams();

  const datesWithSnapshots = snapshots.filter(({ snapshots }) => snapshots.length).map(({ date }) => new Date(date));
  const isIn = (date: Date) => datesWithSnapshots.some((d) => d.toDateString() === date.toDateString());
  const last10Snapshots = sortBy(snapshots, ({ date }) => new Date(date)).slice(-10);

  return (
    <>
      <div className={isActiveClass('snapshotsCalendar', Boolean(snapshots.length))}>
        <div className="overlay">
          {isLoading ? (
            <LoaderIcon />
          ) : hasIDs ? (
            <p>No snapshots found</p>
          ) : (
            <p>Please select {type === 'gpo' ? 'GPO' : 'Organization'} and provider</p>
          )}
        </div>
        <Calendar
          onClickDay={(d) => {
            deleteQueryParam('snapshotID');
            setSelectedSnapshots(snapshots.find(({ date }) => formatDate(date) === formatDate(d)) || null);
          }}
          tileClassName={({ date }) => (isIn(date) ? 'hasSnapshots' : null)}
        />
      </div>

      {selectedSnapshots ? (
        <div className="selectedPreview paper">
          <>
            <p>{formatDate(selectedSnapshots?.date)}</p>
            <ul>
              {selectedSnapshots.snapshots.map((el, i) => (
                <li key={el}>
                  <button
                    type="button"
                    onClick={() => {
                      updateQueryParam('snapshotID', el);
                    }}
                  >
                    Snapshot {i + 1}
                  </button>
                </li>
              ))}
            </ul>
          </>
        </div>
      ) : (
        <>
          {last10Snapshots.length ? (
            <div className="lastTen paper">
              <h3>Latest 10 Snapshots</h3>
              <div className="lists">
                {last10Snapshots?.map((el) => (
                  <div key={el.date.toString()} className="group">
                    <p>{formatDate(el?.date as Date)}</p>
                    <ul>
                      {el.snapshots.map((snapshot, i) => (
                        <li key={snapshot}>
                          <button
                            type="button"
                            onClick={() => {
                              updateQueryParam('snapshotID', snapshot);
                            }}
                          >
                            Snapshot {i + 1}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};
