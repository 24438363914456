import { Payment } from 'types/payment.types';

import { isActiveClass } from 'util/classes';
import { PayInvoiceSubmitProps } from '.';

interface Props {
  payments: Payment[];
  activePayment: string;
  setActivePayment: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  onSubmit: (data: PayInvoiceSubmitProps) => void;
}

export const Cards = ({
  payments,
  activePayment,
  setActivePayment,
  isLoading,
  onSubmit,
}: Props) => (
  <>
    <div className="cards">
      {payments.map((payment) => (
        <div
          key={payment.paymentID}
          className={isActiveClass(
            `card ${payment.brand}`,
            payment.paymentID === activePayment
          )}
          onClick={() => setActivePayment(payment.paymentID)}
        >
          <p>{payment.brand}</p>
          <p>···· ···· ···· {payment.last4}</p>
          <p>
            Exp. date - {payment.expMonth}/{payment.expYear}
          </p>
        </div>
      ))}
    </div>
    <button
      className="contained"
      type="button"
      onClick={() => {
        if (!activePayment) return;
        onSubmit({ paymentID: activePayment });
      }}
      disabled={isLoading}
    >
      Pay with card
    </button>
  </>
);
