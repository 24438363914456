import {
  PurchaseOrderItemPayload,
  PurchaseOrderPayload,
} from 'api/purchaseOrders/types';

import { OrderItem } from 'types/order.types';
import { getCurrencyValue, roundCurrencyValue } from './mix';

import { orderSupplierPrice } from './prices';

export const setOtherCostsToPayload = (
  otherCosts: Array<{ label: string; price: number | string }>
): { [key: string]: number } =>
  otherCosts.reduce(
    (obj, item) =>
      Object.assign(obj, { [item.label]: roundCurrencyValue(item.price) }),
    {}
  );

export const convertOtherCostsToFormikFormat = (otherCosts?: {
  [key: string]: number;
}): Array<{ label: string; price: string | number }> => {
  if (otherCosts)
    return Object.entries(otherCosts).map(([label, price]) => ({
      label,
      price: getCurrencyValue(price),
    }));

  return [{ label: '', price: '' }];
};

export const payloadPurchaseOrdersItems = (
  values: any,
  provider?: string
): PurchaseOrderItemPayload[] => {
  const { allItems, selectedItems } = values;

  const res: OrderItem[] = allItems.filter((el: OrderItem) =>
    selectedItems.some(
      (item: OrderItem) =>
        el.item.objectID === item.item.objectID &&
        el.price.provider === item.price.provider
    )
  );

  let output = res.map((item) => ({
    variantID: item.item.objectID,
    quantity: item.quantity,
    price: orderSupplierPrice(item.price),
  }));
  if (provider) {
    output = output.map((el) => ({ ...el, provider }));
  }

  return output;
};

export const getPurchasePayload = (values: any, provider?: string) => {
  const payload: PurchaseOrderPayload = {
    items: payloadPurchaseOrdersItems(values, provider),
  };

  if (values.shippingCosts)
    payload.shippingCosts = roundCurrencyValue(values.shippingCosts);
  if (values.taxCosts) payload.taxCosts = roundCurrencyValue(values.taxCosts);
  if (values.otherCosts.length && values.otherCosts[0].label)
    payload.otherCosts = setOtherCostsToPayload(values.otherCosts);

  return payload;
};
