import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';

export interface User {
  id: string;
  email: string;
}
export const currentUser = async (): Promise<User> => {
  const u = await getCurrentUser();
  const retVal: User = {
    id: u.username,
    email: ''
  };

  const attributes = await fetchUserAttributes();

  if (!attributes) return retVal;
  retVal.email = attributes.email || '';

  return retVal;
};
