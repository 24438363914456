import { useRefinementList } from 'react-instantsearch-hooks';

import ReactSelect from 'components/ui/ReactSelect';

const options = [
  { value: 'true', label: 'Unavailable' },
  { value: 'false', label: 'Available' }
];

export const UnavailableFilter = () => {
  const { items, refine } = useRefinementList({ attribute: 'unavailable' });
  const activeValue = items.find(({ isRefined }) => isRefined)?.value || '';

  return (
    <ReactSelect
      placeholder="Status"
      options={options}
      value={options.find(({ value }) => value === activeValue) || null}
      onChange={({ value }: any) => {
        if (activeValue) refine(activeValue);
        if (value !== activeValue) refine(value);
      }}
    />
  );
};
