/* eslint-disable react-hooks/exhaustive-deps */
import { useQueryParams } from 'hooks/useSearchQuery';
import { GpoHealthSelects } from './GpoHealthSelects';
import { useQuery } from 'react-query';
import { getSingleGpo } from 'api/gpo';
import { ConnectedOrganizations } from './ConnectedOrganizations';
import { getGpoHealthSnapshot, getGpoHealthSpanshots } from 'api/health';
import { useEffect, useState } from 'react';
import { HelathSnapshot } from 'api/health/types';
import { SnapshotsCalendar } from '../SnapshotsCalendar';
import { HealthPieChart } from '../HealthPieChart';

export const GpoHealthPage = () => {
  const { getQueryParam } = useQueryParams();

  const gpoID = getQueryParam('gpoID') || '';
  const providerName = getQueryParam('providerName') || '';
  const snapshotID = getQueryParam('snapshotID') || '';

  const hasGpoAndProvider = Boolean(gpoID) && Boolean(providerName);

  const [selectedSnapshots, setSelectedSnapshots] = useState<HelathSnapshot | null>(null);

  const { data: gpo } = useQuery(['single-gpo', gpoID], () => getSingleGpo(gpoID), { enabled: Boolean(gpoID) });

  const { data: snapshots, isLoading } = useQuery(
    ['gpo-snapshots', gpoID, providerName],
    () => getGpoHealthSpanshots({ gpoID, providerName }),
    { enabled: hasGpoAndProvider }
  );

  const { data: snapshot, isLoading: isLoadingSnapshot } = useQuery(
    ['snapshot', snapshotID],
    () => getGpoHealthSnapshot({ gpoID, providerName, snapshotID }),
    { enabled: hasGpoAndProvider && Boolean(snapshotID) }
  );

  useEffect(() => {
    if (snapshotID) setSelectedSnapshots(snapshots?.find(({ snapshots }) => snapshots.includes(snapshotID)) || null);
  }, [snapshots]);

  return (
    <div className="healthPage">
      <GpoHealthSelects gpo={gpo} setSelectedSnapshots={setSelectedSnapshots} />
      <ConnectedOrganizations members={gpo?.membership || []} />

      <div className="cols">
        <SnapshotsCalendar
          type="gpo"
          snapshots={snapshots || []}
          isLoading={isLoading}
          setSelectedSnapshots={setSelectedSnapshots}
          selectedSnapshots={selectedSnapshots}
          hasIDs={hasGpoAndProvider}
        />

        <HealthPieChart
          data={snapshot}
          id={gpoID}
          providerName={providerName}
          isLoading={isLoadingSnapshot}
          type="gpo"
        />
      </div>
    </div>
  );
};
