export const OrderIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="#080C2D"
      d="M14.001 6.006h-.947l-2.474-4.33-1.158.661 2.097 3.67H4.483l2.097-3.67-1.158-.661-2.474 4.33H2a.667.667 0 00-.643.842l1.866 6.843a1.337 1.337 0 001.286.982h6.982c.6 0 1.128-.403 1.287-.983l1.866-6.842a.665.665 0 00-.644-.842zm-2.51 7.334v.666-.666h-6.98l-1.637-6h10.254l-1.636 6z"
    ></path>
    <path
      fill="#080C2D"
      d="M6 8.668h1.333v3.333H6V8.668zm2.667 0H10v3.333H8.667V8.668z"
    ></path>
  </svg>
);
