import { useState } from 'react';
import { useFormikContext } from 'formik';

import { DownArrowIcon } from 'assets/icons/DownArrow';
import { Select } from 'components/ui/Select';

export const CustomerPreferenceStatus = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { values, setValues }: any = useFormikContext();

  const onClick = (customerPreference: 'WAIT' | 'CANCEL') => {
    setValues({ ...values, customerPreference });
    setIsOpen(false);
  };

  return (
    <div className="field">
      <label>Customer Preference</label>
      <Select
        className="regular"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        options={
          <ul>
            {data.map((el) => (
              <li key={el} onClick={() => onClick(el as any)}>
                {el}
              </li>
            ))}
          </ul>
        }
      >
        <p>{values.customerPreference || ''}</p>
        <DownArrowIcon />
      </Select>
    </div>
  );
};

const data = ['WAIT', 'CANCEL'];
