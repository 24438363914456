import { AlgoliaOrder } from 'types/algolia.types';
import { formatDate } from './dates';
import { convertPrice } from './prices';

export const convertOrdersToCSVformat = (orders: AlgoliaOrder[]) =>
  orders.map((order) => ({
    orderDate: formatDate(order.created),
    orderID: order.orderID,
    orgID: order.orgID,
    officeAddress: order.officeName,
    status: order.status,
    invoiceStatus: !order.invoice || order.orderPaid ? 'Paid' : 'Unpaid',
    subtotal: convertPrice(order.total),
  }));
