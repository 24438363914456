import { useEffect, useMemo, useState } from 'react';
import orderBy from 'lodash/orderBy';

import { Org } from 'api/orgs/types';
import { Provider } from 'api/providers/types';
import { HandleCellOrders } from 'types/table.types';

import { getConnectionContact, getConnectionStatus, getConnectionStatusOrder } from 'util/mix';
import useTableData from 'hooks/useTableData';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { UploadCSVModal } from './UploadCSVModal';
import { Table } from 'components/shared/table';

interface Props {
  allProviders: Provider[];
  org: Org;
}

export const SuppliersTable = ({ org, allProviders }: Props) => {
  const [activeProvider, setActiveProvider] = useState('');

  const providers = useMemo(
    () =>
      orderBy(
        allProviders.map((provider) => ({
          ...provider,
          status: getConnectionStatus(org, provider.name)
        })),
        ['name'],
        ['asc']
      ),
    [allProviders, org]
  );

  const [data, setData] = useState<any[]>(providers);

  useEffect(() => {
    setData(providers);
  }, [providers]);

  const { meta, tableData } = useTableData({
    allData: data
  });

  const handleCellOrders = ({ cell, direction }: HandleCellOrders) => {
    let res: any[] = [];

    switch (cell) {
      case 'Name':
      case 'Logo':
        res = orderBy(providers, (el) => el.name.toLowerCase(), direction);
        break;
      case 'Type':
        res = orderBy(providers, 'category', direction);
        break;
      case 'Connection Status':
        res = orderBy(providers, (el) => getConnectionStatusOrder(org, el.name), direction);
        break;
    }

    setData(res);
  };

  return (
    <>
      <div className="space" />
      <Table
        name="OrgSuppliers"
        handleCellOrders={handleCellOrders}
        title="Suppliers"
        nav={['Logo', 'Name', 'Contact', 'Connection Status', 'Type']}
        allData={providers}
        meta={meta}
        searchKeys={['name', 'category', 'status']}
        setData={setData}
        content={
          <div className="tableBody customScrollbar">
            {tableData.map((provider: Provider) => (
              <div key={provider.name} className="tableRow">
                <div className="tableCell">
                  <div className="supplierLogo">
                    <img src={provider.image} alt={provider.name} />
                  </div>
                </div>
                <TableCellTooltip content={provider.name} />
                <TableCellTooltip content={getConnectionContact(org, provider.name) || 'N/A'} />
                <TableCellTooltip content={getConnectionStatus(org, provider.name)}>
                  <div className={`badge ${getConnectionStatus(org, provider.name)}`}>
                    {getConnectionStatus(org, provider.name)}
                  </div>
                </TableCellTooltip>
                <TableCellTooltip content={provider.category}>
                  <div className={`badge ${provider.category === 'PROCUREMENT' ? 'secondary' : 'success'}`}>
                    {provider.category}
                  </div>
                </TableCellTooltip>
              </div>
            ))}
          </div>
        }
      />
      {activeProvider && (
        <UploadCSVModal name={activeProvider} orgID={org.metadata.orgID} close={() => setActiveProvider('')} />
      )}
    </>
  );
};
