import { convertPrice } from 'util/prices';

export enum PromotionType {
  Fixed = 'Fixed',
  Percentage = 'Percentage',
  BuyXGetY = 'BuyXGetY',
  BulkPrice = 'BulkPrice'
}

export interface Promotion {
  type: () => PromotionType;
  active: () => boolean;
  calculateTotal: (price: number, quantity?: number) => number;
  toString: () => string;
}

export function getPromotion(type: string, data: any): Promotion {
  switch (type) {
    case PromotionType.Fixed:
      return new FixedPromotion(data);
    case PromotionType.Percentage:
      return new PercentagePromotion(data);
    case PromotionType.BuyXGetY:
      return new BuyXGetYPromotion(data);
    case PromotionType.BulkPrice:
      return new BulkPricePromotion(data);
    default:
      throw new Error(`Unknown promotion type: ${type}`);
  }
}

export class BasePromotion {
  constructor(start?: any, end?: any) {
    this.start = new Date(start);
    this.end = new Date(end);
  }
  start?: any;
  end?: any;
  active: () => boolean = function (): boolean {
    const now = new Date();
    // @ts-ignore
    if (this.start && this.start > now) return false;
    // @ts-ignore
    if (this.end && this.end < now) return false;
    return true;
  };
}

export class FixedPromotion extends BasePromotion implements Promotion {
  public amount: number;
  constructor(data: any) {
    super(data.start, data.end);
    this.amount = data.amount;
  }

  type(): PromotionType {
    return PromotionType.Fixed;
  }

  calculateTotal(price: number, quantity?: number): number {
    const qty = quantity || 1;

    return (price - this.amount) * qty;
  }

  toString(): string {
    return `${convertPrice(this.amount)} Off`;
  }
}

export class PercentagePromotion extends BasePromotion implements Promotion {
  public percentage: number;
  constructor(data: any) {
    super(data.start, data.end);
    this.percentage = data.percentage;
  }

  type(): PromotionType {
    return PromotionType.Percentage;
  }

  calculateTotal(price: number, quantity?: number): number {
    const qty = quantity || 1;
    return price * (1 - this.percentage / 100) * qty;
  }

  toString(): string {
    return `${this.percentage}% Off`;
  }
}

export class BuyXGetYPromotion extends BasePromotion implements Promotion {
  public buyX: number;
  public getY: number;
  public getYDiscount: number;
  constructor(data: any) {
    super(data.start, data.end);
    this.buyX = data.buyX;
    this.getY = data.getY;
    this.getYDiscount = data.getYDiscount;
  }

  type(): PromotionType {
    return PromotionType.BuyXGetY;
  }

  calculateTotal(price: number, quantity?: number): number {
    const qty = quantity || 1;
    // Calculat discounted price based on discount percentage
    const discountedPrice = price * (1 - this.getYDiscount / 100);

    // Calculate number of full promotion cycles
    const promotionCycleSize = this.buyX + this.getY;
    const fullCycles = Math.floor(qty / promotionCycleSize);
    const remainingItems = qty % promotionCycleSize;

    // Calculate number of items that are full price
    let fullPriceItemCount = fullCycles * this.buyX;
    if (remainingItems >= this.buyX) {
      fullPriceItemCount += this.buyX;
    } else {
      fullPriceItemCount += remainingItems;
    }

    // Calculate number of items that are discounted
    const discountedPriceItemCount = qty - fullPriceItemCount;

    // Calculate total
    return fullPriceItemCount * price + discountedPriceItemCount * discountedPrice;
  }

  toString(): string {
    return `Buy ${this.buyX} Get ${this.getY} ${this.getYDiscount < 100 ? `${this.getYDiscount}% Off` : 'Free'}`;
  }
}

export class BulkPricePromotion extends BasePromotion implements Promotion {
  public threshold: number;
  public price: number;
  constructor(data: any) {
    super(data.start, data.end);
    this.threshold = data.threshold;
    this.price = data.price;
  }

  type(): PromotionType {
    return PromotionType.BulkPrice;
  }

  calculateTotal(price: number, quantity?: number): number {
    const qty = quantity || 1;
    if (qty < this.threshold) {
      return price * qty;
    }
    return this.price * qty;
  }

  toString(): string {
    return `${this.threshold} Or More At ${convertPrice(this.price)}`;
  }
}

export interface SinglePromotion {
  type: string;
  displayText: string;
  data: {
    amount?: number;
    percentage?: number;
    buyX?: number;
    getY?: number;
    getYDiscount?: number;
    threshold?: number;
    price?: number;
  };
}
