import { useSearchBox } from 'react-instantsearch-hooks';
import { MoreItemsProps } from '.';
import { AlgoliaHits } from './AlgoliaHits';
import { AlgoliaSearch } from './AlgoliaSearch';

export const Container = (props: MoreItemsProps) => {
  const { query } = useSearchBox();

  return (
    <>
      <AlgoliaSearch />
      {(query || props.isFormulary) && <AlgoliaHits {...props} />}
    </>
  );
};
