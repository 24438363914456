/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { Table } from 'components/shared/table';
import { Modal } from 'components/ui/Modal';
import useTableData from 'hooks/useTableData';
import { useMutation } from 'react-query';
import { createAdminUser, deleteAdminUser, listAdminUserGroups, listAdminUsers, updateAdminUser } from 'api/settings';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { toast } from 'react-toastify';
import { TableDropdownCell } from 'components/shared/table/TableDropdownCell';
import { adminUserSchema } from 'util/validationSchemas';
import { Field, Form, Formik } from 'formik';
import { FieldError } from 'components/shared/FormikError';
import { User, CreateUserPayload, UpdateUserPayload } from 'api/settings/types';
import { Select } from 'components/ui/Select';


export const AdminUsers = () => {
  // Table data
  const [selectedCell, setSelectedCell] = useState('');
  const [groups, setGroups] = useState<string[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const { meta, tableData } = useTableData({ allData: users });

  // Modals + outside click
  const [userMarkedForUpdate, setUserMarkedForUpdate] = useState<User | undefined>(undefined);
  const [userMarkedForDeletion, setUserMarkedForDeletion] = useState('');
  const [createModal, setCreateModal] = useState(false);
  const dotsRef = useRef(null);
  useOutsideClick(dotsRef, () => selectedCell && setSelectedCell(''));

  // Mutations
  const { mutate: listUserGroupsMutate, isLoading: isUserGroupsLoading } = useMutation(listAdminUserGroups);
  const { mutate: listUsersMutate, isLoading: isUsersLoading } = useMutation(listAdminUsers);
  const { mutate: deleteMutate, isLoading: isDeleteUserLoading } = useMutation((id: string) => deleteAdminUser(id));
  const { mutate: createMutate, isLoading: isCreateUserLoading } = useMutation((body: CreateUserPayload) => createAdminUser(body));
  const { mutate: updateMutate, isLoading: isUpdateUserLoading } = useMutation((body: UpdateUserPayload) => updateAdminUser(body));
  const onDelete = (id: string) => {
    deleteMutate(id, {
      onSuccess() {
        setUserMarkedForDeletion('');
        fetchData();
      },
      onError(err) {
        toast.error(`Failed to delete user: ${err}`);
      }
    });
  };

  const onCreate = (values: any) => {
    const payload = { ...values }
    createMutate(payload, {
      onSuccess() {
        setCreateModal(false);
        fetchData();
      },
      onError(err) {
        toast.error(`Failed to create user: ${err}`);
      }

    });
  }

  const onUpdate = (values: any) => {
    const payload = { ...values }
    updateMutate(payload, {
      onSuccess() {
        setUserMarkedForUpdate(undefined);
        fetchData();
      },
      onError(err) {
        toast.error(`Failed to create user: ${err}`);
      }

    });
  }

  const fetchData = () => {
    // Fetch admin user groups
    listUserGroupsMutate(undefined, {
      onSuccess(res) {
        setGroups(res);
      },
      onError(err) {
        toast.error(`Failed to fetch admin user groups: ${err}`);
      }
    });
    // Fetch admin users
    listUsersMutate(undefined, {
      onSuccess(res) {
        setUsers(res);
      },
      onError(err) {
        toast.error(`Failed to fetch admin users: ${err}`);
      }
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Table
        name="AdminUsers"
        title={"Admin Users"}
        setData={setUsers}
        nav={[
          'ID',
          'Name',
          'Email',
          'Status',
          'Groups',
          ''
        ]}
        meta={meta}
        allData={users}
        headerContent={
          <button className="outlined lg" onClick={() => setCreateModal(true)}>
            Add User
          </button>
        }
        isLoading={isUsersLoading}
        content={
          <div className="tableBody">
            {tableData.map((el: User) => {
              return (
                <div key={el.id} className="tableRow">
                  <TableCellTooltip content={el.id} />
                  <TableCellTooltip content={el.name} />
                  <TableCellTooltip content={el.email} />
                  <TableCellTooltip content={el.status} />
                  <TableCellTooltip content={el.groups?.map((el) => el.replace(/admin-core-infra-.*-admin-userpool-/, "")).join(", ")} />
                  <TableDropdownCell id={el.id} active={selectedCell} onClick={setSelectedCell} ref={dotsRef}>
                    <li onClick={() => { setUserMarkedForUpdate(el); setSelectedCell('') }}>
                      Update User
                    </li>
                    <li className="danger" onClick={() => { setUserMarkedForDeletion(el.id); setSelectedCell('') }}>
                      Delete User
                    </li>
                  </TableDropdownCell>
                </div>
              );
            })}
          </div>
        }
      />

      {/* Create User Modal */}
      <Modal
        open={createModal}
        close={() => setCreateModal(false)}
        title={`Create User`}
        className="createUserModal"
      >
        <Formik
          initialValues={{
            name: '',
            email: '',
          }}
          onSubmit={onCreate}
          validationSchema={adminUserSchema}
        >
          {({ isValid, dirty }) => (
            <Form>
              <div className="fields">
                <div className="field">
                  <label>Name</label>
                  <Field name="name" required />
                  <FieldError name="name" />
                </div>
                <div className="field">
                  <label>Email</label>
                  <Field name="email" />
                  <FieldError name="email" />
                </div>
                <div className="field">
                  <label>Groups</label>
                  <Field as="select" name="groups" multiple>
                    {
                      groups.map(group => (
                        <option key={group} value={group}>{group.replace(/admin-core-infra-.*-admin-userpool-/, "")}</option>
                      ))
                    }
                  </Field>
                  <FieldError name="groups" />
                </div>
              </div>
              <button type="submit" className="contained" disabled={isCreateUserLoading || !isValid || !dirty}>
                Add User
              </button>
            </Form>
          )}
        </Formik>
      </Modal>

      {/* Update User Modal */}
      <Modal
        open={userMarkedForUpdate !== undefined}
        close={() => setUserMarkedForUpdate(undefined)}
        title={`Update User`}
        className="updateUserModal"
      >
        <Formik
          initialValues={{
            id: userMarkedForUpdate?.id,
            name: userMarkedForUpdate?.name,
            email: userMarkedForUpdate?.email,
            groups: userMarkedForUpdate?.groups
          }}
          onSubmit={onUpdate}
          validationSchema={adminUserSchema}
        >
          {({ isValid, dirty }) => (
            <Form>
              <div className="fields">
                <div className="field">
                  <label>Name</label>
                  <Field name="name" required />
                  <FieldError name="name" />
                </div>
                <div className="field">
                  <label>Email</label>
                  <Field name="email" />
                  <FieldError name="email" />
                </div>
                <div className="field">
                  <label>Groups</label>
                  <Field as="select" name="groups" multiple>
                    {
                      groups.map(group => (
                        <option key={group} value={group}>{group.replace(/admin-core-infra-.*-admin-userpool-/, "")}</option>
                      ))
                    }
                  </Field>
                  <FieldError name="groups" />
                </div>
              </div>
              <button type="submit" className="contained" disabled={isUpdateUserLoading || !isValid || !dirty}>
                Update User
              </button>
            </Form>
          )}
        </Formik>
      </Modal>

      {/* Delete User Modal */}
      <Modal
        open={userMarkedForDeletion !== ''}
        close={() => setUserMarkedForDeletion('')}
        title={`Delete User`}
        className="deleteUserModal"
      >
        <h3>Are you sure you want to delete this user?</h3>
        <div className="buttons">
          <button className="contained danger" disabled={isDeleteUserLoading} onClick={() => onDelete(userMarkedForDeletion)}>
            Delete user
          </button>
          <button className="contained cancel" onClick={() => setUserMarkedForDeletion('')}>
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};
