interface Props {
  hits: any[];
  content: JSX.Element;
}

export const TableBody = ({ hits, content }: Props) => (
  <>
    {hits.length ? (
      content
    ) : (
      <p className="noSearchResults">No search results...</p>
    )}
  </>
);
