import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import orderBy from 'lodash/orderBy';

import { Org, OrgUserInvite } from 'api/orgs/types';
import { HandleCellOrders } from 'types/table.types';

import useTableData from 'hooks/useTableData';
import { deleteOrgUserInvite } from 'api/orgs';
import { Table } from 'components/shared/table';
import { InviteUserModal } from './InviteUserModal';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { TableDropdownCell } from 'components/shared/table/TableDropdownCell';
import { useOutsideClick } from 'hooks/useOutsideClick';

interface Props {
  org: Org;
  refetchOrg: () => void;
}

export const OrgUserInvitationsTable = ({ org, refetchOrg }: Props) => {
  const [isOpenInviteModal, setIsOpenInviteModal] = useState(false);
  const [selectedCell, setSelectedCell] = useState('');
  const [data, setData] = useState<OrgUserInvite[]>([]);

  const dotsRef = useRef(null);
  useOutsideClick(dotsRef, () => selectedCell && setSelectedCell(''));

  const allSortedData = orderBy(org.invites, ['email'], ['asc']);

  useEffect(() => {
    setData(allSortedData);
  }, [org.invites]);

  const { meta, tableData } = useTableData({ allData: data });
  const { mutate: deleteInvite } = useMutation((id: string) => deleteOrgUserInvite(org.metadata.orgID, id));
  const onDelete = (id: string) => deleteInvite(id, { onSuccess: refetchOrg });

  const handleCellOrders = ({ cell, direction }: HandleCellOrders) => {
    let res: any[] = [];

    switch (cell) {
      case 'Invite #':
        res = orderBy(org.invites, 'inviteID', direction);
        break;
      case 'Email':
        res = orderBy(org.invites, 'email', direction);
        break;
      case 'Role':
        res = orderBy(org.invites, 'role', direction);
        break;
      default:
        res = data;
        break;
    }

    setData(res);
  };

  return (
    <>
      <div className="space" />
      <Table
        name="OrgUserInvitations"
        title="Invitations Users"
        nav={['Invite #', 'Email', 'Role', 'Assigned offices', '']}
        allData={allSortedData}
        handleCellOrders={handleCellOrders}
        meta={meta}
        setData={setData}
        searchKeys={['email', 'role']}
        headerContent={
          <button className="outlined lg" onClick={() => setIsOpenInviteModal(true)}>
            Invite User
          </button>
        }
        content={
          <div className="tableBody customScrollbar">
            {tableData.map((invite: OrgUserInvite) => (
              <div key={invite.inviteID} className="tableRow">
                <TableCellTooltip content={invite.inviteID} />
                <TableCellTooltip content={invite.email} />
                <TableCellTooltip content={invite.role} />
                <TableCellTooltip content={invite.offices?.length || ''} />
                <TableDropdownCell id={invite.inviteID} active={selectedCell} onClick={setSelectedCell} ref={dotsRef}>
                  <li
                    className="danger"
                    onClick={() => {
                      onDelete(invite.inviteID);
                      setSelectedCell('');
                    }}
                  >
                    Delete Invite
                  </li>
                  <li
                    className="primary"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${process.env.REACT_APP_FALIAM_BASE_URL}/sign-in?invite=${invite.inviteID}`
                      );
                      setSelectedCell('');
                    }}
                  >
                    Copy Invite Link
                  </li>
                </TableDropdownCell>
              </div>
            ))}
          </div>
        }
      />
      {isOpenInviteModal && (
        <InviteUserModal org={org} close={() => setIsOpenInviteModal(false)} refetchOrg={refetchOrg} />
      )}
    </>
  );
};
