import CurrencyInput from 'react-currency-input-field';

import { useProvidersStrore } from 'stores/ProvidersStore';
import { InputField } from 'components/ui/Input';
import { SelectInput } from 'components/ui/SelectInput';

interface Props {
  formik: any;
}

export const validStatuses = ['Available', 'Out of Stock', 'Discontinued', 'Backordered', 'Unavailable'];

export const emptySku = {
  provider: '',
  sku: '',
  title: '',
  url: '',
  status: null,
  buyingPrice: 0,
  sellingPrice: 0,
  createdAt: new Date(),
  updatedAt: new Date()
};

export const SkusPart = ({ formik }: Props) => {
  const { providers } = useProvidersStrore();

  const providersOptions = providers.map((p) => ({ label: p.name, value: p.name }));

  return (
    <div className="paper">
      <div className="paperHeader">
        <h2>Suppliers</h2>
      </div>
      <div className="paperBody">
        {formik.values.skus.length === 0 && (
          <div className={`input-field valid`}>
            <span className="errorMsg">At least one sku is required</span>
          </div>
        )}
        {formik.values.skus.map((_: any, i: number) => (
          <div key={i} className="sku">
            <div className="fieldsX2">
              <div className="input-field">
                <SelectInput formik={formik} label="Provider" name={`skus[${i}].provider`} options={providersOptions} />
              </div>
              <InputField formik={formik} name={`skus[${i}].sku`} label="SKU" placeholder="Provider SKU" />
            </div>
            <div className="fieldsX2">
              <InputField formik={formik} name={`skus[${i}].title`} label="Title" placeholder="Provider Title" />
              <InputField formik={formik} name={`skus[${i}].url`} label="URL" placeholder="Provider URL" />
            </div>
            {providers.find((p) => p.name === formik.values.skus[i].provider)?.category === 'PARTNER' && (
              <div className="fieldsX2">
                <div className="input-field">
                  <label>Buying Price</label>
                  <CurrencyInput
                    prefix="$"
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.skus[i].buyingPrice}
                    onValueChange={(value: any) => formik.setFieldValue(`skus[${i}].buyingPrice`, value)}
                  />
                </div>
                <div className="input-field">
                  <label>Selling Price</label>
                  <CurrencyInput
                    prefix="$"
                    decimalsLimit={2}
                    decimalScale={2}
                    value={formik.values.skus[i].sellingPrice}
                    onValueChange={(value: any) => formik.setFieldValue(`skus[${i}].sellingPrice`, value)}
                  />
                </div>
              </div>
            )}
            <div className="fieldsX2">
              <div className="input-field">
                <label>Created At</label>
                <p>{formik.values.skus[i].createdAt.toLocaleString()}</p>
              </div>
              <div className="input-field">
                <label>Updated At</label>
                <p>{formik.values.skus[i].updatedAt.toLocaleString()}</p>
              </div>
            </div>
            <div className="actionRow">
              <div className="input-field">
                <SelectInput
                  formik={formik}
                  label="Status"
                  name={`skus[${i}].status`}
                  options={validStatuses.map((s) => ({ label: s, value: s }))}
                />
              </div>
              <button
                type="button"
                className="outlined danger sm"
                onClick={() =>
                  formik.setFieldValue(
                    'skus',
                    formik.values.skus.filter((_: any, j: number) => j !== i)
                  )
                }
              >
                Remove
              </button>
            </div>
          </div>
        ))}
        <button
          type="button"
          className="outlined lg w-fit"
          onClick={() => formik.setFieldValue('skus', [...formik.values.skus, emptySku])}
        >
          Add new supplier
        </button>
      </div>
    </div>
  );
};
