import { Field, Form, Formik } from 'formik';
import { useMutation } from 'react-query';

import { CreateCsOverridePayload } from 'api/purchaseOrders/types';

import { createCsOverride } from 'api/purchaseOrders';
import { useOrderStore } from 'stores/OrderStore';
import { isActiveClass } from 'util/classes';
import { Modal } from 'components/ui/Modal';

interface Props {
  close: () => void;
}

export const CreateCsOverride = ({ close }: Props) => {
  const initialValues: CreateCsOverridePayload['body'] = {
    allApprovalsCollected: false,
    allOrdersPlaced: false,
    allOrdersConfirmed: false,
    allOrdersShipped: false,
    allOrdersDelivered: false,
    allItemsHavePackingSlips: false,
    allItemsInvoicedByProvider: false,
    allItemsInvoicedByFaliam: false,
    allFaliamInvoicesPaid: false,
    description: ''
  };

  const { order, setOrder } = useOrderStore();
  const { mutate, isLoading } = useMutation(createCsOverride);

  const onSubmit = (values: CreateCsOverridePayload['body']) => {
    const body = {
      orderID: order.orderID,
      orgID: order.orgID,
      body: values
    };

    mutate(body, {
      onSuccess: (res) => {
        close();
        setOrder(res);
      }
    });
  };

  return (
    <Modal open={true} close={close} title="Create Customer Support Override" className="createCsOverrideModal">
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, setFieldValue }: any) => (
          <Form>
            {fields.map(({ label, keyName }) => (
              <div key={keyName} className="switchBtn" onClick={() => setFieldValue(keyName, !values[keyName])}>
                <p>{label}</p>
                <div className={isActiveClass('buffer', values[keyName])} />
              </div>
            ))}
            <div className="field">
              <label>Description</label>
              <Field name="description" as="textarea" placeholder="Write here..." />
            </div>
            <button type="submit" className="contained" disabled={isLoading}>
              Create Override
            </button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const fields = [
  { label: 'All Approvals Collected', keyName: 'allApprovalsCollected' },
  { label: 'All Orders Placed', keyName: 'allOrdersPlaced' },
  { label: 'All Orders Confirmed', keyName: 'allOrdersConfirmed' },
  { label: 'All Orders Shipped', keyName: 'allOrdersShipped' },
  { label: 'All Orders Delivered', keyName: 'allOrdersDelivered' },
  { label: 'All Items Have Packing Slips', keyName: 'allItemsHavePackingSlips' },
  { label: 'All Items Invoiced By Provider', keyName: 'allItemsInvoicedByProvider' },
  { label: 'All Items Invoiced By Faliam', keyName: 'allItemsInvoicedByFaliam' },
  { label: 'All Faliam Invoiced Paid', keyName: 'allFaliamInvoicedPaid' }
];
