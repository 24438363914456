import { useRef, useState } from 'react';
import { Form, Formik, Field } from 'formik';
import { DateRangePicker } from 'react-date-range';
import dayjs from 'dayjs';

import { DownArrowIcon } from 'assets/icons/DownArrow';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { Modal } from 'components/ui/Modal';
import { Select } from 'components/ui/Select';
import { CurrencyField } from 'components/ui/CurrencyField';
import { roundCurrencyValue } from 'util/mix';

const typeOptions = ['Fixed', 'Percentage', 'BuyXGetY', 'BulkPrice'];

interface Props {
  onSuccess: (data: any) => void;
  onClose: () => void;
}

interface FormikState {
  type: string;
  amount: number;
  buyX: number;
  getY: number;
  price: number;
  threshold: number;
  percentage: number;
  getYDiscount: number;
  startDate?: Date;
  endDate?: Date;
}

export const AddPromotionModal = ({ onSuccess, onClose }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);

  const ref = useRef(null);
  useOutsideClick(ref, () => isOpenDatePicker && setIsOpenDatePicker(false));

  const initialValues: FormikState = {
    type: 'Fixed',
    amount: 0,
    percentage: 0,
    threshold: 0,
    price: 0,
    buyX: 0,
    getY: 1,
    getYDiscount: 1,
    startDate: undefined,
    endDate: undefined
  };

  const onSubmit = (values: FormikState) => {
    let body: any = {};

    switch (values.type) {
      case 'Fixed':
        body = { amount: roundCurrencyValue(values.amount) };
        break;
      case 'Percentage':
        body = {
          percentage: values.percentage,
          start: dayjs(values.startDate).format('YYYY-MM-DD'),
          end: dayjs(values.endDate).format('YYYY-MM-DD')
        };
        break;
      case 'BuyXGetY':
        body = {
          buyX: values.buyX,
          getY: values.getY,
          getYDiscount: values.getYDiscount,
          start: dayjs(values.startDate).format('YYYY-MM-DD'),
          end: dayjs(values.endDate).format('YYYY-MM-DD')
        };
        break;
      case 'BulkPrice':
        body = {
          price: roundCurrencyValue(values.price),
          threshold: values.threshold,
          start: dayjs(values.startDate).format('YYYY-MM-DD'),
          end: dayjs(values.endDate).format('YYYY-MM-DD')
        };

        break;
    }

    if (!values.startDate || !values.endDate) {
      delete body.start;
      delete body.end;
    }

    const payload = {
      type: values.type,
      data: body
    };

    onSuccess(payload);
  };

  return (
    <Modal open={true} close={onClose} title="Add Promotion" className="promotionModal">
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, setFieldValue, setValues }) => (
          <Form>
            <Select
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              className="regular"
              options={
                <ul>
                  {typeOptions.map((el) => (
                    <li
                      key={el}
                      onClick={() => {
                        setFieldValue('type', el);
                        setIsOpen(false);
                      }}
                    >
                      {el}
                    </li>
                  ))}
                </ul>
              }
            >
              <p>{values.type}</p>
              <DownArrowIcon />
            </Select>
            {values.type === 'Fixed' ? (
              <CurrencyField name="amount" label="Amount" />
            ) : (
              <div className="field" ref={ref}>
                <label>Date range (optional)</label>
                <div className="fakeField" onClick={() => setIsOpenDatePicker((p) => !p)}>
                  {values.startDate && values.endDate
                    ? dayjs(values.startDate).format('YYYY-MM-DD') + ' - ' + dayjs(values.endDate).format('YYYY-MM-DD')
                    : ''}
                </div>
                {isOpenDatePicker && (
                  <div className="date-picker-relative">
                    <DateRangePicker
                      ranges={[{ key: 'selection', startDate: values.startDate, endDate: values.endDate }]}
                      onChange={({ selection }) =>
                        setValues({ ...values, startDate: selection.startDate, endDate: selection.endDate })
                      }
                    />
                  </div>
                )}
              </div>
            )}
            {values.type === 'Percentage' && (
              <div className="field">
                <label>Percentage</label>
                <Field type="number" name="percentage" />
              </div>
            )}
            {values.type === 'BulkPrice' && (
              <>
                <CurrencyField name="price" label="Price" />
                <div className="field">
                  <label>Threshold</label>
                  <Field type="number" name="threshold" />
                </div>
              </>
            )}
            {values.type === 'BuyXGetY' && (
              <>
                <div className="field">
                  <label>Buy X</label>
                  <Field type="number" name="buyX" />
                </div>
                <div className="field">
                  <label>Get Y</label>
                  <Field type="number" name="getY" />
                </div>
                <div className="field">
                  <label>Get Y Discount (%)</label>
                  <Field type="number" name="getYDiscount" />
                </div>
              </>
            )}
            <button type="submit" className="contained">
              Save Changes
            </button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
