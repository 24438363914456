import { Field, Form, Formik } from 'formik';
import { useMutation } from 'react-query';

import { InviteOrgUserPayload, Org } from 'api/orgs/types';

import { isActiveClass } from 'util/classes';
import { Modal } from 'components/ui/Modal';
import { inviteOrgUser } from 'api/orgs';
import { Offices } from './Offices';
import {
  AccountingRole,
  AdministratorRole,
  OfficeManagerRole,
  PurchasingTeamRole,
  RegionalPurchasingTeamRole,
  roles
} from 'util/data';
import { toast } from 'react-toastify';

interface Props {
  org: Org;
  close: () => void;
  refetchOrg: () => void;
}

export const InviteUserModal = ({ org, close, refetchOrg }: Props) => {
  const { mutate, isLoading } = useMutation((body: InviteOrgUserPayload) => inviteOrgUser(org.metadata.orgID, body));

  const onSubmit = (values: InviteOrgUserPayload) => {
    const payload = { ...values };

    switch (payload.role) {
      case AdministratorRole:
        payload.offices = [];
        break;
      case PurchasingTeamRole:
        if (values.offices.length === org.offices.length) {
          payload.offices = [];
        } else {
          payload.role = RegionalPurchasingTeamRole;
          payload.offices = values.offices;
        }
        break;
      case OfficeManagerRole:
        payload.offices = values.offices;
        break;
      case AccountingRole:
        payload.offices = [];
        break;
      default:
        throw toast('invalid role', { type: 'error' });
    }

    mutate(payload, {
      onSuccess() {
        close();
        refetchOrg();
      }
    });
  };

  return (
    <Modal open={true} close={close} title="Invite User" className="inviteOrgUserModal">
      <Formik initialValues={{ email: '', role: AdministratorRole, offices: [] }} onSubmit={onSubmit}>
        {({ values, setFieldValue }: any) => (
          <Form>
            <div className="field">
              <label>Invite by email</label>
              <Field type="email" name="email" required />
            </div>
            <div className="field">
              <label>Choose Role</label>
              <div className="buttons">
                {roles.map((role) => (
                  <button
                    key={role}
                    type="button"
                    className={isActiveClass('outlined', role === values.role)}
                    onClick={() => {
                      setFieldValue('role', role);

                      if (role === 'PurchasingTeam') {
                        setFieldValue(
                          'offices',
                          org.offices.map((office) => office.officeID)
                        );
                      }
                    }}
                  >
                    {role}
                  </button>
                ))}
              </div>
            </div>
            {['OfficeManager', 'PurchasingTeam'].includes(values.role) && <Offices offices={org.offices} />}
            <button type="submit" className="contained mt24" disabled={isLoading}>
              Send Invite
            </button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
