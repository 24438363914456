import { useEffect, useRef, useState } from 'react';
import orderBy from 'lodash/orderBy';

import { DraftOrder } from 'api/orgs/types';
import { HandleCellOrders } from 'types/table.types';

import { formatDate } from 'util/dates';
import { convertPrice } from 'util/prices';
import { numberOfSuppliers } from 'util/orders';
import { formatOfficeAddress } from 'util/offices';
import useTableData from 'hooks/useTableData';
import { Table } from 'components/shared/table';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { OrderItemsReviewer } from 'components/shared/OrderItemsReviewer';
import { OrderItemsSuppliersModal } from 'components/shared/orgs/OrderItemsSuppliersModal';
import { TableDropdownCell } from 'components/shared/table/TableDropdownCell';
import { getPromotion } from 'types/promotions.types';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { DeclineDraftOrderModal } from './DeclineDraftOrderModal';

interface Props {
  draftOrders: DraftOrder[];
  refetch: () => any;
}

export const DraftOrdersTable = ({ draftOrders, refetch }: Props) => {
  const [data, setData] = useState(draftOrders);
  const [selectedCell, setSelectedCell] = useState('');
  const [activeSupplierModal, setActiveSupplierModal] = useState<DraftOrder | null>(null);
  const [activeItemsModal, setActiveItemsModal] = useState<DraftOrder | null>(null);
  const [declineModal, setDeclineModal] = useState<DraftOrder | null>(null);

  const { meta, tableData } = useTableData({ allData: data });

  const allData = orderBy(
    draftOrders.map((item) => ({
      ...item,
      total: item.items.reduce((acc, item) => {
        const price = item.price.customSellingPrice || item.price.sellingPrice;
        const promo: any = item.price.promotions?.[0];
        const promoPrice = promo ? getPromotion(promo.type, promo.data).calculateTotal(price) : 0;
        const activePrice = promo ? promoPrice : price;
        const res = acc + item.quantity * activePrice;

        return res;
      }, 0)
    })),
    ['total'],
    ['desc']
  );

  const dotsRef = useRef(null);
  useOutsideClick(dotsRef, () => selectedCell && setSelectedCell(''));

  useEffect(() => {
    setData(allData);
  }, [draftOrders]);

  const handleCellOrders = ({ cell, direction }: HandleCellOrders) => {
    let res: any[] = [];

    switch (cell) {
      case 'Placed On':
        res = orderBy(draftOrders, 'created', direction);
        break;
      case 'Order #':
        res = orderBy(draftOrders, 'orderID', direction);
        break;
      case 'Office Name':
        res = orderBy(draftOrders, (el) => el.office.name, direction);
        break;
      case 'Address':
        res = orderBy(draftOrders, (el) => formatOfficeAddress(el.office), direction);
        break;
      case '# Suppliers':
        res = orderBy(draftOrders, (el) => numberOfSuppliers(el.items), direction);
        break;
      case '# Items':
        res = orderBy(draftOrders, (el) => el.items.length, direction);
        break;
      case 'Subtotal':
        res = orderBy(draftOrders, 'subtotal', direction);
        break;
      default:
        res = data;
        break;
    }

    setData(res);
  };

  return (
    <>
      <div className="space" />
      <Table
        name="OrgDraftOrders"
        title="Draft Orders"
        nav={[
          'Placed On',
          'Order #',
          'Office Name',
          'Address',
          '# Suppliers',
          '# Items',
          'Subtotal',
          'Total',
          'Status',
          ''
        ]}
        allData={allData}
        handleCellOrders={handleCellOrders}
        setData={setData}
        searchKeys={['orderID', 'office.name']}
        meta={meta}
        content={
          <div className="tableBody customScrollbar">
            {tableData.map((draftOrder) => {
              return (
                <div key={draftOrder.orderID} className="tableRow">
                  <TableCellTooltip content={formatDate(draftOrder.created)} />
                  <TableCellTooltip content={draftOrder.orderID} />
                  <TableCellTooltip content={draftOrder.office.name} />
                  <TableCellTooltip content={formatOfficeAddress(draftOrder.office)} />
                  <TableCellTooltip content={numberOfSuppliers(draftOrder.items)}>
                    <span className="linked" onClick={() => setActiveSupplierModal(draftOrder)}>
                      {numberOfSuppliers(draftOrder.items)}
                    </span>
                  </TableCellTooltip>
                  <TableCellTooltip content={draftOrder.items.length}>
                    <span className="linked" onClick={() => setActiveItemsModal(draftOrder)}>
                      {draftOrder.items.length}
                    </span>
                  </TableCellTooltip>
                  <TableCellTooltip content={convertPrice(draftOrder.subtotal)} />
                  <TableCellTooltip content={convertPrice(draftOrder.total)} />
                  <div className="tableCell">
                    {draftOrder.declined && <div className="badge orderStatusBG danger">Declined</div>}
                  </div>
                  {draftOrder.declined ? (
                    <div className="tableCell" />
                  ) : (
                    <TableDropdownCell
                      id={draftOrder.orderID}
                      active={selectedCell}
                      onClick={setSelectedCell}
                      ref={dotsRef}
                    >
                      <li className="danger" onClick={() => setDeclineModal(draftOrder)}>
                        Decline Draft Order
                      </li>
                    </TableDropdownCell>
                  )}
                </div>
              );
            })}
          </div>
        }
      />
      {activeSupplierModal && (
        <OrderItemsSuppliersModal order={activeSupplierModal} close={() => setActiveSupplierModal(null)} />
      )}
      {activeItemsModal && (
        <OrderItemsReviewer items={activeItemsModal.items} close={() => setActiveItemsModal(null)} />
      )}
      {declineModal && (
        <DeclineDraftOrderModal data={declineModal} onClose={() => setDeclineModal(null)} refetch={refetch} />
      )}
    </>
  );
};
