export const FaliamLogoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="128"
    height="26"
    fill="none"
    viewBox="0 0 128 26"
    className="faliamLogoIcon"
  >
    <path
      fill="#000"
      d="M46.498.19c1.256 0 2.254 1.027 2.254 2.322 0 1.294-.998 2.284-2.255 2.284-1.256 0-2.217-.99-2.217-2.284 0-1.295.96-2.323 2.218-2.323zM0 6.966h4.103V0h9.092v3.502h-5.36v3.464h4.288v3.465H7.836v15.226H4.103V10.431H0V6.966zM22.438 6.58c1.848 0 3.252.685 4.213 1.409.555.418 1.072.875 1.442 1.37h.184V6.961h3.734v18.69h-3.734v-2.55h-.184a7.536 7.536 0 01-1.442 1.447c-.96.723-2.365 1.447-4.213 1.447-4.805 0-8.539-3.845-8.539-9.708 0-5.862 3.734-9.707 8.539-9.707zm.702 15.95c3.03 0 5.137-2.17 5.137-6.243s-2.143-6.243-5.137-6.243c-3.253 0-5.323 2.17-5.323 6.243s2.107 6.243 5.323 6.243zM40.03 0h-3.734v25.62h3.733V0zM48.382 6.965h-3.734v18.691h3.733V6.965zM60.1 6.58c1.847 0 3.252.685 4.213 1.409.554.418 1.072.875 1.441 1.37h.185V6.961h3.733v18.69H65.94v-2.55h-.185a7.536 7.536 0 01-1.441 1.447c-.961.723-2.366 1.447-4.214 1.447-4.805 0-8.538-3.845-8.538-9.708 0-5.862 3.733-9.707 8.538-9.707zm.701 15.95c3.031 0 5.138-2.17 5.138-6.243s-2.144-6.243-5.138-6.243c-3.252 0-5.322 2.17-5.322 6.243s2.107 6.243 5.322 6.243zM73.961 6.973h3.734v2.551h.184c.333-.533.703-1.028 1.22-1.447.85-.723 2.144-1.446 4.14-1.446 2.328 0 3.77.875 4.657 1.751a6.017 6.017 0 011.22 1.713h.184c.333-.61.777-1.218 1.405-1.713.961-.876 2.513-1.751 4.842-1.751 4.14 0 6.764 2.703 6.764 7.88v11.154h-3.733V14.663c0-3.121-1.405-4.568-3.918-4.568-2.44 0-4.62 2.208-4.62 5.291v10.24h-3.733V14.664c0-3.121-1.405-4.568-3.918-4.568-2.44 0-4.62 2.208-4.62 5.291v10.24h-3.734V6.974h-.074z"
    ></path>
    <path
      fill="#5AEACF"
      d="M120.901 7v6.395h1.109c2.809 0 5.1-2.36 5.1-5.253V7h-6.209zM114.655 19.794h1.109c2.809 0 5.101-2.36 5.101-5.254v-1.142h-6.21v6.396zM120.9 1.751V.61h-6.209v6.396h1.109c2.809 0 5.1-2.36 5.1-5.254zM114.655 7h-6.21v6.395h1.109c2.809 0 5.101-2.36 5.101-5.253V7z"
    ></path>
  </svg>
);
