import { ItemWithBoxes } from './ProviderInvoiceForm';

interface Props {
  itemsWithBoxes: ItemWithBoxes[];
  setItemsWithBoxes: React.Dispatch<React.SetStateAction<ItemWithBoxes[]>>;
}

export const useToggles = ({ itemsWithBoxes, setItemsWithBoxes }: Props) => {
  // Find index of item in itemsWithBoxes array
  const findIndexOfItem = (el: ItemWithBoxes) => {
    const cloned = [...itemsWithBoxes];
    const index = cloned.findIndex(
      ({ item }) =>
        item.item.objectID === el.item.item.objectID &&
        item.price.provider === el.item.price.provider &&
        item.price.buyingPrice === el.item.price.buyingPrice
    );

    return { cloned, index };
  };

  // Toggle all items
  const onToggleAll = () => {
    const cloned = [...itemsWithBoxes];
    const isChecked = cloned.every((el) => el.isChecked);

    cloned.forEach((el) => {
      const item = {
        ...el,
        isChecked: !isChecked,
        boxes: el.boxes.map((box) => ({ ...box, isChecked: !isChecked }))
      };
      const { index } = findIndexOfItem(el);

      cloned[index] = item;
    });

    setItemsWithBoxes(cloned);
  };

  // Toggle collapse of item
  const onToggleCollapse = (itemWithBoxes: ItemWithBoxes) => {
    const { cloned, index } = findIndexOfItem(itemWithBoxes);
    const item = { ...cloned[index], isCollapsed: !cloned[index].isCollapsed };

    cloned[index] = item;

    setItemsWithBoxes(cloned);
  };

  // Toggle item (checkbox)
  const onToggleItem = (itemWithBoxes: ItemWithBoxes) => {
    const { cloned, index } = findIndexOfItem(itemWithBoxes);
    const item = {
      ...cloned[index],
      isChecked: !cloned[index].isChecked,
      boxes: cloned[index].boxes.map((box) => ({
        ...box,
        isChecked: !cloned[index].isChecked
      }))
    };

    cloned[index] = item;

    setItemsWithBoxes(cloned);
  };

  // Toggle box (checkbox)
  const onToggleBox = (itemWithBoxes: ItemWithBoxes, box: ItemWithBoxes['boxes'][0]) => {
    const { cloned, index: itemIndex } = findIndexOfItem(itemWithBoxes);
    const boxIndex = cloned[itemIndex].boxes.findIndex((b) => b.id === box.id);
    const clonedBox = {
      ...cloned[itemIndex].boxes[boxIndex],
      isChecked: !cloned[itemIndex].boxes[boxIndex].isChecked
    };

    cloned[itemIndex].boxes[boxIndex] = clonedBox;

    setItemsWithBoxes(cloned);
  };

  const onAddNewItemBox = (el: ItemWithBoxes) => {
    const { item, boxes } = el;
    const newBox: ItemWithBoxes['boxes'][0] = {
      id: `${item.item.objectID}:${boxes.length}`,
      status: boxes[0].status,
      price: boxes[0].price,
      isChecked: true
    };
    const { cloned, index } = findIndexOfItem(el);

    cloned[index].boxes.push(newBox);

    setItemsWithBoxes(cloned);
  };

  return {
    findIndexOfItem,
    onAddNewItemBox,
    onToggleAll,
    onToggleCollapse,
    onToggleItem,
    onToggleBox
  };
};
