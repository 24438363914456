import { AppRouter } from 'routes';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Login } from './pages/login';
import { isActiveClass } from 'util/classes';
import { getProviders } from 'api/providers';
import { Sidebar } from 'components/shared/Sidebar';
import { providersKeys } from 'api/providers/queries';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useProvidersStrore } from 'stores/ProvidersStore';
import { getStorageItem, setStorageItem } from 'util/localStorage';

export const App = () => {
  const [isLogged, setIsLogged] = useState((getStorageItem('isLogged') as boolean) || false);

  const { authStatus } = useAuthenticator();
  const { setProviders } = useProvidersStrore();
  const [isSidebarExpand, setIsSidebarExpand] = useState(false);

  useQuery(providersKeys.list(), getProviders, { onSuccess: (res) => setProviders(res) });

  useEffect(() => {
    if (authStatus === 'authenticated') {
      setStorageItem('isLogged', true);
      setIsLogged(true);
    }
    // eslint-disable-next-line
  }, [authStatus]);

  return (
    <>
      {isLogged ? (
        <main className={isActiveClass('pageWrapper', isSidebarExpand)}>
          <BrowserRouter>
            <Sidebar
              setIsLogged={setIsLogged}
              isExpand={isSidebarExpand}
              toggleExpand={() => setIsSidebarExpand((p) => !p)}
            />
            <AppRouter />
          </BrowserRouter>
        </main>
      ) : (
        <Login />
      )}
    </>
  );
};
