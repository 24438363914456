import { useState } from 'react';
import { useQuery } from 'react-query';

import { fethAutoCartQueueJobs } from 'api/jobs';
import { SearchIcon } from 'assets/icons/SearchIcon';
import { LoaderIcon } from 'assets/icons/LoaderIcon';
import { AutoCartQueueTable } from './AutoCartQueueTable';

export const AutoCartQueueJobsTables = () => {
  const { data, isLoading, refetch } = useQuery(['autoCartQueueJobs'], fethAutoCartQueueJobs);
  const [inputValue, setInputValue] = useState('');

  if (isLoading) return <LoaderIcon />;

  return (
    <div className="autoCartQueueTables">
      <div className="sectionHeader">
        <h1>Auto Cart Order Queue</h1>
        <div className="tableSearch">
          <SearchIcon />
          <input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Search by Organization Name"
          />
        </div>
        <button type="button" className="outlined" onClick={() => refetch()}>
          Manual Refresh
        </button>
      </div>

      {data ? (
        <div className="tables">
          {Object.entries(data)
            .filter(([orgName]) => orgName.toLocaleLowerCase().trim()?.includes(inputValue.toLocaleLowerCase().trim()))
            .map(([orgName, orgData]) => (
              <div key={orgName} className="paper">
                <AutoCartQueueTable orgName={orgName} data={orgData} refetch={refetch} />
              </div>
            ))}
        </div>
      ) : (
        <div className="centeredContainer">No Data to display</div>
      )}
    </div>
  );
};
