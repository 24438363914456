import { useState } from 'react';
import orderBy from 'lodash/orderBy';

import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { RequiresOpsActionItem } from 'types/order.types';

import { TableCellTooltip } from 'components/shared/table/TableCell';
import { NestedTable } from 'components/shared/table/NestedTable';
import { DownArrowIcon } from 'assets/icons/DownArrow';
import { useOrderStore } from 'stores/OrderStore';
import { DragIcon } from 'assets/icons/DragIcon';
import { Table } from 'components/shared/table';
import useTableData from 'hooks/useTableData';

interface Props {
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

export const RequiresOpsAction = ({ dragHandleProps }: Props) => {
  const [collapsed, setCollapsed] = useState<string[]>([]);
  const [nestedCollapsed, setNestedCollapsed] = useState<string[]>([]);
  const { order } = useOrderStore();

  const data = orderBy(
    Object.entries(order.orderOverview.RequiresOpsAction || {}).map(([supplier, item]) => ({
      ...item,
      supplier
    })),
    (el) => el.supplier.toLowerCase()
  );
  const { meta, tableData } = useTableData({ allData: data });
  const toggleCollapse = (id: string) => setCollapsed((p) => (p.includes(id) ? p.filter((e) => e !== id) : [...p, id]));
  const toggleNestedCollapse = (id: string) =>
    setNestedCollapsed((p) => (p.includes(id) ? p.filter((e) => e !== id) : [...p, id]));
  const activeClass = (field: string) => (field === 'Yes' ? 'successCell' : 'dangerCell');

  return (
    <Table
      name="RequiresOpsAction"
      title={
        <>
          <span {...dragHandleProps}>
            <DragIcon />
          </span>
          Requires Ops Action
        </>
      }
      nav={[
        'Seller',
        'Seller Status',
        '# Items',
        'QTY',
        'PO',
        'Confirmation #',
        'Tracking #',
        'Invoice #',
        'BO Items',
        'FI Required',
        'FI Status',
        'Last Updated',
        ''
      ]}
      meta={meta}
      allData={data}
      emptyMessage="Order has all needed documents, no ops action items remain"
      content={
        <div className="tableBody nested">
          {tableData.map((item: RequiresOpsActionItem) => (
            <div key={item.supplier} className="groupWithChildren">
              <div className="tableRow">
                <TableCellTooltip content={item.supplier} />
                <TableCellTooltip
                  content={item.SellerStatus.split('_')
                    .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
                    .join(' ')}
                />
                <TableCellTooltip content={Object.keys(item.StackDetails).length} />
                <TableCellTooltip content={item.Qty} />
                <TableCellTooltip content={item.PO} className={activeClass(item.PO)} />
                <TableCellTooltip content={item.ConfirmationNum} className={activeClass(item.ConfirmationNum)} />
                <TableCellTooltip content={item.TrackingNum} className={activeClass(item.TrackingNum)} />
                <TableCellTooltip content={item.SupplierInvoice} className={activeClass(item.SupplierInvoice)} />
                <TableCellTooltip content={item.BoItems} className={activeClass(item.BoItems)} />
                <TableCellTooltip content={item.FaliamInvoice} />
                <TableCellTooltip
                  content={item.FaliamInvoiceStatus}
                  className={`${item.FaliamInvoiceStatus !== 'N/A' ? 'successCell' : 'dangerCell'}`}
                />
                <TableCellTooltip content={item.LastUpdated} />
                <div className="tableCell">
                  <div className="tableDownArrow" onClick={() => toggleCollapse(item.supplier)}>
                    <DownArrowIcon />
                  </div>
                </div>
              </div>
              {collapsed.includes(item.supplier) && (
                <NestedTable
                  className="tableRequiresOpsActionItems"
                  nav={[
                    '#',
                    'Item',
                    'Seller SKU',
                    'MFR Code',
                    'Item Status',
                    'QTY',
                    'PO',
                    'Conf. #',
                    'Tracking #',
                    'Invoices',
                    'On BO',
                    ''
                  ]}
                >
                  {Object.values(item.StackDetails).map((el, itemIndex) => (
                    <div key={`${item.supplier}:${el.SellerSku}`}>
                      <div className="tableRow">
                        <TableCellTooltip content={`${itemIndex + 1}.`} />
                        <TableCellTooltip content={el.ItemTitle} />
                        <TableCellTooltip content={el.SellerSku} />
                        <TableCellTooltip content={el.MfrCode} />
                        <TableCellTooltip
                          content={el.StackStatus.split('_')
                            .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
                            .join(' ')}
                        />
                        <TableCellTooltip content={el.Qty} />
                        <TableCellTooltip content={el.PO} className={activeClass(el.PO)} />
                        <TableCellTooltip content={el.ConfirmationNum} className={activeClass(el.ConfirmationNum)} />
                        <TableCellTooltip content={el.TrackingNum} className={activeClass(el.TrackingNum)} />
                        <TableCellTooltip content={el.SupplierInvoice} className={activeClass(el.SupplierInvoice)} />
                        <TableCellTooltip content={el.OnBO} className={activeClass(el.OnBO)} />
                        <div className="tableCell">
                          <div
                            className="tableDownArrow"
                            onClick={() => toggleNestedCollapse(`${item.supplier}:${el.SellerSku}`)}
                          >
                            <DownArrowIcon />
                          </div>
                        </div>
                      </div>

                      {nestedCollapsed.includes(`${item.supplier}:${el.SellerSku}`) && (
                        <NestedTable
                          className="tableRequiresOpsActionBoxes"
                          nav={['Box #', 'Box Status', 'Conf. #', 'Carrier', 'Tracking #', 'Invoice #']}
                        >
                          {Object.values(el.BoxDetails).map((box, itemIndex) => (
                            <div key={`${el.SellerSku}:${box.BoxStatus}`}>
                              <div className="tableRow">
                                <TableCellTooltip content={`${itemIndex + 1}.`} />
                                <TableCellTooltip
                                  content={box.BoxStatus.split('_')
                                    .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
                                    .join(' ')}
                                />
                                <TableCellTooltip content={box.POConf_num} />
                                <TableCellTooltip content={box.Carrier} />
                                <TableCellTooltip content={box.TrackingNum} />
                                <TableCellTooltip content={box.SupplierInvoiceNum} />
                              </div>
                            </div>
                          ))}
                        </NestedTable>
                      )}
                    </div>
                  ))}
                </NestedTable>
              )}
            </div>
          ))}
        </div>
      }
    />
  );
};
