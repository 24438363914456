import { toast } from 'react-toastify';

import { apiCall, BASE_URL_VARIANTS } from 'api';

import { ProductPayload, UpdateProductPayload } from './types';

export const fetchProduct = async (variantID: string) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    const { data } = await request({ url: `/variants/${variantID}`, method: 'GET' });

    return {
      ...data,
      skus: data.skus.map((sku: any) => ({ ...sku, createdAt: new Date(sku.createdAt), updatedAt: new Date(sku.updatedAt) })),
    } as ProductPayload;
  } catch (error) { }
};

export const createProduct = async (body: ProductPayload) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    await request({ data: body, url: `/variants`, method: 'POST' });

    toast.success('Product has been created.');
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

export const updateProduct = async ({ variantID, body }: UpdateProductPayload) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    await request({ data: body, url: `/variants/${variantID}`, method: 'PATCH' });

    toast.success('Product has been updated.');
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};
