import { useState } from 'react';

import { UploadGpoPrices } from 'api/gpo/types';
import { Price, UploadData } from 'types/tableflow.types';

import { CSVDownload } from 'components/shared/CSVDownload';
import { convertPrice, dollarsToCents } from 'util/prices';
import { uploadGpoPrices } from 'api/gpo';
import { debounce } from 'lodash';
import { CSVImporter } from 'csv-import-react';

interface Props {
  gpoID: string;
}

export const UploadPrices = ({ gpoID }: Props) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [issues, setIssues] = useState<UploadGpoPrices['prices']>([]);

  const formattedData = issues.map((issue) => ({
    provider: issue.provider,
    sku: issue.sku,
    price: convertPrice(issue.price),
    error: issue.error
  }));

  const dataHandler = async (data: UploadData<Price>): Promise<void> => {
    // Alert on API issues
    setIssues([]);
    if (data.error) return alert(data.error);

    let body: UploadGpoPrices['prices'] = [];
    const issues: UploadGpoPrices['prices'] = [];
    let i = -1;

    for (const row of data.rows) {
      i++;
      body.push({
        provider: row.values.supplier,
        sku: row.values.sku,
        price: dollarsToCents(row.values.price)
      });
      // Update prices every 1000 rows or at the end of the file
      if (body.length === 1000 || i === data.rows.length - 1) {
        const rsp = await uploadGpoPrices({ gpoID, prices: body });
        if (rsp.length > 0) issues.push(...rsp);
        body = [];
      }
    }

    setIssues(issues);
  };

  const onComplete: any = debounce(dataHandler, 500);

  return (
    <>
      <button className="outlined lg" onClick={() => setIsOpenModal(true)}>
        Upload Prices
      </button>
      <CSVImporter
        isModal={true}
        modalIsOpen={isOpenModal}
        modalOnCloseTriggered={() => setIsOpenModal(false)}
        onComplete={onComplete}
        primaryColor="#36bebc"
        modalCloseOnOutsideClick={true}
        template={{
          columns: [
            {
              "id": "95d0f74b-8191-489f-a134-527050632cee",
              "name": "Supplier",
              "key": "supplier",
              "required": true,
              "data_type": "string",
              "description": "",
              "suggested_mappings": []
            },
            {
              "id": "a1475594-5d11-44f8-8d71-56628d9ca6c3",
              "name": "SKU",
              "key": "sku",
              "required": true,
              "data_type": "string",
              "description": "",
              "suggested_mappings": []
            },
            {
              "id": "b8439f08-2a5b-4e6e-8188-be21e6718990",
              "name": "Price",
              "key": "price",
              "required": true,
              "data_type": "string",
              "description": "",
              "suggested_mappings": []
            }
          ]
        }}
      />
      {issues.length ? <CSVDownload data={formattedData} filename="price_upload_issues.csv" /> : null}
    </>
  );
};
