import { useState } from 'react';
import { useClearRefinements, useSearchBox } from 'react-instantsearch-hooks';

import { CsvButton } from './Csv';
import { DatePicker } from './Date';
import { DeliveryStatuses } from './DeliveryStatuses';
import { PaymentStatuses } from './PaymentStatuses';
import { Orgs } from './Orgs';

export const Filters = () => {
  const [date, setDate] = useState<Date | null>(null);
  const [payment, setPayment] = useState<string | null>(null);

  const { refine } = useClearRefinements();
  const { refine: refineSearch } = useSearchBox();

  const clearAllFilters = () => {
    setDate(null);
    setPayment(null);
    refine();
    refineSearch('');
  };

  return (
    <div className="flexBetween filters">
      <div className="buttons">
        <CsvButton />
        <DatePicker date={date} setDate={setDate} />
        <DeliveryStatuses />
        <PaymentStatuses payment={payment} setPayment={setPayment} />
        <Orgs />
      </div>
      <button className="btn outlined lg auto-width" onClick={clearAllFilters}>
        Clear Filters
      </button>
    </div>
  );
};
