import { useEffect, useState, useRef } from 'react';

import { fetchManufacturers, updateManufacturers } from 'api/manufacturers';
import Editor from 'react-simple-code-editor';
import ReactDiffViewer from 'react-diff-viewer-continued';
import { toast } from 'react-toastify';
import { ManufacturerConfig, normalize, validate } from 'api/manufacturers/types';

type EditorState = 'reading' | 'editing' | 'confirming' | 'updating';

export const ManufacturersPage = () => {
  const [originalValid, setOriginalValid] = useState('');
  const [originalMapping, setOriginalMapping] = useState('');
  const [valid, setValid] = useState('');
  const [mapping, setMapping] = useState('');
  const [editorState, setEditorState] = useState<EditorState>('reading');
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    const errs: string[] = [];
    if (!['confirming', 'updating'].includes(editorState)) {
      return;
    }

    const config: ManufacturerConfig = {
      validManufacturers: [],
      manufacturerMapping: new Map()
    };

    try {
      config.validManufacturers = JSON.parse(valid);
    } catch (e) {
      errs.push('Invalid JSON in valid manufacturers');
    }

    try {
      config.manufacturerMapping = new Map(Object.entries(JSON.parse(mapping)));
    } catch (e) {
      errs.push('Invalid JSON in manufacturer mapping');
    }

    if (errs.length > 0) {
      setErrors(errs);
      return;
    }

    normalize(config);
    setErrors(Array.from(new Set(validate(config))));
  }, [editorState]);

  const validEditor = useRef<any>(null);
  useEffect(() => {
    if (validEditor.current) {
      validEditor.current.session = {
        history: {
          stack: [],
          offset: -1
        }
      };
    }
  }, [validEditor]);

  const mappingEditor = useRef<any>(null);
  useEffect(() => {
    if (mappingEditor.current) {
      mappingEditor.current.session = {
        history: {
          stack: [],
          offset: -1
        }
      };
    }
  }, [mappingEditor]);

  useEffect(() => {
    fetchManufacturers()
      .then((data) => {
        setOriginalMapping(JSON.stringify(data.manufacturerMapping, null, 2));
        setOriginalValid(JSON.stringify(data.validManufacturers, null, 2));
        setMapping(JSON.stringify(data.manufacturerMapping, null, 2));
        setValid(JSON.stringify(data.validManufacturers, null, 2));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const onEditClick = () => {
    if (editorState === 'reading') {
      setEditorState('editing');
    } else {
      setEditorState('confirming');
    }
  };

  const onConfirm = () => {
    setEditorState('updating');
    // Update manufacturer config
    const body = {
      manufacturerMapping: JSON.parse(mapping),
      validManufacturers: JSON.parse(valid)
    };
    updateManufacturers(body)
      .then((data) => {
        toast.success('Changes saved successfully');
        setMapping(JSON.stringify(data.manufacturerMapping, null, 2));
        setValid(JSON.stringify(data.validManufacturers, null, 2));
        setEditorState('reading');
      })
      .catch((err) => {
        toast.error('Failed to save changes - check console logs for more information');
        console.error(err);
        setEditorState('confirming');
      });
  };
  const onCancel = () => {
    setEditorState('editing');
  };

  return (
    <div className="manufacturersPage">
      <div className="paper">
        <div className="paperHeader">
          <div className="Hero">
            <button className="contained lg auto-width" disabled={editorState === 'updating'} onClick={onEditClick}>
              {editorState === 'reading' ? 'Edit' : 'Save'}
            </button>
          </div>
        </div>
        <div className="paperBody">
          <h1>Valid Manufacturers</h1>
          <div className="codeWrapper">
            <Editor
              ref={validEditor}
              value={valid}
              onValueChange={(valid: string) => setValid(valid)}
              highlight={(valid: string) => valid}
              padding={10}
              tabSize={2}
              readOnly={editorState !== 'editing'}
            />
          </div>
          <h1>Manufacturer Mapping</h1>
          <div className="codeWrapper">
            <Editor
              ref={mappingEditor}
              value={mapping}
              onValueChange={(mapping: string) => setMapping(mapping)}
              highlight={(mapping: string) => mapping}
              padding={10}
              tabSize={2}
              readOnly={editorState !== 'editing'}
            />
          </div>
        </div>
      </div>
      {['confirming', 'updating'].includes(editorState) && (
        <div className="confirmModal">
          <div className="confirmHero">
            <h2>Publish Changes?</h2>
            <div className="right">
              <button className="outlined lg" onClick={onCancel} disabled={editorState === 'updating'}>
                Cancel
              </button>
              <button
                disabled={errors.length > 0 || editorState === 'updating'}
                className="contained lg"
                onClick={onConfirm}
              >
                Publish
              </button>
            </div>
          </div>
          <div className="confirmContent">
            {errors.length > 0 && (
              <div className="diffSection">
                <h3>Issues</h3>
                <ul className="errors">
                  {errors.map((error, i) => (
                    <li key={i}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className="diffSection">
              <h3>Valid Manufacturer Changes</h3>
              <ReactDiffViewer oldValue={originalValid} newValue={valid} splitView={true} />
            </div>
            <div className="diffSection">
              <h3>Manufacturer Mapping Changes</h3>
              <ReactDiffViewer oldValue={originalMapping} newValue={mapping} splitView={true} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
