import { useRefinementList } from 'react-instantsearch-hooks';

import ReactSelect from 'components/ui/ReactSelect';

const formatSubcategoryName = (subcategory: string) => {
  const text = subcategory.split('>');
  return text.length > 1 ? text[1].trim() : text;
};

export const SubcategoryFilter = () => {
  const { items, refine } = useRefinementList({ attribute: 'categories.lvl1', limit: 100000 });
  const activeValue = items.find(({ isRefined }) => isRefined)?.value || '';
  const options = items.map(({ label, value }) => ({ label: formatSubcategoryName(label), value }));

  return (
    <ReactSelect
      placeholder="Subcategory"
      options={options}
      value={options.find(({ value }) => value === activeValue) || null}
      onChange={({ value }: any) => {
        if (activeValue) refine(activeValue);
        if (value !== activeValue) refine(value);
      }}
    />
  );
};
