import { Configure, InstantSearch } from 'react-instantsearch-hooks';

import { Org } from 'api/orgs/types';
import { MoreItemsState } from './AlgoliaHits';

import { algoliaKeys, searchClient } from 'lib/algolia/aloglia-config';
import { Container } from './container';

export interface MoreItemsProps {
  org?: Org;
  addedItems: MoreItemsState[];
  setAddedItems: React.Dispatch<React.SetStateAction<MoreItemsState[]>>;
  callback: (
    items: MoreItemsState[],
    newItems: MoreItemsState[],
    op: 'create' | 'delete'
  ) => void;
  isFormulary?: boolean;
}

export const MoreItems = (props: MoreItemsProps) => (
  <div className="moreItems">
    <InstantSearch
      indexName={algoliaKeys.indexName.regular}
      searchClient={searchClient}
    >
      <Configure filters="NOT unavailable:true" />
      <Container {...props} />
    </InstantSearch>
  </div>
);
