import { useState } from 'react';
import { useMutation } from 'react-query';
import { Formik, Form } from 'formik';
import dayjs from 'dayjs';

import { CreateBackOrderPayload } from 'api/purchaseOrders/types';
import { OrderItem } from 'types/order.types';

import { ProvidersSelect } from 'pages/order/purchaseOrders/form/ProvidersSelect';
import { CreateBackOrderTable } from './CreateBackOrderTable';
import { createBackOrder } from 'api/purchaseOrders';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { useOrderStore } from 'stores/OrderStore';
import { groupOrdersBySupplier } from 'util/mix';

interface Props {
  close: () => void;
}

export type BackOrderType = OrderItem & {
  appliedQty: number;
  isChecked: boolean;
  eta: string | Date;
};

interface FormikState {
  provider: string;
}

export const CreateBackOrder = ({ close }: Props) => {
  const [items, setItems] = useState<BackOrderType[]>([]);

  const initialValues: FormikState = { provider: '' };

  const { order, setOrder } = useOrderStore();
  const { mutate, isLoading } = useMutation(createBackOrder);

  const onSubmit = ({ provider }: FormikState) => {
    const payload: CreateBackOrderPayload = {
      orgID: order.orgID,
      orderID: order.orderID,
      body: {
        items: items
          .filter((el) => el.isChecked)
          .map((item) => {
            const res: CreateBackOrderPayload['body']['items'][0] = {
              provider,
              quantity: item.appliedQty,
              variantID: item.item.objectID
            };

            if (item.eta) res.expectedDeliveryDate = dayjs(item.eta).format('YYYY-MM-DD');

            return res;
          })
      }
    };

    mutate(payload, {
      onSuccess: (res) => {
        close();
        setOrder(res);
      }
    });
  };

  return (
    <div className="orderFullScreenModal">
      <div className="paper">
        <div className="paperHeader">
          <h2>Create New Backorder</h2>
          <CloseIcon className="closeIcon" onClick={close} />
        </div>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, setFieldValue }) => (
            <Form>
              <div className="paperBody">
                <div className="gridX2">
                  <ProvidersSelect
                    isMultipleSelect={false}
                    providers={groupOrdersBySupplier(order.items).map(([s]) => s)}
                    onChange={(p: string) => setFieldValue('provider', p)}
                    items={(p: string) => order.items.filter((el: any) => el.price.provider === p)}
                    variant="backedOrder"
                    allItems={items}
                  />
                  <div className="gridx3">
                    <div className="field">
                      <label># BO Items</label>
                      <input readOnly value={items.filter((i) => i.isChecked).length} />
                    </div>
                    <div className="field">
                      <label>BO Date Known</label>
                      <input readOnly value={items.filter((i) => i.isChecked && i.eta).length} />
                    </div>
                    <div className="field">
                      <label>BO Date Unknown</label>
                      <input readOnly value={items.filter((i) => i.isChecked && !i.eta).length} />
                    </div>
                  </div>
                </div>
                <div className="mt24">
                  <CreateBackOrderTable order={order} provider={values.provider} items={items} setItems={setItems} />
                </div>
              </div>
              <div className="submitBtnHolder">
                <button className="contained" type="submit" disabled={isLoading}>
                  Create Backorder
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
