import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import ReactTooltip from 'react-tooltip';

import { AlgoliaOrder } from 'types/algolia.types';

import { adminSearchClient, algoliaKeys } from 'lib/algolia/aloglia-config';
import { convertOrdersToCSVformat } from 'util/csv';
import { CloudIcon } from 'assets/icons/CloudIcon';

export const CsvButton = () => {
  const [orders, setOrders] = useState<AlgoliaOrder[]>([]);

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    const i = adminSearchClient.initIndex(algoliaKeys.indexName.orders);
    const { hits } = await i.search('', { hitsPerPage: 10000 });
    setOrders(hits as AlgoliaOrder[]);
  };

  return (
    <>
      <button className="rounded" data-tip="Export CSV">
        <CSVLink data={convertOrdersToCSVformat(orders)} target="_blank">
          <CloudIcon />
        </CSVLink>
      </button>
      <ReactTooltip place="bottom" />
    </>
  );
};
