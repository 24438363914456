interface Props {
  value: string;
  error: boolean;
  setValue: (value: string) => void;
  setError: (error: boolean) => void;
}

export const StripeCardNameField = ({
  value,
  setValue,
  error,
  setError,
}: Props) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);
    setError(value.length ? false : true);
  };

  return (
    <>
      <div className="field">
        <label>Name on card</label>
        <input
          type="text"
          name="cardName"
          value={value}
          onChange={onChange}
          className={error ? 'invalid' : 'valid'}
        />
      </div>
      {error && <p className="fieldError">The filed is required</p>}
    </>
  );
};
