import Axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';

export const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const RETAIL_URL = process.env.REACT_APP_RETAIL_API_BASE_URL;
const RETAIL_PUBLIC_URL = process.env.REACT_APP_PUBLIC_RETAIL_API_BASE_URL;
const RETAIL_AUTH_URL = process.env.REACT_APP_RETAIL_AUTH_API_BASE_URL;
const RETAIL_SNAPSHOT_URL = process.env.REACT_APP_RETAIL_SNAPSHOT_BASE_URL;
const PROCUREMENT_URL = process.env.REACT_APP_PROCUREMENT_API_BASE_URL;

export enum BASE_URL_VARIANTS {
  PROCUREMENT = 'procurement-admin',
  RETAIL_PUBLIC = 'retail-public',
  RETAIL_AUTH = 'retail-auth-session',
  RETAIL = 'retail-admin',
  RETAIL_SNAPSHOT = 'retail-snapshot-admin'
}

const getBaseURL = (requestType: BASE_URL_VARIANTS | string) => {
  if (requestType === BASE_URL_VARIANTS.RETAIL && RETAIL_URL) return RETAIL_URL;

  if (requestType === BASE_URL_VARIANTS.PROCUREMENT && PROCUREMENT_URL) return PROCUREMENT_URL;

  if (requestType === BASE_URL_VARIANTS.RETAIL_PUBLIC && RETAIL_PUBLIC_URL) return RETAIL_PUBLIC_URL;

  if (requestType === BASE_URL_VARIANTS.RETAIL_AUTH && RETAIL_AUTH_URL) return RETAIL_PUBLIC_URL;

  if (requestType === BASE_URL_VARIANTS.RETAIL_SNAPSHOT && RETAIL_SNAPSHOT_URL) return RETAIL_SNAPSHOT_URL;

  return `${BASE_URL}/${requestType}`;
};

const apiCall = async (requestType: BASE_URL_VARIANTS | string, auth = true) => {
  const baseURL = getBaseURL(requestType);
  const axios = Axios.create({
    baseURL: baseURL,
    headers: { Accept: 'application/json' }
  });

  if (auth) {
    const session = await fetchAuthSession();
    const token = session.tokens?.idToken?.toString();
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  return axios;
};

export { apiCall, getBaseURL };
