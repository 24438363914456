import { StripeCardExpiryElementChangeEvent } from '@stripe/stripe-js';
import { CardExpiryElement } from '@stripe/react-stripe-js';

import { stripeStyle } from './styles';

export const StripeCardExpiryField = () => {
  const onChange = (e: StripeCardExpiryElementChangeEvent) => {
    if (e.complete) {
      const el: HTMLInputElement | null = document.querySelector(
        '.stripeCardCvcField input'
      );

      if (el) el.focus();
    }
  };

  return (
    <div className="field">
      <label>Exp. date</label>
      <CardExpiryElement
        options={stripeStyle}
        className="stripeField stripeCardExpiryField"
        onChange={onChange}
      />
    </div>
  );
};
