/* eslint-disable react-hooks/exhaustive-deps */

import ReactSelect from 'react-select';
import { useProvidersStrore } from 'stores/ProvidersStore';
import { useQuery } from 'react-query';
import { getOrgs } from 'api/orgs';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Org } from 'api/orgs/types';
import { HealthSelects } from '.';
import { useQueryParams } from 'hooks/useSearchQuery';
import { HelathSnapshot } from 'api/health/types';

type Props = {
  org?: Org;
  setSelectedSnapshots: Dispatch<SetStateAction<HelathSnapshot | null>>;
};

export const OrgHealthSelects = ({ org, setSelectedSnapshots }: Props) => {
  const { getQueryParam, updateCustomParams } = useQueryParams();

  const [selects, setSelects] = useState<HealthSelects>({ org: null, provider: null });

  const orgID = getQueryParam('orgID');
  const providerName = getQueryParam('providerName');

  const { providers } = useProvidersStrore();
  const { data: allOrgs } = useQuery(['all-orgs'], getOrgs);

  const connectedProviders = useMemo(
    () =>
      providers?.filter(({ name }) => {
        const credentialsOfSupplier = org?.credentials?.filter(({ providerName }) => providerName === name);
        return credentialsOfSupplier?.length ? credentialsOfSupplier.every(({ gpoConnected }) => !gpoConnected) : false;
      }),
    [org, providers]
  );

  const orgInitial = () => {
    const org = allOrgs?.find(({ id }) => id === orgID);
    return org ? { label: org?.name, value: org?.id } : null;
  };

  const providerIniital = () => {
    const provider = providers.find((provider) => provider.name === providerName);
    return provider ? { label: provider?.name, value: provider?.name } : null;
  };

  useEffect(() => {
    if (orgID && allOrgs) setSelects((p) => ({ ...p, org: orgInitial() }));
    if (providerName && providers) setSelects((p) => ({ ...p, provider: providerIniital() }));
  }, [allOrgs, providers]);

  return (
    <div className="healthSelects">
      <div className="group">
        <p>Organization</p>
        <ReactSelect
          className="select"
          placeholder="Select Organization"
          options={allOrgs?.map((org) => ({ label: org.name, value: org.id } || []))}
          onChange={(org: any) => {
            setSelects({ org, provider: null });
            updateCustomParams({ orgID: org.value }, ['providerName', 'snapshotID']);
            setSelectedSnapshots(null);
          }}
          value={selects.org}
        />
      </div>

      <div className="group">
        <p>Provider</p>
        <ReactSelect
          className="select"
          placeholder="Select Provider"
          options={connectedProviders.map((provider) => ({ label: provider.name, value: provider.name }))}
          onChange={(provider: any) => {
            setSelects((p) => ({ ...p, provider }));
            updateCustomParams({ providerName: provider.value }, ['snapshotID']);
            setSelectedSnapshots(null);
          }}
          value={selects.provider}
        />
      </div>
    </div>
  );
};
