import { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import orderBy from 'lodash/orderBy';

import { DeleteBackOrderPayload } from 'api/purchaseOrders/types';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { OrderItem } from 'types/order.types';

import { TableDropdownCell } from 'components/shared/table/TableDropdownCell';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { convertPrice, orderSupplierPrice } from 'util/prices';
import { UpdateBackOrder } from './form/UpdateBackOrder';
import { CreateBackOrder } from './form/CreateBackOrder';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { deleteBackOrder } from 'api/purchaseOrders';
import { useOrderStore } from 'stores/OrderStore';
import { Table } from 'components/shared/table';
import useTableData from 'hooks/useTableData';
import { formatProductHref } from 'util/mix';
import { formatDate } from 'util/dates';
import { DragIcon } from 'assets/icons/DragIcon';

interface Props {
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

export const BackOrdersTable = ({ dragHandleProps }: Props) => {
  const { order, setOrder } = useOrderStore();
  const [cellID, setCellID] = useState('');
  const [isOpenCreateForm, setIsOpenCreateForm] = useState(false);
  const [itemForUpdate, setItemForUpdate] = useState<OrderItem | null>(null);

  const allData = orderBy(order.items.filter((el) => el.backOrder) || [], (e) => e.item.title);
  const { meta, tableData } = useTableData({ allData });
  const { mutate: deleteItem } = useMutation(deleteBackOrder);

  const dotsRef = useRef(null);
  useOutsideClick(dotsRef, () => cellID && setCellID(''));

  const onDelete = (backOrderID: string) => {
    const payload: DeleteBackOrderPayload = {
      orderID: order.orderID,
      orgID: order.orgID,
      backOrderID
    };

    deleteItem(payload, {
      onSuccess: (res) => {
        setOrder(res);
        setCellID('');
      }
    });
  };

  return (
    <>
      <Table
        name="BackOrders"
        title={
          <>
            <span {...dragHandleProps}>
              <DragIcon />
            </span>
            Backordered Items
          </>
        }
        nav={['#', 'Title', 'MFR Code', 'Supplier', 'Ordered QTY', 'BO QTY', 'Customer Preference', 'EDD', 'Total', '']}
        meta={meta}
        allData={allData}
        headerContent={
          <button className="outlined lg" onClick={() => setIsOpenCreateForm(true)}>
            Create Backorder
          </button>
        }
        content={
          <div className="tableBody">
            {tableData.map((item: OrderItem, i) => (
              <div key={`${item.item.objectID}-${item.price.provider}`} className="tableRow">
                <TableCellTooltip content={`${i + 1}.`} />
                <TableCellTooltip content={item.item.title} />
                <TableCellTooltip content={item.item.manufacturerCode}>
                  <a
                    href={formatProductHref(item.item.objectID || '', item.item.title)}
                    className="link"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {item.item.manufacturerCode}
                  </a>
                </TableCellTooltip>
                <TableCellTooltip content={item.price.provider} />
                <TableCellTooltip content={item.quantity} />
                <TableCellTooltip content={item.backOrder?.quantity || 0} />
                <TableCellTooltip content={item.backOrder?.customerPreference || ''} />
                <TableCellTooltip content={item.backOrder ? formatDate(item.backOrder.backOrder) : ''} />
                <TableCellTooltip content={convertPrice(orderSupplierPrice(item.price) * item.quantity)} />
                <TableDropdownCell
                  id={`${item.item.objectID}-${item.price.provider}`}
                  active={cellID}
                  onClick={setCellID}
                  ref={dotsRef}
                >
                  <li onClick={() => setItemForUpdate(item)}>Update Backorder</li>
                  <li className="danger" onClick={() => onDelete(item.backOrder?.id || '')}>
                    Delete Backorder
                  </li>
                </TableDropdownCell>
              </div>
            ))}
          </div>
        }
      />
      {isOpenCreateForm && <CreateBackOrder close={() => setIsOpenCreateForm(false)} />}
      {itemForUpdate && <UpdateBackOrder item={itemForUpdate} close={() => setItemForUpdate(null)} />}
    </>
  );
};
