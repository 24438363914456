import create from 'zustand';

import { Order } from 'types/order.types';

interface State {
  order: Order;
  setOrder: (order: Order) => void;
}

const useOrderStore = create<State>((set) => ({
  order: {} as Order,
  setOrder: (order: Order) => set({ order })
}));

export { useOrderStore };
