/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { Org } from 'api/orgs/types';
import { Modal } from 'components/ui/Modal';
import { orgsKeys } from 'api/orgs/queries';
import { SelectOption } from 'components/shared/OfficesSelect';
import { deleteOrgCredentials, getOfficeCredentials, getOrgCredentials } from 'api/orgs';
import { RepresentativeSelects } from 'pages/singleGpo/members/addRepresentative/RepresentativeSelects';
import { CopyIcon } from 'assets/icons/CopyIcon';
import { toast } from 'react-toastify';
import { queryClient } from 'lib/react-quert';

interface Props {
  close: () => void;
  org: Org;
}

export const RetrieveCredentialsModal = ({ close, org }: Props) => {
  const orgID = org.metadata.orgID;

  const [provider, setProvider] = useState<SelectOption>();
  const [office, setOffice] = useState<SelectOption>();

  const [deleteCredentialsPayload, setDeleteCredentialsPayload] = useState<{
    orgID: string;
    provider: string;
  } | null>(null);

  const areInputsPopulated = Boolean(provider?.value) && Boolean(office?.value);

  const { data: orgCredentials, refetch: refetchOrgCredentials } = useQuery(
    orgsKeys.orgCredentials(orgID, provider?.value || ''),
    () => getOrgCredentials(orgID, provider?.value || ''),
    {
      enabled: false,
      onError: () => {
        queryClient.removeQueries(orgsKeys.orgCredentials(orgID, provider?.value || ''));
      }
    }
  );

  const { data: officeCredentials, refetch: refetchOfficeCredentials } = useQuery(
    ['office-credentials'],
    () => getOfficeCredentials(orgID, office?.value || '', provider?.value || ''),
    { enabled: false }
  );

  const { mutate, isLoading } = useMutation(deleteOrgCredentials, {
    onSuccess: () => {
      setDeleteCredentialsPayload(null);
      refetchOrgCredentials();
    }
  });

  const gpoRepresentatives = useMemo(() => {
    if (org && areInputsPopulated) {
      const isOrg = office?.label === 'Organization';
      const gpoCredentials = org?.credentials.filter(
        ({ providerName, gpoConnected }) => providerName === provider?.value && gpoConnected
      );

      const filteredCredentials = gpoCredentials?.filter(
        ({ offices, representatives }) => (isOrg ? representatives : offices?.[office?.value || ''] || [])?.length
      );

      return filteredCredentials?.map(({ gpoName, offices, representatives: reps }) => {
        const representatives = isOrg ? reps : offices?.[office?.value || ''] || [];
        return { gpoName, representatives };
      });
    }
  }, [org, areInputsPopulated, office, provider]);

  const nonGpoRepresentatives = useMemo(() => {
    if (org && areInputsPopulated) {
      const isOrg = office?.label === 'Organization';
      const nonGpoCredentials = org?.credentials.find(
        ({ providerName, gpoConnected }) => providerName === provider?.value && !gpoConnected
      );
      return isOrg ? nonGpoCredentials?.representatives || [] : nonGpoCredentials?.offices?.[office?.value || ''] || [];
    }
  }, [org, areInputsPopulated, office, provider]);

  useEffect(() => {
    if (areInputsPopulated) office?.label === 'Organization' ? refetchOrgCredentials() : refetchOfficeCredentials();
  }, [provider, office]);

  const copyToClipboard = (content: string) => {
    if (content?.length) {
      navigator.clipboard.writeText(content);
      toast.success('Copied to clipboard');
    }
  };

  return (
    <Modal
      open={true}
      close={close}
      title="Retrieve Credentials"
      className={`retrieveCredentialsModal ${!areInputsPopulated ? 'notPopulated' : ''}`}
    >
      {deleteCredentialsPayload && (
        <Modal
          open={Boolean(deleteCredentialsPayload)}
          close={() => setDeleteCredentialsPayload(null)}
          title={`Delete credentials`}
          className="deleteCredentials"
        >
          <h2>Are you sure you want to delete {deleteCredentialsPayload.provider} credentials?</h2>

          <p>NOTE: This process may take up to an hour to finish!</p>

          <div className="buttons">
            <button className="contained danger" disabled={isLoading} onClick={() => mutate(deleteCredentialsPayload)}>
              Delete credentials
            </button>
            <button className="contained cancel" onClick={() => setDeleteCredentialsPayload(null)}>
              Cancel
            </button>
          </div>
        </Modal>
      )}
      <>
        <div className="selects">
          <RepresentativeSelects
            setOffice={setOffice}
            setProvider={setProvider}
            office={office}
            provider={provider}
            org={org}
          />
        </div>

        {areInputsPopulated && (
          <>
            <div className="dataContainer">
              {office?.label === 'Organization' && (orgCredentials?.username || orgCredentials?.password) && (
                <button
                  type="button"
                  className="contained danger lg deleteCredentialsBtn"
                  onClick={() => {
                    setDeleteCredentialsPayload({ orgID, provider: provider?.value || '' });
                  }}
                >
                  Delete Organization Credentials
                </button>
              )}

              <h3> {office?.label === 'Organization' ? 'Organization' : 'Office'} credentials</h3>
              <div className="dataContainer">
                {office?.label === 'Organization' ? (
                  <>
                    {orgCredentials?.username ? (
                      <div className="credentials">
                        <div className="row" onClick={() => copyToClipboard(orgCredentials?.username || '')}>
                          <p>Username: {orgCredentials?.username}</p>
                          <CopyIcon />
                        </div>
                        <div className="row" onClick={() => copyToClipboard(orgCredentials?.password || '')}>
                          <p>Password: {orgCredentials?.password}</p>
                          <CopyIcon />
                        </div>
                      </div>
                    ) : (
                      <p>No organization credentials found...</p>
                    )}
                  </>
                ) : (
                  <>
                    {officeCredentials?.username ? (
                      <div className="credentials">
                        <div className="row" onClick={() => copyToClipboard(officeCredentials?.username || '')}>
                          <p>Username: {officeCredentials?.username}</p>
                          <CopyIcon />
                        </div>
                        <div className="row" onClick={() => copyToClipboard(officeCredentials.password || '')}>
                          <p>Password: {officeCredentials?.password}</p>
                          <CopyIcon />
                        </div>
                      </div>
                    ) : (
                      <p>No office credentials found....</p>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="dataContainer">
              <h3>{office?.label === 'Organization' ? 'Organization' : 'Office'} representatives</h3>
              {nonGpoRepresentatives?.length ? (
                <div className="col">
                  {nonGpoRepresentatives.map((el, i) => (
                    <div className="repPaper" key={el?.email || '' + el?.phone + i}>
                      <div className="left">
                        {el?.email && (
                          <div className="row" onClick={() => copyToClipboard(el?.email || '')}>
                            <p>{el?.email}</p>
                            <CopyIcon />
                          </div>
                        )}
                        {el?.phone && (
                          <div className="row" onClick={() => copyToClipboard(el?.phone || '')}>
                            <p>{el?.phone}</p>
                            <CopyIcon />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No representatives found...</p>
              )}
            </div>

            {gpoRepresentatives?.length ? (
              <div className="dataContainer">
                <h3>{office?.label === 'Organization' ? 'GPO Organization' : 'GPO Office'} representatives</h3>
                <>
                  {gpoRepresentatives?.map(({ gpoName, representatives }, i) => (
                    <div className="gpoTab" key={(gpoName || '') + i}>
                      <p className="name">GPO - {gpoName}</p>
                      <div className="col">
                        {representatives.map((el, i) => (
                          <div className="repPaper" key={el?.email || '' + el?.phone || '' + i}>
                            <div className="left">
                              <p>{el?.email}</p>
                              <p>{el?.phone}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </>
              </div>
            ) : null}
          </>
        )}
      </>
    </Modal>
  );
};
