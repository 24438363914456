import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'The field is required',
    oneOf: ''
  },
  string: {
    email: 'Email must be valid'
  }
});

export const officeSchema = Yup.object().shape({
  name: Yup.string().required(),
  streetNum: Yup.string().required(),
  locality: Yup.string().required(),
  state: Yup.string().required(),
  zip: Yup.string().required(),
  lat: Yup.number().required(),
  lng: Yup.number().required()
});

export const adminUserSchema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().required().email(),
});
