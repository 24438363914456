import { TableMetaTypes } from 'types/table.types';

import { TablePagination } from './TablePagination';
import { TablePerPage } from './TablePerPage';

export const TableLegend = (props: TableMetaTypes) => (
  <div className="tableLegend">
    <TablePerPage {...props} />
    <TablePagination {...props} />
  </div>
);
