import { useEffect, useState } from 'react';
import { useSearchBox } from 'react-instantsearch-hooks';

import { SearchIcon } from 'assets/icons/SearchIcon';
import useDebounce from 'hooks/useDebounce';

export const AlgoliaSearch = () => {
  const [value, setValue] = useState('');
  const { refine, query } = useSearchBox();
  const { onDebounce } = useDebounce();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    onDebounce(newValue, refine);
  };

  useEffect(() => {
    if (!query) setValue('');
  }, [query]);

  return (
    <div className="algoliaSearch">
      <SearchIcon />
      <input
        type="search"
        value={value}
        onChange={onChange}
        placeholder="Search products"
      />
    </div>
  );
};
