import { useState } from 'react';
import { useFormikContext } from 'formik';
import flattenDeep from 'lodash/flattenDeep';

import { Order, ProviderInvoice } from 'types/order.types';
import { OrgOrderItem } from 'types/orgs.types';

import { DownArrowIcon } from 'assets/icons/DownArrow';
import { Select } from 'components/ui/Select';
import { isActiveClass } from 'util/classes';

interface Props {
  order: Order;
  allProviderInvoices: ProviderInvoice[];
}

export const ProviderInvoicesSelect = ({ allProviderInvoices, order }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { values, setValues }: any = useFormikContext();
  const selectedInvoices: string[] = values.invoicesIDS;

  const handleInvoicesIDs = (id: string) => {
    if (selectedInvoices.includes(id)) return selectedInvoices.filter((el) => el !== id);

    return [...selectedInvoices, id];
  };

  const onClick = ({ id }: ProviderInvoice) => {
    const invoicesIDS = handleInvoicesIDs(id);
    const invoicesItems = allProviderInvoices.filter((el) => invoicesIDS.includes(el.id));
    const items = handleItems(invoicesItems);
    const res = { ...values, invoicesIDS, items: items, allItems: items };

    setValues(res);
    setIsOpen(false);
  };

  const handleItems = (providerInvoices: ProviderInvoice[]) => {
    const items = flattenDeep(providerInvoices.map((el) => el.items));
    const result = [];
    const map = new Map();

    for (const item of items) {
      const key = `${item.item.objectID}:${item.price.provider}`;

      if (!map.has(key)) {
        map.set(key, { ...item, quantity: 0 });
        result.push(map.get(key));
      }

      map.get(key).quantity += item.quantity;
    }

    return result;
  };

  const renderBadgeByProvider = (item: ProviderInvoice) => {
    const { provider, items } = item;

    const allOrderedItems = order.faliamInvoices.reduce((acc, curr) => [...acc, ...curr.items], [] as OrgOrderItem[]);
    const supplierProviderItems = allOrderedItems.filter((item) => item.price.provider === provider);

    const supplierItems = items.filter((item) => item.price.provider === provider);

    if (supplierProviderItems.length >= supplierItems.length)
      return <span className="badge success">Fully Covered</span>;
    return (
      allOrderedItems.some((el) => el.price.provider === provider) && (
        <span className="badge secondary">Partially Covered</span>
      )
    );
  };

  return (
    <div className="field">
      <label>Choose Provider Invoices</label>
      <Select
        className="regular suppliersSelect"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        options={
          <ul>
            {allProviderInvoices.map((item) => (
              <li
                key={item.id}
                onClick={() => onClick(item)}
                className={isActiveClass('', selectedInvoices.includes(item.id))}
              >
                {item.provider}
                {renderBadgeByProvider(item)}
              </li>
            ))}
          </ul>
        }
      >
        <p style={{ wordBreak: 'break-all' }}>
          {allProviderInvoices
            .filter((el) => selectedInvoices.includes(el.id))
            .map((el) => el.provider)
            .join(', ')}
        </p>
        <DownArrowIcon />
      </Select>
    </div>
  );
};
