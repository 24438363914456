import { apiCall, BASE_URL_VARIANTS } from 'api';

import { Provider, UpdateOfficeRepresentativesPayload, UpdateOrgRepresentativesPayload } from './types';
import { toast } from 'react-toastify';

export const getProviders = async (): Promise<Provider[]> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL_PUBLIC, false);

  const { data } = await request({
    url: `/providers`,
    method: 'GET'
  });

  return data;
};

export const newProvider = async (p: Provider): Promise<Provider> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL, true);

  const {data} = await request({
    url: `/providers`,
    method: 'POST',
    data: p
  });

  return data
};

export const updateOrgRepresentatives = async ({
  orgID,
  gpoID,
  contacts,
  providerName
}: UpdateOrgRepresentativesPayload) => {
  try {
    const request = await apiCall(BASE_URL_VARIANTS.PROCUREMENT);

    await request({
      url: `/orgs/${orgID}/representatives/${providerName}`,
      method: 'PUT',
      data: { gpo: gpoID, contacts }
    });

    toast.success('Organization repsesentatives updated successfully!');
  } catch (err: any) {
    toast.error('Something went wrong!');
    return Promise.reject(err);
  }
};

export const updateOfficeRepresentatives = async ({
  orgID,
  gpoID,
  officeID,
  contacts,
  providerName
}: UpdateOfficeRepresentativesPayload) => {
  try {
    const request = await apiCall(BASE_URL_VARIANTS.PROCUREMENT);

    await request({
      url: `/orgs/${orgID}/offices/${officeID}/representatives/${providerName}`,
      method: 'PUT',
      data: { gpo: gpoID, contacts }
    });

    toast.success('Office repsesentatives updated successfully!');
  } catch (err: any) {
    toast.error('Something went wrong!');
    return Promise.reject(err);
  }
};
