import { toast } from 'react-toastify';

import {
  BudgetPayload,
  CreateOrgPaymentPayload,
  DeleteDraftOrderPayload,
  DraftOrder,
  InviteOrgUserPayload,
  NewOrgPayload,
  Org,
  OrgCredentialsResponse,
  OrgFormularyItem,
  OrgHistoryItem,
  OrgMetadata,
  OrgOfficePayload,
  UpdateOrgItemsPricesPayload,
  Price,
  StoreOrgOrderPayload,
  UpdateFormularyItemPayload,
  UpdateOrgItemsPricesResponse,
  UpdateFormularyItemResponse,
  UpdateOfficeFormularyParams,
  InvoicesResponse,
  UpdateOrgItemsPromotionsPayload,
  UpdateOrgItemsPromotionsResponse,
  AllOrgsResponse
} from './types';

import { apiCall, BASE_URL_VARIANTS } from 'api';
import { OrgOrder } from 'types/orgs.types';

// Org

export const getOrgs = async () => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);
  const { data } = await request<AllOrgsResponse>({ url: `/organizations`, method: 'GET' });
  return data;
};

export const getOrg = async (id: string): Promise<Org> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  const { data } = await request({
    url: `/organizations/${id}`,
    method: 'GET'
  });

  return data;
};

export const createOrg = async (body: NewOrgPayload): Promise<any> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    await request({
      data: body,
      url: `/organizations`,
      method: 'POST'
    });

    toast('Organization has been created.', { type: 'success' });
  } catch (error) {}
};

export const updateOrg = async (orgID: string, body: OrgMetadata) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}`,
      method: 'PATCH',
      data: body
    });

    toast('Organization info has been updated.', { type: 'success' });
    return data;
  } catch (error) {}
};

// Providers

export const getOrgCredentials = async (orgID: string, provider: string): Promise<OrgCredentialsResponse> => {
  try {
    const request = await apiCall(BASE_URL_VARIANTS.PROCUREMENT);

    const { data } = await request({
      url: `/orgs/${orgID}/credentials`,
      method: 'GET',
      params: { provider: encodeURIComponent(provider) }
    });

    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteOrgCredentials = async ({ orgID, provider }: { orgID: string; provider: string }) => {
  const request = await apiCall(BASE_URL_VARIANTS.PROCUREMENT);

  try {
    await request({
      url: `/orgs/${orgID}/credentials`,
      method: 'DELETE',
      params: { provider: encodeURIComponent(provider) }
    });
    toast('Credentials have been deleted.', { type: 'success' });
  } catch (error) {
    toast('Error deleting credentials!', { type: 'error' });
  }
};

export const getOfficeCredentials = async (
  orgID: string,
  officeID: string,
  provider: string
): Promise<{ username: string; password: string }> => {
  const request = await apiCall(BASE_URL_VARIANTS.PROCUREMENT);

  const { data } = await request({
    url: `/orgs/${orgID}/offices/${officeID}/credentials`,
    method: 'GET',
    params: { provider: encodeURIComponent(provider) }
  });

  return data;
};

export const uploadProviderCSVFile = async (orgID: string, name: string, file: any) => {
  const request = await apiCall(BASE_URL_VARIANTS.PROCUREMENT);
  const notAuthRequest = await apiCall(BASE_URL_VARIANTS.PROCUREMENT, false);

  try {
    const { data } = await request({
      url: `/orgs/${orgID}/providers/${name}/prices/url`,
      method: 'GET'
    });
    if (data.url) notAuthRequest({ url: data.url, method: 'PUT', data: file });
    toast('CSV has been uploaded.', { type: 'success' });
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

// Users

export const deleteOrgUser = async (orgID: string, userID: string) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/users/${userID}`,
      method: 'DELETE'
    });
    toast('User has been deleted.', { type: 'success' });

    return data;
  } catch (error) {}
};

// Invites

export const inviteOrgUser = async (orgID: string, body: InviteOrgUserPayload) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    await request({
      url: `/organizations/${orgID}/invites`,
      method: 'POST',
      data: body
    });
    toast('Invitation has been sent.', { type: 'success' });
  } catch (error) {}
};

export const deleteOrgUserInvite = async (orgID: string, inviteID: string) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    await request({
      url: `/organizations/${orgID}/invites/${inviteID}`,
      method: 'DELETE'
    });
    toast('Invitation has been deleted', { type: 'success' });
  } catch (error) {}
};

// Offices

export const createOrgOffice = async (orgID: string, body: OrgOfficePayload) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/offices`,
      method: 'POST',
      data: body
    });

    toast('Office has been created.', { type: 'success' });
    return data;
  } catch (error) {}
};

export const updateOrgOffice = async (orgID: string, officeID: string, body: OrgOfficePayload) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/offices/${officeID}`,
      method: 'PATCH',
      data: body
    });

    toast('Office name has been updated.', { type: 'success' });
    return data;
  } catch (error) {}
};

export const deleteOrgOffice = async (orgID: string, officeID: string) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/offices/${officeID}`,
      method: 'DELETE'
    });

    toast('Office has been deleted.', { type: 'success' });
    return data;
  } catch (error) {
    toast('This office cannot be deleted because it has either orders or draft orders tied to it.', { type: 'error' });
  }
};

export const getOrgOfficeTaxRate = async (orgID: string, officeID: string, amount: number): Promise<number> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  const { data } = await request({
    url: `/organizations/${orgID}/offices/${officeID}/taxrate`,
    method: 'GET',
    params: { amount: Math.trunc(amount * 100) }
  });

  return data.rate || 0;
};

// Payment

export const createOrgPayment = async (orgID: string, body: CreateOrgPaymentPayload) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/payments`,
      method: 'POST',
      data: body
    });
    toast('Payment has been created.', { type: 'success' });

    return data;
  } catch (error) {}
};

export const deleteOrgPayment = async (orgID: string, paymentID: string) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/payments/${paymentID}`,
      method: 'DELETE'
    });

    toast('Payment has been deleted.', { type: 'success' });

    return data;
  } catch (error) {}
};

// Prices

export const getOrgItemsPrices = async (orgID: string, ids: string[]): Promise<Price[]> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  const { data } = await request({
    url: `/organizations/${orgID}/prices`,
    method: 'GET',
    params: { variantID: ids.join(',') }
  });

  return data;
};

export const updateOrgItemsPrices = async (
  orgID: string,
  body: UpdateOrgItemsPricesPayload
): Promise<UpdateOrgItemsPricesResponse[]> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/prices`,
      method: 'POST',
      data: body
    });
    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

export const updateOrgItemsPromotions = async (
  orgID: string,
  body: UpdateOrgItemsPromotionsPayload
): Promise<UpdateOrgItemsPromotionsResponse[]> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/promotions`,
      method: 'POST',
      data: body
    });
    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

// Orders

export const getOrgOrders = async (orgID: string): Promise<OrgOrder[]> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  const { data } = await request({
    url: `/v2/organizations/${orgID}/orders`,
    method: 'GET'
  });

  return data;
};

export const createOrgOrder = async (orgID: string, body: StoreOrgOrderPayload) => {
  try {
    const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

    await request({
      url: `/v2/organizations/${orgID}/orders`,
      method: 'POST',
      data: body
    });

    toast('Order has been created.', { type: 'success' });
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

// Formulary

export const getOrgFormularyItems = async (orgID: string): Promise<OrgFormularyItem[]> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  const { data } = await request({
    url: `/organizations/${orgID}/formulary`,
    method: 'GET'
  });

  return data;
};

export const getOrgOfficeFormularyItems = async (orgID: string, officeID: string): Promise<OrgFormularyItem[]> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  const { data } = await request({
    url: `/organizations/${orgID}/offices/${officeID}/formulary`,
    method: 'GET'
  });

  return data;
};

export const updateOrgFormularyItems = async (
  orgID: string,
  items: UpdateFormularyItemPayload[]
): Promise<UpdateFormularyItemResponse[]> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/formulary`,
      method: 'PATCH',
      data: { operations: items }
    });
    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

export const updateOrgOfficeFormularyItems = async ({
  orgID,
  officeID,
  items
}: UpdateOfficeFormularyParams): Promise<UpdateFormularyItemResponse[]> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/offices/${officeID}/formulary`,
      method: 'PATCH',
      data: { operations: items }
    });
    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

// Invoices

export const getOrgInvoices = async ({
  orgID,
  pageToken,
  maxResults
}: {
  orgID: string;
  maxResults: number;
  pageToken?: string;
}): Promise<InvoicesResponse> => {
  try {
    const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

    const { data } = await request({
      url: `/v2/organizations/${orgID}/invoices`,
      method: 'GET',
      params: { maxResults, pageToken }
    });

    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

// Budget

export const updateOrgBudget = async (body: BudgetPayload) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);
  const { orgID, budget, officeID, type } = body;
  let data;

  if (type === 'delete') {
    data = { [officeID]: null };
  } else {
    data = {
      [officeID]: {
        january: {
          total: budget
        },
        february: {
          total: budget
        },
        march: {
          total: budget
        },
        april: {
          total: budget
        },
        may: {
          total: budget
        },
        june: {
          total: budget
        },
        july: {
          total: budget
        },
        august: {
          total: budget
        },
        september: {
          total: budget
        },
        october: {
          total: budget
        },
        november: {
          total: budget
        },
        december: {
          total: budget
        }
      }
    };
  }

  try {
    await request({
      url: `/organizations/${orgID}/restrictions`,
      method: 'PATCH',
      data
    });
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

// History items

export const getOrgHistoryItems = async (orgID: string): Promise<OrgHistoryItem> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  const { data } = await request({
    url: `/v2/organizations/${orgID}/item-history`,
    method: 'GET'
  });

  return data;
};

// Draft Orders
export const getOrgDraftOrders = async (orgID: string): Promise<DraftOrder[]> => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  const url = `/v2/organizations/${orgID}/draftorders`;
  const { data } = await request({ url, method: 'GET' });
  const { data: declined } = await request({ url: `${url}?declined=true`, method: 'GET' });

  return [...data, ...declined];
};

export const deleteOrgDraftOrder = async ({ orgID, orderID, reason }: DeleteDraftOrderPayload) => {
  const request = await apiCall(BASE_URL_VARIANTS.RETAIL);

  try {
    await request({
      url: `/v2/organizations/${orgID}/draftorders/${orderID}`,
      method: 'PUT',
      data: reason ? { decline: { reason } } : undefined
    });

    toast.success('Draft order has been declined.');
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};
