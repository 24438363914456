import { User } from 'api/users/types';
import { Link } from 'react-router-dom';

interface Props {
  organizations: User['organizations'];
}

export const UserOrganizations = ({ organizations }: Props) => {
  return (
    <div className="paper userOrganizations">
      <div className="paperHeader">
        <p>User Organizations</p>
      </div>
      <div className="paperBody">
        <div className="blocks">
          {organizations.length ? (
            organizations.map(({ id, name }) => (
              <Link key={id} to={`/org/${id}`} className="block">
                <div className="head">{name}</div>
                <p>{id}</p>
              </Link>
            ))
          ) : (
            <p className="fieldError">
              The user does not belong to any organization.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
