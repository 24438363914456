import { Formik, Form, Field } from 'formik';
import { useMutation } from 'react-query';

import { CreateUserPayload, ImpersonateUserPayload } from 'api/users/types';

import { createUser } from 'api/users/index';
import { Modal } from 'components/ui/Modal';

interface Props {
  open: boolean;
  close: () => void;
}

export const CreateUserModal = ({ open, close }: Props) => {
  const { mutate, isLoading } = useMutation((payload: CreateUserPayload) => createUser(payload));

  const onSubmit = (values: CreateUserPayload) => {
    mutate(values, {
      onSuccess() { close() },
      onError(error) { console.log('error', error) },
    });
  };

  return (
    <Modal open={open} close={close} title="Create User" className="createUserModal">
      <Formik initialValues={{
        name: '',
        email: '',
        password: '',
      }} onSubmit={onSubmit}>
        <Form>
          <div className="fields">
            <div className="field">
              <label htmlFor="name">Name</label>
              <Field id="name" name="name" />
            </div>
            <div className="field">
              <label htmlFor="email">Email</label>
              <Field id="email" type="email" name="email" />
            </div>
            <div className="field">
              <label htmlFor="password">Password</label>
              <Field id="password" type="password" name="password" />
            </div>
            <button className="contained" type="submit" disabled={isLoading}>
              Create User
            </button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};
