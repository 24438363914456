import { useState } from 'react';

import { TableMetaTypes } from 'types/table.types';

import { DownArrowIcon } from 'assets/icons/DownArrow';
import { isActiveClass } from 'util/classes';
import { Select } from 'components/ui/Select';

export const TablePerPage = ({ perPage, setPerPage, setCurrentPage, allData }: TableMetaTypes) => {
  const [isOpen, setIsOpen] = useState(false);

  const onSelect = (value: number) => {
    setIsOpen(false);
    setPerPage(value);
    setCurrentPage(1);
  };

  if (allData.length < 12) return <p className="fz14">Showing {allData.length} items</p>;

  return (
    <div className="tablePerPage">
      Show
      <Select
        className="regular"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        options={
          <ul>
            {items.map((item) => (
              <li
                key={item.id}
                onClick={() => onSelect(item.value)}
                className={isActiveClass('', perPage === item.value)}
              >
                {item.value}
              </li>
            ))}
          </ul>
        }
      >
        {perPage}
        <DownArrowIcon />
      </Select>
      of {allData.length} results per page
    </div>
  );
};

const items = [
  { id: 1, value: 12 },
  { id: 2, value: 25 },
  { id: 3, value: 50 }
];
