import { QueryKey } from 'react-query';
import { GetPDFParams } from './types';

const ordersKeys = {
  all: ['orders'] as const,
  order: (orgID: QueryKey, orderID: QueryKey) =>
    [...ordersKeys.all, 'order', orgID, orderID] as const,
  snapshotOrder: (orgID: QueryKey, orderID: QueryKey) =>
    [...ordersKeys.all, 'snapshotOrder', orgID, orderID] as const,
  getProviderToken: (orderID: QueryKey, name: string) =>
    [...ordersKeys.all, 'getProviderToken', orderID, name] as const,
  getPDF: (params: GetPDFParams) =>
    [...ordersKeys.all, 'getPDF', params] as const,
};

export { ordersKeys };
