import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import findIndex from 'lodash/findIndex';
import orderBy from 'lodash/orderBy';

import { CancelPurchaseOrderConfirmationPayload, DeletePurchaseOrderPayload } from 'api/purchaseOrders/types';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { Order, PurchaseOrder } from 'types/order.types';
import { HandleCellOrders } from 'types/table.types';

import { cancelPurchaseOrderConfirmation, deletePurchaseOrder } from 'api/purchaseOrders';
import { TableDropdownCell } from 'components/shared/table/TableDropdownCell';
import { ConfirmPurchaseOrderForm } from './form/ConfirmPurchaseOrderForm';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { getBackOrderItems, totalOrderItemsQty } from 'util/orders';
import { NestedTable } from 'components/shared/table/NestedTable';
import { PurchaseOrderForm } from './form/PurchaseOrderForm';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { DownArrowIcon } from 'assets/icons/DownArrow';
import { getOrderItemStatus } from 'util/ordersItems';
import { useOrderStore } from 'stores/OrderStore';
import { groupOrdersBySupplier } from 'util/mix';
import { Table } from 'components/shared/table';
import useTableData from 'hooks/useTableData';
import { DragIcon } from 'assets/icons/DragIcon';

interface Props {
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

type StateVariant = PurchaseOrder | null;

type PurchaseOrderFormState = PurchaseOrder | null | boolean;

export const PurchaseOrdersTable = ({ dragHandleProps }: Props) => {
  const [data, setData] = useState<PurchaseOrder[]>([]);
  const [cellID, setCellID] = useState('');
  const [collapsed, setCollapsed] = useState<string[]>([]);
  const [purchaseOrderFormState, setPurchaseOrderFormState] = useState<PurchaseOrderFormState>(null);
  const [purchaseOrderForConfirm, setPurchaseOrderForConfirm] = useState<StateVariant>(null);

  const { order, setOrder } = useOrderStore();
  const { purchaseOrders, items, shipments, providerInvoices } = order;
  const isPurchaseOrder = typeof purchaseOrderFormState === 'boolean';

  const { meta, tableData } = useTableData({ allData: data });
  const { mutate: deleteOrder } = useMutation(deletePurchaseOrder);
  const { mutate: cancelOrder } = useMutation(cancelPurchaseOrderConfirmation);

  const dotsRef = useRef(null);
  useOutsideClick(dotsRef, () => cellID && setCellID(''));

  useEffect(() => {
    setData(orderBy(purchaseOrders || [], 'created'));
  }, [purchaseOrders]);

  const onSuccess = (res: Order) => {
    setCellID('');
    setOrder(res);
  };

  const onDelete = () => {
    const payload: DeletePurchaseOrderPayload = {
      orderID: order.orderID,
      orgID: order.orgID,
      purchaseOrderID: cellID
    };

    deleteOrder(payload, { onSuccess });
  };

  const onCancelConirmation = (purchaseOrderID: string) => {
    const payload: CancelPurchaseOrderConfirmationPayload = {
      orderID: order.orderID,
      orgID: order.orgID,
      purchaseOrderID
    };

    cancelOrder(payload, { onSuccess });
  };

  const handleCellOrders = ({ cell, direction }: HandleCellOrders) => {
    const d = purchaseOrders || [];
    let res: any[] = [];

    switch (cell) {
      case 'Seller':
        res = orderBy(d, 'provider', direction);
        break;
      case 'Seller PO #':
        res = orderBy(d, 'referenceID', direction);
        break;
      case 'Status':
        res = orderBy(d, 'confirmed', direction);
        break;
      case '# Items':
        res = orderBy(d, (e) => e.items.length, direction);
        break;
      case 'Qty':
        res = orderBy(d, (e) => totalOrderItemsQty(e.items), direction);
        break;
      case "Tracking #'s":
        res = orderBy(d, (el) => shipments.filter((s) => s.purchaseOrderID === el.id).length, direction);
        break;
      case '# Invoices':
        res = orderBy(d, (el) => providerInvoices.filter((i) => i.provider === el.provider).length, direction);
        break;
      case '# BO items':
        res = orderBy(
          d,
          (el) => getBackOrderItems(el.items).filter((i) => i.price.provider === el.provider).length,
          direction
        );
        break;
      default:
        res = data;
        break;
    }

    setData(res);
  };

  const toggleCollapse = (id: string) =>
    setCollapsed((p) => (p.includes(id) ? p.filter((el) => el !== id) : [...p, id]));

  return (
    <>
      <Table
        name="PurchaseOrders"
        title={
          <>
            <span {...dragHandleProps}>
              <DragIcon />
            </span>
            Purchase Orders
          </>
        }
        headerContent={
          <button
            className="outlined lg"
            data-tip={order?.status === 'pending' ? 'Please approve order first' : ''}
            disabled={order?.status === 'pending'}
            onClick={() => setPurchaseOrderFormState(true)}
          >
            Create Purchase Order
          </button>
        }
        handleCellOrders={handleCellOrders}
        nav={[
          '#',
          'Seller',
          'Seller PO #',
          'Status',
          '# Items',
          'Qty',
          "Tracking #'s",
          '# Invoices',
          '# BO items',
          '',
          ' '
        ]}
        meta={meta}
        allData={data}
        content={
          <div className="tableBody nested">
            {tableData.map((el: PurchaseOrder) => (
              <div key={el.id} className="groupWithChildren">
                <div className="tableRow">
                  <TableCellTooltip content={findIndex(data, (fi) => fi.id === el.id) + 1 + '.'} />
                  <TableCellTooltip content={el.provider} />
                  <TableCellTooltip content={el.referenceID || 'Add'}>
                    <span
                      className={el.referenceID ? 'cur-pointer' : 'linked'}
                      onClick={() => setPurchaseOrderForConfirm(el)}
                    >
                      {el.referenceID || 'Add'}
                    </span>
                  </TableCellTooltip>
                  <TableCellTooltip content={el.confirmed ? 'Confirmed' : 'Not Confirmed'} />
                  <TableCellTooltip content={el.items.length} />
                  <TableCellTooltip content={totalOrderItemsQty(el.items)} />
                  <TableCellTooltip content={shipments.filter((s) => s.purchaseOrderID === el.id).length} />
                  <TableCellTooltip
                    content={providerInvoices?.filter((i) => i.provider === el.provider)?.length || 0}
                  />
                  <TableCellTooltip
                    content={getBackOrderItems(order.items).filter((i) => i.price.provider === el.provider).length}
                  />
                  <TableDropdownCell id={el.id} active={cellID} onClick={setCellID} ref={dotsRef}>
                    <li onClick={() => setPurchaseOrderForConfirm(el)}>
                      {el.confirmed ? 'Edit' : 'Add'} Seller Order #
                    </li>
                    <li onClick={() => setPurchaseOrderFormState(el)}>Edit purchase order</li>
                    {el.confirmed && (
                      <li className="danger" onClick={() => onCancelConirmation(el.id)}>
                        Delete Seller Order #
                      </li>
                    )}
                    <li className="danger" onClick={onDelete}>
                      Delete purchase order
                    </li>
                  </TableDropdownCell>
                  <div className="tableCell">
                    <div className="tableDownArrow" onClick={() => toggleCollapse(el.id)}>
                      <DownArrowIcon />
                    </div>
                  </div>
                </div>
                {collapsed.includes(el.id) && (
                  <NestedTable
                    className="tablePurchaseOrdersItems"
                    nav={['# Item', 'Seller SKU', 'Qty', 'Item Status', 'Item']}
                  >
                    {el.items.map((item, itemIndex) => {
                      const itemStatus = getOrderItemStatus(order, item.item.objectID);

                      return (
                        <div key={item.item.objectID} className="tableRow">
                          <TableCellTooltip content={`${itemIndex + 1}.`} />
                          <TableCellTooltip content={item.price.sku} />
                          <TableCellTooltip content={item.quantity} />
                          <TableCellTooltip className={`orderItemStatus ${itemStatus}`} content={itemStatus} />
                          <TableCellTooltip content={item.item.title} />
                        </div>
                      );
                    })}
                  </NestedTable>
                )}
              </div>
            ))}
          </div>
        }
      />
      {purchaseOrderForConfirm && (
        <ConfirmPurchaseOrderForm
          purchaseOrder={purchaseOrderForConfirm}
          close={() => setPurchaseOrderForConfirm(null)}
        />
      )}
      {purchaseOrderFormState && (
        <PurchaseOrderForm
          purchaseOrder={isPurchaseOrder ? null : purchaseOrderFormState}
          allItems={isPurchaseOrder ? items : purchaseOrderFormState.items}
          providers={groupOrdersBySupplier(items).map(([s]) => s)}
          close={() => setPurchaseOrderFormState(null)}
        />
      )}
    </>
  );
};
