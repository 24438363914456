import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Document, Page, pdfjs } from 'react-pdf';

import { ProviderInvoice } from 'types/order.types';
import { GetPDFParams } from 'api/orders/types';

import { TablePagination } from 'components/shared/table/TablePagination';
import { useOrderStore } from 'stores/OrderStore';
import { ordersKeys } from 'api/orders/queries';
import { deletePDF, getPDF } from 'api/orders';

interface Props {
  docID: string;
  files: ProviderInvoice['files'];
  refetch: () => void;
}

export const PdfViewer = ({ docID, files, refetch }: Props) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(1);

  const { order } = useOrderStore();
  const pdfParams: GetPDFParams = {
    orderID: order.orderID,
    orgID: order.orgID,
    docID,
    filename: files ? encodeURIComponent(Object.values(files)[currentPage - 1]?.Filename) : ''
  };

  const { data, isLoading } = useQuery(ordersKeys.getPDF(pdfParams), () => getPDF(pdfParams));
  const { mutate: onDeleteFile, isLoading: isLoadingDeleteFile } = useMutation(deletePDF);

  useEffect(() => {
    if (data)
      pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, [data, files]);

  const handleDownloadPDF = () => {
    if (!data) return;

    if (data.type === 'image') {
      const a = document.createElement('a');
      a.href = data.file as string;
      a.target = '_blank';
      a.download = pdfParams.filename || '';
      a.click();
      return;
    } else {
      const blobUrl = URL.createObjectURL(data.file as any);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = pdfParams.filename || '';
      a.click();
    }
  };

  return (
    <div>
      <div className="pdfViewer withoutScrollbar">
        {!isLoading ? (
          data && (
            <>
              {data.type === 'pdf' ? (
                <Document file={data.file} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
                  {Array.from(new Array(numPages), (_, i) => (
                    <Page
                      key={`page_${i + 1}`}
                      pageNumber={i + 1}
                      renderMode="canvas"
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                    />
                  ))}
                </Document>
              ) : (
                <div style={{ background: `url(${data.file})` }} className="previewImg" />
              )}
            </>
          )
        ) : (
          <div>Loading...</div>
        )}
      </div>

      <div className="flexBetween">
        <TablePagination
          allData={Object.values(files || {})}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
        <div className="flexAlign gg8">
          <button type="button" className="outlined sm" onClick={handleDownloadPDF}>
            Download file
          </button>
          <button
            type="button"
            className="outlined danger sm"
            disabled={isLoadingDeleteFile}
            onClick={() => onDeleteFile(pdfParams, { onSuccess: () => refetch() })}
          >
            Delete file
          </button>
        </div>
      </div>
    </div>
  );
};
