import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import orderBy from 'lodash/orderBy';

import { Org } from 'api/orgs/types';
import { Payment } from 'types/payment.types';
import { HandleCellOrders } from 'types/table.types';

import { deleteOrgPayment } from 'api/orgs';
import useTableData from 'hooks/useTableData';
import { getPaymentOffice } from 'util/offices';
import { Table } from 'components/shared/table';
import { AddPayment } from 'components/shared/payments/addPayment';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { TableDropdownCell } from 'components/shared/table/TableDropdownCell';
import { useOutsideClick } from 'hooks/useOutsideClick';

interface Props {
  org: Org;
  refetchOrg: () => void;
}

export const OrgPaymentsTable = ({ org, refetchOrg }: Props) => {
  const [selectedCell, setSelectedCell] = useState('');
  const [isOpenNewPaymentModal, setIsOpenNewPaymentModal] = useState(false);
  const [data, setData] = useState<Payment[]>([]);

  const { meta, tableData } = useTableData({ allData: data });
  const { mutate } = useMutation((id: string) => deleteOrgPayment(org.metadata.orgID, id));

  const dotsRef = useRef(null);
  useOutsideClick(dotsRef, () => selectedCell && setSelectedCell(''));

  const onDelete = (id: string) => {
    mutate(id, {
      onSuccess() {
        setSelectedCell('');
        refetchOrg();
      }
    });
  };

  useEffect(() => {
    setData(org.payments);
  }, [org.payments]);

  const handleCellOrders = ({ cell, direction }: HandleCellOrders) => {
    let res: any[] = [];

    switch (cell) {
      case 'Payment method':
        res = orderBy(org.payments, 'brand', direction);
        break;
      case 'Exp. Date':
        res = orderBy(org.payments, (el) => `${el.expMonth} ${el.expYear}`, direction);
        break;
      default:
        res = data;
        break;
    }

    setData(res);
  };

  return (
    <>
      <Table
        name="OrgPaymentMethods"
        title="Payment Methods"
        nav={['Payment method', 'Exp. Date', 'Offices that can use this card', '']}
        allData={org.payments}
        handleCellOrders={handleCellOrders}
        meta={meta}
        headerContent={
          <button className="outlined lg" onClick={() => setIsOpenNewPaymentModal(true)}>
            Add Payment
          </button>
        }
        content={
          <div className="tableBody customScrollbar">
            {tableData.map((payment: Payment) => (
              <div key={payment.paymentID} className="tableRow">
                <div className="tableCell">
                  <p>{payment.brand}</p>
                  <p>ending in {payment.last4}</p>
                </div>
                <TableCellTooltip content={`${payment.expMonth}/${payment.expYear}`} />
                <TableCellTooltip content={getPaymentOffice(org.offices, payment.officeID || '')} />
                <TableDropdownCell id={payment.paymentID} active={selectedCell} onClick={setSelectedCell} ref={dotsRef}>
                  <li className="danger" onClick={() => onDelete(payment.paymentID)}>
                    Delete Payment
                  </li>
                </TableDropdownCell>
              </div>
            ))}
          </div>
        }
      />
      {isOpenNewPaymentModal && (
        <AddPayment
          org={org}
          onClose={() => setIsOpenNewPaymentModal(false)}
          onSuccess={() => {
            setIsOpenNewPaymentModal(false);
            refetchOrg();
          }}
        />
      )}
    </>
  );
};
