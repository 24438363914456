import { useRef, useState } from 'react';
import { useMutation } from 'react-query';

import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { DeleteCsOverridePayload } from 'api/purchaseOrders/types';
import { CsOverrides } from 'types/order.types';

import { TableDropdownCell } from 'components/shared/table/TableDropdownCell';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { deleteCsOverride } from 'api/purchaseOrders';
import { CreateCsOverride } from './CreateCsOverride';
import { CheckIcon } from 'assets/icons/CheckIcon';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { useOrderStore } from 'stores/OrderStore';
import { Table } from 'components/shared/table';
import useTableData from 'hooks/useTableData';
import { DragIcon } from 'assets/icons/DragIcon';

interface Props {
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

export const CsOverrideTable = ({ dragHandleProps }: Props) => {
  const [cellID, setCellID] = useState('');
  const [isOpenCreateForm, setIsOpenCreateForm] = useState(false);

  const { order, setOrder } = useOrderStore();

  const allData = order.csOverrides || [];
  const { meta, tableData } = useTableData({ allData });
  const { mutate: deleteItem } = useMutation(deleteCsOverride);

  const dotsRef = useRef(null);
  useOutsideClick(dotsRef, () => cellID && setCellID(''));

  const onDelete = () => {
    const payload: DeleteCsOverridePayload = {
      orderID: order.orderID,
      orgID: order.orgID,
      overrideID: cellID
    };

    deleteItem(payload, {
      onSuccess: (res) => {
        setOrder(res);
        setCellID('');
      }
    });
  };

  const cell = (isTrushy: boolean) => (
    <div className="tableCell withIcon">{isTrushy ? <CheckIcon className="s" /> : <CloseIcon className="d" />}</div>
  );

  return (
    <>
      <Table
        name="CsOverride"
        title={
          <>
            <span {...dragHandleProps}>
              <DragIcon />
            </span>
            Overrides
          </>
        }
        nav={[
          'Description',
          'Approvals Collected',
          'Orders Placed',
          'Orders Confirmed',
          'Orders Shipped',
          'Orders Delivered',
          'Items Have Packing Slips',
          'Items Invoiced By Provider',
          'Items Invoiced By Faliam',
          'Faliam Invoiced Paid',
          ''
        ]}
        meta={meta}
        allData={allData}
        headerContent={
          <button className="outlined lg" onClick={() => setIsOpenCreateForm(true)}>
            Create Override
          </button>
        }
        content={
          <div className="tableBody">
            {tableData.map((item: CsOverrides) => (
              <div key={item.id} className="tableRow">
                <TableCellTooltip content={item.description} />
                {cell(item.allApprovalsCollected)}
                {cell(item.allOrdersPlaced)}
                {cell(item.allOrdersConfirmed)}
                {cell(item.allOrdersShipped)}
                {cell(item.allOrdersDelivered)}
                {cell(item.allItemsHavePackingSlips)}
                {cell(item.allItemsInvoicedByProvider)}
                {cell(item.allItemsInvoicedByFaliam)}
                {cell(item.allFaliamInvoicesPaid)}
                <TableDropdownCell id={item.id} active={cellID} onClick={setCellID} ref={dotsRef}>
                  <li className="danger" onClick={onDelete}>
                    Delete CS Override
                  </li>
                </TableDropdownCell>
              </div>
            ))}
          </div>
        }
      />
      {isOpenCreateForm && <CreateCsOverride close={() => setIsOpenCreateForm(false)} />}
    </>
  );
};
