export type ManufacturerConfig = {
  validManufacturers: string[],
  manufacturerMapping: Map<string, string>
}

export function normalizeName(name: string): string {
  return name.replace(/[^a-zA-Z0-9]+/g, "").toLowerCase();
}

export function normalize(config: ManufacturerConfig): void {
  // Normalize the valid manufacturers
  let newValid: string[] = config.validManufacturers.map(manufacturer => normalizeName(manufacturer));
  newValid.sort();

  // Normalize the mapping
  let newMapping: Map<string, string> = new Map();
  config.manufacturerMapping.forEach((value, key) => {
      newMapping.set(normalizeName(key), value);
  });

  // Set the new values
  config.manufacturerMapping = newMapping;
  config.validManufacturers = newValid;
}

export function validate(config: ManufacturerConfig): string[] {
  let errors: string[] = [];

  // All mapped manufacturers must be valid
  config.manufacturerMapping.forEach((value, key) => {
      if (!config.validManufacturers.includes(normalizeName(value))) {
          errors.push(`manufacturer ${value} not found in valid manufacturers`);
      }
  });

  // All manufacturers must be unique
  let uniqueManufacturers: Set<string> = new Set();
  config.validManufacturers.forEach(manufacturer => {
      if (uniqueManufacturers.has(manufacturer)) {
          errors.push(`duplicate manufacturer ${manufacturer}`);
      } else {
          uniqueManufacturers.add(manufacturer);
      }
  });

  return errors;
}
