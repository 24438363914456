import { useRefinementList } from 'react-instantsearch-hooks';

import ReactSelect from 'components/ui/ReactSelect';

export const SupplierFilter = () => {
  const { items, refine } = useRefinementList({ attribute: 'providers', limit: 100000 });
  const activeValue = items.find(({ isRefined }) => isRefined)?.value || '';

  return (
    <ReactSelect
      placeholder="Provider"
      options={items}
      value={items.find(({ value }) => value === activeValue) || null}
      onChange={({ value }: any) => {
        if (activeValue) refine(activeValue);
        if (value !== activeValue) refine(value);
      }}
    />
  );
};
