import { StripeCardNumberElementChangeEvent } from '@stripe/stripe-js';
import { CardNumberElement } from '@stripe/react-stripe-js';

import { stripeStyle } from './styles';

export const StripeCardNumberField = () => {
  const onChange = (e: StripeCardNumberElementChangeEvent) => {
    if (e.complete) {
      const el: HTMLInputElement | null = document.querySelector(
        '.stripeCardExpiryField input'
      );

      if (el) el.focus();
    }
  };

  return (
    <div className="field">
      <label>Card number</label>
      <CardNumberElement
        options={stripeStyle}
        className="stripeField stripeCardNumberField"
        onChange={onChange}
      />
    </div>
  );
};
