import { LoaderIcon } from 'assets/icons/LoaderIcon';

interface Props {
  content: JSX.Element;
  data: any[];
  emptyMessage?: string;
  isLoading?: boolean;
}

export const TableBody = ({ data, content, emptyMessage, isLoading }: Props) => {
  if (isLoading) return <LoaderIcon />;
  if (data.length) return content;
  return <p className="noSearchResults">{emptyMessage || 'No search results...'}</p>;
};
