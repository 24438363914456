import { useState } from 'react';

import { Org } from 'api/orgs/types';

import { Stripe } from '../stripe';
import { Modal } from 'components/ui/Modal';
import { Payment } from 'types/payment.types';

interface Props {
  org: Org;
  onClose: () => void;
  onSuccess: (item: Payment) => void;
}

export const AddPayment = ({ org, onClose, onSuccess }: Props) => {
  const [officeID, setOfficeID] = useState('');

  return (
    <Modal
      open={true}
      close={onClose}
      title="Add credit or debit cards"
      className="newPaymentMethodModal"
    >
      <Stripe
        org={org}
        onSuccess={onSuccess}
        officeID={officeID}
        setOfficeID={setOfficeID}
      />
    </Modal>
  );
};
