import { useHits } from 'react-instantsearch-hooks';
import { useMutation } from 'react-query';

import { AlgoliaOrg } from 'types/algolia.types';
import { GpoMember } from 'api/gpo/types';

import { deleteGpoMember, storeGpoMember } from 'api/gpo';
import { TableSearch } from 'components/shared/algoliaTable/TableSearch';
import { TableLegend } from 'components/shared/algoliaTable/TableLegend';
import { Link } from 'react-router-dom';

interface Props {
  id: string;
  members: GpoMember[];
  refetch: () => void;
}

export const ListOfOrgs = ({ id, members, refetch }: Props) => {
  const { hits, results }: any = useHits();
  const { mutate: onCreate } = useMutation(storeGpoMember);
  const { mutate: onDelete } = useMutation(deleteGpoMember);

  const onStoreMember = (orgID: string) => {
    onCreate({ id, orgID }, { onSuccess: refetch });
  };

  const onDeleteMember = (orgID: string) => {
    onDelete({ id, orgID }, { onSuccess: refetch });
  };

  return (
    <>
      <div className="listOfOrgs">
        <TableSearch />
        <ul className="allOrgs">
          {((hits || []) as AlgoliaOrg[]).map((el) => {
            const isAdded = members.some((member) => member.orgID === el.orgID);

            return (
              <li key={el.orgID}>
                <Link to={`/org/${el.orgID}`}>{el.orgName}</Link>
                {isAdded ? (
                  <button className="outlined danger" onClick={() => onDeleteMember(el.orgID)}>
                    Remove Member
                  </button>
                ) : (
                  <button className="outlined" onClick={() => onStoreMember(el.orgID)}>
                    Add Member
                  </button>
                )}
              </li>
            );
          })}
        </ul>
        <TableLegend totalHits={results?.nbHits || 0} />
      </div>
    </>
  );
};
