import { useQuery } from 'react-query';

import { getUser } from 'api/users';
import { usersKeys } from 'api/users/queries';
import { Modal } from 'components/ui/Modal';
import { UserDetails } from 'pages/user/UserDetails';
import { LoaderIcon } from 'assets/icons/LoaderIcon';

interface Props {
  userID: string;
  close: () => void;
  refetchOrg: () => void;
}

export const UpdateUserModal = ({ userID, close, refetchOrg }: Props) => {
  const { data: user, isLoading } = useQuery(usersKeys.user(userID), async () => {
    const data = await getUser(userID);
    return data;
  });

  const onSuccess = () => {
    close();
    refetchOrg();
  };

  return (
    <Modal open={true} close={close} title="Update User Informations" className="updateOrgUserModal">
      {isLoading ? <LoaderIcon /> : user && <UserDetails metadata={user.metadata} onSuccess={onSuccess} />}
    </Modal>
  );
};
