import { useState, useEffect } from 'react';

import { Office } from 'types/offices.types';
import { Org } from 'api/orgs/types';

import { SearchIcon } from 'assets/icons/SearchIcon';
import { CheckIcon } from 'assets/icons/CheckIcon';
import { isActiveClass } from 'util/classes';
import { formatOffice, formatOfficeAddress } from 'util/offices';
import { searchByKeys } from 'util/mix';
import { Checkbox } from 'components/ui/Checkbox';

interface Props {
  org: Org;
  officeID: string;
  setOfficeID: (officeID: string) => void;
}

export const AssignOffice = ({ org, officeID, setOfficeID }: Props) => {
  const [allOffices, setAllOffices] = useState<Office[]>([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setAllOffices(org.offices);
  }, [org.offices]);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    const result = searchByKeys(
      ['route', 'locality', 'state', 'zip', 'streetNum'],
      org.offices,
      value
    );
    setAllOffices(result);
  };

  const toggleOffice = (id: string) => setOfficeID(officeID === id ? '' : id);

  return (
    <div className="assignOffice">
      <h2>Assigne Card To Office(s)</h2>
      <div className="search">
        <SearchIcon />
        <input
          type="text"
          placeholder="Search office"
          value={inputValue}
          onChange={onSearch}
        />
      </div>
      <div className="alertBox">
        <div className="checkboxRow">
          <Checkbox
            isEnabled={officeID === 'main'}
            onChange={() => toggleOffice('main')}
          />
          <p>Select as the main organisation card (for all offices)</p>
        </div>
      </div>
      {allOffices.length ? (
        <ul className="customScrollbar">
          {allOffices.map((office) => (
            <li
              key={office.officeID}
              className={isActiveClass('', officeID === office.officeID)}
              onClick={() => toggleOffice(office.officeID)}
            >
              <div className="circle">
                <CheckIcon />
              </div>
              <h3>{formatOfficeAddress(office)}</h3>
              <p>{formatOffice(office)}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p className="noOffices">No search results.</p>
      )}
    </div>
  );
};
