import { Order } from 'types/order.types';

export const getOrderItemStatus = (order: Order, id: string) => {
  const el =
    order.orderOverview.StackInfo.find((stack) => stack.VariantID === id)
      ?.ItemStackStatus || '';

  const status = el.replace('ITEM_STACK_', '');

  return status.toLowerCase();
};
