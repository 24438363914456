export const FileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="20"
    fill="none"
    viewBox="0 0 16 20"
  >
    <path
      fill="#000"
      d="M15.937 6.68c-.011-.032-.02-.063-.033-.094a.997.997 0 00-.196-.293l-6-6a.997.997 0 00-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 00-.259-.051C9.04.011 9.021 0 9 0H2C.897 0 0 .897 0 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V7c0-.021-.011-.04-.013-.062a.987.987 0 00-.05-.258zM12.586 6H10V3.414L12.586 6zM2 18V2h6v5a1 1 0 001 1h5l.002 10H2z"
    ></path>
  </svg>
);
