import { Portal } from 'react-portal';

import { CloseIcon } from 'assets/icons/CloseIcon';

interface Props {
  open: boolean;
  close: () => void;
  title: string;
  children: any;
  className: string;
}

export const Modal = ({ open, close, title, children, className }: Props) => {
  if (!open) return null;

  return (
    <Portal>
      <div className={`modal ${className}`} onMouseDown={close}>
        <div className="modalBox" onMouseDown={(e) => e.stopPropagation()}>
          <div className="modalHeader">
            <p>{title}</p>
            <CloseIcon onClick={close} />
          </div>
          <div className="modalBody">{children}</div>
        </div>
      </div>
    </Portal>
  );
};

Modal.defaultProps = {
  className: '',
};
