import { useState } from 'react';
import { HandleCellOrders } from 'types/table.types';

interface Props {
  nav: string[];
  handleCellOrders?: (value: HandleCellOrders) => void;
}

export const TableHeader = ({ nav, handleCellOrders }: Props) => {
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');

  const handleDirection = (): 'asc' | 'desc' => {
    if (direction === 'asc') return 'desc';
    return 'asc';
  };

  const onClick = (cell: string) => {
    if (!handleCellOrders) return;

    const newDir = handleDirection();
    setDirection(newDir);
    handleCellOrders({ cell, direction: newDir });
  };

  return (
    <div className="tableRow tableHeader">
      {nav.map((cell) => (
        <div key={cell} className="tableCell">
          <span data-tip={cell} onClick={() => onClick(cell)}>
            {cell}
          </span>
        </div>
      ))}
    </div>
  );
};
