/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';

import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { UpdateOrderStatusPayload } from 'api/orders/types';
import { OrderStatusVariants } from 'types/order.types';
import { Org } from 'api/orgs/types';

import { TableCellTooltip } from 'components/shared/table/TableCell';
import { useMissingOrderItems } from 'hooks/useMissingOrderItems';
import { formatOffice, formatOfficeAddress } from 'util/offices';
import { CustomerStatusModal } from './CustomerStatusModal';
import { DownArrowIcon } from 'assets/icons/DownArrow';
import { useOrderStore } from 'stores/OrderStore';
import { DragIcon } from 'assets/icons/DragIcon';
import { Table } from 'components/shared/table';
import { updateOrderStatus } from 'api/orders';
import useTableData from 'hooks/useTableData';
import { formatDate } from 'util/dates';
import { Modal } from 'components/ui/Modal';

interface Props {
  org?: Org;
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

interface State {
  datePlaced: string;
  orderID: string;
  shippingAddress: string;
  officeName: string;
  orgName: string;
  orgID: string;
  customerStatus: OrderStatusVariants;
  opsStatus: OrderStatusVariants;
  name: string;
  userID: string;
  approverEmail: string;
}

export const SummaryTable = ({ dragHandleProps, org }: Props) => {
  const [data, setData] = useState<State[]>([]);
  const [openCustomerStatusModal, setOpenCustomerStatusModal] = useState(false);
  const [openOpsStatusSelect, setOpenOpsStatusSelect] = useState(false);

  const { meta, tableData } = useTableData({ allData: data });
  const { mutate } = useMutation(updateOrderStatus);
  const { order, setOrder } = useOrderStore();
  const { missingOrderItemsLength } = useMissingOrderItems({ order });

  const [securityModal, setSecurityModal] = useState(false);

  useEffect(() => {
    const payload: State = {
      datePlaced: formatDate(order.created),
      orderID: order.orderID,
      shippingAddress: `${formatOffice(order.office)} ${formatOfficeAddress(order.office)}`,
      officeName: order.office.name,
      orgName: org?.metadata.orgName || '',
      orgID: org?.metadata.orgID || '',
      customerStatus: order.status,
      opsStatus: order.status,
      name: order.creatorInfo.Name,
      userID: order.creatorInfo.UserID,
      approverEmail: order.approverEmail || ''
    };

    setData([payload]);
  }, [order]);

  const changeStatus = (status: boolean) => {
    const payload: UpdateOrderStatusPayload = {
      orgID: org?.metadata.orgID || '',
      orderID: order.orderID,
      body: { approve: status }
    };

    mutate(payload, { onSuccess: (res) => setOrder(res) });
  };

  const oldOrderPay = order.payments.find((el) => !el.invoiceID);

  const getCardInfo = () => {
    if (!oldOrderPay) return null;
    if (oldOrderPay?.cardInfo)
      return `Card: ${oldOrderPay.cardInfo.brand}, Exp: ${oldOrderPay.cardInfo.expMonth}/${oldOrderPay.cardInfo.expYear}, Ending: ${oldOrderPay.cardInfo.last4}`;
    else if (oldOrderPay?.checkInfo) {
      return `Check number: ${oldOrderPay.checkInfo.checkNumber}, Date: ${formatDate(oldOrderPay.checkInfo.date)}`;
    }
  };

  const changeStatusOps = (bool: boolean) => {
    changeStatus(bool);
    setOpenOpsStatusSelect(false);
  };

  return (
    <>
      <Table
        name="OrderSummary"
        title={
          <>
            <span {...dragHandleProps}>
              <DragIcon />
            </span>
            Order Summary
          </>
        }
        setData={setData}
        nav={[
          'Date Placed',
          'Order #',
          'Shipping Address',
          'Office Name',
          'Org Name',
          'Creator',
          'Approver',
          'Customer Status',
          'Ops Status',
          'Action'
        ]}
        meta={meta}
        allData={data}
        headerContent={
          oldOrderPay && (
            <div className="badge badgePay success">
              Paid with {oldOrderPay?.cardInfo ? 'Card' : 'Check'}
              <div className="badge-abs">{getCardInfo()}</div>
            </div>
          )
        }
        content={
          <div className="tableBody">
            {tableData.map((el: State) => {
              const isPendingOrDeclined = el.customerStatus === 'pending' || el.customerStatus === 'declined';
              return (
                <div key={el.orderID} className="tableRow">
                  <TableCellTooltip content={el.datePlaced} />
                  <TableCellTooltip content={el.orderID} />
                  <TableCellTooltip content={el.shippingAddress} />
                  <TableCellTooltip content={el.officeName} />
                  <TableCellTooltip content={el.orgName}>
                    <Link to={`/org/${el.orgID}`} className="link">
                      {el.orgName}
                    </Link>
                  </TableCellTooltip>
                  <TableCellTooltip content={el.name}>
                    <Link to={`/user/${el.userID}`} className="link">
                      {el.name}
                    </Link>
                  </TableCellTooltip>
                  <TableCellTooltip content={el.approverEmail}>{el.approverEmail}</TableCellTooltip>
                  <div className="tableCell">
                    <div
                      className={`cur-pointer orderStatus ${el.customerStatus}`}
                      onClick={() => setOpenCustomerStatusModal(true)}
                    >
                      {el.customerStatus}
                      <DownArrowIcon />
                    </div>
                  </div>
                  <div className="tableCell regular danger">
                    <span>{missingOrderItemsLength ? `${missingOrderItemsLength} Sellers Missing Info` : 'Done'}</span>
                  </div>
                  <div className={`tableCell regular ${isPendingOrDeclined ? 'cur-pointer' : ''}`}>
                    <span>
                      {isPendingOrDeclined ? (
                        <>
                          {el.customerStatus === 'pending' ? (
                            <div className="centered" onClick={() => setOpenOpsStatusSelect((p) => !p)}>
                              Approve/Reject <DownArrowIcon />{' '}
                            </div>
                          ) : (
                            <div onClick={() => setSecurityModal(true)}>Approve</div>
                          )}
                        </>
                      ) : (
                        'No actions allowed'
                      )}
                    </span>
                    {openOpsStatusSelect && (
                      <ul>
                        <li onClick={() => changeStatusOps(true)}>Approve Order</li>
                        <li onClick={() => changeStatusOps(false)}>Reject Order</li>
                      </ul>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        }
      />
      {openCustomerStatusModal && <CustomerStatusModal close={() => setOpenCustomerStatusModal(false)} />}

      <Modal
        open={securityModal}
        close={() => setSecurityModal(false)}
        title={`Secuirty check`}
        className="gpoAlertModal"
      >
        <h2>Are you sure you want to approve this already rejected order?</h2>
        <div className="buttons">
          <button
            className="contained primary"
            onClick={() => {
              changeStatus(true);
              setSecurityModal(false);
            }}
          >
            Approve
          </button>
          <button className="contained cancel" onClick={() => setSecurityModal(false)}>
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};
