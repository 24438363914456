import { useFormikContext } from 'formik';
import intersectionBy from 'lodash/intersectionBy';

import { convertPrice, orderItemsTotalPrice } from 'util/prices';
import { FaliamLogoIcon } from 'assets/icons/FaliamLogo';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { Items } from './Items';

interface Props {
  close: () => void;
  button: { text: string; isLoading: boolean };
  children: JSX.Element;
  hiddenOrders?: boolean;
  showingTotal?: boolean;
  canUpdatePrice?: boolean;
  variant?: string;
}

export const FullScreenModal = ({
  close,
  button,
  children,
  hiddenOrders = false,
  showingTotal = false,
  canUpdatePrice = false,
  variant
}: Props) => {
  const { values }: any = useFormikContext();
  const subtotal = orderItemsTotalPrice(intersectionBy(values.allItems, values.selectedItems, (e) => e.item.objectID));
  const otherCosts = values?.otherCosts?.reduce((acc: any, el: any) => acc + el.price, 0);
  const additonalCosts = values.shippingCosts + values.taxCosts + otherCosts;
  const total = subtotal / 100 + additonalCosts;

  return (
    <div className="fullScreenModal">
      <div className="fullScreenModal__Header">
        <FaliamLogoIcon />
        <CloseIcon onClick={close} />
      </div>
      <div className="fullScreenModal__Body customScrollbar">
        <div className="fullScreenModal__Body__Content">
          {children}
          {!hiddenOrders && <Items canUpdatePrice={canUpdatePrice} variant={variant} />}
        </div>
      </div>
      <div className="fullScreenModal__Footer">
        {showingTotal && (
          <div className="sum">
            <p>Subtotal: {convertPrice(subtotal)}</p>
            <p>Total: {convertPrice(total * 100)}</p>
          </div>
        )}
        <button className="contained" type="submit" disabled={button.isLoading}>
          {button.text}
        </button>
      </div>
    </div>
  );
};
