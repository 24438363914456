import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { getSingleGpo } from 'api/gpo';
import { gposKeys } from 'api/gpo/queries';
import { Members } from './members';
import { Partnerships } from './partnerships';
import { UploadPrices } from './UploadPrices';

export const SingleGpoPage = () => {
  const params: any = useParams();
  const gpoID = params.gpoID as string;

  const { data, isLoading, isError, refetch } = useQuery(gposKeys.singleGPO(gpoID), () => getSingleGpo(gpoID), {
    enabled: gpoID ? true : false
  });

  if (isLoading) return <div>Loading...</div>;
  else if (isError || !data) return <div>Something went wrong</div>;
  return (
    <div className="singleGpoPageWrapper">
      <div className="flexTop">
        <h1>
          GPO ID: <span>{data.id}</span>
        </h1>
        <div className="row">
          <Link className="outlined lg" target="_blank" to={`/health/gpo?gpoID=${gpoID}`}>
            GPO Health Page
          </Link>
          <UploadPrices gpoID={gpoID} />
        </div>
      </div>
      <h2 className="gpoNameSubtitle">
        <img src={data.image} alt={data.name} width={50} height={50} />
        {data.name}
      </h2>
      <Members
        gpoName={data?.name}
        members={data.membership || []}
        partners={data.partners || []}
        gpoID={gpoID}
        refetch={refetch}
      />
      <Partnerships partners={data.partners || []} gpoID={gpoID} refetch={refetch} />
    </div>
  );
};
