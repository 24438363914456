import { useEffect, useState } from 'react';
import { OrgGpo } from 'api/orgs/types';
import useTableData from 'hooks/useTableData';
import { Table } from 'components/shared/table';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { Link } from 'react-router-dom';

type Props = {
  gpos: OrgGpo[];
  href?: string;
};

export const OrgGposTable = ({ gpos, href }: Props) => {
  const [data, setData] = useState<OrgGpo[]>([]);
  const { meta, tableData } = useTableData({ allData: data });

  useEffect(() => {
    if (gpos) setData(gpos);
  }, [gpos]);

  return (
    <>
      <div className="space" />
      <Table
        name="OrgGpos"
        title="Organization GPO's"
        nav={['ID', 'Name']}
        allData={gpos}
        setData={setData}
        meta={meta}
        content={
          <div className="tableBody customScrollbar">
            {tableData.map((gpo) => (
              <div key={gpo.id} className="tableRow">
                <TableCellTooltip content={gpo.id}>
                  <Link to={`${href}?gpoID=${gpo.id}`} target="_blank" className="link">
                    {gpo.id}
                  </Link>
                </TableCellTooltip>
                <TableCellTooltip content={gpo.name} />
              </div>
            ))}
          </div>
        }
      />
    </>
  );
};
