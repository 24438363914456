import { useState } from 'react';
import PhoneInput2 from 'react-phone-input-2';

import { RepresentativeWithExtension } from 'api/orgs/types';

type Props = {
  initialValue?: RepresentativeWithExtension;
  isLoading?: boolean;
  onAction: (values: RepresentativeWithExtension) => void;
  close: () => void;
};

export const RepresentativeActionForm = ({ initialValue, close, isLoading, onAction }: Props) => {
  const [newRep, setNewRep] = useState<RepresentativeWithExtension>(initialValue || { email: '' });

  return (
    <>
      <Input
        label="Representative's email"
        type="email"
        name="email"
        value={newRep.email}
        onChange={(e: any) => setNewRep((p) => ({ ...p, email: e.target.value }))}
      />

      <div className={`input-field `}>
        <label>Representative's phone</label>
        <PhoneInput2
          country="us"
          onlyCountries={['us']}
          inputProps={{ name: 'phone', country: 'us' }}
          value={newRep.phone}
          onChange={(value) => setNewRep({ ...newRep, phone: value.length ? `+${value}` : '' })}
          containerClass="faliamPhoneInput"
          disableDropdown
        />
      </div>

      <Input
        label="Representative's phone extension"
        name="extension"
        type="text"
        value={newRep.extension}
        onChange={(e: any) => setNewRep((p) => ({ ...p, extension: e.target.value }))}
      />

      <div className="buttons-grid">
        <button type="button" className="contained" disabled={isLoading} onClick={() => onAction(newRep)}>
          Save Changes
        </button>
        <button className="outlined" type="button" onClick={close}>
          Cancel
        </button>
      </div>
    </>
  );
};

type InputProps = { label: string } & any;

const Input = ({ label, ...rest }: InputProps) => (
  <div className={`input-field`}>
    <label>{label}</label>
    <input {...rest} />
  </div>
);
