import { Modal } from 'components/ui/Modal';

interface Props {
  close: () => void;
  variant: 'member' | 'partner';
  onDelete: () => void;
}

export const AlertModal = ({ close, variant, onDelete }: Props) => (
  <Modal open={true} close={close} title={`Remove ${variant}`} className="gpoAlertModal">
    <h2>Are you sure you want to remove this {variant} from this GPO?</h2>
    <div className="buttons">
      <button className="contained danger" onClick={onDelete}>
        Remove {variant}
      </button>
      <button className="contained cancel" onClick={close}>
        Cancel
      </button>
    </div>
  </Modal>
);
