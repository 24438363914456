import { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import Calendar from 'react-calendar';

import { DownArrowIcon } from 'assets/icons/DownArrow';
import { TrashIcon } from 'assets/icons/TrashIcon';
import { isActiveClass } from 'util/classes';
import { Modal } from 'components/ui/Modal';
import { faliamCarriers } from 'util/data';
import { formatDate } from 'util/dates';

export const AdditionalTrackingNumbers = () => {
  const [isOpen, setIsOpen] = useState(-1);
  const [isOpenCalendar, setIsOpenCalendar] = useState(-1);
  const { values, setValues, setFieldValue }: any = useFormikContext();

  const addRow = () => {
    setValues({
      ...values,
      additionalTrackingNumbers: [
        ...values.additionalTrackingNumbers,
        { TrackingNumber: '', Carrier: '', Eta: new Date() }
      ]
    });
  };

  const removeRow = (values: any, index: number) => {
    setFieldValue(
      'additionalTrackingNumbers',
      values.additionalTrackingNumbers.filter((_: any, i: number) => index !== i)
    );
  };

  const onSelectCarrier = (carrier: string, index: number) => {
    setFieldValue(`additionalTrackingNumbers[${index}].Carrier`, carrier);
    setIsOpen(-1);
  };

  return (
    <div className="additionalTrackingNumbers">
      {values.additionalTrackingNumbers.map((_: any, i: number) => (
        <div key={i} className="formikRow">
          <div className="field">
            <label>Tracking Number</label>
            <Field
              type="string"
              name={`additionalTrackingNumbers[${i}].TrackingNumber`}
              onChange={(e: any) => {
                const value = e.target.value;

                if (value.includes(',')) {
                  const trackingNumbers = value.split(',').map((trackingNumber: string) => ({
                    TrackingNumber: trackingNumber.trim(),
                    Carrier: '',
                    Eta: new Date()
                  }));
                  setFieldValue('additionalTrackingNumbers', trackingNumbers);
                } else {
                  setFieldValue(`additionalTrackingNumbers[${i}].TrackingNumber`, value);
                }
              }}
            />
          </div>

          <div className="field">
            <label>Carrier</label>
            <div className={isActiveClass('select regular', isOpen === i)}>
              <div className="selectPreview" onClick={() => setIsOpen((p) => (p === i ? -1 : i))}>
                <p>
                  {
                    faliamCarriers.find((carrier) => carrier.value === values.additionalTrackingNumbers[i].Carrier)
                      ?.label
                  }
                </p>
                <DownArrowIcon />
              </div>
              <div className="selectOptions">
                <ul>
                  {faliamCarriers.map((carrier) => (
                    <li
                      key={carrier.value}
                      onClick={() => onSelectCarrier(carrier.value, i)}
                      className={isActiveClass('', values.carrier === carrier.value)}
                    >
                      {carrier.label}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {values.additionalTrackingNumbers[i].Carrier === 'faliam' && (
            <div className="field" onClick={() => setIsOpenCalendar(i)}>
              <label>EDD</label>
              <div className="fakeField">{formatDate(values.additionalTrackingNumbers[i].Eta)}</div>
            </div>
          )}
          {values.additionalTrackingNumbers.length > 1 && (
            <button type="button" className="rounded lg">
              <TrashIcon onClick={() => removeRow(values, i)} />
            </button>
          )}
        </div>
      ))}

      <button type="button" className="outlined lg mt24" onClick={addRow}>
        Add Another Tracking #
      </button>
      {isOpenCalendar !== -1 && (
        <Modal open={true} close={() => setIsOpenCalendar(-1)} title="Select Date" className="ordersDatePicker">
          <Calendar
            onChange={(date: Date) => {
              setFieldValue(`additionalTrackingNumbers[${isOpenCalendar}].Eta`, date);
              setIsOpenCalendar(-1);
            }}
            value={values.date}
          />
        </Modal>
      )}
    </div>
  );
};
