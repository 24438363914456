import { InputField } from 'components/ui/Input';

interface Props {
  formik: any;
}

export const ImagesPart = ({ formik }: Props) => (
  <div className="paper">
    <div className="paperHeader">
      <h2>Images</h2>
    </div>
    <div className="paperBody">
      {formik.values.images.map((_: any, i: number) => (
        <div key={i} className="urlRow">
          <InputField formik={formik} name={`images.${i}`} label={`URL ${i + 1}`} placeholder="Image URL" />
          <button
            type="button"
            className="outlined danger sm"
            onClick={() =>
              formik.setFieldValue(
                'images',
                formik.values.images.filter((_: any, index: number) => index !== i)
              )
            }
          >
            Remove
          </button>
        </div>
      ))}
      <button
        type="button"
        className="outlined lg w-fit"
        onClick={() => formik.setFieldValue('images', [...formik.values.images, ''])}
      >
        Add new URL
      </button>
    </div>
  </div>
);
