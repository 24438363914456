import { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import orderBy from 'lodash/orderBy';

import { GpoMember, GpoPartner } from 'api/gpo/types';

import { SearchIcon } from 'assets/icons/SearchIcon';
import { AddNewMember } from './addNewMember';
import { AlertModal } from '../AlertModal';
import { deleteGpoMember } from 'api/gpo';
import { Link } from 'react-router-dom';
import { RepresentativeCredentialsModal } from './addRepresentative/RepresentativeCredentialsModal';

interface Props {
  gpoID: string;
  gpoName?: string;
  refetch: () => void;
  members: GpoMember[];
  partners: GpoPartner[];
}

export const Members = ({ members, gpoID, refetch, partners, gpoName }: Props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [alertModal, setAlertModal] = useState('');
  const [isOpenNewMemberForm, setIsOpenNewMemberForm] = useState(false);

  const [memberForCredentials, setMemnerForCredentials] = useState<GpoMember | null>(null);

  const { mutate } = useMutation(deleteGpoMember);

  const onDelete = () => {
    if (!alertModal) return;

    mutate(
      { id: gpoID, orgID: alertModal },
      {
        onSuccess: () => {
          refetch();
          setAlertModal('');
        }
      }
    );
  };

  const filteredMembers = useMemo(() => {
    return members.filter((item) => item.orgName.toLowerCase().includes(searchQuery.toLowerCase()));
  }, [members, searchQuery]);

  return (
    <>
      <div className="gpoTable">
        <div className="gpoTableHero">
          <div className="left">
            <h3>Members</h3>
            <div className="searchInput">
              <input
                type="search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search..."
              />
              <SearchIcon />
            </div>
          </div>
          <button className="outlined lg" onClick={() => setIsOpenNewMemberForm(true)}>
            Add Member
          </button>
        </div>
        <div className="gpoTableBody members">
          <div className="row headRow">
            <div className="cell">Org #</div>
            <div className="cell">Org Name</div>
            <div className="cell">Status</div>
            <div className="cell"></div>
          </div>
          {filteredMembers.length ? (
            orderBy(filteredMembers, ['orgName'], ['asc']).map((member) => (
              <div className="row" key={member.orgID}>
                <div className="cell linkCell">
                  <Link to={`/org/${member.orgID}`}>{member.orgID}</Link>
                </div>
                <div className="cell">{member.orgName}</div>
                <div className="cell">
                  <span className={`badge ${member.validated ? 'success' : 'secondary'}`}>
                    {member.validated ? 'Validated' : 'Pending'}
                  </span>
                </div>
                <div className="cell flex">
                  <button className="outlined lg" onClick={() => setMemnerForCredentials(member)}>
                    Credentials
                  </button>
                  <button className="outlined lg danger" onClick={() => setAlertModal(member.orgID)}>
                    Remove
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="noDataParagraph">No members...</p>
          )}
        </div>
      </div>
      {alertModal && <AlertModal variant="member" close={() => setAlertModal('')} onDelete={onDelete} />}
      {isOpenNewMemberForm && (
        <AddNewMember members={members} id={gpoID} refetch={refetch} close={() => setIsOpenNewMemberForm(false)} />
      )}

      {memberForCredentials && (
        <RepresentativeCredentialsModal
          gpoID={gpoID}
          gpoName={gpoName}
          close={() => setMemnerForCredentials(null)}
          member={memberForCredentials}
          partners={partners}
          refetch={refetch}
        />
      )}
    </>
  );
};
