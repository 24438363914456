import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import orderBy from 'lodash/orderBy';
import dayjs from 'dayjs';

import { BudgetPayload, Org, Restriction } from 'api/orgs/types';
import { HandleCellOrders } from 'types/table.types';
import { OrgOrder } from 'types/orgs.types';

import { updateOrgBudget } from 'api/orgs';
import { convertPrice } from 'util/prices';
import useTableData from 'hooks/useTableData';
import { formatOfficeAddress } from 'util/offices';
import { Table } from 'components/shared/table';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { TableDropdownCell } from 'components/shared/table/TableDropdownCell';
import { BudgetFormModal } from './BudgetFormModal';
import { useOutsideClick } from 'hooks/useOutsideClick';

interface Props {
  orders: OrgOrder[];
  org: Org;
  refetchOrg: () => void;
}

export const BudgetsTable = ({ org, orders, refetchOrg }: Props) => {
  const [selectedCell, setSelectedCell] = useState('');
  const [isOpenModal, setIsOpenModal] = useState<boolean | { budget: number; officeID: string }>(false);

  const [data, setData] = useState<Restriction[]>([]);

  const { meta, tableData } = useTableData({ allData: data });
  const { mutate: deleteBudget } = useMutation(updateOrgBudget);
  const orgID = org.metadata.orgID;

  const dotsRef = useRef(null);
  useOutsideClick(dotsRef, () => selectedCell && setSelectedCell(''));

  const allData = orderBy(
    org.restrictions.map((item) => ({
      ...item,
      office: org.offices.find(({ officeID }) => officeID === item.officeID)
    })),
    ['january.total'],
    ['desc']
  );

  useEffect(() => {
    setData(allData);
  }, [org.restrictions]);

  const handleCellOrders = ({ cell, direction }: HandleCellOrders) => {
    let res: any[] = [];

    switch (cell) {
      case 'Total Budget':
        res = orderBy(org.restrictions, (e) => e.january.total, direction);
        break;
      default:
        res = data;
        break;
    }

    setData(res);
  };

  const getOrdersByOffice = (officeID: string) =>
    orders.filter(
      (el) =>
        el.office.officeID === officeID &&
        dayjs(el.created) > dayjs().startOf('month') &&
        dayjs(el.created) < dayjs().endOf('month')
    );

  const onDelete = (officeID: string) => {
    const payload: BudgetPayload = {
      orgID,
      budget: null,
      officeID,
      type: 'delete'
    };

    deleteBudget(payload, {
      onSuccess: () => {
        setSelectedCell('');
        refetchOrg();
      }
    });
  };

  return (
    <>
      <div className="space" />
      <Table
        name="Budgets"
        title="Budgets"
        nav={['Office Name', 'Address', 'Orders this month', 'Monthly spent', 'Total Budget', '']}
        handleCellOrders={handleCellOrders}
        allData={allData}
        setData={setData}
        searchKeys={['office.name']}
        meta={meta}
        headerContent={
          <button className="outlined lg" onClick={() => setIsOpenModal(true)}>
            Add Budget
          </button>
        }
        content={
          <div className="tableBody customScrollbar">
            {tableData.map((item) => {
              return (
                <div key={item.officeID} className="tableRow">
                  <TableCellTooltip content={item.office ? item.office.name : ''} />
                  <TableCellTooltip content={item.office ? formatOfficeAddress(item.office) : ''} />
                  <TableCellTooltip content={getOrdersByOffice(item.officeID).length} />
                  <TableCellTooltip
                    content={convertPrice(getOrdersByOffice(item.officeID).reduce((sum, item) => sum + item.total, 0))}
                  />
                  <TableCellTooltip content={convertPrice(item.january.total)} />
                  <TableDropdownCell id={item.officeID} active={selectedCell} onClick={setSelectedCell} ref={dotsRef}>
                    <li
                      onClick={() =>
                        setIsOpenModal({
                          officeID: item.officeID,
                          budget: item.january.total / 100
                        })
                      }
                    >
                      Edit Budget
                    </li>
                    <li className="danger" onClick={() => onDelete(item.officeID)}>
                      Delete Budget
                    </li>
                  </TableDropdownCell>
                </div>
              );
            })}
          </div>
        }
      />
      {isOpenModal && (
        <BudgetFormModal
          org={org}
          data={typeof isOpenModal !== 'boolean' && isOpenModal}
          refetchOrg={refetchOrg}
          close={() => setIsOpenModal(false)}
        />
      )}
    </>
  );
};
