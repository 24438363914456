import { InstantSearch } from 'react-instantsearch-hooks';

import { adminSearchClient, algoliaKeys } from 'lib/algolia/aloglia-config';
import { ListOfOrgs } from './ListOfOrgs';
import { Modal } from 'components/ui/Modal';
import { GpoMember } from 'api/gpo/types';

interface Props {
  id: string;
  members: GpoMember[];
  close: () => void;
  refetch: () => void;
}

export const AddNewMember = ({ id, refetch, close, members }: Props) => (
  <Modal open={true} close={close} title="Add New Member" className="addNewMemberModal">
    <InstantSearch searchClient={adminSearchClient} indexName={algoliaKeys.indexName.organizations}>
      <ListOfOrgs id={id} refetch={refetch} members={members} />
    </InstantSearch>
  </Modal>
);
