import { useProvidersStrore } from 'stores/ProvidersStore';

export const useSupplierLogo = () => {
  const { providers } = useProvidersStrore();

  const getSupplierLogo = (name: string) =>
    providers.find((provider) => provider.name === name)?.image || '';

  return {
    getSupplierLogo,
  };
};
